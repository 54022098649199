#neworder,
.tender-form {
  $radio-label-mb: 8px;
  $content-margin: 28px;

  .form-etd-group {
    *:not(.radio-etd, .radio-periodic) {
      transition-property: none;
    }
    .form-item-name-etd,
    .form-etd-etd-radio-label {
      position: initial;
      transition-property: none;
    }
    .ant-radio {
      position: relative;
      top: 3px;
    }
  }
  .title,
  .description {
    white-space: break-spaces;
  }
  .title {
    font-size: large;
    @extend %bold;
    color: $all-black;
  }
  .description {
    @extend %regular;
    color: $light-gray;
  }
  .form-etd-etd,
  .form-etd-periodic {
    margin-left: $content-margin;
  }
  .form-etd-group {
    display: flex;
    flex-direction: column;

    * {
      transition-delay: 0;
    }
  }
  .etd-price-volume {
    position: relative;
    display: flex;
    flex-direction: column;
  }
  .etd-price-volume .ant-radio-wrapper {
    display: flex;
    align-items: flex-start;
    gap: 10px;

    :nth-child(2) {
      padding: 0;
    }
  }
  .form-etd-etd-radio-label,
  .form-etd-periodic-radio-label,
  .form-item-name-etd label {
    @extend %bold;
    border: solid 1px transparent; // Align with form items.
    margin-bottom: $radio-label-mb;
    color: $all-black;

    span.popover-link {
      position: absolute;
      right: 0;
      z-index: 2;
    }
  }
  .form-etd-periodic-period {
    position: relative;
    display: flex;
    gap: 2%;
    .form-item-name-deliveryfrequency {
      margin-right: 1%;
    }
    .form-item-name-totaldeliveries {
      margin-right: 1%;
    }
    .form-item-name-deliveryfrequency,
    .form-item-name-totaldeliveries {
      flex-grow: 1;
      display: inline-block;

      .ant-form-item-children {
        position: relative;

        #deliveryfrequency > div,
        .ant-input-number {
          width: 100%;
          height: 40px;
        }
        .ant-select-selection {
          border-radius: 4px;
        }
      }
    }
  }
  .etd-price-volume .ant-form-item-label {
    white-space: break-spaces;
  }
  .etd-mode-etd {
    .form-item-name-etd .ant-form-item-label {
      position: absolute;
      transform: translate(0, -100%);
    }
  }
  // Open / close radio content.
  .etd-mode-etd {
    .form-etd-etd {
      max-height: 56px;
    }
  }
  .etd-mode-periodic {
    .form-etd-periodic {
      transition-property: max-height;
      max-height: 172px;

      &.transition-init {
        overflow: hidden;

        &.transition-closed {
          max-height: 0;
        }
      }
    }
  }
  // Patch for radio position adjustment.
  .etd-mode-etd {
    .form-etd-etd-radio-label {
      opacity: 0;
      pointer-events: none;
    }
  }
  .etd-mode-periodic {
    .form-item-name-etd .ant-form-item-label {
      opacity: 0;
      pointer-events: none;
    }
    .form-etd-etd-radio-label {
      opacity: 1;
    }
  }
  // Patch for even widths.
  .form-item-name-deliveryfrequency,
  .form-item-name-totaldeliveries {
    max-width: 49%;
  }
  // Patch title / description font.
  .form-etd-periodic {
    label {
      @extend %regular;
      color: $light-gray;
    }
  }
}
