// status
.status-cancelled,
.status-expired,
.status-reverted,
.status-traded,
.status-working,
.status-busted,

// new customers
.status-active,
.status-requested,
.status-processed,

.status-incomplete,
.status-pending,
.status-rejected,

// type
.type-bid,
.type-offer,
.type-sell,
.type-buy {
  background: $white;
  border: 1px solid $lightest-gray;
  border-radius: 100px;
  color: $light-gray;
  display: inline-block;
  font-size: 0.65rem;
  line-height: 1.2rem;
  padding: 0 10px;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  width: 100%;

  &.inline {
    width: auto !important;
  }

  &.tender-overview-page {
    height: 1.2rem;
  }
}

.type-bid {
  background: $bid;
  border-color: $bid;
  color: $white;

  &.counteronly {
    background: lighten($bid, 52%);
    color: $bid;
  }
}
.type-offer {
  background: $offer;
  border-color: $offer;
  color: $white;

  &.counteronly {
    background: lighten($offer, 48%);
    color: $offer;
  }
}
.type-buy {
  background: $white;
  border-color: $bid;
  color: $light-gray;
  vertical-align: middle;
}
.type-sell {
  background: $white;
  border-color: $offer;
  color: $light-gray;
  vertical-align: middle;
}

.status-declined {
  color: $error;

  &:before {
    @extend %fontello;
    color: $error;
    content: '\e814';
    display: inline-block;
    font-size: 0.9rem;
    margin: 0 5px 0 0;
    position: relative;
  }
}

.status-cancelled,
.status-rejected {
  border-color: lighten($status-cancelled, 25%);
  color: $status-cancelled;
}

.status-expired {
  border-color: lighten($status-expired, 20%);
  color: $status-expired;
}

.status-reverted {
  border-color: lighten($status-reverted, 40%);
  color: $status-reverted;
}

.status-incomplete {
  border-color: lighten($highlight, 40%);
  color: $highlight;
}

.status-traded {
  border-color: lighten($status-traded, 40%);
  color: $status-traded;
}

.status-working,
.status-processed {
  border-color: lighten($status-working, 40%);
  color: $status-working;
}

.status-active {
  background: $status-working;
  border-color: $status-working;
  color: $white;
}

.status-busted,
.status-requested,
.status-pending {
  border-color: lighten($status-busted, 10%);
  color: $status-busted;
}
