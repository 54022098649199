﻿// nui notifications

// help text (?) styling
.popover-link {
  @extend %nd;

  cursor: help;
  display: inline-block;
  font-size: 0;
  margin: 0;
  vertical-align: middle;

  &:before {
    @extend %fontello;
    color: rgba(0, 0, 0, 0.25);
    content: '\e805';
    font-size: 1.2rem;
    margin: 0;
  }
  &:hover {
    &:before {
      color: rgba(0, 0, 0, 0.5);
    }
  }
}

// specific for order form
.order-form .form-item-type-Boolean .ant-form-item-label {
  overflow: visible;
  position: static;

  label {
    position: static;
  }
  .popover-link {
    position: absolute;
    right: 0;
    top: 20px;
    z-index: 2;
  }
}

.order-form
  .form-item-type-Boolean
  + .form-item-type-Boolean
  .ant-form-item-label {
  .popover-link {
    top: 10px;
  }
}

.notifications-fieldset {
  border-bottom: 1px solid darken($hr, 10%);
  border-top: 1px solid darken($hr, 10%);
  margin: 10px 0 15px;
  padding-bottom: 6px;

  h4 {
    margin: 10px 0;
  }
  > div.no-ws {
    box-shadow: 0 -1px 0 darken($hr, 10%) inset;
  }
  .notification-set {
    border-bottom: 1px solid darken($hr, 10%);
    border-right: 1px solid darken($hr, 10%);
    display: inline-block;
    margin: 0;
    min-height: 40px;
    padding: 10px 0;
    position: relative;
    vertical-align: top;
    width: 50%;

    .popover-link {
      @include pos-right(13, 9);
      z-index: 10;
    }

    &:nth-child(2n) {
      border-right: 0;
      padding-left: 10px;

      .pop-panel {
        padding: 10px 10px 10px 15px;
        right: -10px;
      }
    }
    &.all {
      span {
        @extend %bold;
        color: $all-black;
      }
    }

    // no user select
    label {
      user-select: none;
      -webkit-user-select: none;
      display: block;
      max-width: calc(100% - 32px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  // pop panels
  .show-pop-panel {
    color: $mid-gray;
    cursor: pointer;
    font-size: 1.25rem;
    line-height: 14px;
    margin-top: 2px;
    opacity: 0.35;
    position: absolute;
    right: 5px;
    top: 10px;
    transition: 0.2s all ease;
    z-index: 3;

    &:hover {
      opacity: 1;
    }
  }

  .pop-panel {
    background: $white;
    border: 1px solid $hr;
    border-radius: 2px;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 10px;
    position: absolute;
    right: -5px;
    top: 40px;
    width: calc(100% + 15px);
    z-index: 4;

    label {
      max-width: 100%;
      overflow: visible;
      text-overflow: none;
      white-space: normal;
    }

    .closeme {
      cursor: pointer;
      font-size: 0.8rem;
    }
  }
}

// tabs
html .sub-configuration {
  .ant-tabs {
    overflow: visible;

    .ant-tabs-nav-wrap {
      margin: 0 0 15px;
      padding: 0;
    }
  }
  h4 {
    font-size: 1rem;
  }
}

.timezone-alert {
  padding-left: 45px;

  &:before {
    @extend %fontello;
    display: inline;
    content: '\e80d';
    color: $warning;
    font-size: 1.35rem;
    left: 9px;
    position: absolute;
  }
}
