// popovers (used in presets only! - I think!)
.ant-popover {
  .ant-popover-inner {
    background: $white;
    border: 1px solid $lightest-gray;
    border-radius: 3px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.15);
  }
  .ant-popover-title {
    @extend %bold;
    color: $all-black;
  }
}
