// progress meter
.progress {
  display: block;
  margin-bottom: 20px;
  vertical-align: top;
  white-space: nowrap;

  @include min-screen($min-large) {
    float: right;
    margin: 10px 0;
  }
  .ant-progress-line {
    width: calc(100% - 15px);

    @include min-screen($min-small) {
      display: inline-block;
      margin: 0;
      width: 360px;
    }
    @include min-screen($min-large) {
      width: 260;
    }
  }
  p.vol {
    display: block;
    font-size: 0.7rem;
    margin: 0 10px 0 0;
    padding: 0;

    strong {
      color: $all-black;
    }
    @include min-screen($min-xlarge) {
      display: inline-block;
    }
  }
}

.progress {
  .ant-progress {
    .ant-progress-outer {
      .ant-progress-inner {
        background-color: rgba(0, 0, 0, 0.1);
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1) inset;
        height: 12px;

        .ant-progress-bg {
          background-color: var(--link);
          border-radius: 100px;
          height: 12px !important;
        }
      }
    }
    .ant-progress-text {
      font-size: 0.7rem;
    }
  }
}
