﻿// footer
.footer {
  background: #f4f4f4;
  margin-top: -1px;
  min-width: 320px;
  padding: 20px 0 90px;
  position: relative;
  z-index: 0;

  @include min-screen($min-medium) {
    padding: 20px 0 70px;
  }
  .logo {
    padding: 10px 0 0 50px;

    img {
      display: inline-block;
      left: 0;
      margin-right: 10px;
      opacity: 0.75;
      position: absolute;
      top: 13px;
      vertical-align: top;
      width: 40px;
    }
    p {
      color: $mid-gray;
      display: inline-block;
      font-size: 0.7rem;
      line-height: 1.1rem;
      margin: 0;
      overflow: hidden;
      padding: 0;
      vertical-align: top;

      strong {
        display: block;
        font-size: 0.85rem;
        margin: 14px 0 0;

        span {
          @extend %regular;
          color: $mid-gray;
        }
      }
    }
  }
  .copy {
    display: inline-block;
    font-size: 0.85rem;
    margin: 0 30px 0 0;

    .companyname {
      display: block;
      @include min-screen($min-xlarge) {
        display: inline-block;
        padding-right: 20px;
      }
    }
    a {
      white-space: nowrap;
    }
  }

  .applinks {
    font-size: 0.85rem;
    padding: 0;

    a:not(.imglink) {
      white-space: nowrap;
    }
    a.imglink {
      @extend %nd;
    }
    img {
      display: inline-block;
      margin: 10px 10px 10px 0;
      width: 120px;
    }
    @include min-screen($min-medium) {
      text-align: right;
      img {
        margin: 10px 0 10px 10px;
      }
    }
  }
}

body.loaded,
body.reverse {
  background: $white;
  .footer {
    background: $white;
  }
}

// Cols
.xs24.md16.lg14,
.xs24.md8.lg10,
.xs24.md12.md12push,
.xs24.md12.md12pull {
  display: block;
  position: relative;
  width: 100%;

  &:after {
    clear: both;
    content: ' ';
    display: block;
    height: 0;
    visibility: hidden;
  }
  @include min-screen($min-medium) {
    float: left;
    width: 66.66666667%;
  }
  @include min-screen($min-large) {
    width: 58.33333333%;
  }
}

.xs24.md8.lg10 {
  @include min-screen($min-medium) {
    width: 33.33333333%;
  }
  @include min-screen($min-large) {
    width: 41.66666667%;
  }
}

// 2nd row
.xs24.md12.md12push,
.xs24.md12.md12pull {
  @include min-screen($min-medium) {
    width: 50%;
  }
  @include min-screen($min-large) {
    width: 50%;
  }
}

.xs24.md12.md12push {
  @include min-screen($min-medium) {
    left: 50%;
  }
}

.xs24.md12.md12pull {
  @include min-screen($min-medium) {
    right: 50%;
  }
}
