.duplicate-order {
  .duplicate-order-content {
    padding: 0 0 10px;

    .nui-alert {
      margin: 0 0 10px;
    }

    .index-name-summary {
      margin-top: 0 !important;
    }
  }

  .modal-footer {
    border-top: 1px solid $hr;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px 0 0;
  }
}
