// config

// assets folder
$assets-path: '../assets';

%clearfix {
  clear: both;
  content: ' ';
  display: block;
  height: 0;
  visibility: hidden;
}

// media queries
@mixin max-screen($res) {
  @media screen and (max-width: $res) {
    @content;
  }
}

@mixin min-screen($res) {
  @media screen and (min-width: $res) {
    @content;
  }
}

// breakpoints
$max-xxsmall: 420px;

// breakpoints
$min-xsmall: 320px;
$max-xsmall: 575px;

$min-small: 576px;
$max-small: 767px;

$min-medium: 768px;
$max-medium: 991px;

$min-mlarge: 880px;
$max-mlarge: 991px;

$min-large: 992px;
$max-large: 1199px;

$min-mxlarge: 1096px;
$max-mxlarge: 1199px;

$min-xlarge: 1200px;
$max-xlarge: 1600px;

$min-xxlarge: 1610px;
$min-xxxlarge: 1750px;

// responsive table
$max-rt: 768px;
$min-rt: 769px;

// fonts
$fontpath: '#{$assets-path}/font';

// fontello
[class^='icon-']:before,
[class*=' icon-']:before,
%fontello {
  display: inline-block;
  font-family: 'fontello';
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1em;
  margin-left: 0.2em;
  margin-right: 0.2em;
  speak: none;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  width: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'fontello';
  src: url('#{$fontpath}/fontello/fontello.svg#fontello') format('svg');
  src: url('#{$fontpath}/fontello/fontello.eot');
  src: url('#{$fontpath}/fontello/fontello.eot?#iefix')
      format('embedded-opentype'),
    url('#{$fontpath}/fontello/fontello.woff') format('woff'),
    url('#{$fontpath}/fontello/fontello.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'open_sansregular';
  src: url('#{$fontpath}/opensans/opensans-regular-webfont.svg#open_sansregular')
    format('svg');
  src: url('#{$fontpath}/opensans/opensans-regular-webfont.eot');
  src: url('#{$fontpath}/opensans/opensans-regular-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('#{$fontpath}/opensans/opensans-regular-webfont.woff2') format('woff2'),
    url('#{$fontpath}/opensans/opensans-regular-webfont.woff') format('woff'),
    url('#{$fontpath}/opensans/opensans-regular-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'open_sanssemibold';
  src: url('#{$fontpath}/opensans/opensans-semibold-webfont.svg#open_sanssemibold')
    format('svg');
  src: url('#{$fontpath}/opensans/opensans-semibold-webfont.eot');
  src: url('#{$fontpath}/opensans/opensans-semibold-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('#{$fontpath}/opensans/opensans-semibold-webfont.woff2') format('woff2'),
    url('#{$fontpath}/opensans/opensans-semibold-webfont.woff') format('woff'),
    url('#{$fontpath}/opensans/opensans-semibold-webfont.ttf')
      format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'open_sans_lightregular';
  src: url('#{$fontpath}/opensans/opensans-light-webfont.svg#open_sans_lightregular')
    format('svg');
  src: url('#{$fontpath}/opensans/opensans-light-webfont.eot');
  src: url('#{$fontpath}/opensans/opensans-light-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('#{$fontpath}/opensans/opensans-light-webfont.woff2') format('woff2'),
    url('#{$fontpath}/opensans/opensans-light-webfont.woff') format('woff'),
    url('#{$fontpath}/opensans/opensans-light-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'open_sans_italic';
  src: url('#{$fontpath}/opensans/opensans-italic-webfont.svg#open_sans_italic')
    format('svg');
  src: url('#{$fontpath}/opensans/opensans-italic-webfont.eot');
  src: url('#{$fontpath}/opensans/opensans-italic-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('#{$fontpath}/opensans/opensans-italic-webfont.woff') format('woff'),
    url('#{$fontpath}/opensans/opensans-italic-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'poppins_extra_light';
  src: url('#{$fontpath}/poppins/poppins-extralight-webfont.eot');
  src: url('#{$fontpath}/poppins/poppins-extralight-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('#{$fontpath}/poppins/poppins-extralight-webfont.woff2') format('woff2'),
    url('#{$fontpath}/poppins/poppins-extralight-webfont.woff') format('woff'),
    url('#{$fontpath}/poppins/poppins-extralight-webfont.ttf')
      format('truetype'),
    url('#{$fontpath}/poppins/poppins-extralight-webfont.svg#poppinsextralight')
      format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'poppins_extra_light_italic';
  src: url('#{$fontpath}/poppins/poppins-extralightitalic-webfont.eot');
  src: url('#{$fontpath}/poppins/poppins-extralightitalic-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('#{$fontpath}/poppins/poppins-extralightitalic-webfont.woff2')
      format('woff2'),
    url('#{$fontpath}/poppins/poppins-extralightitalic-webfont.woff')
      format('woff'),
    url('#{$fontpath}/poppins/poppins-extralightitalic-webfont.ttf')
      format('truetype'),
    url('#{$fontpath}/poppins/poppins-extralightitalic-webfont.svg#poppinsextralight_italic')
      format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'poppins_italic';
  src: url('#{$fontpath}/poppins/poppins-italic-webfont.eot');
  src: url('#{$fontpath}/poppins/poppins-italic-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('#{$fontpath}/poppins/poppins-italic-webfont.woff2') format('woff2'),
    url('#{$fontpath}/poppins/poppins-italic-webfont.woff') format('woff'),
    url('#{$fontpath}/poppins/poppins-italic-webfont.ttf') format('truetype'),
    url('#{$fontpath}/poppins/poppins-italic-webfont.svg#poppinsitalic')
      format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'poppins_light';
  src: url('#{$fontpath}/poppins/poppins-light-webfont.eot');
  src: url('#{$fontpath}/poppins/poppins-light-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('#{$fontpath}/poppins/poppins-light-webfont.woff2') format('woff2'),
    url('#{$fontpath}/poppins/poppins-light-webfont.woff') format('woff'),
    url('#{$fontpath}/poppins/poppins-light-webfont.ttf') format('truetype'),
    url('#{$fontpath}/poppins/poppins-light-webfont.svg#poppinslight')
      format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'poppins_light_italic';
  src: url('#{$fontpath}/poppins/poppins-lightitalic-webfont.eot');
  src: url('#{$fontpath}/poppins/poppins-lightitalic-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('#{$fontpath}/poppins/poppins-lightitalic-webfont.woff2')
      format('woff2'),
    url('#{$fontpath}/poppins/poppins-lightitalic-webfont.woff') format('woff'),
    url('#{$fontpath}/poppins/poppins-lightitalic-webfont.ttf')
      format('truetype'),
    url('#{$fontpath}/poppins/poppins-lightitalic-webfont.svg#poppinslight_italic')
      format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'poppins_regular';
  src: url('#{$fontpath}/poppins/poppins-regular-webfont.eot');
  src: url('#{$fontpath}/poppins/poppins-regular-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('#{$fontpath}/poppins/poppins-regular-webfont.woff2') format('woff2'),
    url('#{$fontpath}/poppins/poppins-regular-webfont.woff') format('woff'),
    url('#{$fontpath}/poppins/poppins-regular-webfont.ttf') format('truetype'),
    url('#{$fontpath}/poppins/poppins-regular-webfont.svg#poppinsregular')
      format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'poppins_semi_bold';
  src: url('#{$fontpath}/poppins/poppins-semibold-webfont.eot');
  src: url('#{$fontpath}/poppins/poppins-semibold-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('#{$fontpath}/poppins/poppins-semibold-webfont.woff2') format('woff2'),
    url('#{$fontpath}/poppins/poppins-semibold-webfont.woff') format('woff'),
    url('#{$fontpath}/poppins/poppins-semibold-webfont.ttf') format('truetype'),
    url('#{$fontpath}/poppins/poppins-semibold-webfont.svg#poppinssemibold')
      format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'poppins_thin_italic';
  src: url('#{$fontpath}/poppins/poppins-thinitalic-webfont.eot');
  src: url('#{$fontpath}/poppins/poppins-thinitalic-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('#{$fontpath}/poppins/poppins-thinitalic-webfont.woff2') format('woff2'),
    url('#{$fontpath}/poppins/poppins-thinitalic-webfont.woff') format('woff'),
    url('#{$fontpath}/poppins/poppins-thinitalic-webfont.ttf')
      format('truetype'),
    url('#{$fontpath}/poppins/poppins-thinitalic-webfont.svg#poppinsthin_italic')
      format('svg');
  font-weight: normal;
  font-style: italic;
}

%animate-spin,
.animate-spin {
  -webkit-animation: spin 1s infinite linear;
  animation: spin 1s infinite linear;
  display: inline-block;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

%bold {
  font-family: 'poppins_semi_bold', sans-serif;
  font-weight: normal;
}

html.no-touch %bold,
html.no-touch body %bold {
  font-family: 'poppins_semi_bold', Arial, Helvetica, sans-serif;
  font-weight: normal;
}

// open sans regular, or fw 400
%regular {
  font-family: 'poppins_regular', sans-serif;
  font-weight: normal;
}

html.no-touch %regular,
html.no-touch body %regular {
  font-family: 'poppins_regular', Arial, Helvetica, sans-serif;
  font-weight: normal;
}

%em {
  font-family: 'poppins_italic', sans-serif;
  font-weight: normal;
}

em,
.em,
i {
  @extend %em;
}

html.no-touch %em,
html.no-touch body %em {
  font-family: 'poppins_italic', Arial, Helvetica, sans-serif;
  font-weight: normal;
}

// open sans light, or fw 300
%light {
  font-family: 'poppins_light', sans-serif;
  font-weight: normal;
}

html.no-touch %light,
html.no-touch body %light {
  font-family: 'poppins_light', Arial, Helvetica, sans-serif;
  font-weight: normal;
}

// colours
$body: #f4f4f4;

$primary-color: #0c4f6b;
$secondary-color: #0d8abc;

$h1: #666;
$h2: #2d4673;
$h3: #000;

$hr: #d9d9d9;

$link: #0d8abc;
$link-hover: #000;

$pill-color: #0d8abc;
$pill-reverse: #fff;

$mobile-nav-background: #0c4f6b;

// forms
$input-border-color: #c7c7c7;
$input-color: #7e7e7e;

// bg section
$bg-section: #f6f6f6;

// alerts
$alert-bg: #e6f7ff;
$alert-color: #91d5ff;

$alert-error-bg: #fff1f0;
$alert-error-color: #ffa39e;

$alert-warning-bg: #fffbe6;
$alert-warning-color: #ffe58f;

$alert-success-bg: #f6ffed;
$alert-success-color: #b7eb8f;

$popover-color: #06445d;

$warning: #ec4004;
$highlight: #c68508;

$ok: #4ea216;
$cancel: #bd1010;
$error: #bd1010;
$focus: #ffffe6;
$own: #c68508;

$online: darken($mobile-nav-background, 20%);

// tender status
$tender-active: #4ea216;
$tender-finished: #8da6b6;
$tender-published: #e58a01;
$tender-finalised: #163a66;
$tender-cancelled: #bd1010;

// generic status
$status-active: #4ea216;
$status-inactive: #bd1010;
$status-finished: #8da6b6;
$status-published: #e58a01;
$status-finalised: #163a66;
$status-cancelled: #ff4646;
$status-reverted: #4272ae;
$status-traded: #137cb3;
$status-busted: #bc3879;
$status-working: #349337;

// banner status
$status-scheduled: #e58a01;
$status-live: #4ea216;
$status-paused: #d94a4a;
$status-draft: #2d76cf;
$status-expired: #c89934;

// others
@mixin tender-not-started {
  background: $white;
  border: 1px solid lighten($tender-finalised, 25%);
  color: $tender-finalised;
}

@mixin nui-status-not-started {
  background: #fff;
  border: 1px solid lighten($status-finalised, 25%);
  color: $status-finalised;
}

$table-td-even: #f6f8fd;
$table-td-odd: darken($table-td-even, 2%);

$generic-table-td-even: #f6f8fd;
$generic-table-td-odd: darken($generic-table-td-even, 2%);

$tender-list: #f6f8fd;
$tender-list-more: #e6eaf4;

// navbar
$navbar: #233444;
$navbar-color: rgba(255, 255, 255, 0.6);
$navbar-link: rgba(255, 255, 255, 0.6);
$navbar-link-active: #fff;

// checkboxes and radio, normally same as link
$checkbox: #0d8abc;
$radio: #0d8abc;

// offer and bid colours
$bid: #77b82a;
$offer: #c84d31;

// panels
$main-panel: #999999;
$light-panel: #f2f2f2;

// grays
$white: #fff;

$lightest-gray: lighten(#727e82, 30%);
$lighter-gray: lighten(#727e82, 15%);
$light-gray: #727e82;
$mid-gray: darken(#727e82, 15%);
$dark-gray: darken(#727e82, 30%);

$all-black: #000;

$modal-bg: #f2f2f2;

// legend
$legend: #ccc;
$specs: #104372;
$export: #407210;
$halal: #d38806;
$kosher: #b29e1e;
$organic: #22ba2a;
$other: #999;

$empty: #f9fbff;
$product-col-width: 415px;

// marketplace cell widths
$cell-width: 130px;
$cell-width-small: 40px; // was 35px;

* .lightest-gray {
  color: $lightest-gray !important;
}
* .light-gray {
  color: $light-gray !important;
}
* .mid-gray {
  color: $mid-gray !important;
}
* .dark-gray {
  color: $dark-gray !important;
}
* .all-black {
  color: $all-black !important;
}

@mixin legend($clr) {
  border: 1px solid lighten($clr, 20%);
  color: $clr;
}

@mixin inputbox {
  background: $white;
  border: 1px solid $input-border-color;
  border-radius: 3px;
  color: $input-color;
  min-height: 40px;
  position: relative;
}

@mixin pill($clr) {
  @extend %nd;
  background: $white;
  border: 1px solid lighten($clr, 40%);
  border-radius: 3px;
  color: $clr;
  display: inline-block;
  font-size: 0.65rem;
  height: 22px;
  line-height: 21px;
  margin: 0 5px 0 0;
  padding: 0 10px;
  text-transform: uppercase;
  transition: 0.2s all linear;
  &:hover {
    border: 1px solid lighten($clr, 20%);
  }
  &:active,
  &:focus {
    border: 1px solid $clr;
  }
  &.selected {
    background: $clr;
    border: 1px solid $clr;
    color: $white;
  }
}

@mixin alternate($odd, $even) {
  tbody {
    tr:nth-child(odd) {
      td {
        background-color: $odd;
      }
      &:hover {
        td {
          background-color: darken($odd, 2%);
        }
      }
    }
    tr:nth-child(even) {
      td {
        background-color: $even;
      }
      &:hover {
        td {
          background-color: darken($even, 2%);
        }
      }
    }
  }
}

@mixin expand {
  background: $white;
  border: 1px solid $hr;
  border-radius: 3px;
  display: block;
  font-size: 0;
  height: 18px;
  line-height: 18px;
  left: 0;
  position: absolute;
  text-align: center;
  top: 10px;
  width: 18px;
  &:before {
    @include arrow(6, 2);
    font-size: 1rem;
    transform: scale(0.8, 0.8);
    -webkit-transform: scale(0.8, 0.8);
  }
}

%word-wrap {
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;

  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.word-wrap {
  @extend %word-wrap;
}

%ellipsis {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ellipsis {
  @extend %ellipsis;
}

@mixin pos-left($top, $left) {
  left: $left + px;
  position: absolute;
  top: $top + px;
  z-index: 2;
}

@mixin pos-right($top, $right) {
  position: absolute;
  right: $right + px;
  top: $top + px;
  z-index: 2;
}

// field sizes
$field-large: 500px;
$field-small: 300px;
$field-tiny: 100px;

// animated underline
@mixin anilink($clr) {
  background-image: linear-gradient(to left, $clr 0%, $clr 100%);
  background-repeat: no-repeat;
  background-size: 0% 1px;
  background-position: 0 100%;
  color: $clr;
  display: inline;
  text-decoration: none;
  transition: background-size 0.3s ease-in;
  &:hover {
    background-size: 100% 1px;
  }
}

@mixin revanilink($clr) {
  background-image: linear-gradient(to left, $clr 0%, $clr 100%);
  background-repeat: no-repeat;
  background-size: 100% 1px;
  background-position: 0 100%;
  color: $clr;
  display: inline;
  text-decoration: none;
  transition: background-size 0.3s ease-in;
  &:hover {
    background-size: 0% 1px;
  }
}

.anilink {
  @include anilink($link);
}

.revanilink {
  @include revanilink($link);
}

%offset {
  height: 0 !important;
  left: -1000px !important;
  margin: 0 !important;
  opacity: 0 !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  top: -1000px !important;
  transition: none !important;
  width: 0 !important;
  z-index: -2 !important;
}

.offset,
.visually-hidden,
.ui-helper-hidden-accessible {
  @extend %offset;
}

%slidetoggle {
  border: 0 !important;
  height: 0 !important;
  opacity: 0 !important;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  overflow: hidden !important;
  transition: 0.2s height linear !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.slide-toggle {
  @extend %slidetoggle;
}

@mixin arrow($top, $right) {
  border-top: 7px solid $light-gray;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  content: ' ';
  display: block;
  height: 0;
  pointer-events: none;
  position: absolute;
  right: $right + px;
  top: $top + px;
  width: 0;
  z-index: 2;
}

// nui css variables
:root {
  --loader: #4267b9;
  --loader-before: #1ba9d6;
  --loader-after: #0abfa9;
  --primary-color: #162f53;
  --secondary-color: #1ba9d6;
  --link: #1ba9d6;
  --highlight: #eb3b2b;
  --navbar: #182037;
  --mobile-nav-background: #367bc2;
  --checkbox: #1ba9d6;
  --radio: #1ba9d6;
  --main-panel: #999999;
  --light-panel: #f2f2f2;
  --panel-h2: #666;
  --place-new-order: #1ba9d6;

  --bid: #77b82d;
  --offer: #c84d31;
  --link-hover: #000;
  --sticky-nav-position: sticky;
}
