.futures {
  .futures-info {
    display: flex;
    padding: 8px 0 16px 0;

    .data-info {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 50%;

      .item {
        display: flex;
        gap: 32px;
        justify-content: space-between;
      }
    }
  }

  .futures-content {
    .futures-table {
      .th-content,
      .td-content {
        text-align: right;

        .unit {
          display: block;
          text-align: right;
        }
      }

      .column-period {
        vertical-align: top;
      }
    }
  }
}
