// new offer/bid forms in left panel; account panel in right panel
#neworder,
#account {
  .ant-drawer-content-wrapper {
    width: 100vw !important;

    @include min-screen($min-small) {
      width: 600px !important;
    }
    .ant-drawer-content {
      background: var(--light-panel);
      height: 100vh;
      overflow-x: hidden;
      z-index: 10;

      @include min-screen($min-small) {
        background: linear-gradient(
          to left,
          var(--light-panel) 0%,
          var(--light-panel) 85%,
          var(--main-panel) 85%,
          var(--main-panel) 100%
        );
      }
    }

    .ant-drawer-body {
      height: 100vh;
      padding: 0;

      > div {
        height: auto;
        overflow: unset;

        .button-set {
          overflow: hidden;
        }
      }
    }

    .ant-drawer-header-no-title {
      button.ant-drawer-close {
        @extend %regular;
        outline: none;
        position: absolute;
        right: 10px;
        top: 15px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        text-align: center;
        z-index: 2;

        &:after {
          color: $light-gray;
          content: '+';
          display: block;
          font-size: 3rem;
        }
        i.anticon {
          display: none;
        }
        @include min-screen($min-small) {
          left: 0;
          position: fixed;
          right: auto;
          width: 90px;

          &:after {
            color: $white;
          }
        }
      }
    }

    .wrapper {
      height: 100vh;
      overflow-x: hidden;
      overflow-y: auto;
      padding: 20px;
      position: relative;

      @include max-screen($max-small) {
        padding-bottom: 60px;
      }
      @include min-screen($min-small) {
        margin-left: calc(12% + 9px);
        padding: 20px 35px 20px 40px;
        width: calc(88% - 9px);
      }

      h2 {
        @extend %light;
        color: var(--panel-h2);
        font-size: 2rem;
        margin: 0 0 10px;

        small {
          @extend %regular;
          color: $mid-gray;
          font-size: 0.8rem;
          position: relative;
          top: -5px;
          white-space: nowrap;

          &:before {
            border-left: 1px solid $lightest-gray;
            content: ' ';
            display: inline-block;
            height: 30px;
            margin: 0 10px 0 0;
            position: relative;
            top: 10px;
          }
        }
      }

      .dropcheck {
        button.nui-button.showoptions {
          width: 100% !important;
        }
      }

      .ant-btn {
        border: 0;
        height: auto;
        margin: 0 10px 10px 0;

        &.ant-btn-primary {
          @extend %button;
          background: var(--primary-color);
          height: auto;
        }
        &.ant-btn-link {
          @extend %button;
          @extend %regular;
          background: $white;
          border: 1px solid $lightest-gray;
          color: $link;

          &:active,
          &:hover,
          &:focus {
            background: $white;
            color: var(--link);
          }
        }
      }
    }
  }
}

// presets
.presets {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 4;

  .presets-dd {
    @extend %dd;
    background: none;
    border: 1px solid transparent;
    border-bottom: 0;
    border-radius: 3px 3px 0 0;
    color: var(--link);
    font-size: 0.7rem;
    height: auto;
    padding: 2px 30px 0 0;
    position: relative;
    text-align: right;
    text-transform: uppercase;
    width: 100%;
    z-index: 3;

    &:hover {
      @extend %nd;
      color: $all-black;
    }
    &:after {
      @include arrow(20, 12);
      transform: scale(0.7, 0.7);
      -webkit-transform: scale(0.7, 0.7);
    }
  }
  .presets-ul {
    background: $white;
    border-radius: 3px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    height: 0;
    margin: -37px 0 0;
    max-height: 300px;
    min-height: 36px;
    opacity: 0;
    overflow: hidden;
    overflow-y: auto;
    padding: 36px 0 0;
    position: relative;
    transition: none;
    width: 250px;

    li {
      background: transparent;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      color: $light-gray;
      line-height: normal;
      margin: 0;
      padding: 8px 30px 8px 10px;
      position: relative;
      text-align: center;
      white-space: normal;

      button {
        background: none;
        border: 0;
        line-height: 1rem;
        padding: 2px;
        text-align: left;
        width: 100%;
      }
      button.icon-trash {
        border: 0;
        color: $light-gray;
        display: block;
        font-size: 1rem;
        height: 32px;
        padding: 0;
        position: absolute;
        right: 2px;
        text-align: center;
        top: 2px;
        width: 34px;

        &:hover {
          color: $all-black;
        }
      }
      &:hover {
        background: lighten($link, 58%);
        color: $all-black;
      }
      &.ant-dropdown-menu-item-disabled {
        color: $light-gray;

        a.icon-trash {
          display: none;
        }
        &:hover {
          background: $white;
        }
      }
    }
  }
  &.open {
    .presets-ul {
      height: auto;
      opacity: 1;
    }
    .presets-dd {
      background: $white;
      border-color: rgba(0, 0, 0, 0.1);
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);

      &:after {
        transform: scale(0.7, 0.7) rotate(180deg);
        -webkit-transform: scale(0.7, 0.7) rotate(180deg);
      }
    }
  }
}

// add preset title popover
.preset-name {
  input.ant-input,
  input.ant-input:focus {
    background: #f9f9f9;
    border-color: $lightest-gray;
    box-shadow: none;
    height: 37px;
    margin-right: 10px;
  }
  input.ant-input:hover {
    background: $white;
    border-color: $light-gray;
  }
}

// account panel overrides
#account {
  .ant-drawer-content-wrapper {
    .ant-drawer-content {
      @include min-screen($min-small) {
        background: linear-gradient(
          to right,
          var(--light-panel) 0,
          var(--light-panel) 490px,
          var(--main-panel) 490px,
          var(--main-panel) 100%
        );
      }
    }
    .ant-drawer-header-no-title {
      @include min-screen($min-small) {
        button.ant-drawer-close {
          left: auto;
          right: 16px;
          width: 93px;
        }
      }
    }
    .wrapper {
      height: auto;
      min-height: 100vh;
      overflow: hidden;

      @include min-screen($min-small) {
        margin-left: 0;
        margin-right: calc(12% + 9px);
        padding: 0 49px 20px 35px;
        width: calc(88% - 9px);
      }
      h2 {
        margin: -5px 0 10px;

        @include min-screen($min-small) {
          margin: -10px 0 20px;
        }
      }
    }

    button.signout {
      background: none;
      border: 0;
      float: right;
      padding: 0;
      position: relative;
      right: 50px;
      top: 30px;
      z-index: 2;

      @include min-screen($min-small) {
        right: 128px;
      }
      span.icon-logout {
        color: $light-gray;
        display: inline-block;
        font-size: 1.2rem;
        margin-right: 5px;
        vertical-align: middle;

        @include max-screen($max-xsmall) {
          font-size: 1.35rem;

          &:before {
            position: relative;
            right: -2px;
            top: -2px;
          }
          &:after {
            background: $lightest-gray;
            content: ' ';
            display: inline-block;
            height: 26px;
            position: absolute;
            right: -5px;
            top: 0;
            width: 1px;
          }
        }
      }
      span.signout-txt {
        @extend %dd;
        color: var(--link);
        display: none;
        white-space: nowrap;

        @include min-screen($min-small) {
          display: inline-block;
          font-size: 0.9rem;
        }
      }
      &:hover {
        span.signout-txt {
          @extend %nd;
          color: $all-black;
        }
      }
    }

    div.numbered-sections .numbered.outside:before {
      left: 0;
      @include min-screen($min-small) {
        left: -28px;
      }
    }

    h3 {
      &.numbered {
        font-size: 1rem;
      }
    }

    .ant-row {
      padding-left: 0;
      padding-right: 0;
    }

    .ant-form-item {
      &.form-item-type-Checkbox,
      &.form-item-type-RadioSelect {
        label:after {
          display: none;
        }
      }
    }

    // profile fields
    .form-item-name-numeric_group label:after {
      display: none;
    }

    @include min-screen($min-small) {
      .form-item-name-firstname {
        display: inline-block;
        vertical-align: middle;
        width: calc(40% - 6px);
      }

      .form-item-name-lastname {
        display: inline-block;
        margin-left: 12px;
        vertical-align: middle;
        width: calc(60% - 6px);
      }

      .form-item-name-mobile,
      .form-item-name-phone,
      .form-item-name-numeric_decimal,
      .form-item-name-numeric_group {
        display: inline-block;
        vertical-align: middle;
        width: calc(50% - 6px);

        label {
          white-space: nowrap;
        }
      }
      .form-item-name-phone,
      .form-item-name-numeric_decimal {
        margin-left: 12px;
      }
    }

    .form-item-name-autoemailsignin {
      margin-top: 20px;
    }

    .form-item-name-autoemailsignin,
    .form-item-name-doemail,
    .form-item-name-dailyreports,
    .form-item-name-monthlyreports {
      > .ant-form-item-label {
        padding-left: 30px;
        text-align: left;

        label {
          display: inline-block;
        }
      }
      .ant-form-item-control-wrapper {
        display: inline-block;
        left: 0;
        position: absolute;
        top: -7px;
      }
    }

    .form-item-name-dailyreports,
    .form-item-name-monthlyreports {
      display: inline-block;
      margin-right: 10px;
      vertical-align: top;
      width: auto;

      .ant-form-item-label {
        display: inline-block;
        vertical-align: top;
        width: auto;

        label {
          padding-right: 20px;
        }
      }
    }

    .form-item-name-timezone {
      margin-bottom: 15px;
    }

    .form-item-name-autologout {
      > .ant-form-item-label {
        display: inline-block;
        padding-right: 20px;
        width: auto;

        label {
          padding: 0;
        }
      }
      .ant-form-item-control-wrapper {
        display: inline-block;
        top: -2px;
        white-space: nowrap;
        width: auto;
      }
    }
  }
}

////////////////////////////// nui panel (right) //////////////////////////////

#account .ant-drawer-content-wrapper .ant-drawer-body {
  height: calc(100vh + 1px);
}

.bglayer {
  background: rgba(0, 0, 0, 0.15);
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
}

.nui-account-panel {
  background: var(--light-panel);
  box-shadow: -6px 0 6px rgba(0, 0, 0, 0.25);
  height: 100vh;
  position: fixed;
  left: auto;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1000;

  @include min-screen($min-small) {
    background: linear-gradient(
      to right,
      var(--light-panel) 0%,
      var(--light-panel) 85%,
      var(--main-panel) 85%,
      var(--main-panel) 100%
    );
    max-width: 600px;
    width: 100%;
  }
  button.close {
    @extend %regular;
    background: 0;
    border: 0;
    box-shadow: none;
    height: 50px;
    outline: none;
    position: fixed;
    right: 10px;
    top: 15px;
    width: 50px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    text-align: center;
    z-index: 1001;

    &:after {
      color: $light-gray;
      content: '+';
      display: block;
      font-size: 3rem;
    }
    @include min-screen($min-small) {
      right: 20px;

      &:after {
        color: $white;
      }
    }
  }
  h2 {
    color: $h2;
  }
  .wrapper {
    overflow-y: auto;
    padding: 0 20px;

    @include min-screen($min-small) {
      max-width: 85%;
    }
  }
}

// drawer content
.panel-content {
  height: 100vh;
  padding: 20px;

  h2 {
    @extend %light;
    font-size: 1.5rem;

    @include min-screen($min-small) {
      font-size: 2rem;
    }
  }

  h3 {
    @extend %bold;
    color: $all-black;
    font-size: 1.1rem;
  }

  strong {
    color: $all-black;
  }
}

// panel navigator
ul.panel-navigator {
  list-style: none;
  margin: -5px 0 20px;
  padding: 0;
  transition: none;

  @include max-screen($max-xsmall) {
    background: var(--main-panel);
    bottom: 0;
    display: flex;
    right: 0;
    margin: 0;
    overflow-y: visible !important;
    position: fixed;
    width: 100%;
    z-index: 1002;

    li {
      border-bottom: 0;
      display: inline-flex;
      flex: 1;

      a,
      button {
        font-size: 0.6rem;
        height: 100%;
        margin: 0 auto 10px;
        padding: 15px 0 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
      }
    }
  }

  @include min-screen($min-small) {
    height: calc(100% - 85px);
    overflow-x: hidden;
    overflow-y: auto;
  }

  .notifications-panel-area {
    a,
    button {
      position: relative;

      span.notification {
        @extend %regular;
        background: var(--highlight);
        border-radius: 50px;
        color: $white;
        display: block;
        font-size: 0.7rem;
        left: 48px;
        line-height: 0.7rem;
        padding: 2px 6px 3px;
        position: absolute;
        top: -3px;
        z-index: 2;

        @include max-screen($max-xsmall) {
          left: 43px;
          top: 12px;
        }
      }
    }
  }

  @include min-screen($min-small) {
    display: block;
    margin: 0;
    position: fixed;
    right: 17px;
    top: 85px;
    width: 93px;
    z-index: 1001;
  }

  li {
    border-bottom: 1px solid transparent;
    display: block;

    a,
    button {
      @extend %nd;
      @extend %bold;
      background: 0;
      border: 0;
      color: rgba(255, 255, 255, 0.5);
      display: block;
      font-size: 0.7rem;
      margin: 0;
      padding: 12px 0 10px;
      text-align: center;
      width: 100%;

      &:hover {
        color: $white;
      }
      &:before {
        display: block;
        font-size: 1.25rem;
        height: 23px;
        margin: 0 auto;
      }
      &.icon-user {
        &:before {
          background: rgba(255, 255, 255, 0.5);
          border-radius: 50%;
          color: var(--main-panel);
          font-size: 1rem;
          height: 23px;
          line-height: 23px;
          text-align: center;
          width: 23px;
        }
        &:hover {
          &:before {
            background: $white;
          }
        }
        b {
          display: inline-block;
          margin-top: 5px;
        }
      }
      &.icon-cog {
        &:before {
          font-size: 1.35rem;
        }
      }
    }
    &.selected {
      background: rgba(0, 0, 0, 0.075);
      border-bottom: 1px solid rgba(255, 255, 255, 0.05);
      box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.05) inset,
        0 1px 0 rgba(0, 0, 0, 0.15) inset;
      a,
      button {
        color: $white;

        &.icon-user {
          &:before {
            background: $white;
            color: var(--main-panel);
          }
        }
      }
    }
  }
}

// configuration
.configuration-list {
  border-bottom: 1px solid $hr;
  border-top: 1px solid $hr;
  list-style: none;
  margin: 20px 0;
  padding: 10px 0;
  column-count: 2;
  -ms-column-count: 2;
  -moz-column-count: 2;
  -o-column-count: 2;
  -webkit-column-count: 2;

  li {
    display: inline-block;
    line-height: 1.5rem;
    width: 100%;

    &:before {
      color: $lightest-gray;
      content: '-';
      display: inline-block;
      margin-right: 5px;
    }
    a {
      font-size: 0.9rem;
    }
    &.selected {
      a {
        @extend %nd;
        @extend %bold;
        color: $all-black;
        pointer-events: none;
      }
    }
  }
}

.platform-notifications {
  h4 {
    border-bottom: 1px solid $hr;
    font-size: 1rem;
    margin: 10px 0;
  }

  .notifications-fieldset {
    border-bottom: 1px solid darken($hr, 10%);
    border-top: 1px solid darken($hr, 10%);
    margin: 10px 0 15px;
    padding-bottom: 6px;

    > div.no-ws {
      box-shadow: 0 -1px 0 darken($hr, 10%) inset;
    }
    .fieldset {
      border-bottom: 1px solid darken($hr, 10%);
      border-right: 1px solid darken($hr, 10%);
      display: inline-block;
      margin: 0;
      min-height: 45px;
      padding: 10px 0;
      vertical-align: top;
      width: calc(50% - 5px);

      &:nth-child(2n) {
        border-right: 0;
        padding-left: 10px;
        width: calc(50% + 5px);
      }
      &.all {
        span {
          @extend %bold;
          color: $all-black;
        }
      }
      label {
        font-size: 0.9rem;
        margin: 0;
      }
    }
  }
}

////////// solutions tab //////////

.solutions-list {
  list-style: none;
  margin: 0;
  padding: 0;

  li.solution-item {
    background: rgba(255, 255, 255, 0.5);
    border: 1px solid $hr;
    border-radius: 3px;
    margin-bottom: 10px;
    padding: 15px;
    position: relative;
    z-index: 2;

    &.active {
      background: $alert-bg;
      border: 1px solid $alert-color;
      z-index: 3;

      button.button {
        display: none;
      }
    }
  }
  h3 {
    @extend %bold;
    color: var(--primary-color);
    display: block;
    font-size: 0.95rem;
    line-height: 1.3rem;
    margin: 0;
    padding-right: 140px;
    position: relative;
    white-space: normal;
  }
  h4 {
    color: $all-black;
    display: none;
    font-size: 0.9rem;
    margin: 20px 0 0;
    padding: 0 0 5px;
  }
  p.owner {
    display: block;
    font-size: 0.7rem;
    margin: 0;
    padding: 0;
  }
  img.solution-logo {
    max-height: 40px;
    max-width: 130px;
    mix-blend-mode: multiply;
    position: absolute;
    right: 10px;
    top: 10px;
  }
  p.solution-detail {
    font-size: 0.8rem;
    margin: 0;
    padding: 0;

    span {
      @extend %bold;
      color: $all-black;
      margin-right: 8px;
    }
  }
  ul.solution-roles {
    list-style: none;
    margin: 0;
    padding: 0 0 20px;

    li.solution-role {
      padding-top: 10px;
      position: relative;
    }
  }
  p.solution-detail:not(.solution-type) {
    span {
      display: inline-block;
      white-space: nowrap;
      width: 80px;
    }
  }
  p.solution-detail.solution-terms,
  p.solution-detail.solution-privacy {
    span {
      width: auto;
    }
  }
  p.solution-detail.solution-type {
    span {
      @extend %bold;
      background: $white;
      border-radius: 3px;
      border: 1px solid transparent;
      color: $white;
      display: inline-block;
      font-size: 0.7rem;
      height: 20px;
      line-height: 18px;
      margin: 0;
      padding: 0 10px;
      position: absolute;
      right: 0;
      text-align: center;
      text-transform: uppercase;
      top: 10px;
    }

    &.buyer {
      span {
        background: $bid;
      }
    }
    &.seller {
      span {
        background: $offer;
      }
    }
  }

  button.show-details,
  button.hide-details {
    @extend %dd;
    background: none;
    border: 0;
    box-shadow: none;
    color: var(--link);
    font-size: 0.8rem;
    margin: 0;
    padding: 0 0 0 25px;
    position: relative;

    span.arrow {
      @include expand;
      background: $white;
      left: 0;
      top: 2px;
      z-index: 2;
    }
    &:active,
    &:focus,
    &:hover {
      @extend %nd;
      color: $all-black;
    }
  }
  button.hide-details {
    span.arrow {
      transform: rotate(-180deg);
      -webkit-transform: rotate(-180deg);

      &:before {
        top: 5px;
      }
    }
  }
}

// exchange rates section
.exchange-info {
  background: #eaeaea;
  margin: 20px 0;
  padding: 10px 20px;

  .current {
    border-bottom: 1px solid $lightest-gray;
    margin-bottom: 15px;
    padding-bottom: 10px;

    .view-exchange {
      display: inline-block;
      margin-right: 5px;
      vertical-align: top;
      width: calc(33% - 6.66px);

      span.exchange-rate {
        @extend %bold;
        color: $all-black;
        font-size: 1.5rem;
      }
      span.exchange-currency {
        @extend %regular;
        color: $warning;
        display: block;
        font-size: 0.7rem;
      }
    }
  }
  .set-exchange {
    padding-bottom: 20px;

    .currency,
    .rate {
      display: inline-block;
      margin-right: 10px;
      width: calc(33% - 6.66px);
    }
    .currency {
      width: 118px;
    }
    .submit {
      display: inline-block;
      width: calc(33% - 6.66px);
    }
    label {
      @extend %bold;
      color: $all-black;
      display: block;
      font-size: 0.9rem;
      margin: 0 0 4px;
    }

    // select
    .ant-select {
      width: 100%;
    }
    .ant-select .ant-select-selection--single {
      background: $white;
      border: 1px solid $lightest-gray;
      border-radius: 3px;
      display: block;
      height: 44px;
      line-height: 38px;
      outline: none;
      width: 100%;

      &:active,
      &:focus {
        box-shadow: none !important;
        outline: 0 !important;
      }

      span.ant-select-arrow {
        i {
          @include arrow(3, 0);
          -webkit-transform: scale(0.8, 0.8);
          transform: scale(0.8, 0.8);

          svg {
            display: none;
          }
        }
      }
      .ant-select-selection__rendered {
        line-height: 44px;
      }
    }

    .ant-select-focused.ant-select-open .ant-select-selection--single {
      span.ant-select-arrow {
        i {
          -webkit-transform: scale(0.8, 0.8) rotate(180deg);
          transform: scale(0.8, 0.8) rotate(180deg);
        }
      }
    }

    .currency {
      label {
        position: relative;
        top: -3px;
      }
      .currency-name {
        display: block;
        height: 44px;
        line-height: 42px;
      }
    }
    .currency-rate {
      .ant-input-number {
        height: 100%;
        width: auto;
      }

      .ant-input-number,
      .ant-input-number-handler-wrap,
      .ant-input-number-input-wrap,
      .ant-input {
        box-shadow: none !important;
        outline: 0 !important;

        &:active,
        &:focus {
          box-shadow: none !important;
          outline: 0 !important;
        }
      }
      .ant-input-number {
        &:active,
        &:focus,
        &:hover {
          border-color: $light-gray;
        }
      }
      input {
        color: $all-black;
        display: block;
        height: 44px;
        line-height: 42px;

        &:active,
        &:focus,
        &:hover {
          box-shadow: none;
          outline: 0;
        }
      }
    }
    .button {
      display: block;
      height: 44px;
      line-height: 42px;
      margin: 0;
      padding: 0;
      width: 100%;
    }
  }

  .exchange-history {
    border-top: 1px solid $lightest-gray;
    padding: 10px 0 5px;

    button {
      @extend %dd;
      background: none;
      border: 0;
      color: $link;
      font-size: 0.8rem;
      padding: 0 0 0 20px;
      position: relative;

      &:hover {
        @extend %nd;
        color: $all-black;
      }
      span.arrow {
        @include arrow(7, 0);
        left: 0;
        right: auto;
        transform: scale(0.8, 0.8);
        -webkit-transform: scale(0.8, 0.8);
      }

      &.hideme {
        span.arrow {
          transform: rotate(180deg) scale(0.8, 0.8);
          -webkit-transform: rotate(180deg) scale(0.8, 0.8);
        }
      }
    }

    table {
      background: none;
      border: none;
      margin: 10px 0 0;
      width: 100%;

      tr {
        th {
          background: none;
          color: $all-black;
          font-size: 0.8rem;
          padding: 2px 0;

          span.cur {
            @extend %regular;
            color: $mid-gray;
            font-size: 0.7rem;
          }
        }
        td {
          background: none;
          color: $light-gray;
          font-size: 0.8rem;
          padding: 2px 0;
          white-space: nowrap;
        }
        th:first-child,
        td:first-child {
          padding-left: 20px;
          width: 128px;
        }
      }
    }
  }
}

/////// add phone log in steps ///////

.mobile-login-option {
  position: relative;

  .phone-label {
    color: $all-black;
    position: relative;
    top: 5px;
  }
  span.not-verified,
  span.verified {
    @extend %regular;
    font-size: 0.7rem;
    text-transform: uppercase;
  }
  span.not-verified {
    color: $warning;
  }
  span.verified {
    color: $ok;
  }
  span.not-specified,
  span.phone-number {
    @extend %regular;
    color: $mid-gray;
    font-size: 0.8rem;
    left: 100px;
    position: absolute;
    top: 6px;
  }

  button.reverse {
    padding: 4px 12px 5px;
  }

  button.button-link {
    position: relative;
    top: -2px;
  }

  .PhoneInput,
  .phone-verification {
    .PhoneInputCountry,
    input.PhoneInputInput,
    .phone-verification-input {
      background: $white;
      border-radius: 3px;
      border: 1px solid $lightest-gray;
      box-shadow: none;
      color: $all-black;
      height: 40px;
      line-height: 38px;
      padding: 5px 10px;

      &:active,
      &:focus,
      &:hover {
        border: 1px solid var(--link);
        box-shadow: none;
        outline: 0;
      }
    }
    .PhoneInputCountry {
      margin-right: 12px;
      position: relative;
      width: 70px;

      .PhoneInputCountrySelectArrow {
        @include arrow(15, 10);
        border-bottom: 0;
        opacity: 1;
        -webkit-transform: scale(0.8, 0.8);
        transform: scale(0.8, 0.8);
      }
      .PhoneInputCountryIcon {
        line-height: 0;

        &:before {
          @extend %fontello;
          color: $lightest-gray;
          content: '\e816';
          font-size: 1.25rem;
          margin: -3px 0 0;
        }
        svg {
          display: none;
        }
      }
      .PhoneInputCountryIcon--border {
        background: none;
        border: 0;
        box-shadow: none;
        line-height: 0;

        img {
          border: 1px solid rgba(0, 0, 0, 0.2);
          display: block;
          height: auto;
          left: -2px;
          max-width: none;
          min-height: 21px;
          padding: 2px;
          position: relative;
          top: -4px;
          width: 30px;
        }
        &:before {
          display: none;
        }
      }
    }
  }
  .phone-verification-input {
    display: block;
    width: 100%;
  }
}

//////////////////////////// TODO animation ////////////////////////////

.nui-account-panel {
  right: -600px;
}

.show-right-panel {
  overflow: hidden;
  -webkit-transform: translate(-600px, 0);
  transform: translate(-600px, 0);
  transition: transform 0.6s ease;

  #account {
    right: -600px;
    button.ant-drawer-close {
      right: -588px;
    }
  }
}

.show-left-panel {
  overflow: hidden;
  -webkit-transform: translate(600px, 0);
  transform: translate(600px, 0);
  transition: transform 0.6s ease;

  #neworder .ant-drawer-content-wrapper {
    left: -600px;
    button.ant-drawer-close {
      left: -600px;
    }
  }
}

// security, browser list
.panel-loading {
  margin-left: 0;
  margin-right: calc(12% + 9px);
  padding: 0 49px 20px 35px;
  position: relative;
  top: calc(50vh - 40px);
  width: calc(88% - 9px);
}

.browser-list,
.devices-list {
  font-size: 0.9rem;
  list-style: none;
  margin: 0 0 20px;
  padding: 0;

  .mini-label {
    @extend %bold;
    color: $all-black;
    display: block;
    font-size: 0.7rem;
    line-height: 1rem;
    margin: 0;
    padding: 0;
  }
  button.nui-button-icon {
    position: absolute;
    right: 0;
    top: 5px;
    transform: scale(0.8, 0.8);
    -webkit-transform: scale(0.8, 0.8);
  }
  li {
    border-bottom: 1px solid $hr;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    border-top: 1px solid $hr;
    color: $light-gray;
    font-size: 0.8rem;
    line-height: 1.2rem;
    margin: 5px 0;
    padding: 5px 0;
    position: relative;
    vertical-align: top;
    z-index: 1;

    .since,
    .expires,
    .device {
      display: inline-block;
      padding: 5px 0;
      vertical-align: top;
      width: 50%;
    }
    .user-agent {
      color: $light-gray;
      padding: 5px 0;
      word-break: break-word;
    }
    .current {
      @extend %bold;
      color: $ok;
      font-size: 0.7rem;
      padding-bottom: 5px;

      &:before {
        @extend %fontello;
        content: '\e802';
        font-size: 0.8rem;
      }
    }
  }
}

.devices-list {
  li {
    padding-left: 30px;
    position: relative;

    .image-container {
      left: 0;
      position: absolute;
      top: 15px;

      span:before {
        color: $light-gray;
        font-size: 1.25rem;
        margin: 0;
      }
    }
  }
}

// private filter
.nui-form .ant-form-item.form-item-name-deorco {
  .ant-form-item-label,
  .ant-form-item-control-wrapper {
    display: inline-block;
    vertical-align: middle;
  }
  .ant-form-item-label {
    margin: 0;
    padding-right: 10px;
    width: 80px;

    label {
      margin: 0;
      top: -1px;
    }
  }
}

.nui-form .indexed-totals .ant-form-item-control {
  line-height: initial;
}
