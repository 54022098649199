// nui table
html {
  .nui-table table {
    border-collapse: collapse;

    tr:last-child {
      td {
        border-bottom: 1px solid $hr;
      }
    }
    tbody tr td {
      padding: 5px 10px;

      &.nui-table-column-has-sorters {
        padding-right: 32px;
      }
    }
    td.col-loading,
    th.col-loading {
      border-right: 1px solid $hr;
    }
  }

  .table-fixed table,
  table.fixed {
    table-layout: fixed;

    tr td:first-child {
      @include min-screen($min-medium) {
        width: 35%;
      }
    }
  }

  .nui-table-tbody > tr.nui-table-row-hover,
  .nui-table-tbody > tr:hover {
    &:nth-child(even) {
      > td {
        background: inherit;
        box-shadow: 0 -1px 0 #d9d9d9 inset, 0 1px 0 #d9d9d9,
          0 -5px 0 rgba(0, 0, 0, 0.035) inset;

        &:first-child {
          background: $white;
        }
      }
    }
  }
}

// generic table
span.tiny-label {
  @extend %regular;
  color: $light-gray;
  display: block;
  font-size: 0.7rem;
  margin-top: -4px;
  white-space: nowrap;
}

.generic-table {
  margin-bottom: -1px;
  margin-top: -1px;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  z-index: 2;

  .nui-spin-nested-loading {
    .nui-spin-spinning {
      top: 100px;
    }
  }

  tr > th {
    font-weight: normal;
  }

  tr {
    td,
    td:active,
    td:focus,
    td:hover {
      cursor: default;
    }
    td.column-nowrap {
      white-space: nowrap;
    }
  }

  .nui-table-title > h3 {
    margin: 5px 0 15px;

    span {
      @extend %light;
      color: $mid-gray;
      font-size: 1rem;
    }
  }

  .nui-table-title {
    border-radius: 0;
    padding: 10px 0 0;
    top: auto;
  }

  .nui-table-content {
    .nui-table-body {
      table {
        background: $white;
        border-collapse: collapse;
        border-radius: 3px;
        border: 1px solid $lightest-gray;
        margin: 0;
        padding: 5px;
        width: 100%;

        thead.nui-table-thead {
          th {
            @extend %bold;
            background: rgba(20, 20, 20, 0.1);
            border-bottom: 1px solid $lightest-gray;
            border-right: 1px solid $lightest-gray;
            color: $all-black;
            padding: 10px;
            white-space: nowrap;

            // filter icon
            .anticon-filter {
              background: $lightest-gray;
              border: 0;
              border-radius: 50%;
              color: $white;
              display: block;
              height: 20px;
              margin-top: 10px;
              padding: 0;
              right: 10px;
              width: 20px;

              &:after {
                display: none;
              }
              &:hover {
                background: darken($lightest-gray, 5%);
                color: $white;
              }
            }
            .nui-table-filter-selected.anticon-filter {
              background: $ok;
            }

            .nui-table-column-sorter
              .nui-table-column-sorter-inner
              .nui-table-column-sorter-up,
            .nui-table-column-sorter
              .nui-table-column-sorter-inner
              .nui-table-column-sorter-down {
              &.off {
                color: $light-gray;
              }
              &.on {
                color: $all-black;
              }
            }
          }
        }

        // tbody
        tr {
          td {
            border-bottom: 1px solid $hr;
            border-right: 1px solid $hr;
            overflow: visible;
            padding: 10px;
            vertical-align: top;

            span.icon-block {
              color: $error;
              cursor: not-allowed;
              display: inline-block;
            }
            span.icon-ok {
              color: $ok;
              display: inline-block;
            }
            &.nui-table-column-has-sorters {
              padding-right: 32px;
            }
          }
        }

        tr:nth-child(odd),
        tr:not(.more-details):nth-child(odd):hover {
          td {
            background: $generic-table-td-odd;
          }
        }
        tr:nth-child(even),
        tr:nth-child(even):hover {
          td {
            background: $generic-table-td-even;
          }
        }
        tr:hover {
          td {
            box-shadow: 0 -5px 0 rgba(0, 0, 0, 0.035) inset;
          }
        }

        tr:nth-child(odd),
        tr:nth-child(odd):hover,
        tr:nth-child(even),
        tr:nth-child(even):hover {
          td {
            &:first-child {
              background: $white;
            }
          }
        }
      }
    }
  }
}

// generic table in modal
.modal-table {
  .nui-table-content {
    .nui-table-body {
      table {
        background: $white;
        border-collapse: collapse;
        border-radius: 3px;
        border: 1px solid $lightest-gray;
        margin: 0;
        padding: 5px;
        width: 100%;

        thead.nui-table-thead {
          th {
            @extend %bold;
            background: rgba(20, 20, 20, 0.1);
            border-bottom: 1px solid $lightest-gray;
            border-right: 1px solid $lightest-gray;
            color: $all-black;
            padding: 10px;
            white-space: nowrap;

            span.nui-table-column-title > span {
              @extend %regular;
              color: $mid-gray;
              display: block;
              font-size: 0.7rem;
            }
          }
        }

        // tbody
        tr {
          td {
            border-bottom: 1px solid $hr;
            border-right: 1px solid $hr;
            overflow: visible;
            padding: 10px;
            vertical-align: top;

            span.icon-block {
              color: $error;
              cursor: not-allowed;
            }
            span.icon-ok {
              color: $ok;
            }
          }
        }

        tr:nth-child(odd),
        tr:not(.more-details):nth-child(odd) {
          td {
            background: #f9f9f9;
          }
          &:hover {
            td {
              background: darken(#f9f9f9, 2%);
            }
          }
        }
        tr:nth-child(even) {
          td {
            background: #f2f2f2;
          }
          &:hover {
            td {
              background: darken(#f2f2f2, 2%);
            }
          }
        }
        tr:hover {
          td {
            box-shadow: 0 -5px 0 rgba(0, 0, 0, 0.035) inset;
          }
        }
      }
    }
  }
}

// product tables
.generic-table {
  .nui-table-small > .nui-table-content > .nui-table-body {
    margin: 0;
  }

  .col-product {
    left: 0;
    min-width: 200px;
    position: sticky !important;
    position: -webkit-sticky !important;
    z-index: 4;
  }

  &.product-tables {
    margin: 0 0 20px;
  }
}

.nui-table-small {
  border: 0;
  border-radius: 3px;
}

.nui-table-without-column-header {
  .nui-table-title {
    @extend %bold;
    background: rgba(20, 20, 20, 0.1);
    border: 1px solid $lightest-gray;
    border-bottom: 0;
    border-radius: 3px 3px 0 0;
    color: $all-black;
    margin-bottom: -1px;
    padding: 10px;
  }
}

// alternation
table.alternate {
  @include alternate(#f9f9f9, #f2f2f2);
}

.table-wrapper {
  overflow-x: auto;
  padding: 0 0 5px;
  width: 100%;
  z-index: 2;
}

// rowspan of 2
.generic-table .nui-table-content .nui-table-body table thead.nui-table-thead,
.nui-table-thead {
  tr + tr {
    th {
      background: rgba(20, 20, 20, 0.15);
      border-top: 1px solid $white;
      font-size: 0.75rem;
      padding: 5px 10px;
    }
  }
}

// multiple th rows
.th-rows {
  tr {
    th {
      vertical-align: top;
    }
  }
}

// expand button with text
.generic-table .nui-table-content .nui-table-body table tr td.expand-text {
  width: 1%;

  .nui-table-row-expand-icon {
    display: inline-block;
    height: 25px;
    line-height: 25px;
    margin: 0;
    padding: 0 5px 0 20px;
    width: auto;

    &:after {
      color: var(--link);
      content: 'Details';
      display: inline-block;
      font-size: 0.8rem;
    }
    &:before {
      left: 5px;
      margin-top: 3px;
      right: auto;
    }
  }
}

.generic-table .nui-table-content .nui-table-body table tr th {
  &.col-details-small {
    button.nui-button.expand {
      @extend %bold;
      background: none;
      border-color: transparent;
      box-shadow: none;
      color: $all-black;
      font-size: 0.9rem;
      left: -9px;
      overflow: visible;
      padding: 0 0 0 20px;
      position: relative;
      text-overflow: unset;

      span.arrow {
        @include arrow(8, 0);
        left: 5px;
        right: auto;
        transition: 0.2s all linear;
        transform: scale(0.8) rotate(-180deg);

        &.collapsed {
          transform: scale(0.8);
        }
      }
    }
  }
}

// expanded row
.generic-table.expand-table {
  .nui-table-content .nui-table-body {
    tr,
    tr:nth-child(even),
    tr:nth-child(odd) {
      &.nui-table-expanded-row {
        .item-list {
          max-width: calc(100vw - 45px);
        }
        .nui-alert {
          max-width: calc(100vw - 45px);

          @include min-screen($min-medium) {
            margin: 0 auto;
            max-width: 1280px;
          }
        }

        .wizard-content {
          left: 20px;
          max-width: calc(100vw - 60px);
          position: sticky;
        }
        .item-list {
          border-bottom: 1px solid #d9d9d9;
          border-top: 1px solid #d9d9d9;

          li {
            border-bottom: 1px solid #d9d9d9;
            margin: 0 0 -1px;
            padding: 10px 0;

            @include min-screen($min-medium) {
              display: inline-block;
              vertical-align: top;
              width: 50%;

              &:nth-child(2n) {
                border-left: 1px solid #d9d9d9;
                margin-left: -1px;
                padding-left: 10px;
              }
              &:nth-child(2n-1) {
                border-right: 1px solid #d9d9d9;
              }
            }
            @include min-screen($min-xxlarge) {
              width: 25%;

              &:nth-child(4n-2) {
                border-right: 1px solid #d9d9d9;
                margin-right: -1px;
              }
              &:nth-child(4n-1) {
                padding-left: 10px;
              }
            }
          }
        }
        td,
        td:first-child {
          background: #eef1f8;
          border-bottom: 1px solid $hr;
          box-shadow: 0 -10px 0 $white inset, 0 -11px 0 $hr inset,
            0 2px 2px rgba(0, 0, 0, 0.05) inset;
          padding: 20px 0 30px;
          position: relative;
          z-index: 2;
        }

        td:first-child {
          &:before {
            background: $white;
            bottom: 0;
            content: ' ';
            display: block;
            height: 10px;
            left: -1px;
            position: absolute;
            width: 1px;
            z-index: 2;
          }
        }

        td:last-child {
          &:after {
            background: $white;
            bottom: 0;
            content: ' ';
            display: block;
            height: 10px;
            position: absolute;
            right: -1px;
            width: 1px;
            z-index: 2;
          }
        }
      }
    }
  }
}

.market .generic-table.expand-table {
  .nui-table-content .nui-table-body {
    tr,
    tr:nth-child(even),
    tr:nth-child(odd) {
      &.nui-table-expanded-row {
        td,
        td:first-child {
          padding: 0;
        }
      }
    }
  }
}

.generic-table.expand-table.market-depth {
  .nui-table-content .nui-table-body {
    tr,
    tr:nth-child(even),
    tr:nth-child(odd) {
      &.nui-table-expanded-row {
        td,
        td:first-child {
          border-bottom: 1px solid $hr;
          box-shadow: none;

          &:before,
          &:after {
            display: none;
          }
        }
      }
      &:last-child {
        td,
        td:first-child {
          border-bottom: 0;
        }
      }
    }
  }
}

// bordered table wrapper (for scrolling)
.bordered,
.generic-table.bordered {
  border-left: 1px solid $lightest-gray;
  border-right: 1px solid $lightest-gray;

  table,
  .nui-table-content .nui-table-body table {
    border-left: 0;
    border-right: 0;

    thead,
    tbody {
      tr {
        th:first-child,
        td:first-child {
          border-left: 0;
        }
        th:last-child,
        td:last-child {
          border-right: 0;
        }
      }
    }
  }
}

// col/content classes
td.td-col-white {
  background: $white !important;
}

td.col.id span {
  white-space: nowrap;
}

.td-col-id,
td,
.col-price-unit {
  min-width: 110px;

  .td-content {
    .block:not(.mb-5),
    .index-name {
      font-size: 0.75rem;
      white-space: nowrap;
    }
  }
}

.col-product-small,
.td-col-product,
.col-product,
.col-createdat,
.col-created {
  min-width: 150px;
}

.col-etd-to {
  .times-rotate {
    display: inline-block;
    font-size: 1.25rem;
    margin: 0;
    position: relative;
    transform: rotate(45deg);
    vertical-align: middle;
  }

  .delivery-volume {
    span,
    strong {
      margin-right: 4px;
    }
  }

  .periodic-delivery {
    @extend %regular;
    background: $white;
    border: 1px solid $light-gray;
    border-radius: 4px;
    color: $light-gray;
    display: inline-block;
    font-size: 0.6rem;
    height: 20px;
    line-height: 20px;
    margin: 5px 0;
    min-width: 92px;
    overflow: hidden;
    padding: 0 5px 0 25px;
    position: relative;
    text-align: center;
    text-transform: uppercase;

    &:before {
      background: $light-gray;
      color: $white;
      font-size: 0.8rem;
      height: 18px;
      left: 0;
      line-height: 18px;
      margin: 0;
      position: absolute;
      text-align: center;
      width: 21px;
    }
    &.daily {
      border-color: #d68721;
      color: #d68721;

      &:before {
        background: #d68721;
        color: $white;
      }
    }
    &.weekly {
      border-color: #e63a2b;
      color: #e63a2b;

      &:before {
        background: #e63a2b;
        color: $white;
      }
    }
    &.monthly {
      border-color: #3aa06a;
      color: #3aa06a;

      &:before {
        background: #3aa06a;
        color: $white;
      }
    }
    &.quarterly {
      border-color: #3a46a0;
      color: #3a46a0;
      font-size: 0.7rem;

      &:before {
        background: #3a46a0;
        color: $white;
      }
    }
  }
}

.col-customer,
.col-division {
  min-width: 200px;

  @include min-screen($min-medium) {
    max-width: 400px;
    width: 400px;
  }
}

.col-shortcode,
.col-reference {
  width: 100px;
}

.col-creditlimit,
.col-creditremaining {
  width: 100px;
}

.col-unit {
  width: 300px;
}

.col-company {
  width: 600px;
}

.col-phone,
.col-mobile {
  white-space: nowrap;
}

// global table sorters
.nui-table-thead {
  tr {
    th {
      .nui-table-column-sorter {
        .nui-table-column-sorter-inner {
          .nui-table-column-sorter-up.on,
          .nui-table-column-sorter-down.on {
            color: $all-black;
          }
        }
      }
    }
  }
}

.nui-table-thead
  > tr
  > th
  .nui-table-header-column
  .nui-table-column-sorters:hover::before {
  background: inherit !important;
}

// light tables, pagination
.full-page-content {
  .nui-pagination {
    display: inline-block;
    left: 0;
    overflow: visible;
    padding-left: 20px !important;
    padding-right: 20px !important;
    position: sticky;
    position: -webkit-sticky;
    white-space: nowrap;
    width: 0;

    @include min-screen($min-medium) {
      padding-left: 40px !important;
      padding-right: 40px !important;
    }
  }

  &.participants {
    .generic-table {
      tbody {
        tr {
          td {
            &.col-country-code {
              max-width: none !important;
              text-align: center;

              .td-content {
                max-width: none !important;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
}

// alignments
table {
  thead,
  tbody {
    tr {
      th.align-left .th-content,
      td.align-left .td-content {
        text-align: left;
      }

      th.align-center .th-content,
      td.align-center .td-content {
        text-align: center;
      }

      th.align-left div,
      th.align-left span,
      td.align-left div,
      td.align-left span {
        display: block;
        text-align: left;
      }

      th.align-right .th-content,
      td.align-right .td-content {
        text-align: right;
      }

      th.align-right div,
      th.align-right span,
      th.align-right p,
      td.align-right div,
      td.align-right span,
      td.align-right p {
        display: block;
        text-align: right;
      }
    }
  }
}

.full-page-content .generic-table table.table.light,
.full-page-content table,
.full-page-content .generic-table .nui-table-content .nui-table-body table {
  border: 0;
  border-collapse: separate;
  border-spacing: 0;
  padding: 0;
  width: 100%;

  thead,
  thead.nui-table-thead {
    tr:first-child {
      th {
        a.sortem,
        .icon-sort {
          @extend %nd;
          background: none;
          border: 0;
          box-shadow: none;
          color: $all-black;
          display: block;
          height: 100%;
          padding-right: 35px;
          width: 100%;

          &:before {
            color: rgba(0, 0, 0, 0.25);
            font-size: 1rem;
            position: absolute;
            right: 0;
            text-align: left;
            top: 14px;
            width: 15px;
            z-index: 2;
          }
          &.up,
          &.down {
            &:after {
              @extend %fontello;
              color: $mid-gray;
              content: '\f0de';
              font-size: 1rem;
              position: absolute;
              right: 0;
              text-align: left;
              top: 14px;
              width: 15px;
              z-index: 3;
            }
          }
          &.down {
            &:after {
              content: '\f0dd';
            }
          }
        }
        button.icon-sort {
          &:before {
            top: 14px;
          }
        }
      }
    }
    tr,
    tr:last-child {
      th {
        background: $white;
        border-right: 1px solid $hr;
        border-radius: 0 !important;
        border-top: 1px solid $hr;
        position: relative;
        white-space: nowrap;

        &:first-child {
          padding-left: 20px;

          @include min-screen($min-medium) {
            padding-left: 40px;
          }
        }
        &:last-child {
          padding-right: 20px;

          a.sortem {
            border-right: 0;
          }
          @include min-screen($min-medium) {
            padding-right: 40px;
          }
        }

        &.col-participant {
          padding-left: 80px;

          @include min-screen($min-medium) {
            padding-left: 90px;
          }
          &.viewer {
            padding-left: 20px !important;

            @include min-screen($min-medium) {
              padding-left: 40px !important;
            }
          }
        }

        &.col-country-code {
          text-align: center;
        }

        // country code
        span.icon-globe {
          color: $lightest-gray;
          font-size: 1.3rem;
        }
      }
    }
    tr + tr:last-child {
      th {
        @extend %regular;
        border-top: 0;
        color: $mid-gray;
        font-size: 0.7rem;
      }
    }
    tr + tr {
      th {
        padding-left: 10px !important;
        padding-right: 10px !important;

        a.sortem {
          &:before {
            top: 8px;
          }
          &.up,
          &.down {
            &:after {
              top: 8px;
            }
          }
        }
      }
    }
  }
  tbody {
    tr,
    tr:hover {
      td {
        border: 0;
        border-bottom: 1px solid $hr;
        border-right: 1px solid $hr;
        border-radius: 0 !important;
        padding: 0;
        vertical-align: top;

        .td-content {
          line-height: 1.2rem;
          padding: 10px;

          .td-content {
            padding: 0;
          }
        }
        p,
        address {
          margin: 0;
          padding: 0;
        }
        &.col-note {
          .td-content {
            max-width: 300px;
            min-width: 200px;
          }
        }
        &.col-address {
          .td-content {
            max-width: 500px;
          }
        }
        &.col-country-code {
          max-width: 70px;
          text-align: center;
          width: 70px;

          .td-content {
            max-width: 70px;
            text-align: center;
          }
          span.country-code {
            @extend %bold;
            color: $all-black;
            cursor: help;
            font-size: 0.75rem;
            text-transform: uppercase;

            // display as tag
            background: $white;
            border: 1px solid $lightest-gray;
            border-radius: 100px;
            display: inline-block;
            font-size: 0.65rem;
            line-height: 1.2rem;
            padding: 0 10px;
            text-align: center;
            text-transform: uppercase;
            white-space: nowrap;
          }
        }
        &.col-participant {
          min-width: 280px;

          .td-content {
            max-width: 420px;
            min-width: 280px;
            padding-left: 60px;
            position: relative;

            span.block {
              word-break: break-all;
            }
            button {
              left: 0;
              position: absolute;
            }
          }
          &.viewer {
            padding-left: 20px !important;

            .td-content {
              padding-left: 0;
            }
            @include min-screen($min-medium) {
              min-width: 240px;
              width: 240px;
              padding-left: 40px !important;

              .td-content {
                width: 240px;
              }
            }
          }
        }
        &.col-accepted-by-them {
          span.icon-ok,
          span.icon-block {
            display: block;
            margin-bottom: 5px;
          }
        }
        &.col-status {
          button {
            margin: 5px 0;
            width: 100%;
          }
        }
        &.col-address,
        &.col-comment {
          .td-content {
            min-width: 250px;
          }
        }
        &.col-rev {
          text-align: right;
          .td-content {
            text-align: right;
          }
        }
        &:first-child {
          padding-left: 15px !important;

          @include min-screen($min-medium) {
            padding-left: 30px !important;
          }
        }
        &:last-child {
          padding-right: 15px !important;

          @include min-screen($min-medium) {
            padding-right: 30px !important;
          }
        }
        span.smaller {
          color: $light-gray;
          line-height: 1.2rem;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        span.divider {
          color: $lightest-gray;
        }
      }

      // alternation
      &:nth-child(odd) {
        td {
          background: #fafbfe;
        }
        &:hover {
          td {
            box-shadow: 0 -5px 0 rgba(0, 0, 0, 0.035) inset;
          }
        }
      }
      &:nth-child(even) {
        td {
          background: #f6f8fd;
        }
        &:hover {
          td {
            box-shadow: 0 -5px 0 rgba(0, 0, 0, 0.035) inset;
          }
        }
      }

      &.nui-table-expanded-row:hover {
        td,
        td:first-child {
          box-shadow: 0 -10px 0 $white inset, 0 -11px 0 $hr inset,
            0 2px 2px rgba(0, 0, 0, 0.05) inset;
        }
      }
    }
  }

  // stick-em
  thead tr th.col-participant,
  tbody tr td.col-participant {
    box-shadow: 2px 0 2px rgba(0, 0, 0, 0.05);
    left: 0;
    position: sticky !important;
    position: -webkit-sticky !important;
    z-index: 2;
  }
  tbody tr:hover td.col-participant {
    box-shadow: 2px 0 2px rgba(0, 0, 0, 0.05),
      0 -5px 0 rgba(0, 0, 0, 0.035) inset !important;
  }

  // light version
  &.light {
    thead {
      tr,
      tr:last-child {
        th {
          background: $white;
        }
      }
    }
  }
}

// light tables in modal
html .nui-modal .nui-modal-content {
  .full-page-content {
    .table-wrapper {
      table.light {
        border: 0;
        border-left: 1px solid $hr;

        thead tr th {
          border-bottom: 1px solid $hr;
          color: $all-black;

          // change this if we add .th-content!
          span.icon-globe {
            position: absolute;
            top: 5px;
          }
        }
        tbody tr td {
          padding: 0 !important;
        }
      }
    }
  }
}

// sticky edit and delete (warehouses, products)
td.col-edit,
td.col-delete {
  min-width: 48px;
}

.full-page-content .generic-table.light .table-wrapper.th-sticky,
.full-page-content .th-sticky.generic-table.nui-table {
  table {
    thead,
    tbody {
      tr,
      tr:hover {
        td,
        th,
        td:hover,
        th:hover {
          &.col-edit,
          &.col-delete {
            background: $white;
            box-shadow: none;
            max-width: 48px;
            min-width: 48px;
            overflow: hidden;
            padding: 0 !important;
            position: sticky !important;
            position: -webkit-sticky !important;
            right: 0;
            text-align: center;
            top: auto;
            width: 49px;
            z-index: 21;

            .td-content {
              min-height: 50px;
              padding: 5px;
              width: 60px;

              // btns
              button {
                background: none;
                border: 0;
              }
              .nui-button {
                margin: 3px;
              }
              .edit-warehouse span,
              .delete-warehouse span {
                @extend %nd;
                background: var(--link);
                border: 1px solid var(--link);
                border-radius: 3px;
                box-shadow: 0 -2px 0 rgba(0, 0, 0, 0.25) inset;
                color: $white;
                display: block;
                font-size: 1rem;
                height: 32px;
                line-height: 32px;
                margin: 3px auto;
                padding: 0 0 2px 2px;
                text-align: center;
                transition: 0.2s all ease;
                width: 32px;
              }
              .delete-warehouse span {
                background: $white;
                border-color: $lightest-gray;
                box-shadow: 0 -2px 0 rgba(0, 0, 0, 0.1) inset;

                &:before {
                  color: $light-gray;
                }
                &:active,
                &:hover,
                &:focus {
                  background: $white;
                  border-color: $light-gray;
                  box-shadow: none;

                  &:before {
                    color: $all-black;
                  }
                }
              }
            }
          }
        }
        th:last-child,
        td:last-child,
        th:last-child:hover,
        td:last-child:hover {
          width: 85px;
        }
        th.col-edit,
        td.col-edit,
        th.col-edit:hover,
        td.col-edit:hover {
          border-right: 0;
          box-shadow: -3px 0 3px rgba(0, 0, 0, 0.075), -1px 0 0 #d9d9d9 inset;
          right: 65px;

          @include min-screen($min-medium) {
            right: 85px;
          }
        }

        th.col-edit.edit-only,
        td.col-edit.edit-only,
        th.col-edit.edit-only:hover,
        td.col-edit.edit-only:hover {
          border-right: 0;
          box-shadow: -3px 0 3px rgba(0, 0, 0, 0.075), -1px 0 0 #d9d9d9 inset;
          min-width: 65px;
          right: 0;

          @include min-screen($min-medium) {
            min-width: 85px;
            right: 0;
          }
        }

        // only delete column
        th.col-delete,
        td.col-delete,
        th.col-delete:target,
        td.col-delete:hover {
          border-right: 0;
          box-shadow: -3px 0 3px rgba(0, 0, 0, 0.075), -1px 0 0 #d9d9d9 inset;
          min-width: 65px;
          width: 85px;

          @include min-screen($min-medium) {
            min-width: 85px;
          }
        }

        th.col-edit + th.col-delete,
        th.col-edit + td.col-delete,
        th.col-edit + th.col-delete:target,
        th.col-edit + td.col-delete:hover {
          box-shadow: none;
        }

        td.col-edit + td.col-delete {
          box-shadow: none;
        }

        th.col-edit,
        th.col-delete {
          background: rgba(255, 255, 255, 0.75) !important;
        }
      }
    }
  }
}

// table sorters
.nui-table-column-sorter {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 2;
  height: 20px;
  padding-right: 5px;

  .nui-table-column-sorter-inner-full {
    margin: 0 !important;
  }
}

.nui-table-column-has-sorters {
  padding-right: 35px !important;

  &.nui-table-align-right {
    padding-right: 35px !important;
  }
  &.nui-table-column-has-filters {
    .nui-table-header-column {
      padding-right: 29px;
    }
    i.anticon-filter {
      margin-right: 28px;
    }
  }
}

.market-depth {
  .nui-table-column-sorter {
    border-right: 1px solid $lightest-gray;
  }
}

// semi-transparent background for edit and delete sticky headers
th.col-edit,
th.col-delete {
  background: rgba(255, 255, 255, 0.75) !important;
}

// products table
.full-page-content .generic-table.products-table {
  .nui-table-content .nui-table-body {
    table {
      tbody {
        tr td {
          padding: 10px !important;

          &:first-child {
            padding-left: 20px !important;

            @include min-screen($min-large) {
              padding-left: 40px !important;
            }
          }
          &:last-child {
            padding-right: 20px !important;

            @include min-screen($min-large) {
              padding-right: 40px !important;
            }
          }
        }
      }
    }
  }
}

.full-page-content
  .customer-table
  .generic-table
  .nui-table-content
  .nui-table-body
  table {
  border-top: 1px solid $hr;

  thead,
  tbody {
    tr {
      th,
      td {
        padding: 10px !important;
      }
      th:first-child,
      td:first-child {
        max-width: 300px;
        padding-left: 20px !important;
        width: 300px;

        @include min-screen($min-large) {
          padding-left: 40px !important;
        }
      }
      th:last-child,
      td:last-child {
        padding-right: 20px !important;

        @include min-screen($min-large) {
          padding-right: 40px !important;
        }
      }

      th:nth-child(2),
      td:nth-child(2) {
        max-width: 100px;
        width: 100px;
      }

      th:nth-child(3),
      td:nth-child(3) {
        max-width: 200px;
        width: 200px;
      }
    }
  }
}

// reference price
.ref-price {
  .nui-fieldset {
    .input-holder {
      width: 80px;

      input {
        min-height: 36px;
      }
    }
  }
}

.nui-modal {
  h2#customers {
    border-bottom: 1px solid $hr;
    margin-bottom: 20px;
    margin-top: -25px;
    padding: 0 0 15px;
  }
  .customer-table {
    margin: 0 0 20px;

    .generic-table {
      margin: 0;
    }
  }
}

.nui-modal {
  &.medium {
    .customer-table {
      max-height: 50vh;
    }
  }
}

// products table in modal
.nui-modal {
  .full-page-header {
    background: none;
    margin: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .nui-row {
    padding-left: 0;
    padding-right: 0;
  }

  table tr:hover td:first-child {
    border-left: 1px solid $hr !important;
  }

  .full-page-content {
    h2.light {
      font-size: 1.3rem;
    }
    .generic-table,
    .products-table,
    .generic-table.products-table {
      margin-top: 0;

      .nui-table-content .nui-table-body {
        table {
          tbody,
          thead {
            tr {
              td,
              th {
                padding: 10px !important;

                &:first-child,
                &:last-child {
                  padding: 10px !important;
                }
              }
            }
          }
        }
      }
    }
    .customer-table {
      .generic-table .nui-table-content .nui-table-body table {
        border-top: 0;
      }
    }
  }
}

// new customers page

html .full-page-content .generic-table.light {
  thead,
  tbody {
    tr {
      .col-buyer {
        left: 0;
        min-width: 200px;
        position: sticky !important;
        position: -webkit-sticky !important;
        z-index: 4;

        @include min-screen($min-medium) {
          max-width: 290px;
          min-width: 290px;
          width: 290px;
        }
      }

      .col-buyer-shortcode,
      .col-seller-shortcode {
        max-width: 150px;
        width: 150px;
      }

      .col-seller {
        min-width: 240px;
        width: auto !important;
      }

      .col-switcher,
      .col-expand {
        max-width: 110px;
        width: 110px;
      }

      .col-desc {
        width: auto;
      }
    }
  }
}

.full-page-content.tab-content {
  background: #f9f9f9;
  margin-bottom: -10px;
  margin-top: -5px;
  padding-top: 20px;

  .nui-tabs-nav {
    padding: 0 20px;

    @include min-screen($min-medium) {
      padding: 0 40px;
    }
  }
}

// nui expand button
.nui-table-row-expand-icon {
  @include expand;
  cursor: pointer;
  display: inline-block;
  left: auto;
  margin: 0 10px 0 0;
  position: relative;
  top: -1px;
  vertical-align: middle;

  &:before {
    cursor: pointer;
    left: 5px;
    right: auto;
    transition: all 0.2s linear;
  }
  &.nui-table-row-collapsed {
    &:after {
      content: ' ';
    }
  }
  &.nui-table-row-expanded {
    &:after {
      content: ' ';
    }
    &:before {
      top: 4px;
      transform: rotate(180deg) scale(0.8, 0.8);
      -webkit-transform: rotate(180deg) scale(0.8, 0.8);
    }
  }
}

.generic-table
  .nui-table-content
  .nui-table-body
  table
  tr
  td
  .nui-table-row-expand-icon {
  color: var(--link);
  display: inline-block;
  font-size: 0.8rem;
  height: 25px;
  line-height: 25px;
  margin: 10px 10px 10px 0;
  padding: 0 5px 0 20px;
  width: auto;

  &:before {
    top: 9px;
  }
  &:after {
    content: 'Details';
  }
  &.nui-table-row-expanded {
    &:before {
      top: 8px;
    }
  }
}

// simple table (see consolidation for example)
html,
html
  .full-page-content
  .generic-table.expand-table
  .nui-table-content
  .nui-table-body
  tr.nui-table-expanded-row
  td {
  .nui-table {
    &.simple-table {
      table {
        background: none;
        width: 100%;
      }
      thead {
        tr {
          th {
            background: none !important;
            border: 0;
            border-bottom: 1px solid $hr;
            border-left: 1px solid $hr;
            box-shadow: none;
            color: $all-black;
            padding: 0;

            &:first-child {
              border-left: 0;
            }
          }
        }
      }

      tbody {
        tr,
        tr:hover {
          td,
          td:first-child {
            background: none;
            border: 0;
            border-bottom: 1px solid $hr;
            border-left: 1px solid $hr;
            box-shadow: none;
            white-space: nowrap;
          }
          td:first-child {
            border-left: 0;
          }
        }
      }

      thead,
      tbody {
        tr,
        tr:hover {
          th,
          td {
            padding: 0;
            vertical-align: middle;

            &:first-child {
              background: none;
              padding: 0 !important;
            }
            &:last-child {
              background: none;
              padding: 0 !important;
            }
            .th-content,
            .td-content {
              padding: 10px;
              .nui-check-fieldset {
                margin: 0;
              }
            }

            &.col-add {
              background: #eef1f8 !important;
              box-shadow: 2px 0 2px rgba(0, 0, 0, 0.1);
              left: 0;
              position: sticky;
              position: -webkit-sticky;
              width: 20px;

              .th-content,
              .td-content {
                padding: 10px 0;
              }
            }
            &.col-delete {
              text-align: right;
            }
          }
        }
      }
    }
  }
}

// deliveries table
.delivery-trades-table {
  border-top: 1px solid $lightest-gray;
}

html .full-page-content .generic-table.light.deliveries-table thead,
html .full-page-content .generic-table.light.deliveries-table tbody {
  tr {
    td {
      white-space: nowrap;
    }
    .col-buyer {
      min-width: 1px;
      position: relative !important;
    }
  }
}

.deliveries-table {
  margin-top: -62px;

  .col-details {
    width: 100px;
  }
}

//// refactoring

// sticky
.th-sticky tr {
  position: relative;
}

table thead th.sticky,
table tbody td.sticky {
  background: $white !important;
  position: sticky !important;
  position: -webkit-sticky !important;

  &.on-left {
    left: 0;
    right: auto;
  }
  &.on-right {
    left: auto;
    right: 0;
  }
}

table thead th.sticky {
  background: rgba(255, 255, 255, 0.75) !important;
}

// shading
table thead th.shaded-right,
table tbody td.shaded-right {
  box-shadow: 2px 0 2px rgba(0, 0, 0, 0.05);
}

table thead tr:hover th.shaded-right,
table tbody tr:hover td.shaded-right {
  box-shadow: 2px 0 2px rgba(0, 0, 0, 0.05), 0 -5px 0 rgba(0, 0, 0, 0.035) inset !important;
}

table thead th.shaded-left,
table tbody td.shaded-left,
%shaded-left {
  box-shadow: -2px 0 2px rgba(0, 0, 0, 0.05);
}

table thead tr:hover th.shaded-left,
table tbody tr:hover td.shaded-left {
  box-shadow: -2px 0 2px rgba(0, 0, 0, 0.05),
    0 -5px 0 rgba(0, 0, 0, 0.035) inset !important;
}

// no data
.nui-table-placeholder {
  position: relative;
  z-index: 21;
}
