// filters

// filter dropdown in th
//======================
table.generic-table,
.ant-table-default table {
  .ant-table-thead > tr > th.ant-table-column-has-filters {
    .ant-table-column-title {
      padding-right: 10px;
    }
  }
  > th.ant-table-column-has-filters .ant-table-filter-selected.anticon-filter {
    color: $ok;
  }
}

.ant-dropdown {
  .ant-table-filter-dropdown {
    border: 1px solid $lightest-gray;
    border-radius: 3px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
    font-size: 0.8rem;
    margin-left: 25px;
    margin-top: 10px;
    min-width: 150px;

    ul {
      padding: 0;
    }
    .ant-dropdown-menu-item {
      margin-top: 2px;

      &:hover {
        background: rgba(0, 0, 0, 0.05);
      }
      label + span {
        font-size: 0.8rem;
      }
    }
    .ant-dropdown-menu-item-selected {
      background: rgba(0, 0, 0, 0.05);
    }
    .ant-table-filter-dropdown-link {
      color: var(--link);
    }
    .ant-table-filter-dropdown-btns {
      padding-top: 8px;

      a.ant-table-filter-dropdown-link {
        border-radius: 3px;
        display: inline-block;
        float: none;
        margin: 0 10px 2px 0;
        padding: 2px 14px;

        &.confirm {
          @extend %nd;
          background: var(--link);
          color: $white;
        }
      }
    }
  }
}

// orders/trade filters (etd)
.filter-fields,
.ant-row.ant-form-item.filter-fields {
  display: block;
  padding: 11px 0 0;
  white-space: nowrap;

  @include min-screen($min-small) {
    display: inline-block;
    vertical-align: top;
  }

  .ant-col.ant-form-item-label {
    @extend %bold;
    color: $all-black;
    padding: 0;
    text-align: left;

    label {
      color: $all-black;
      font-size: 0.9rem;

      &:after {
        display: none;
      }
    }
    @include min-screen($min-xsmall) {
      display: inline-block;
      margin: 0 20px 0 0;
      vertical-align: middle;
      width: 90px;
    }
  }
  .ant-col.ant-form-item-control-wrapper {
    @include min-screen($min-xsmall) {
      display: inline-block;
      max-width: 360px;
      vertical-align: middle;
      width: calc(100% - 110px);
    }
  }
}

.status-filter {
  .ant-form-item-control {
    line-height: 25px;
  }
  .ant-row.ant-form-item {
    padding: 10px 0 0;

    @include min-screen($min-medium) {
      margin-bottom: -10px;
    }
    .ant-checkbox-wrapper,
    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      line-height: 1rem;
      margin-left: 0;
      margin-right: 8px;
    }
    .ant-col.ant-form-item-label {
      @extend %bold;
      color: $all-black;
      margin: 0 0 8px;
      padding: 0;
      text-align: left;

      label {
        color: $all-black;
        font-size: 0.9rem;

        &:after {
          display: none;
        }
      }
      @include min-screen($min-small) {
        display: inline-block;
        margin: 0 20px 0 0;
        vertical-align: middle;
        width: 150px;
      }
    }
  }
  .ant-col.ant-form-item-control-wrapper {
    @include min-screen($min-small) {
      display: inline-block;
      vertical-align: middle;
      white-space: nowrap;
      width: auto;
    }
  }
}

// orders/trade filter-section advanced version
.filter-section {
  @include min-screen($min-medium) {
    min-height: 10px;
  }
  .filter-block {
    height: 0;
    padding: 0;
    overflow: hidden;
    transition: 0.2s all ease;

    .nui-field-holder {
      padding: 0 0 20px;
      position: relative;
      vertical-align: top;
      z-index: 7;

      &.cb-set {
        z-index: 8;
      }

      .ant-calendar-picker {
        display: block;
        > .ant-calendar-picker-input {
          line-height: 34px;
          min-height: 44px;
        }
      }

      > label {
        @extend %bold;
        color: $all-black;
        display: block;
        margin: 0 0 5px;
        width: 100%;
      }
      @include min-screen($min-medium) {
        display: inline-block;
        padding: 0 20px 20px 0;
        width: 50%;
      }
      @include min-screen($min-xlarge) {
        width: 33%;
      }
    }
    .nui-field-holder {
      .search-holder {
        position: relative;

        &:after {
          @extend %fontello;
          color: rgba(0, 0, 0, 0.25);
          content: '\e815';
          font-size: 1.2rem;
          pointer-events: none;
          position: absolute;
          right: 11px;
          top: 11px;
          z-index: 3;
        }
        &:active,
        &:focus {
          &:after {
            color: rgba(5, 2, 2, 0.5);
          }
        }
      }
      input[type='search'] {
        background: $white;
        border-radius: 3px;
        border: 1px solid $lightest-gray;
        box-sizing: border-box;
        color: $all-black;
        height: 44px;
        outline: 0;
        padding: 5px 10px;
        position: relative;
        transition: none;
        vertical-align: top;
        width: 100%;
        z-index: 3;

        &:active,
        &:focus,
        &:hover {
          border-color: $mid-gray;
        }
      }
    }
  }

  @include min-screen($min-small) {
    border-left: 1px solid transparent;
    display: inline-block;
    margin: 12px 0 0 0;
    padding: 0 0 0 15px;
    vertical-align: top;
    width: calc(100% - 220px);
  }
  @include min-screen($min-small) {
    margin: 12px 0 0 15px;
  }

  .filterme {
    .filter-message {
      display: block;
      padding: 10px 0;

      @include min-screen($min-medium) {
        white-space: nowrap;
      }
      @include min-screen($min-xlarge) {
        padding: 10px 0 0;
      }
    }

    button.filter-button {
      margin: 0 15px 0 0;
      padding: 8px 24px;
      position: relative;

      @include max-screen($max-xsmall) {
        margin-right: 0;
        position: absolute;
        right: 20px;
        top: 10px;
      }
      span.arrow {
        @include arrow(14, 0);
        border-top-color: $white;
        left: 10px;
        right: auto;
        -webkit-transform: scale(0.8, 0.8);
        transform: scale(0.8, 0.8);
      }
    }
  }

  &.opened {
    .filter-block {
      height: auto;
      margin: 20px 0 10px;
      overflow: unset;

      @include min-screen($min-small) {
        margin: 10px 0 -20px;
      }
      @include min-screen($min-medium) {
        width: calc(100% + 25px);
      }
      @include min-screen($min-xlarge) {
        width: calc(100% + 35px);
      }
    }

    .filterme {
      .filter-message {
        @include max-screen($max-small) {
          border-bottom: 1px solid $lightest-gray;
        }
      }

      button {
        span.arrow {
          -webkit-transform: scale(0.8, 0.8) rotate(180deg);
          transform: scale(0.8, 0.8) rotate(180deg);
        }
      }
    }
    @include min-screen($min-small) {
      border-left-color: $hr;
      position: relative;
    }
  }
}

// dropcheck
.filter-section,
.nui-form {
  .dropcheck {
    background: $white;
    border-radius: 3px;
    border: 1px solid $lightest-gray;
    box-sizing: border-box;
    color: $all-black;
    height: 44px;
    padding: 5px 10px;
    position: relative;
    transition: none;
    vertical-align: top;
    width: 100%;
    z-index: 3;

    .check-field {
      margin: 0;

      .nui-check-fieldset {
        margin: 0;
      }
      &.option-all {
        .nui-label {
          @extend %bold;
          color: $all-black;
        }
      }
    }

    .showoptions {
      background: none;
      border: 0;
      box-shadow: none;
      display: block;
      height: 42px;
      padding-left: 10px;
      position: absolute;
      right: 0;
      text-align: left;
      top: 0;
      width: 100%;
      z-index: 5;

      span.total {
        color: $light-gray;
        display: inline-block;
        font-size: 0.9rem;
        max-width: 100%;
        overflow: hidden;
        padding-right: 10px;
        padding-top: 3px;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      span.arrow {
        @include arrow(18, 15);
      }
    }

    .options {
      height: 0;
      overflow: hidden;
      transition: none;

      &.visible {
        background: $white;
        border-radius: 0 0 3px 3px;
        border: 1px solid $lightest-gray;
        border-top: 0;
        box-sizing: border-box;
        box-shadow: 0 1px 0 $lightest-gray inset;
        color: $all-black;
        height: auto;
        left: -1px;
        max-height: 300px;
        overflow-y: auto;
        padding: 0;
        position: absolute;
        top: 40px;
        vertical-align: top;
        width: calc(100% + 2px);
        z-index: 3;

        &:before {
          background: $white;
          content: ' ';
          display: block;
          height: 3px;
          left: 0;
          top: -1px;
          width: 10px;
        }
        &:after {
          background: $white;
          content: ' ';
          display: block;
          height: 3px;
          position: absolute;
          right: 0;
          top: -1px;
          width: 10px;
          z-index: 5;
        }
      }
      .check-field {
        padding: 5px 10px;

        .nui-label {
          display: block;
        }
        &:hover {
          background: rgba(0, 0, 0, 0.05);
        }
      }
    }

    // product filter
    .check-fieldset.all.product-filter,
    .check-fieldset.parent.product-filter {
      > .nui-check-fieldset {
        .nui-label {
          @extend %bold;
          border-top: 1px solid $hr;
          color: $all-black;
          padding-top: 10px;
          width: 100%;

          &:before {
            top: 10px;
          }
        }
      }
    }

    .check-fieldset.parent.product-filter {
      &:only-of-type {
        > .nui-check-fieldset {
          .nui-label {
            border-top: 0;
          }
        }
      }
    }

    .check-fieldset.parent.product-filter {
      fieldset.child {
        .check-fieldset {
          &:hover {
            background: rgba(0, 0, 0, 0.05);
          }
        }
      }
    }

    .check-fieldset.all.product-filter {
      > .nui-check-fieldset {
        .nui-label {
          border: 0;
          padding-top: 0;

          &:before {
            top: 0;
          }
        }
      }
    }

    // and since this set up is different than the other fieldsets...
    .check-fieldset.all.product-filter,
    .check-fieldset.parent.product-filter {
      .nui-check-fieldset {
        margin: 0;
        padding: 5px 10px;
      }
    }
  }
}

.filter-section {
  .filter-block {
    .nui-field-holder {
      &:hover {
        .dropcheck,
        .options {
          border-color: $mid-gray;
        }
      }
    }
  }
}

// export to csv
.filter-export {
  min-height: 12px;
  position: relative;

  .filterme button.filter-button {
    font-size: 0.8rem;
  }

  @include min-screen($min-small) {
    display: inline-block;
    margin: 12px 0 2px 15px;
    vertical-align: top;
    width: calc(100% - 220px);
  }
  .filter-section {
    margin: 0;
    width: 100%;

    @include min-screen($min-small) {
      margin: 0;
    }
    @include max-screen($max-xsmall) {
      .filterme button.filter-button {
        right: 0;
        top: -30px;
      }
      .filter-message {
        padding-top: 25px;
      }
      &.opened {
        margin-top: -20px;
        padding-top: 10px;

        .filterme button.filter-button {
          top: -10px;
        }
      }
    }
  }
}

// export button
.filter-export {
  button.export-button {
    font-size: 0.8rem;
    min-width: 80px;
    padding: 8px 16px;
    position: absolute;
    right: 94px;
    top: -30px;
    z-index: 3;

    span:before {
      display: inline-block;
      font-size: 1rem !important;
      margin: 0 10px 0 -5px !important;
    }
    .icon-spin6 {
      display: inline-block;
      left: -5px;
      margin: 0 !important;
      position: relative;

      &:before {
        font-size: 0.8rem !important;
        margin: 0 !important;
      }
    }
    .icon-spin6 + .icon-export {
      display: none;
    }

    @include min-screen($min-small) {
      left: 110px;
      right: auto;
      top: 0;
    }
    @include min-screen($min-large) {
      left: 160px;
    }
  }

  @include max-screen($max-xsmall) {
    .filter-section.opened {
      button.export-button {
        top: -10px;
      }
    }
  }
}

// cancel all orders button
.filter-export + .has-dd-holder-reverse.cancel-all {
  font-size: 0.8rem;
  right: 204px;
  top: 17px;

  @include min-screen($min-small) {
    left: 356px;
    right: auto;
    top: 12px;
  }
  @include min-screen($min-medium) {
    left: 391px;
    top: 22px;
  }
  @include min-screen($min-large) {
    left: 492px;
  }
}

.filter-export,
.filter-export * {
  transition: none !important;
}

// export to csv (only)
.filter-export {
  .export-button:only-of-type {
    left: 0;

    .show-for-large {
      position: relative;
      visibility: visible;
      z-index: 2;
    }
    @include max-screen($max-xsmall) {
      left: auto;
      right: 0;
      top: -30px;
    }
  }
}

// customer request filter
.customer-request-filter {
  position: relative;

  .customer-request-filter-block {
    @include min-screen($min-medium) {
      padding: 10px 150px 0 0;
    }
    @include min-screen($min-large) {
      padding: 15px 150px 0 0;
    }
  }
  span.status {
    @extend %bold;
    color: $all-black;
    display: block;

    @include min-screen($min-large) {
      display: inline-block;
      margin-right: 20px;
      vertical-align: middle;
    }
  }
  .nui-check-fieldset {
    display: inline-block;
    margin: 0 10px 0 0;
    vertical-align: middle;
  }
  .new-request {
    margin-top: 10px;

    @include min-screen($min-medium) {
      position: absolute;
      right: 40px;
      top: 30px;
    }
    @include min-screen($min-large) {
      top: 14px;
    }
  }
}

// new marketplace filter
button.nui-button.show-filter-section {
  font-size: 0.8rem;
  margin-right: 10px;
  padding-left: 10px;
  padding-right: 15px;
  position: relative;
  top: 2px;

  span.arrow {
    &:after {
      display: none;
    }
  }
  @include min-screen($min-medium) {
    margin-right: 0;
    top: -5px;
  }
  span.show-if-filtered {
    color: $white;
    display: inline-block;
    font-size: 0.9rem;
    margin: 0 5px 0 0;
  }
  span.arrow {
    @include arrow(0, 0);
    border-top-color: rgba(255, 255, 255, 0.75);
    display: inline-block;
    margin: 0 5px 0 0;
    padding: 0;
    position: relative;
    right: auto;
    top: -1px;
    transform: scale(0.8, 0.8);
    -webkit-transform: scale(0.8, 0.8);
    vertical-align: middle;
  }
  &.opened {
    span.arrow {
      transform: scale(0.8, 0.8) rotate(180deg);
      -webkit-transform: scale(0.8, 0.8) rotate(180deg);
    }
  }
}

// filter block
.market-filter-full {
  background: #f9f9f9;
  margin: 10px 0 0 0;
  padding: 10px 0;
  transition: all 0.2s ease;
  width: 100vw;

  .filter-presets {
    max-height: 200px;
    overflow: hidden;
    overflow-y: auto;
    padding: 0 0 20px;

    h2 {
      @extend %bold;
      color: $all-black;
      font-size: 0.9rem;
      margin: 0;
      padding: 0;
    }
    p {
      color: $light-gray;
      display: block;
      font-size: 0.75rem;
      line-height: 1rem;
      padding: 0 0 5px;
    }
    ul {
      list-style: none;
      margin: 0 10px 0 0;
      padding: 0;

      li {
        border-bottom: 1px solid #dedede;
        display: block;
        margin: 0;
        padding: 0;
        position: relative;

        span.smaller {
          padding-bottom: 5px;
        }
        .nui-button.nui-button-link {
          @extend %regular;
          display: block;
          font-size: 0.8rem;
          line-height: 1.1rem;
          padding: 5px 30px 5px 0;
        }
        button.deleteme {
          background: none !important;
          border: 0;
          line-height: 25px;
          margin: 0;
          padding: 0 !important;
          position: absolute;
          right: 0;
          text-align: right;
          top: 0;
          width: 35px;
          z-index: 3;

          span:before {
            color: $light-gray !important;
            margin: 0;
          }
          &:hover {
            background: none;
            border: 0;
            box-shadow: none;

            span:before {
              color: $mid-gray !important;
            }
          }
        }
      }
    }
    @include min-screen($min-medium) {
      border-right: 1px solid $hr;
      display: inline-block;
      padding: 0 10px 0 0;
      vertical-align: top;
      width: 200px;
    }
  }

  .filter-block {
    height: auto;
    overflow: unset;
    transition: none;

    @include min-screen($min-medium) {
      border-left: 1px solid $hr;
      display: inline-block;
      margin-left: -1px;
      padding: 0 0 0 20px;
      vertical-align: top;
      width: calc(100% - 200px);
    }
    .nui-field-holder {
      @include min-screen($min-xxlarge) {
        width: 25%;
      }
    }
  }
}

.market-filter-message {
  transition: 0.1s all linear;
  white-space: nowrap;

  p,
  button.nui-button {
    display: inline-block;
    margin: 0;
    padding: 10px 0 0;
    vertical-align: middle;
  }
}

.nui-fieldset.save-order-preset {
  input {
    padding-right: 75px;
  }
  button {
    background: none;
    border: 0;
    box-shadow: none;
    padding: 0;

    span {
      cursor: pointer;
      display: block;
      font-size: 0.75rem;
      padding: 4px 8px 4px 6px !important;
      position: absolute;
      right: 28px;
      top: 8px;
      z-index: 3;
    }
  }
}

// help dd for marketplace
.mpkey {
  display: inline-block;
  position: relative;
  vertical-align: middle;

  .show-mpkey-panel,
  .nui-button.nui-primary.show-mpkey-panel {
    background: var(--link);
    border-radius: 50%;
    color: $white;
    cursor: pointer;
    display: block;
    height: 22px;
    line-height: 20px;
    margin: 0 10px;
    min-height: 1px;
    padding: 0;
    position: relative;
    text-align: center;
    width: 23px;
    z-index: 11;

    @include min-screen($min-medium) {
      top: -5px;
    }
    &:before {
      font-size: 1rem;
      line-height: 22px;
      margin: 0;
    }
  }
  .mpkey-panel {
    background: $white;
    border: 1px solid $input-border-color;
    border-radius: 3px;
    color: $input-color;
    min-height: 40px;

    @include min-screen($min-small) {
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
    }
    height: auto;
    left: -1px;
    opacity: 1;
    overflow: hidden;
    padding: 10px;
    position: absolute;
    top: -19px;
    transition: all 0.2s linear;
    width: 320px;
    z-index: 20;

    @include max-screen($max-small) {
      top: -14px;
      width: 270px;
    }
    h2 {
      color: $all-black;
      display: block;
      font-size: 1rem;
      margin: 0;
      padding: 0;

      em.icon-help {
        background: var(--link);
        border-radius: 50%;
        color: $white;
        cursor: pointer;
        display: inline-block;
        height: 22px;
        line-height: 26px;
        margin: 0 5px 0 0;
        min-height: 1px;
        padding: 0;
        position: relative;
        text-align: center;
        width: 23px !important;
        z-index: 11;

        &:before {
          @extend %fontello;
          color: $white;
          content: '\f128';
          margin: 0;
        }
      }
    }
    h2 + p {
      color: $light-gray;
      font-size: 0.7rem;
      line-height: 1rem;
      padding: 0 0 0 30px;
    }

    p.icon-help-circled {
      font-size: 0.8rem;
      line-height: 1.2rem;
      padding: 5px 0 5px 40px;
      position: relative;

      &:before {
        color: $lightest-gray;
        font-size: 1.2rem;
        left: 1px;
        position: absolute;
        top: 6px;
      }
    }

    ul {
      border-bottom: 1px solid $hr;
      border-top: 1px solid $hr;
      font-size: 0.8rem;
      list-style: none;
      margin: 5px 0 10px;
      padding: 5px 0 10px;

      li {
        padding: 2px 0 2px 40px;
        position: relative;
      }
      em {
        display: inline-block;
        font-style: normal;
      }
      .mp-help-icon {
        cursor: default;
        display: inline-block;
        left: 0;
        position: absolute;
        top: 3px;

        // broker order in help panel
        &.broker-offer-bid {
          span {
            background: $white;
            border-radius: 50%;
            border: 1px solid #0d8abc;
            display: block;
            height: 12px;
            left: 9px;
            position: relative;
            top: 4px;
            width: 12px;

            &:before {
              background: #0d8abc;
              border-radius: 50%;
              content: ' ';
              display: block;
              height: 6px;
              left: 2px;
              position: absolute;
              top: 2px;
              width: 6px;
              z-index: 3;
            }
          }
        }

        &.my-offer-bid {
          margin: 0 0 0 5px;

          span {
            display: block;
            height: 19px;
            position: relative;
            width: 19px;
          }
          &:before {
            background: #c68508;
            border-radius: 50%;
            content: ' ';
            display: block;
            height: 6px;
            left: 50%;
            margin: -3px 0 0 -3px;
            position: absolute;
            top: 50%;
            width: 6px;
            z-index: 2;
          }
        }
        &.private {
          @extend %bold;
          background: #e6e6e6;
          border-radius: 50%;
          color: $dark-gray;
          display: block;
          font-size: 0.6rem;
          height: 19px;
          line-height: 19px;
          margin: 0 0 0 5px;
          padding: 0;
          text-align: center;
          text-transform: uppercase;
          width: 19px;
        }
        &.best-counter {
          min-width: 1px;
          width: 30px;
        }
      }
    }
  }
}

// customer filters
.customer-filters {
  .dropcheck {
    background: $white;
    border-radius: 3px;
    border: 1px solid $lightest-gray;
    box-sizing: border-box;
    color: $all-black;
    height: 44px;
    padding: 5px 10px;
    position: relative;
    transition: none;
    vertical-align: top;
    width: 25%;
    z-index: 3;
    margin-top: 5px;

    .check-field {
      margin: 0;

      .nui-check-fieldset {
        margin: 0;
      }
      &.option-all {
        .nui-label {
          @extend %bold;
          color: $all-black;
        }
      }
    }

    .showoptions {
      background: none;
      border: 0;
      box-shadow: none;
      display: block;
      height: 42px;
      padding-left: 10px;
      position: absolute;
      right: 0;
      text-align: left;
      top: 0;
      width: 100%;
      z-index: 5;

      span.total {
        color: $light-gray;
        display: inline-block;
        font-size: 0.9rem;
        max-width: 100%;
        overflow: hidden;
        padding-right: 10px;
        padding-top: 3px;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      span.arrow {
        @include arrow(18, 15);
      }
    }

    .options {
      height: 0;
      overflow: hidden;
      transition: none;

      &.visible {
        background: $white;
        border-radius: 0 0 3px 3px;
        border: 1px solid $lightest-gray;
        border-top: 0;
        box-sizing: border-box;
        box-shadow: 0 1px 0 $lightest-gray inset;
        color: $all-black;
        height: auto;
        left: -1px;
        max-height: 300px;
        overflow-y: auto;
        padding: 0;
        position: absolute;
        top: 40px;
        vertical-align: top;
        width: calc(100% + 2px);
        z-index: 3;

        &:before {
          background: $white;
          content: ' ';
          display: block;
          height: 3px;
          left: 0;
          top: -1px;
          width: 10px;
        }
        &:after {
          background: $white;
          content: ' ';
          display: block;
          height: 3px;
          position: absolute;
          right: 0;
          top: -1px;
          width: 10px;
          z-index: 5;
        }
      }
      .check-field {
        padding: 5px 10px;

        .nui-label {
          display: block;
        }
        &:hover {
          background: rgba(0, 0, 0, 0.05);
        }
      }
    }
  }
  border-top: 1px solid $hr;
  margin: 20px 0 -15px;
  padding: 10px 0 20px;

  span.status {
    @extend %bold;
    color: $all-black;
    display: block;
    font-size: 0.9rem;
    margin: 0 0 5px;

    @include min-screen($min-medium) {
      display: inline-block;
      margin: 0 10px 0 0;
      vertical-align: top;
      width: 150px;
    }
  }
  .status + div {
    margin-top: -5px;
  }

  .nui-check-fieldset {
    display: inline-block;
    margin: 5px 10px 0 0;
    min-width: 115px;
    vertical-align: top;

    @include min-screen($min-medium) {
      margin: 5px 20px 0 0;
    }
    label {
      margin: 0;
    }
  }
  @include min-screen($min-medium) {
    margin: 10px 0 -30px;
    min-height: 35px;

    .nui-check-fieldset {
      min-width: 1px;
    }
    .status + div {
      display: inline-block;
      vertical-align: top;
      width: calc(100% - 160px);
    }
  }
}

// dashboard filter
html .dashboard2 .filter-section,
html .dashboard2 .filter-section.opened {
  &.dashboard-filter {
    border: 0;
    border-bottom: 1px solid $hr;
    display: block;
    margin: 0 0 20px;
    padding: 0 0 10px;
    position: relative;
    right: auto;
    top: auto;
    width: calc(100% + 20px);

    @include min-screen($min-large) {
      width: calc(100% + 20px);
    }

    .filter-block {
      margin: 0;
      width: 100%;
    }

    .filter-message {
      strong {
        color: $all-black;
      }
    }
    .nui-fieldset {
      text-align: left;
      white-space: normal;
    }

    .nui-fieldset,
    .nui-field-holder {
      display: inline-block;
      padding: 0 20px 20px 0;
      vertical-align: top;

      &.currency-filter {
        width: 50%;

        @include min-screen($min-small) {
          width: 32%;
        }
        @include min-screen($min-medium) {
          width: 22%;
        }
      }
      &.unit-filter {
        width: 50%;
        @include min-screen($min-small) {
          width: 30%;
        }
        @include min-screen($min-medium) {
          width: 23%;
        }
      }
      &.tradetype-filter {
        width: 50%;
        @include min-screen($min-small) {
          width: 30%;
        }
        @include min-screen($min-medium) {
          width: 22%;
        }
      }
      &.product-filter {
        width: 50%;
        @include min-screen($min-small) {
          width: 30%;
        }
        @include min-screen($min-medium) {
          width: 22%;
        }
      }
      &.products-filter {
        width: 50%;
        @include min-screen($min-small) {
          width: 40%;
        }
        @include min-screen($min-medium) {
          width: 33%;
        }
      }

      // customer engagement
      &.viewedon-filter {
        width: 50%;
        @include min-screen($min-medium) {
          width: 200px;
        }
      }
      &.platformrole-filter {
        width: 50%;
        @include min-screen($min-medium) {
          width: 200px;
        }
      }

      // if currency
      &.currency-filter + .unit-filter {
        width: 50%;
        @include min-screen($min-small) {
          width: 34%;
        }
        @include min-screen($min-medium) {
          width: 23%;
        }
      }

      &.currency-filter + .unit-filter + .tradetype-filter {
        width: 50%;
        @include min-screen($min-small) {
          width: 32%;
        }
        @include min-screen($min-medium) {
          width: 22%;
        }
      }

      &.currency-filter + .unit-filter + .tradetype-filter + .products-filter {
        width: 50%;
        @include min-screen($min-small) {
          width: 33%;
        }
        @include min-screen($min-medium) {
          width: 33%;
        }
      }

      label {
        display: block !important;
        margin: 0 0 8px;
      }
      .select-holder {
        max-width: none;
      }
    }
  }
}
