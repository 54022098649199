// wizard
.step-sticker {
  background: $white;
  border-bottom: 1px solid $hr;
  border-top: 1px solid $hr;
  min-height: 30px;
  padding: 10px 20px;
  position: relative;
  top: -5px;
  margin: 0 0 0 -20px;
  width: calc(100% + 40px);

  @include max-screen($max-small) {
    padding: 10px 30px;
    position: relative !important;
    width: calc(100% + 40px) !important;
  }
}

.ant-steps,
.ant-steps.ant-steps-horizontal.ant-steps-label-horizontal {
  .ant-steps-item {
    @include max-screen($max-small) {
      display: inline-block;
      padding-right: 0;
      width: auto;
    }
    .ant-steps-item-icon {
      @extend %bold;
      border-radius: 50%;
      font-size: 0.7rem;
      height: 20px;
      line-height: 19px;
      width: 20px;
      position: relative;
      top: 5px;
    }
    .ant-steps-item-content {
      width: 100%;

      @include max-screen($max-small) {
        min-height: 30px;
      }
      .ant-steps-item-title {
        font-size: 0.9rem;
      }
      .ant-steps-item-description {
        font-size: 0.7rem;
        max-width: calc(100% - 25px);

        @include max-screen($max-small) {
          display: none;
        }
      }
    }

    .ant-steps-item-content .ant-steps-item-title {
      &:after {
        background-color: transparent;
        border-top: 1px dashed $lightest-gray;
      }
    }

    // finished step
    &.ant-steps-item-finish {
      .ant-steps-item-icon {
        background: var(--link);
        border-color: var(--link);

        .ant-steps-icon {
          font-size: 0;
          &:before {
            @extend %fontello;
            color: $white;
            content: '\e802';
            font-size: 0.7rem;
          }
        }
      }
      .ant-steps-item-content .ant-steps-item-title {
        &:after {
          background-color: transparent;
          border-top: 1px dashed $light-gray;
        }
      }
    }

    // error
    &.ant-steps-item-error {
      .ant-steps-item-icon {
        background: $cancel;
        border-color: $cancel;

        .ant-steps-icon {
          font-size: 0;
          &:before {
            @extend %fontello;
            color: $white;
            content: '\e803';
            font-size: 0.7rem;
          }
        }
      }
      .ant-steps-item-content {
        .ant-steps-item-title,
        .ant-steps-item-description {
          color: $cancel;
        }
      }
    }

    // current step
    &.ant-steps-item-process {
      .ant-steps-item-icon {
        background: var(--link);
        border-color: var(--link);
      }
    }
  }
}

.ant-steps {
  .ant-steps-item {
    &.ant-steps-item-process {
      .ant-steps-item-icon {
        background: var(--link);
        border-color: var(--link);
        border-radius: 50%;
        font-size: 0.7rem;
        height: 20px;
        line-height: 19px;
        width: 20px;
        position: relative;
        top: 5px;

        > .ant-steps-icon {
          color: $white;
          i {
            display: none;
          }
        }
      }
    }
    &.ant-steps-item-finish {
      .ant-steps-item-icon {
        background: var(--link);
        border-color: var(--link);
        border-radius: 50%;
        font-size: 0.7rem;
        height: 20px;
        line-height: 19px;
        width: 20px;
        position: relative;
        top: 5px;

        > .ant-steps-icon {
          &:before {
            @extend %fontello;
            color: $white;
            content: '\e802';
            font-size: 0.7rem;
          }
          i {
            display: none;
          }
        }
      }
    }
  }
}

.wizard-content {
  > div {
    padding: 0 15px;

    @include max-screen($max-small) {
      padding: 0;
    }
  }
}

//////////////////////////////////////////////////////////// new wizard ////////////////////////////////////////////////////////////

.wizard {
  &.multiple {
    counter-reset: wizard-counter;
    position: relative;

    hr.dashed {
      border-top-color: $lightest-gray;
    }

    .title-block,
    .stepper {
      @include max-screen($max-small) {
        display: none;
      }
    }

    .title-block {
      h2 {
        @extend %bold;
        color: $all-black;
        margin-top: 0;

        span {
          @extend %regular;
          color: $light-gray;
          float: right;
          font-size: 0.8rem;

          strong {
            color: $all-black;
          }
        }
      }
      .nui-alert {
        margin-bottom: 20px;
      }
    }

    .form-steps-container {
      .nui-alert {
        margin-bottom: 20px;
      }
    }

    div.wizard-steps {
      margin: 0 0 20px;
      padding: 20px 134px 0 0;
      position: relative;
      vertical-align: top;

      @include max-screen($max-small) {
        padding: 20px 0 0;
      }

      ul {
        border-bottom: 1px solid $hr;
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          display: block;
          padding-right: 20px;
          position: relative;
          width: 100%;

          h2 {
            @extend %bold;
            color: $all-black;
            font-size: 1.1rem;
            margin: 0;
            padding: 0;
            // add counter
            counter-increment: wizard-counter;
            padding-left: 25px;
            position: relative;

            @include min-screen($min-medium) {
              font-size: 0.9rem;
            }

            &:before {
              @extend %regular;
              color: $light-gray;
              content: counter(wizard-counter) '.';
              display: inline-block;
              font-size: 0.9rem;
              left: 0;
              padding: 0 5px;
              position: absolute;
              text-align: right;
              vertical-align: middle;
              width: 20px;
            }
          }
          p.description {
            color: $light-gray;
            font-size: 0.7rem;
            line-height: 1rem;
            margin: 0;
            max-width: calc(100% - 110px);
            padding-bottom: 10px;
            padding-left: 25px;

            @include min-screen($min-medium) {
              max-width: 235px;
            }
          }
          &.disabled {
            h2 {
              color: $light-gray;

              a {
                pointer-events: none;
              }
            }
          }
          &.active,
          &.active.disabled {
            h2 {
              color: $all-black;

              a {
                pointer-events: none;
              }
            }
          }
          // status
          &.incomplete,
          &.completed {
            &:before {
              @extend %fontello;
              color: $ok;
              content: '\e847';
              display: none;
              font-size: 1rem;
              left: -20px;
              position: absolute;
              top: 5px;
            }
            @include min-screen($min-medium) {
              &:before {
                display: inline-block;
              }
            }
          }

          &.incomplete {
            &:before {
              color: $warning;
              content: '\e80e';
            }
          }

          &.active,
          &.active.incomplete,
          &.active.completed {
            &:before {
              @extend %fontello;
              color: $all-black;
              content: '\f105';
              display: none;
              font-size: 1.15rem;
              left: -20px;
              position: absolute;
              top: 2px;
            }
            @include min-screen($min-medium) {
              &:before {
                display: inline-block;
              }
              h2:before {
                color: $all-black;
              }
            }

            h2 a {
              @extend %nd;
              color: $all-black;
              cursor: default;
            }
          }

          @include max-screen($max-small) {
            opacity: 0;
            position: absolute;
            z-index: -1;

            &.active {
              opacity: 1;
              position: relative;
              z-index: 1;
            }
          }
        }
      }
      @include min-screen($min-medium) {
        display: inline-block;
        padding: 20px 0 0;
        position: sticky;
        position: -webkit-sticky;
        top: 82px;
        width: 250px;

        ul {
          border-bottom: 0;
        }
      }
      @include min-screen($min-xlarge) {
        max-width: 360px;
        width: 25%;
      }

      li.wizard-step,
      li.wizard-step-final {
        display: block;
        padding-right: 20px;
        position: relative;
        width: 100%;

        &.disabled {
          h2 {
            color: $light-gray;
          }
        }
        &.active.disabled {
          h2 {
            color: $all-black;
          }
        }

        // status
        &.selected {
          &:before {
            @extend %fontello;
            content: '\f105';
            display: none;
            font-size: 1.15rem;
            left: -20px;
            position: absolute;
            top: 4px;
          }
          @include min-screen($min-medium) {
            &:before {
              display: inline-block;
            }
            h2:before {
              color: $all-black;
            }
          }
        }

        &.incomplete,
        &.completed {
          &:before {
            @extend %fontello;
            color: $ok;
            content: '\e847';
            display: none;
            font-size: 1rem;
            left: -20px;
            position: absolute;
            top: 5px;
          }
          @include min-screen($min-medium) {
            &:before {
              display: inline-block;
            }
          }
        }

        &.incomplete {
          &:before {
            color: $warning;
            content: '\e80e';
          }
        }

        @include max-screen($max-small) {
          opacity: 0;
          position: absolute;
          z-index: -1;

          &.selected {
            opacity: 1;
            position: relative;
            z-index: 1;
          }
        }
      }

      li.wizard-step-final,
      li.wizard-step-final.active {
        &:before {
          @include min-screen($min-medium) {
            top: 8px;
          }
        }
        h2 {
          counter-increment: none;
          margin: 0;
          padding: 0;

          @include min-screen($min-medium) {
            border-top: 1px solid $hr;
            margin: 0 0 0 25px;
            padding: 5px 0 0 0;
          }
          &:before {
            display: none;
          }
        }
        p {
          @include max-screen($max-small) {
            padding-left: 0;
          }
        }
      }
    }

    // mobile stepper
    .mobile-stepper {
      position: absolute;
      right: 0;
      top: 25px;
      z-index: 2;

      a,
      span {
        color: $light-gray;
        display: inline-block;
        font-size: 0.7rem;
        margin: 0 5px 0 2px;
        vertical-align: middle;

        strong {
          @extend %bold;
          color: $all-black;
        }
      }
      a {
        background: var(--link);
        border-radius: 50%;
        color: $white;
        font-size: 0.9rem;
        height: 16px;
        line-height: 16px;
        text-align: center;
        width: 16px;

        &:before {
          font-size: 0.7rem;
          margin: 0;
        }
        &.disabled {
          opacity: 0.5;
        }
      }
    }

    .wizard-content {
      .sticky-btm {
        background: rgba(249, 249, 249, 0.75);
        border-top: 1px solid $lightest-gray;
        bottom: 0;
        box-shadow: 0 -10px 10px -5px rgba(249, 249, 249, 0.75);
        margin-left: 0;
        margin-top: 20px;
        padding: 20px 0 10px;
        position: sticky;
        position: -webkit-sticky;
        z-index: 50;

        @include min-screen($min-medium) {
          max-width: 600px;
        }
      }

      hr + hr {
        display: none;
      }

      > div {
        padding: 0;
      }
      h3 {
        font-size: 1.1rem;
      }
      .container {
        @include min-screen($min-medium) {
          padding-top: 20px;
        }
      }
      div.numbered-sections {
        h3.numbered.outside {
          padding-left: 0;

          &:before {
            display: none;
          }
          @include min-screen($min-medium) {
            &:before {
              display: block;
            }
          }
        }
      }
    }

    // final step
    .wizard-steps {
      li.wizard-step-final.active,
      li.wizard-step-final.disabled.active {
        @include max-screen($max-small) {
          h2 {
            color: $all-black;
          }
          p.description {
            padding-left: 0;
          }
        }
      }
    }

    .wizard-content {
      &.no-summary {
        @include min-screen($min-medium) {
          border-right: 0;
          max-width: 560px;
          padding-right: 0;
          width: calc(100% - 250px) !important;

          .container {
            max-width: none;
          }
        }
        .title-block {
          h2 span {
            display: none;
          }
        }
        .title-block + hr.dashed {
          display: none;
        }
        .customer-review {
          .nui-alert + .summary-list {
            border-top: 1px dashed $lightest-gray;
            margin-top: 20px;
            padding-top: 20px;
          }

          .d-value,
          .d-title {
            display: inline-block;
            margin: 0;
            text-align: left;
            width: 50%;
          }

          h3 {
            @extend %light;
            color: $mid-gray;
            font-size: 1.3rem;
          }

          .not-set {
            position: relative;

            &:before {
              font-size: 0.8rem;
              left: -20px;
              position: absolute;
              top: 3px;
            }
          }

          dl.summary-list {
            dt,
            dd {
              font-size: 0.9rem;
            }
            dt {
              line-height: 1.25rem;
              h4 {
                font-size: 1rem;
              }
            }
            dd {
              font-size: 0.85rem;
              line-height: 1.25rem;
              min-height: 1.25rem;
            }

            dd.spec {
              em.legend {
                transform: none;
                -webkit-transform: none;
              }
            }
          }
          dl.summary-list:not(.stacked) {
            dt {
              width: 50%;
            }
            dd {
              left: 50%;
              width: 50%;
            }

            dt.stacked,
            dt.stacked + dd {
              left: 0;
              position: relative;
              width: 100%;
            }
          }
        }
      }
    }
  }

  // logo upload field
  .nui-form .upload-media {
    .ant-form-item-label label {
      @extend %bold;
      color: $all-black;
      font-size: 1.1rem;
    }
    .logo-file-input-holder {
      background: $white;
      border: 1px solid $lightest-gray;
      border-radius: 3px;
      cursor: pointer;
      position: relative;
      transition: none;

      &:before {
        @extend %fontello;
        color: $lightest-gray;
        content: '\f1c5';
        font-size: 1.5rem;
        left: 3px;
        pointer-events: none;
        position: absolute;
        top: 11px;
        z-index: 2;
      }
      &:after {
        border-bottom: 1px dashed $lightest-gray;
        color: $light-gray;
        content: 'Select image to upload';
        height: 43px;
        left: 40px;
        pointer-events: none;
        position: absolute;
        top: 2px;
        width: calc(100% - 160px);
      }
      &:active,
      &:focus,
      &:hover {
        border-color: $mid-gray;
        &:after {
          border-color: $mid-gray;
        }
      }
      input {
        opacity: 0;
      }
    }
    span.value-name,
    span.removed-value {
      display: inline-block;
      margin-left: 10px;
      margin-right: 10px;

      span {
        margin-right: 8px;
      }
    }

    img.value-preview {
      display: block;
      float: right;
      margin: -10px 10px 10px;
      max-height: 40px;
      max-width: 100px;
      mix-blend-mode: multiply;
    }
  }

  // wizard summary
  &.has-summary {
    .wizard-content {
      .formstatus {
        width: auto;
      }
      span.prefix {
        @extend %bold;
        border-right: 1px solid $lightest-gray;
        color: $all-black;
        font-size: 0.6rem;
        height: 32px;
        left: 0;
        line-height: 33px;
        margin: 0 0 0 5px;
        padding: 0 10px 0 5px;
        position: relative;
        text-transform: uppercase;
        top: 69px;
        width: auto;
        z-index: 3;
      }
      @include min-screen($min-medium) {
        display: inline-block;
        padding-left: 20px;
        vertical-align: top;
        width: calc(100% - 250px);

        .fieldset,
        fieldset {
          max-width: 768px;
        }
      }

      @include max-screen($max-xsmall) {
        fieldset,
        .fieldset {
          label,
          .input-holder,
          .input-holder > div {
            display: block;
            margin-left: 0;
            width: 100% !important;
          }
          .input-holder > div {
            padding-bottom: 15px;
          }
        }
        label.inline.w-60 {
          display: inline-block !important;
          width: 60px !important;
        }
      }
    }
    // and large
    .wizard-content {
      @include min-screen($min-large) {
        transition: 0.2 all linear;
      }
      .fieldset.has-popover .input-holder > div.popover {
        width: 30px !important;
      }

      @include min-screen($min-medium) {
        .fieldset,
        fieldset,
        .container {
          max-width: 560px;
        }
      }

      @include min-screen($min-large) {
        border-right: 1px solid $hr;
        padding-right: 40px;
        width: calc(100% - 250px - 241px);
      }
      @include min-screen($min-xlarge) {
        width: calc(100% - 25% - 321px);
      }
      @include min-screen($min-xxlarge) {
        width: calc(100% - 25% - 421px);
        .fieldset,
        fieldset {
          max-width: 768px;
        }
      }
    }
  }

  .wizard-summary {
    border-left: 1px solid $hr;
    display: none;
    margin-left: -1px;
    padding-left: 20px;
    padding-top: 20px;
    vertical-align: top;
    width: 240px;

    h3 {
      font-size: 1rem;
    }

    .formstatus {
      width: auto;
    }

    h3.icon-folder-open {
      &:before {
        color: $lightest-gray;
        margin: 0 5px 0 0;
      }
    }

    .edit-delete {
      @extend %regular;
      color: $light-gray;
      float: right;

      button,
      a {
        font-size: 0.6rem;
        display: inline-block;
        padding: 0;
        text-transform: uppercase;
      }
      a {
        margin-right: 2px;
      }
      button {
        margin-left: 2px;
      }
    }

    @include min-screen($min-large) {
      display: inline-block;
      position: sticky;
      position: -webkit-sticky;
      top: 82px;
      transition: 0.2 all linear;
    }
    @include min-screen($min-xlarge) {
      width: 320px;
    }
    @include min-screen($min-xxlarge) {
      width: 420px;
    }
  }

  dl.summary-list {
    counter-reset: summary-counter;
    margin-bottom: 20px;
    position: relative;

    .not-set {
      position: relative;
      &:before {
        font-size: 0.8rem;
        left: -20px;
        position: absolute;
        top: 3px;
      }
    }
    dt,
    dd {
      display: block;
      font-size: 0.8rem;
      margin: 0;
    }
    dt {
      @extend %bold;
      color: $all-black;
      left: 0;
      line-height: 1.1rem;
      margin-top: 5px;
      position: absolute;
      width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      h4 {
        font-size: 0.9rem;
      }
      &:first-of-type {
        margin: 0;
      }
      @include min-screen($min-xlarge) {
        width: 125px;
      }
    }
    dd {
      left: 110px;
      line-height: 1.1rem;
      margin-top: 5px;
      min-height: 1.1rem;
      position: relative;
      width: calc(100% - 110px);

      &:first-of-type {
        margin: 0;
      }
      @include min-screen($min-xlarge) {
        left: 125px;
        width: calc(100% - 125px);
        word-break: break-all;
      }
    }
    dd + dd {
      margin-top: 2.5px;
    }
    dd[class^='icon-'] {
      overflow: hidden;
      padding-left: 20px;
      position: relative;
      text-overflow: ellipsis;
    }
    dd[class^='icon-']:before {
      color: lighten($light-gray, 5%);
      display: inline-block;
      left: 0;
      margin: 0;
      position: absolute;
      top: 4px;
    }
    dd.icon-file-pdf:before {
      color: #ec4004;
    }

    dd.spec {
      overflow: hidden;
      padding-left: 25px;
      position: relative;
      text-overflow: ellipsis;

      em.legend {
        left: 0;
        position: absolute;
        top: 1px;
        transform: scale(0.75);
        -webkit-transform: scale(0.75);
      }
    }
  }

  dl.summary-list.stacked {
    dt,
    dd {
      left: auto;
      position: relative;
      width: 100%;
    }
    dt {
      margin-bottom: 2px;
    }
  }
  dl.summary-list.stacked.numbered {
    dd {
      counter-increment: summary-counter;
      padding-left: 20px;
      position: relative;

      &:before {
        content: counter(summary-counter) '.';
        display: inline-block;
        left: 0;
        position: absolute;
      }
      &:only-of-type {
        padding-left: 0;

        &:before {
          display: none;
        }
      }
    }
  }

  dl.summary-list.stacked.dt-numbered {
    dt {
      counter-increment: summary-counter;
      padding-left: 20px;
      padding-right: 75px;
      position: relative;
      white-space: normal;

      span.edit-delete {
        position: absolute;
        right: 0;
        top: -1px;
      }
      &:before {
        @extend %regular;
        color: $mid-gray;
        content: counter(summary-counter) '.';
        display: inline-block;
        left: 0;
        position: absolute;
      }
    }
    dd {
      border-bottom: 1px solid $hr;
      color: $mid-gray;
      font-size: 0.7rem;
      margin: -5px 0 10px -20px;
      padding: 0 0 5px 40px;
      width: calc(100% + 20px);

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  dd {
    .d-entry {
      clear: both;
      display: block;
      &:after {
        @extend %clearfix;
      }
      .d-title {
        display: inline-block;
        margin: 0 5px 0 0;
        vertical-align: top;
      }
      .d-value {
        display: inline-block;
        vertical-align: top;

        @include min-screen($min-xlarge) {
          float: right;
          text-align: right;
          width: 50%;
        }
      }
      &:last-child {
        border-bottom: 1px solid $hr;
        margin-bottom: 5px;
        padding-bottom: 5px;
      }
    }
    &:last-of-type {
      .d-entry:last-child {
        border: 0;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }

  // summary accordion
  .summary-accordion-entry {
    .summary-accordion-header {
      button.summary-accordion-pin {
        @extend %nd;
        border-bottom: 1px solid $hr;
        border-radius: 0;
        display: block;
        margin-bottom: 0;
        padding: 5px 0;
        position: relative;
        text-align: left;
        width: 100%;

        span {
          color: $lightest-gray;
          font-size: 0.9rem;
          position: absolute;
          right: 0;
          top: 4px;
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          z-index: 2;
        }
        h3 {
          @extend %dd;
          color: var(--link);
          font-size: 1rem;
          margin: 0;
          padding: 0;
        }
        &:hover {
          span {
            color: $light-gray;
          }
          h3 {
            @extend %nd;
            color: $all-black;
          }
        }
      }
      button:disabled {
        background: none !important;
        box-shadow: none !important;

        h3 {
          @extend %nd;
          color: $all-black;
        }
      }
    }
    &.open {
      .summary-accordion-content {
        border-bottom: 1px solid $hr;
        padding-bottom: 0;
        padding-right: 10px;
        padding-top: 10px;

        dl.summary-list {
          margin-bottom: 10px;
        }
      }
    }
    &.pinned {
      button.summary-accordion-pin {
        span {
          transform: none;
          -webkit-transform: none;
        }
      }
      &.open {
        button.summary-accordion-pin {
          span {
            color: $mid-gray;
          }
        }
      }
    }
    &.current {
      button.summary-accordion-pin {
        span {
          display: none;
        }
      }
    }
  }

  .summary-accordion-entry:first-of-type {
    border-top: 1px solid $hr;
    margin-top: 3px;
  }

  // see-unit-details
  .sell-unit-block {
    .nui-check-fieldset,
    h3 {
      display: inline-block;
      vertical-align: top;
    }
    h3 {
      margin-top: -4px;
    }
  }
}

// css to accomodate accordions in summary column
.wizard .wizard-summary {
  padding-bottom: 20px;
  padding-left: 0;
  h3,
  .summary-accordion-entry
    .summary-accordion-header
    button.summary-accordion-pin
    h3 {
    padding-left: 20px;
  }
  dl.summary-list {
    left: 20px;
    width: calc(100% - 20px);
  }
  .nui-alert {
    margin-left: 20px;
  }
}

// form status
.customer-request-table {
  .col-status {
    max-width: 110px;
    min-width: 110px;
    width: 110px;
  }
}

.formstatus {
  @extend %bold;
  background: $white;
  border: 1px solid $light-gray;
  border-radius: 100px;
  color: #999;
  display: inline-block;
  font-size: 0.6rem;
  line-height: 1.2rem;
  padding: 0 10px 0 20px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  width: 100%;

  &.inline {
    width: auto;
  }
  &:before {
    font-size: 0.8rem;
    left: 4px;
    margin: 0 !important;
    position: absolute;
    top: 4px;
  }
  &.rejected {
    border-color: #ffc6c6;
    color: #ff4646;
  }
  &.cancelled {
    color: #ff4646;
    border-color: #ffacac;
  }
  &.processed {
    border-color: #b9f094;
    color: #4ea216;
  }
  &.pending {
    border-color: #e5a8c6;
    color: #bc3879;
  }
  &.incomplete {
    border-color: #fbdc9f;
    color: #c68508;
  }
}

.not-set {
  &:before {
    color: $warning;
  }
  span.optional {
    color: $ok;
    display: inline-block;
    font-size: 0.6rem;
    margin-left: 5px;
    text-transform: uppercase;
  }
}

// item-lists
.wizard-content {
  .item-list {
    list-style: none;
    margin: 0 0 20px;
    padding: 0;

    li {
      border-bottom: 1px solid $hr;
      display: flex;
      padding: 10px 0;
      position: relative;
      .pos-right {
        @include pos-right(9, 0);
      }
      span.pos-right {
        @include pos-right(0, 0);
      }
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        border-bottom: 0;
      }
      label {
        cursor: default;
        display: inline-flex;
        vertical-align: top;
        width: auto;

        button.nui-switch {
          cursor: pointer;
          display: inline-block;
          margin: 0 15px 0 0;
          position: relative;
          top: 1px;
          vertical-align: top;
        }
        button.nui-switch + span {
          line-height: 1.2rem;
          padding-right: 70px;
          padding-top: 1px;
        }
      }
      dl.list {
        display: inline-block;
        margin: 0;
        vertical-align: top;
        width: calc(100% - 80px);
        dt {
          @extend %bold;
          color: $all-black;
          display: inline-block;
          font-size: 0.9rem;
          margin: 0;
          vertical-align: top;
          width: 160px;
        }
        dd {
          color: $mid-gray;
          font-size: 0.9rem;
          display: inline-block;
          margin: 0;
          vertical-align: top;
          white-space: nowrap;
          width: calc(100% - 160px);
        }
      }
      dl.list.stacked {
        dt,
        dd {
          display: block;
          width: 100%;
        }
      }
      .actions {
        display: inline-block;
        vertical-align: top;
      }
    }
    li[dataset] {
      position: relative;

      &:before {
        color: $light-gray;
        content: ' ';
        display: inline-block;
        left: -20px;
        position: absolute;
        top: 10px;
      }
      @include max-screen($max-small) {
        padding-left: 30px;
        &:before {
          left: 0;
        }
      }
    }
    li[dataset='A']:before {
      content: 'A';
    }
    li[dataset='A'] + li[dataset='A']:before {
      display: none;
    }

    li[dataset='B']:before {
      content: 'B';
    }
    li[dataset='B'] + li[dataset='B']:before {
      display: none;
    }

    li[dataset='C']:before {
      content: 'C';
    }
    li[dataset='C'] + li[dataset='C']:before {
      display: none;
    }

    li[dataset='D']:before {
      content: 'D';
    }
    li[dataset='D'] + li[dataset='D']:before {
      display: none;
    }

    li[dataset='E'] {
      &:before {
        content: 'E';
      }
    }
    li[dataset='E'] + li[dataset='E']:before {
      display: none;
    }

    li[dataset='F']:before {
      content: 'F';
    }
    li[dataset='F'] + li[dataset='F']:before {
      display: none;
    }

    li[dataset='G']:before {
      content: 'G';
    }
    li[dataset='G'] + li[dataset='G']:before {
      display: none;
    }

    li[dataset='H']:before {
      content: 'H';
    }
    li[dataset='H'] + li[dataset='H']:before {
      display: none;
    }

    li[dataset='I']:before {
      content: 'I';
    }
    li[dataset='I'] + li[dataset='I']:before {
      display: none;
    }

    li[dataset='J']:before {
      content: 'J';
    }
    li[dataset='J'] + li[dataset='J']:before {
      display: none;
    }

    li[dataset='K']:before {
      content: 'K';
    }
    li[dataset='K'] + li[dataset='K']:before {
      display: none;
    }

    li[dataset='L']:before {
      content: 'L';
    }
    li[dataset='L'] + li[dataset='L']:before {
      display: none;
    }

    li[dataset='M']:before {
      content: 'M';
    }
    li[dataset='M'] + li[dataset='M']:before {
      display: none;
    }

    li[dataset='N']:before {
      content: 'N';
    }
    li[dataset='N'] + li[dataset='N']:before {
      display: none;
    }

    li[dataset='O']:before {
      content: 'O';
    }
    li[dataset='O'] + li[dataset='O']:before {
      display: none;
    }

    li[dataset='P']:before {
      content: 'P';
    }
    li[dataset='P'] + li[dataset='P']:before {
      display: none;
    }

    li[dataset='Q']:before {
      content: 'Q';
    }
    li[dataset='Q'] + li[dataset='Q']:before {
      display: none;
    }

    li[dataset='R']:before {
      content: 'R';
    }
    li[dataset='R'] + li[dataset='R']:before {
      display: none;
    }

    li[dataset='S']:before {
      content: 'S';
    }
    li[dataset='S'] + li[dataset='S']:before {
      display: none;
    }

    li[dataset='T']:before {
      content: 'T';
    }
    li[dataset='T'] + li[dataset='T']:before {
      display: none;
    }

    li[dataset='U']:before {
      content: 'U';
    }
    li[dataset='U'] + li[dataset='U']:before {
      display: none;
    }

    li[dataset='V']:before {
      content: 'V';
    }
    li[dataset='V'] + li[dataset='V']:before {
      display: none;
    }

    li[dataset='W']:before {
      content: 'W';
    }
    li[dataset='W'] + li[dataset='W']:before {
      display: none;
    }

    li[dataset='X']:before {
      content: 'X';
    }
    li[dataset='X'] + li[dataset='X']:before {
      display: none;
    }

    li[dataset='Y']:before {
      content: 'Y';
    }
    li[dataset='Y'] + li[dataset='Y']:before {
      display: none;
    }

    li[dataset='Z']:before {
      content: 'Z';
    }
    li[dataset='Z'] + li[dataset='Z']:before {
      display: none;
    }
  }
}

// teeny tiny selects
.nui-fieldset {
  .select-holder {
    &.tiny {
      width: auto;

      select {
        font-size: 0.8rem;
        line-height: 1;
        min-height: 22px;
        padding: 2px 4px;
      }
    }
  }
  .select-holder {
    &.tiny {
      &:after {
        margin-top: -3px;
        right: 6px;
      }
      select {
        padding: 8px 20px 8px 10px;
      }
    }
  }
}

// specific ant overrides - for now - until we have proper forms

//~//~// Products //~//~//
.wizard-content {
  .form-item-name-shortname,
  .form-item-name-reference,
  .form-item-name-container,
  .form-item-name-packaging {
    @include min-screen($min-medium) {
      max-width: 100%;
      width: 320px;
    }
  }

  .form-item-name-subcurrency {
    .ant-form-item-control-wrapper {
      vertical-align: top !important;
      width: 100% !important;
    }
    .ant-form-item-label {
      width: 100% !important;
    }
    .ant-form-item-label label {
      margin: 0 0 8px !important;
    }
    @include min-screen($min-small) {
      display: inline-block;
      margin-right: 10px;
      vertical-align: top;
      width: calc(50% - 5px);

      &.form-item-type-Boolean {
        padding-top: 0;
      }
    }
  }

  .nui-form.order-form .form-item-name-pricestep,
  .nui-form.order-form .form-item-name-price {
    display: block;
    margin-right: 0;
    width: calc(50% - 5px);

    @include min-screen($min-small) {
      display: inline-block;
    }
    .ant-input-number {
      width: 100%;
    }
  }

  .nui-form.order-form .form-item-name-pricestep {
    margin-right: 10px;
  }

  .form-item-name-baseunit {
    @include min-screen($min-small) {
      display: inline-block;
      padding: 0;
      vertical-align: top;
      width: calc(50% - 5px);
    }
  }

  .form-item-name-loadingunit {
    display: block;
    padding-top: 20px;
    width: calc(50% - 5px);
  }

  .form-item-name-unitfactor {
    width: calc(50% - 5px);
    .ant-input-number {
      width: 100%;
    }
  }

  .ant-row.ant-form-item.form-item-type-Boolean {
    &.form-item-name-agelogic {
      padding: 0 0 20px;

      label:after {
        right: 10px;
      }
    }
    &.form-item-name-marketplace,
    &.form-item-name-tender {
      padding: 0 0 5px;
    }
  }

  .form-item-name-reefer {
    .ant-form-item-label {
      padding-right: 10px;
      width: 120px !important;
    }
  }

  .form-item-name-qty {
    padding-top: 20px;

    .ant-input-number {
      max-width: 100%;
      width: 320px;
    }
  }

  // loading options
  .inset-form {
    .form-item-name-qty {
      width: 320px;
    }
  }
}

.fieldset {
  &.specs {
    label {
      display: none;
    }
  }
}

// inset form
.inset-form {
  background: rgba(0, 0, 0, 0.025);
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  box-shadow: 0 -4px 0 rgba(0, 0, 0, 0.035) inset;
  padding: 20px;

  h3 {
    border-bottom: 1px solid $hr;
    font-size: 1.1rem;
    margin-bottom: 10px;
    padding-bottom: 10px;
  }
  h4 {
    font-size: 1rem;
  }
}

// add product attributes
.button-holder {
  background: $white;
  border: 1px solid $lightest-gray;
  border-radius: 3px;
  box-shadow: 0 -2px 0 rgba(0, 0, 0, 0.1) inset;
  display: block;
  height: 32px;
  line-height: 32px;
  margin: 0;
  overflow: hidden;
  padding: 0;
  text-align: center;
  transition: 0.2s all ease;
  width: 32px;

  button.nui-reverse {
    height: 100%;
    width: 100%;

    span {
      background: none !important;
      border: 0 !important;
      box-shadow: none !important;
      height: 100%;
      margin: 0;
      padding: 0;
      width: 100%;
      &:before {
        color: $light-gray;
        &:hover {
          color: $mid-gray;
        }
      }
    }
  }
}

html div.attribute {
  margin: 0 0 10px;
  position: relative;

  .button-holder {
    position: absolute;
    right: 0;
    top: 0;
  }
  .attribute-name {
    padding: 0 50px 10px 0;

    strong {
      color: $all-black;
      display: block;
      font-size: 1rem;
    }
    span.attribute-category {
      display: block;
      margin: 0 5px 0 0;

      &::first-letter {
        text-transform: uppercase;
      }
    }
  }
  .attribute-options {
    padding: 5px 0;

    strong {
      font-size: 1rem;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        padding: 0;
        position: relative;

        button.nui-button.nui-button-icon {
          span {
            background: none;
            border: 0;
            box-shadow: none;
            height: 20px;
            left: 0;
            line-height: 1;
            margin: 0;
            padding: 0;
            position: absolute;
            text-align: left;
            top: 2px;
            width: 20px;

            &:before {
              color: $light-gray;
              &:hover {
                color: $mid-gray;
              }
            }
          }
        }
      }
    }
  }
}

// wrapper
html div.attribute + .wrapper {
  margin-bottom: 40px;
}

// customer request fields
.content-company-details,
.content-users-details {
  .form-item-name-companyno,
  .form-item-name-currency,
  .form-item-name-role {
    width: 49%;
  }
  .form-item-name-workphone,
  .form-item-name-mobilephone,
  .form-item-name-firstname,
  .form-item-name-lastname,
  .form-item-name-ddiphone {
    display: inline-block;
    vertical-align: top;
    width: 49%;
  }
  .form-item-name-mobilephone,
  .form-item-name-lastname {
    margin-left: 2%;
  }
  .ant-form-item + .form-step {
    margin-top: 25px;
  }
}

.content-address-details,
.content-locations-details {
  .form-item-name-phyaddr2,
  .form-item-name-phycity,
  .form-item-name-addr2,
  .form-item-name-city,
  .form-item-name-postaddr2,
  .form-item-name-postcity {
    @include min-screen($min-xlarge) {
      width: 300px;
    }
  }
  .form-item-name-phystate,
  .form-item-name-poststate,
  .form-item-name-state {
    display: inline-block;
    width: 59%;
    @include min-screen($min-xlarge) {
      width: 300px;
    }
  }
  .form-item-name-phyzip,
  .form-item-name-postzip,
  .form-item-name-zip {
    display: inline-block;
    margin-left: 2%;
    width: 39%;
    @include min-screen($min-xlarge) {
      width: calc(98% - 300px);
    }
  }
}

.content-links-details {
  .form-item-name-creditamount,
  .form-item-name-creditperiod,
  .form-item-name-paymentterm {
    width: 300px;
    @include min-screen($min-xlarge) {
      width: 49%;
    }
    .ant-input-number {
      width: 100%;
    }
  }

  .form-item-name-customerpremium,
  .form-item-name-financepremium {
    width: 300px;

    @include min-screen($min-xlarge) {
      display: inline-block;
      vertical-align: top;
      width: 49%;
    }
    .ant-input-number {
      width: 100%;
    }
  }
  .form-item-name-financepremium {
    @include min-screen($min-xlarge) {
      margin-left: 2%;
    }
  }
}

.form-step {
  margin-top: 20px;
  &:first-of-type {
    margin-top: 0;
  }
}

.switch-list {
  ul.item-list {
    li {
      display: block;
      label {
        display: block;
      }
    }
  }
}

// show summary on small/medium screens
.wizard.multiple {
  .mobile-stepper {
    button.show-summary {
      max-width: none;
      padding: 7px 12px;
      position: absolute;
      right: 0;
      top: -70px;
      width: auto;
      &:first-letter {
        text-transform: uppercase;
      }
      span {
        color: var(--link);
        display: none;
        font-size: 0.8rem;
        margin: 0;
        vertical-align: top;
      }
    }
  }
  // medium
  div.stepper {
    font-size: 0.8rem;
    position: relative;
    top: -7px;
    z-index: 3;

    a.prev,
    a.next {
      display: none;
    }
    strong {
      color: $all-black;
    }
    .nui-button-link {
      border: 0;
      border-radius: 0;
      box-shadow: none;
      float: right;
      margin-left: 5px;

      @include min-screen($min-medium) {
        margin-top: -5px;
      }
      @include min-screen($min-large) {
        display: none;
      }
    }
  }
}

// showing summary panel on click

.wizard-summary {
  transition: 0.2s all ease;

  h3 + .closeme {
    display: none;
  }
}

.showing-summary {
  .wizard-summary {
    @include max-screen($max-medium) {
      background: #f9f9f9;
      box-shadow: -2px 0 4px rgba(0, 0, 0, 0.1);
      display: block;
      height: calc(100% + 20px);
      overflow: hidden;
      position: absolute;
      right: 0;
      top: 0;
      width: 300px;
      z-index: 50;

      .button-set {
        display: none;
      }
      .summary-wrapper {
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        padding-bottom: 20px;
        padding-right: 20px;
        position: sticky;
        position: -webkit-sticky;
        top: 0;
      }
      h3 + .closeme {
        display: block;
        font-size: 0.8rem;
        position: absolute;
        right: 5px;
        top: 0;
        z-index: 3;
      }
    }
    @include max-screen($max-small) {
      height: calc(100% + 10px);
    }
  }
}

// dev mode sticky positions
.dev-mode {
  @include min-screen($min-medium) {
    .wizard.multiple div.wizard-steps {
      top: 102px;
    }
  }
  @include min-screen($min-large) {
    .wizard-summary {
      top: 102px;
    }
  }
}

// product variants
.variants-form {
  counter-reset: variants-counter;

  .variants-item {
    border-top: 1px solid $hr;
    counter-increment: variants-counter;
    margin: 20px;
    padding-left: 25px;
    padding-top: 15px;
    position: relative;

    &:first-of-type {
      border: 0;
    }
    &:last-of-type {
      padding-bottom: 20px;
    }
    &:before {
      color: $mid-gray;
      content: counter(variants-counter) '.';
      display: inline-block;
      font-size: 0.8rem;
      left: 0;
      margin: 0;
      position: absolute;
      text-align: right;
      top: 17px;
      vertical-align: middle;
    }

    .ant-input-number {
      width: calc(50% - 20px);
    }

    .variants-remove {
      position: absolute;
      top: 15px;
      right: 0;
      z-index: 2;
    }

    .ant-form-item {
      padding-bottom: 0;
      padding-top: 15px;

      &:first-child {
        padding-top: 0;
      }
    }
  }
}
