////////////// TENDERS REDESIGN //////////////

//// generic
@-webkit-keyframes routing {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes routing {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.page-route {
  transition: opacity 0.4s ease;
  -webkit-animation: routing 1.5s;
  animation: routing 1.5s;
}

// form
.order-form {
  .title,
  .description {
    white-space: break-spaces;
  }
  .title {
    font-size: large;
    @extend %bold;
    color: $all-black;
  }
}

// main alert
.tenders2 {
  .tender-view {
    > .tender-orders {
      > .nui-alert,
      > .alert-route-error {
        margin: 20px;
        text-align: center;

        @include min-screen($min-medium) {
          margin: 20px 40px;
        }
      }
    }
  }
}

// show help
.tender-method {
  .icon-help-circled {
    color: rgba(0, 0, 0, 0.25);
    cursor: help;
    font-size: 1.2rem;
    margin: 0 5px;
  }
}

.summary-list {
  .tender-method {
    .icon-help-circled {
      font-size: 1rem;
      position: relative;
      top: 2px;
    }
  }
}

//// tenders2 specific
.tenders2 {
  // display popout panel
  .tiny-panel {
    background: $white;
    border-radius: 3px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    display: block;
    padding: 7px 15px;
    position: absolute;
    right: 32px;
    top: -32px;
    width: 200px;
    z-index: 2000;

    @include min-screen($min-medium) {
      left: 32px;
      right: auto;
    }
  }

  .tiny-panel {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        padding: 2px 0 2px 22px;
        position: relative;
        a,
        button {
          background: none;
          border: 0;
          box-shadow: none;
          font-size: 0.9rem;
          padding: 0;

          &:before {
            color: $lightest-gray;
            font-size: 1.1rem;
            left: -10px;
            position: absolute;
            top: 7px;
          }
        }
      }
    }
  }

  // participants activity
  .tender-activity {
    display: inline-block;
    margin-right: 30px;
    position: relative;
    vertical-align: top;
    z-index: 10;

    button.icon-ellipsis {
      background: none;
      border: 0;
      line-height: 1;
      padding: 0;

      &:before {
        display: none;
      }
    }

    span.icon-group {
      &:before {
        background: $lightest-gray;
        border-radius: 50%;
        color: $white;
        cursor: pointer;
        display: block;
        font-size: 1.15rem;
        height: 30px;
        line-height: 30px;
        margin: 3px auto 2px;
        transition: 0.2s all ease;
        width: 30px;
      }
    }
    span.badge {
      background: var(--highlight);
      border: 2px solid #f9f9f9;
      border-radius: 100px;
      color: $white;
      display: block;
      font-size: 0.6rem;
      left: 22px;
      line-height: 1;
      min-height: 22px;
      min-width: 22px;
      padding: 5px 4px;
      position: absolute;
      text-align: center;
      top: -4px;
      transition: 0.2s all ease;
      z-index: 30;
    }
    .title {
      @extend %bold;
      @extend %nd;
      color: $all-black;
      cursor: pointer;
      display: block;
      font-size: 0.5rem;
      line-height: 1;
      margin: 0;
      position: relative;
      text-align: center;
      text-transform: uppercase;

      .arrow {
        @include arrow(1, -14);
        transform: scale(0.8, 0.8);
        top: -0.5px;
        -webkit-transform: scale(0.8, 0.8);
      }
    }

    &:hover {
      span.icon-group {
        &:before {
          background: $light-gray;
        }
      }
      span.badge {
        border-color: var(--highlight);
      }
    }

    .tiny-panel {
      left: -20px;
      padding: 7px 10px;
      right: auto;
      top: 5px;
      width: auto;

      @include min-screen($min-large) {
        left: auto;
        right: -20px;
        top: 5px;
      }
      ul.divisions {
        column-count: auto;
        -ms-column-count: auto;
        -moz-column-count: auto;
        -o-column-count: auto;
        -webkit-column-count: auto;
        min-width: 200px;
        overflow-y: scroll;
        padding: 0;
        position: relative;

        @include min-screen($min-medium) {
          &.gt-20,
          &.gt-40,
          &.gt-60,
          &.gt-80,
          &.gt-100 {
            column-count: 2;
            -ms-column-count: 2;
            -moz-column-count: 2;
            -o-column-count: 2;
            -webkit-column-count: 2;
            min-width: 400px;
          }
        }
        @include max-screen($max-small) {
          max-height: calc(100vh - 380px);
        }
        li {
          display: block;
          font-size: 0.8rem;
          line-height: 1rem;
          padding: 2px 2px 2px 15px;
          white-space: normal;

          &:hover {
            color: $all-black;
          }
          .status {
            float: right;
            font-size: 0.6rem;
            margin: 0 5px;

            &:before {
              border-radius: 50%;
              content: ' ';
              display: block;
              left: 0;
              height: 7px;
              overflow: hidden;
              position: absolute;
              top: 6px;
              width: 7px;
            }

            &.active {
              &:before {
                background: $ok;
                border: 1px solid $ok;
              }
            }
            &.inactive {
              &:before {
                background: $error;
                border: 1px solid $error;
              }
            }
            &.last-seen {
              &:before {
                background: $white;
                border: 1px solid $ok;
              }
            }
          }
        }
      }
    }
  }

  // should be replaced with tabs component
  ul.nav-list {
    border-bottom: 1px solid $lightest-gray;
    border-top: 1px solid $lightest-gray;
    list-style: none;
    margin: 0;
    padding: 11px 0 0;

    li {
      display: inline-block;
      margin: 10px 10px 10px 0;
      position: relative;

      &:after {
        background: transparent;
        border: 0;
        bottom: -10px;
        content: ' ';
        display: block;
        height: 0;
        left: 0;
        margin-left: 0;
        position: absolute;
        right: auto;
        top: auto;
        transform: none;
        -webkit-transform: none;
        transition: height 0.4s ease;
        width: 100%;
        z-index: 0;
      }
      @include min-screen($min-small) {
        margin: 6px 12px 17px 0;

        &:after {
          bottom: -17px;
        }
      }
      a {
        @extend %nd;
        @extend %light;
        color: var(--link);
        font-size: 1.2rem;
        margin: 0;
        padding: 0;

        span {
          color: var(--link);
        }
        @include min-screen($min-small) {
          margin: 0;
        }
      }
      &:active,
      &:hover {
        &:after {
          background: $lightest-gray;
          height: 4px;
        }
        a {
          color: $all-black;

          span {
            color: $all-black;
          }
        }
      }

      &:active {
        &:after {
          height: 6px;
        }
      }

      &.active,
      &.selected:hover {
        a {
          color: $all-black;
        }
        a span {
          background-image: none !important;
          color: $all-black;
        }
        &:after {
          background: var(--link);
          height: 4px;
        }
      }

      &.active:active {
        &:after {
          height: 6px;
        }
      }
    }
  }

  // status tags
  .tender-list,
  .tender2-status {
    span.status {
      background: $white;
      border: 1px solid #2d76cf;
      border-radius: 100px;
      color: #163a66;
      display: inline-block;
      font-size: 0.6rem;
      height: auto;
      line-height: 1.2rem;
      margin: 2px 0;
      min-width: 75px;
      position: absolute;
      right: 0;
      text-align: center;
      text-transform: uppercase;
      top: 25px;
      white-space: nowrap;
      z-index: 2;

      @include min-screen($min-medium) {
        top: 10px;
      }
      &.active {
        background: $tender-active;
        border-color: $tender-active;
        color: $white;
      }
      &.not-started,
      &.unpublished {
        @include tender-not-started;

        &:before {
          background: $lightest-gray;
        }
      }
      &.published {
        background: $tender-published;
        border-color: $tender-published;
        color: $white;
      }
      &.finished {
        background: $tender-finished;
        border-color: $tender-finished;
        color: $white;
      }
      &.finalised {
        background: $tender-finalised;
        border-color: $tender-finalised;
        color: $white;
      }
      &.cancelled,
      &.stopped {
        background: $tender-cancelled;
        border-color: $tender-cancelled;
        color: $white;
      }
      &.archived {
        background: $primary-color;
        border-color: $primary-color;
        color: $white;
      }
    }

    .tender-timer + .status {
      top: 60px;

      @include min-screen($min-medium) {
        top: 10px;
      }
    }
  }
}

.tenders2 {
  // tender header
  a.new-tender {
    display: block;
    float: right;
    margin: 13px 0 0;
    width: 150px;
  }
  .tender2-header {
    @include min-screen($min-medium) {
      padding-right: 300px;

      .nui-bc {
        top: 18px;

        .configuration-options {
          height: 0;
          opacity: 0;
          z-index: -10;
          transition: 0.3s all ease;

          &.open {
            height: auto;
            opacity: 1;
            z-index: 20;
          }
        }
      }
    }
    h1 {
      max-width: none;
      padding: 10px 0;

      @include min-screen($min-medium) {
        padding: 0;
      }
    }
    .page-route,
    h1 {
      display: inline-block;
      margin-right: 20px !important;
      vertical-align: middle;
    }
    .page-route h1 {
      margin-right: 0;
    }
  }

  :not(.tender2-header) {
    .page-route.tender-new {
      border-top: 1px solid $hr;

      @include min-screen($min-medium) {
        margin-top: 5px;
      }
    }
  }

  // overview and details
  .tender-list {
    margin: -1px 0 0;
  }

  // ul reset
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .tender-item {
    border-top: 1px solid $hr;
    transition: all 0.4s ease;

    &:not(:only-child) {
      border-bottom: 1px solid $light-gray;
      margin-bottom: 10px;

      &:hover {
        background: #eef1f8;
        margin-left: -20px;
        padding-left: 20px;
        margin-right: -20px;
        padding-right: 20px;
        @include min-screen($min-medium) {
          margin-left: -40px;
          padding-left: 40px;
          margin-right: -40px;
          padding-right: 40px;
        }
      }
      .tender-item-header h2.tender-name {
        margin: 10px 0 -5px;
        padding: 0;
      }
    }

    .tender-item-header {
      h2.tender-name {
        @extend %bold;
        color: $all-black;
        font-size: 1rem;
        margin: 0;
        padding: 10px 0 0;
        white-space: normal;
        width: auto;

        @include max-screen($max-small) {
          max-width: calc(100% - 100px);
          overflow: hidden;
          text-overflow: ellipsis;
        }
        @include min-screen($min-medium) {
          display: inline-block;
          padding: 10px 0;
          vertical-align: top;
          width: 50%;
        }
      }
    }

    // timer
    .tender-timer {
      @extend %regular;
      color: $mid-gray;
      display: inline-block;
      font-size: 0.9rem;
      text-transform: none;

      @include max-screen($max-small) {
        display: inline-block;
        margin: -5px 0 10px;
        width: 300px;
      }
      &:before {
        color: $light-gray;
        margin: 0 5px 0 0;
      }

      // alt
      padding-left: 40px;
      position: relative;

      &:before {
        font-size: 1.2rem;
        left: 6px;
        margin: 0;
        position: absolute;
        top: 3px;
      }

      &.s60-sec,
      &.s30-sec,
      &.s10-sec {
        color: $warning;

        strong.all-black {
          color: $warning !important;
        }
        &:before {
          color: $warning;
        }
      }
      &.s10-sec {
        &:before {
          -webkit-animation: blinky 0.75s ease infinite;
          animation: blinky 0.75s ease infinite;
        }
      }

      &.no-value {
        @extend %em;
        padding-left: 0;
      }
    }

    // tender status
    .tender2-status {
      padding: 10px 0 0;
      position: relative;
      white-space: nowrap;
      z-index: 2;

      @include min-screen($min-medium) {
        padding: 10px 90px 10px 0;
        vertical-align: top;
        width: 100%;
      }
    }

    // tender details
    .tender2-details {
      border-top: 1px solid $hr;
      padding: 10px 0 10px 40px;
      position: relative;

      &:before {
        @extend %fontello;
        color: var(--link);
        content: '\e804';
        display: block;
        font-size: 1.2rem;
        left: 0;
        position: absolute;
        top: 14px;
      }

      ul {
        @include min-screen($min-small) {
          column-count: 2;
          -ms-column-count: 2;
          -moz-column-count: 2;
          -o-column-count: 2;
          -webkit-column-count: 2;
        }
        @include min-screen($min-large) {
          column-count: 3;
          -ms-column-count: 3;
          -moz-column-count: 3;
          -o-column-count: 3;
          -webkit-column-count: 3;
        }
        @include min-screen($min-xlarge) {
          column-count: 4;
          -ms-column-count: 4;
          -moz-column-count: 4;
          -o-column-count: 4;
          -webkit-column-count: 4;
        }
        li {
          display: inline-block;
          width: 100%;

          strong {
            color: $all-black;
            display: inline-block;
            vertical-align: top;
            width: 100px;
          }
          span {
            display: inline-block;
            vertical-align: top;
            white-space: nowrap;
          }
          em {
            @extend %regular;
            display: inline-block;
            font-style: normal;
            width: 40px;
          }
          a.view {
            font-size: 0.8rem;
          }
        }
      }
    }

    // tender action box (view and action buttons)
    .tender2-action-box {
      border-bottom: 1px solid $hr;
      margin-bottom: 10px;
      padding-bottom: 10px;
      position: relative;
      white-space: nowrap;

      @include min-screen($min-large) {
        border: 0;
        display: inline-block;
        float: right;
        margin-bottom: 0;
        padding: 5px 0 0;
        text-align: right;
        vertical-align: top;
        width: 280px;
      }

      // action button
      .action-list {
        &.disabled {
          cursor: default;
          pointer-events: none;
        }
        &.tender-actions {
          @extend %regular;
          cursor: pointer;
          display: inline-block;
          float: none;
          margin: 0;
          overflow: visible;
          padding: 7px 12px 7px 30px;
          position: relative;
          text-align: left;
          vertical-align: middle;
          width: 150px;

          &:before {
            border-left: 1px solid rgba(255, 255, 255, 0.25);
            content: ' ';
            height: 22px;
            position: absolute;
            right: 30px;
            top: 5px;
            width: 1px;
          }
          &:after {
            @include arrow(14, 10);
            border-top-color: $white;
            transform: scale(0.8, 0.8);
            -webkit-transform: scale(0.8, 0.8);
            visibility: visible;
          }
          .icon-info-circled.action-list-title {
            font-size: 0.8rem;

            &:before {
              font-size: 1rem;
              left: 4px;
              position: absolute;
              top: 9px;
            }
          }
          ul.actions-list-items {
            // reset columns
            column-count: 1;
            -ms-column-count: 1;
            -moz-column-count: 1;
            -o-column-count: 1;
            -webkit-column-count: 1;
            // style
            background: var(--secondary-color);
            border-radius: 0 0 3px 3px;
            // position
            display: none;
            left: 0;
            position: absolute;
            top: 32px;
            width: 100%;
            z-index: 10;

            li {
              display: block;

              a,
              button {
                @extend %nd;
                background: none;
                border: 0;
                border-radius: 0;
                color: $white;
                display: block;
                font-size: 0.8rem;
                padding: 5px 10px;
                text-align: left;
                width: 100%;

                &:hover {
                  background: rgba(0, 0, 0, 0.1);
                }
              }
            }
          }
          &:hover {
            ul.actions-list-items {
              box-shadow: 0 0 500px rgba(0, 0, 0, 0.15) inset;
            }
          }
          &.visible {
            ul.actions-list-items {
              display: block;
            }
          }
        }
      }

      a.nui-button + .action-list {
        margin-left: 10px;
      }
    }

    // if empty
    .tender2-action-box:empty {
      display: none;
    }

    .tender2-action-box:empty + .tender2-details-list {
      padding-right: 85px;

      @include min-screen($min-medium) {
        padding-right: 0;
      }
    }

    // if tender action box exist then..
    .tender2-action-box + .tender2-details-list {
      @include min-screen($min-large) {
        display: inline-block;
        vertical-align: top;
        width: calc(100% - 280px);

        ul {
          column-count: 2;
          -ms-column-count: 2;
          -moz-column-count: 2;
          -o-column-count: 2;
          -webkit-column-count: 2;
        }
      }
      @include min-screen($min-xlarge) {
        ul {
          column-count: 3;
          -ms-column-count: 3;
          -moz-column-count: 3;
          -o-column-count: 3;
          -webkit-column-count: 3;
        }
      }
    }
  }

  // if only one <li>, then show tender details only
  // .tender-item:only-child,
  .tender-item.viewing {
    h2.tender-name {
      display: none;
    }
  }
}

//// ROLODEX styles
.rolodex {
  background: $white;
  border-top: 1px solid $hr;
  display: inline-block;
  width: 100%;
  margin-top: -1px;
  position: relative;

  @include min-screen($min-medium) {
    background: linear-gradient(
      to right,
      $white 0%,
      $white 50%,
      #eef1f8 50%,
      #eef1f8 100%
    );
    &:before {
      box-shadow: 3px 0 3px rgba(0, 0, 0, 0.1);
      content: ' ';
      display: block;
      height: 100%;
      left: calc(50% - 10px);
      position: absolute;
      width: 10px;
      z-index: 4;
    }
  }
}

// tender detail view
.tenders2 {
  .tender-view .tender-orders {
    margin-left: -20px;
    margin-right: -20px;

    @include min-screen($min-medium) {
      margin-left: -40px;
      margin-right: -40px;
    }
  }
}

.res-icon {
  border: 1px solid $light-gray;
  border-radius: 100px;
  color: $ok;
  font-size: 9.8px;
  height: 16px;
  line-height: 1.3;
  padding: 2px 6px;
}

.icon-attention {
  color: $error;
  font-size: 16px;
  line-height: normal;
}

.rolodex-reserve-header {
  display: inline-block;
  margin-left: 30px;
  position: relative;
  top: -2px;

  @include max-screen($max-medium) {
    display: block;
    margin: 0;
    top: 10px;
    width: fit-content;
  }
  @include max-screen($max-small) {
    display: inline-block;
    margin-left: 30px;
    top: -2px;
  }
  @include max-screen($max-xsmall) {
    display: block;
    margin-left: 0;
    top: 10px;
  }
  .res-icon {
    margin-right: 9px;
  }
  .icon-attention {
    margin-right: 5px;
  }
  .rolodex-reserve-items {
    display: inherit;
    font-size: 12.8px;

    & > * {
      display: inline;
      vertical-align: middle;
    }
    div:not(:first-child) {
      margin-left: 16px;
    }
  }
}

//////// rolodex summary column ////////
.rolodex-wheel {
  position: relative;
  z-index: 4;

  @include min-screen($min-medium) {
    display: inline-block;
    padding-bottom: 20px;
    position: sticky;
    top: 80px;
    vertical-align: top;
    width: 50%;
  }

  button.expand {
    background: $white;
    border: 1px solid #d9d9d9;
    border-radius: 3px;
    box-shadow: none !important;
    color: var(--link);
    cursor: pointer;
    display: inline-block;
    font-size: 0.8rem;
    height: 25px;
    line-height: 23px;
    margin: 9px 10px 0 0;
    padding: 0 10px 0 20px;
    text-align: center;
    position: relative;
    vertical-align: middle;
    width: 75px;

    @include max-screen($max-medium) {
      color: $white;
      overflow: hidden;
      padding-right: 0;
      width: 25px;
    }

    span {
      &:before {
        @include arrow(9, 5);
        left: 5px;
        right: auto;
        transform: scale(0.8, 0.8);
        -webkit-transform: scale(0.8, 0.8);
      }
      &.expanded {
        &:before {
          top: 7px;
          transform: rotate(180deg) scale(0.8, 0.8);
          -webkit-transform: rotate(180deg) scale(0.8, 0.8);
        }
      }
    }
  }

  .rolodex-heading {
    padding: 10px 20px;
    position: relative;

    @include min-screen($min-medium) {
      padding: 10px 0 10px 40px;
    }
    h3 {
      @extend %light;
      color: $mid-gray;
      font-size: 1.35rem;
      margin: 0;
      padding: 5px 0;
      display: inline;
    }
  }

  .rolodex-sort {
    border-top: 1px solid $hr;
    margin: 0 20px;
    overflow: hidden;
    padding: 13px 10px 17px 0;
    white-space: nowrap;

    h4 {
      color: $all-black;
      display: inline-block;
      font-size: 0.85rem;
      margin: 0 10px 0 0;
      vertical-align: middle;
    }
    ul.rolodex-sort-options {
      display: inline-block;
      vertical-align: middle;
      white-space: nowrap;

      li {
        display: inline-block;
        line-height: 1;
        margin: 0 5px 0 0;
        vertical-align: middle;

        .nui-button {
          background: transparent;
          border: 1px solid $lightest-gray;
          border-radius: 100px;
          color: var(--link);
          display: inline-block;
          font-size: 0.6rem;
          height: 22px;
          line-height: 24px;
          padding: 0 10px;
          text-transform: uppercase;
          transition: all 0.2s linear;

          &:active,
          &:focus,
          &:hover {
            border-color: var(--link);
            box-shadow: none;
          }
          &.active {
            background: var(--link);
            border-color: var(--link);
            color: $white;
          }
        }
      }
    }
    button.expand {
      display: inline-block;
      margin-top: 0;
    }
    @include min-screen($min-medium) {
      margin-left: 40px;
    }
  }

  .rolodex-card {
    button.nui-button.nui-reverse,
    a.add-counter-bid {
      @extend %regular;
      display: block;
      font-size: 0.8rem;
      margin: 0 0 10px;
      padding: 5px;
      position: relative;
      text-align: center;
      text-overflow: unset;
      width: 100%;

      span.place-bid-price {
        @extend %bold;
        color: $all-black;
        display: inline-block;
        margin-left: 5px;
      }
      @include max-screen($max-large) {
        max-width: 180px;
      }
      @include min-screen($min-medium) {
        margin: 0 0 10px;
      }
      @include min-screen($min-large) {
        margin: 5px 0;
      }
    }
  }

  .rolodex-card-heading {
    min-height: 40px;
    padding: 0;
    position: relative;
    z-index: 2;

    h4 {
      color: $all-black;
      display: inline-block;
      font-size: 0.9rem;
      line-height: 1.3rem;
      margin: 0;
      min-height: 44px;
      padding: 10px 0 0;
      position: relative;
      vertical-align: top;
      width: auto;
      z-index: 2;

      .has-icons {
        .icon-ship {
          @extend %nd;
          background: #10069f;
          border-radius: 50%;
          color: $white;
          display: inline-block;
          font-size: 0.7rem;
          height: 19px;
          line-height: 18px;
          margin: 0 0 0 5px;
          text-align: center;
          vertical-align: middle;
          width: 19px;
          z-index: 2;

          &:active,
          &:focus,
          &:hover {
            color: $white;
          }
          &:before {
            margin: 3px 2px 0 0;
          }
        }
      }

      span.my-variant {
        @extend %regular;
        color: $light-gray;
        display: inline-block;
        font-size: 0.8rem;
        margin-left: 10px;
      }

      @include max-screen($max-small) {
        width: calc(100% - 180px);
      }

      .has-icons {
        float: right;
        white-space: nowrap;
      }

      @include min-screen($min-medium) {
        width: calc(100% - 40px);
      }

      @include min-screen($min-large) {
        box-shadow: 1px 0 0 $hr;
        padding-right: 10px;
        width: calc(100% - 180px - 86px);
      }
      @include min-screen($min-xlarge) {
        font-size: 1rem;
        padding-left: 30px;
      }
    }

    h4 span.order-attr {
      @extend %regular;
      color: $mid-gray;
      display: inline-block;
      font-size: 0.6rem;
      line-height: 1rem;
      margin-left: 5px;
      vertical-align: middle;
    }
  }
}

// min
.rolodex {
  .rolodex-card.min {
    .rolodex-card-heading {
      h4 {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        @include max-screen($max-small) {
          width: calc(100% - 230px);
        }
      }
      div.rank {
        @include min-screen($min-large) {
          a.add-counter-bid {
            left: 10px;
            max-width: none;
            position: absolute;
            top: 6px;
            width: 170px;
          }
        }
        a,
        button {
          margin: 0;

          @include max-screen($max-medium) {
            margin-bottom: 5px !important;
            margin-top: -3px !important;
            width: 250px;
          }
          @include max-screen($max-small) {
            margin-bottom: 0 !important;
          }
        }
        button + span.rank-number,
        a + span.rank-number {
          position: absolute;
          right: 6px;
          top: 16px;

          @include max-screen($max-medium) {
            right: -3px;
            top: 6px;
          }
          @include max-screen($max-small) {
            right: 6px;
            top: 3px;
          }
        }

        a + span.rank-number {
          top: 12px;
          @include max-screen($max-medium) {
            top: 6px;
          }
          @include max-screen($max-small) {
            top: 3px;
          }
        }

        // blind
        button + span.rank-number.blind,
        a + span.rank-number.blind {
          right: 11px;
          top: 14px;

          @include max-screen($max-medium) {
            right: 4px;
            top: 4px;
          }
          @include max-screen($max-small) {
            right: 11px;
            top: 2px;
          }
        }
      }
    }
  }
}

// rolodex card content
.rolodex {
  @-webkit-keyframes card {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes card {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .rolodex-card-content {
    ul.order-attributes {
      margin: 0 0 10px;

      li.attribute {
        @extend %regular;
        border-bottom: 0;
        color: $light-gray;
        display: block;
        font-size: 0.8rem;
        line-height: 1.2rem;
        margin: 0;
        padding: 0;

        strong {
          @extend %bold;
          color: $all-black;
          display: inline-block;
          margin: 0 5px 0 0;
          padding-left: 5px;
          vertical-align: top;
          width: 110px;
        }
      }
    }

    .tender-order-ticket {
      -webkit-animation: card 1.5s;
      animation: card 1.5s;
    }

    .ticket-column {
      @include min-screen($min-large) {
        display: inline-block;
        vertical-align: top;
      }
      &.ticket-order-details {
        ul.data-list {
          margin: 0 0 10px;

          li {
            line-height: 1.2rem;

            h4 {
              color: var(--highlight);
              display: inline-block;
              font-size: 0.8rem;
              line-height: 1.2rem;
              margin: 0;
              padding: 0;
              vertical-align: middle;
              width: 110px;
            }
            .unit-detail {
              display: block;
              font-size: 0.8rem;
              line-height: 1.2rem;
              padding: 0;

              span.unit {
                color: $mid-gray;
                display: inline-block;
                font-size: 0.8rem;
                line-height: 0;
                top: 0;
                vertical-align: middle;
              }
              span.index {
                display: block;
                left: 110px;
                position: relative;
                top: -6px;
              }
            }
          }

          li > strong {
            @extend %regular;
            color: $all-black;
            display: inline-block;
            font-size: 0.8rem;
            line-height: 1.2rem;
            margin: 0;
            padding: 0 0 0 5px;
            vertical-align: middle;
            width: 100px;
          }
          li > span {
            color: $mid-gray;
            display: inline-block;
            font-size: 0.8rem;
            line-height: 1.2rem;
            padding-left: 5px;
            vertical-align: middle;
          }
        }

        @include min-screen($min-large) {
          border-right: 1px solid $hr;
          padding-right: 10px;
          width: calc(100% - 180px);
        }

        ul.data-list.tender2-summary-fields {
          list-style: none;
          margin: 0 0 10px;
          padding: 0;

          li {
            color: $mid-gray;
            font-size: 0.8rem;
            line-height: 1.2rem;
            margin: 0;
            padding: 0;

            span {
              display: inline-block;
              vertical-align: top;
              width: calc(100% - 110px);
            }
            strong {
              @extend %bold;
              color: $all-black;
              display: inline-block;
              vertical-align: top;
              width: 110px;
            }
            &.comment-snippet {
              span {
                max-height: 2.4rem;
                overflow: hidden;

                // solution for chrome only
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;

                @include min-screen($min-xxlarge) {
                  float: right;
                }
              }
            }
          }
          @include min-screen($min-xxlarge) {
            column-count: 2;
            -ms-column-count: 2;
            -moz-column-count: 2;
            -o-column-count: 2;
            -webkit-column-count: 2;
          }
        }
      }

      // bid info
      &.ticket-counter-details {
        border-top: 1px solid $hr;
        padding: 10px 0 0;

        @include min-screen($min-large) {
          border-left: 1px solid $hr;
          margin-left: -1px;
          padding: 5px 0 5px 10px;
          width: 180px;
        }

        .counter-details {
          .unit-detail.total.index {
            width: auto;

            span.index {
              display: block;
              position: relative;
              top: -8px;
              white-space: nowrap;
            }
          }
        }

        .unit-detail {
          display: inline-block;
          font-size: 1rem;
          min-width: 100px;
          padding: 0 0 5px;

          h4 {
            font-size: 0.7rem;
          }
          span.unit {
            display: block;
            font-size: 0.6rem;
          }
          span.index {
            position: relative;
            top: -10px;
          }
          @include min-screen($min-large) {
            min-width: 50%;
          }
        }

        .unit-detail.total {
          @extend %regular;
          color: $mid-gray;
          font-size: 0.7rem;
          min-width: 1px;
          padding-top: 0;
          white-space: normal;

          h4 {
            @extend %bold;
            color: $all-black;
            display: inline-block;
            font-size: 0.7rem;
            margin: 0 5px 0 0;
          }
          span.unit {
            display: inline-block;
            font-size: 0.7rem;
            line-height: 0;
            position: relative;
            top: -1px;
            vertical-align: middle;
          }

          &.index {
            h4 {
              display: block;
            }
          }
        }
      }
    }
  }

  .num-counters,
  .num-counters.rank,
  .rank {
    color: $all-black;
    display: block;
    font-size: 0.8rem;
    margin: -5px 0 0 25px;
    padding: 0 0 10px 25px;
    position: relative;
    width: 85px;

    @include min-screen($min-large) {
      border-left: 1px solid $hr;
      display: inline-block;
      margin: 0;
      padding: 10px 0 10px 25px;
      vertical-align: top;
    }

    &:before {
      background: #87a4af;
      border-radius: 50%;
      content: ' ';
      display: block;
      height: 7px;
      left: 10px;
      line-height: 0;
      position: absolute;
      top: 14px;
      width: 7px;

      @include min-screen($min-large) {
        top: 24px;
      }
    }

    &.none {
      color: $mid-gray;

      &:before {
        background: none;
        border-radius: 0;
        content: '-';
        font-size: 1.5rem;
      }
    }
  }

  .rank:not(.num-counters) {
    padding-left: 10px;
    position: relative;
    width: 180px;

    &.leading {
      color: $ok;
    }
    &.losing {
      color: $error;
    }
    &:before {
      display: none;
    }
  }

  .rank.num-counters:not(.none) {
    &:before {
      top: 7px !important;

      @include min-screen($min-large) {
        top: 17px !important;
      }
    }
  }

  .rank.num-counters.none {
    a.add-counter-bid {
      display: inline-block;
      width: 160px;
    }
  }

  li.data-item.rank .rank-number,
  span.rank-number {
    border-radius: 50%;
    color: $white;
    display: inline-block;
    font-size: 0.65rem;
    height: 18px;
    line-height: 18px;
    margin-left: 10px;
    text-align: center;
    width: 18px;

    @include min-screen($min-large) {
      position: absolute;
      right: 6px;
      top: 14px;
    }
    &.leading {
      background: $ok;
    }
    &.losing {
      background: $error;
    }
    &.blind {
      @extend %regular;
      background: none;
      color: $light-gray;
      font-size: 0.7rem;
      line-height: 1.4rem;
      top: 11px;
    }
  }

  .display-popout {
    position: absolute;
    right: 0;
    top: 10px;
    z-index: 4;

    &.order-actions {
      border: 0;
      margin: 0;
      padding: 0;

      @include min-screen($min-medium) {
        display: none;
      }
      @include min-screen($min-large) {
        display: block;
      }
    }
    &.boxed {
      border: 1px solid $lightest-gray;
      border-radius: 3px;
      min-width: 38px;
      padding: 0 5px;
    }
    .icon-ellipsis {
      background: none;
      border: 0;
      box-shadow: none;
      color: $light-gray;
      font-size: 1.35rem;
      margin: 0;
      padding: 0;

      &:before {
        margin: 0;
      }
      &:hover {
        color: $all-black;
      }
    }
    &.rolodex-popout {
      right: 20px;
      top: 16px;
    }
    &.boxed {
      .icon-ellipsis {
        font-size: 0.7rem;

        &:before {
          color: $light-gray;
          font-size: 1.35rem;
          position: absolute;
          right: 7px;
          top: 3px;
        }
      }
    }
  }

  .display-popout.rolodex-popout {
    position: absolute;
    right: 20px;
    top: 17px;

    .icon-ellipsis {
      @extend %bold;
      color: $all-black;
      padding-right: 35px;

      &:hover {
        color: $all-black;
        &:before {
          color: $all-black;
        }
      }
    }
    .tiny-panel {
      right: 0;

      @include min-screen($min-medium) {
        left: 95px;
        right: auto;
      }
    }
  }
}

.tender-order-ticket {
  .rolodex-card-heading {
    .rank {
      position: relative;
      &:not(.num-counters) {
        .res-tag {
          position: absolute;
          right: 30px;
          top: 12px;
          @include max-screen($max-medium) {
            position: initial;
            right: 0;
            top: 0;
            width: auto;
          }
        }
      }
      &.blind.autobid {
        .res-tag {
          right: 6px;
          top: 12px;
        }
      }
      &.num-counters {
        .res-tag {
          position: absolute;
          right: -89px;

          @include max-screen($max-medium) {
            position: initial;
            right: 0;
          }
        }
      }
    }
    &:has(.display-popout) {
      .res-tag {
        display: inherit;
        position: absolute;
        right: -64px !important;

        @include max-screen($max-medium) {
          display: block;
          position: initial;
          right: 0;
        }
      }
    }
    .res-tag {
      display: inherit;
    }
    .rank {
      &.num-counters {
        .icon-attention,
        .res-icon {
          top: 13px;
        }
      }
    }
    .display-popout {
      .icon-ellipsis {
        .display-popout-label {
          display: none;
        }
      }
    }
  }

  .display-popout + .num-counters.rank {
    @include max-screen($max-small) {
      margin-right: 35px;
    }
  }

  .rolodex-card:hover,
  .rolodex-card.active {
    .display-popout {
      right: 20px;
    }
  }
}

// rolodex hover styles
.rolodex {
  // hover styles
  ul.rolodex-wheel-items {
    > li.rolodex-card:hover,
    > li.rolodex-card.active,
    > li.rolodex-card:active,
    > li.rolodex-card.active:active,
    > li.rolodex-card.active:hover {
      .rolodex-card-heading,
      .rolodex-card-content {
        padding-left: 20px;
        padding-right: 20px;

        @include min-screen($min-medium) {
          padding-left: 40px;
        }
      }
    }
  }

  ul.rolodex-wheel-items {
    > li.rolodex-card {
      border-bottom: 1px solid $hr;
      border-top: 1px solid $hr;
      cursor: pointer;
      margin: -1px 20px 0;
      padding: 0;
      position: relative;

      @include max-screen($max-medium) {
        border-bottom-color: $light-gray;
        margin-bottom: 10px;
      }
      @include min-screen($min-medium) {
        margin-left: 40px;
      }
      &:first-child,
      &:first-child:hover {
        border-top-color: $mid-gray;
      }
    }

    > li.rolodex-card:hover {
      background: lighten(#f7f9fb, 1%);

      @include min-screen($min-large) {
        background: linear-gradient(
          to left,
          $white 0%,
          $white 200px,
          lighten(#f7f9fb, 1%) 200px,
          lighten(#f7f9fb, 1%) 100%
        );
        &:after {
          background: $white;
          bottom: 0;
          content: ' ';
          display: block;
          height: 4px;
          position: absolute;
          right: 0;
          width: 200px;
          z-index: 3;
        }
        box-shadow: 0 -4px 0 rgba(0, 0, 0, 0.05) inset;
      }
    }

    > li.rolodex-card.active,
    > li.rolodex-card.active:hover {
      box-shadow: 0 -2px 0 var(--link) inset;
    }

    > li.rolodex-card:hover,
    > li.rolodex-card:active,
    > li.rolodex-card.active,
    > li.rolodex-card.active:hover {
      border-color: darken($hr, 10%);
      margin-left: 0;
      margin-right: 0;
      z-index: 3;

      &:has(.res-tag) {
        .display-popout {
          right: 20px;
        }
      }

      @include max-screen($max-medium) {
        border-bottom-color: $light-gray;
      }
    }

    > li.rolodex-card:active,
    > li.rolodex-card.active,
    > li.rolodex-card.active:hover {
      background: #f0f3f7;

      @include min-screen($min-large) {
        background: linear-gradient(
          to left,
          #f7f9fb 0%,
          #f7f9fb 200px,
          #f0f3f7 200px,
          #f0f3f7 100%
        );
        &:after {
          background: #f7f9fb;
          bottom: 0;
          content: ' ';
          display: block;
          height: 4px;
          position: absolute;
          right: 0;
          width: 200px;
          z-index: 3;
        }
      }
    }
  }
}

//////// rolodex detail column ////////

.rolodex {
  .rolodex-column.rolodex-content {
    padding-bottom: 20px;
    padding-right: 20px;
    position: relative;
    z-index: 2;

    li.data-item.rank {
      margin-left: 0;

      strong {
        width: auto !important;
      }
    }

    .order-title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      a.button {
        margin-bottom: 10px;
      }
    }

    .order-attributes {
      margin: -5px 0 5px;

      li.attribute {
        border: 0;
        color: $mid-gray;
        font-size: 0.7rem;
        margin: 0;
        padding: 0;

        strong {
          @extend %bold;
          color: $all-black;
          display: inline-block;
          margin-right: 5px;
        }
      }
    }

    // accept docs radio
    .form-item-name-acceptdocs {
      .ant-form-item-label {
        display: block;
        width: 100%;
      }
    }

    span.popover-link {
      position: relative;
      margin-right: 8px;
    }

    @include min-screen($min-medium) {
      display: inline-block;
      padding-right: 40px;
      position: sticky;
      position: -webkit-sticky;
      top: 80px;
      vertical-align: top;
      width: 50%;

      // 15/09/2021 fix
      max-height: calc(100vh - 80px);
      overflow: auto;
    }

    .content {
      min-height: 120px;
      overflow-x: auto;
      overflow-y: visible;
      padding: 10px 0 10px 40px;
      position: relative;

      // data-location
      .data-location {
        &.hide-label {
          dt,
          dd {
            margin: 0;
          }
          dt:not(.location-name) {
            display: none;
          }
        }
      }

      ul.nav-list {
        border-top: 0;
        margin-bottom: 15px;
        padding-top: 0;
        white-space: nowrap;

        li {
          margin-right: 12px;
          transition: 0.2s all ease;

          @include min-screen($min-medium) {
            margin-bottom: 14px;

            &:after {
              bottom: -14px;
            }
          }
          @include min-screen($min-large) {
            margin-right: 24px;
          }
          &:last-child {
            margin-right: 0;
          }
          a {
            font-size: 1rem;
          }
        }
      }

      h3 {
        span.my-variant {
          @extend %regular;
          color: $light-gray;
          display: inline-block;
          font-size: 0.8rem;
          margin-left: 10px;
        }
      }
    }

    .tender-order-details,
    .tender-order-history,
    .tender-counter-orders {
      ul.product-details {
        strong {
          color: $all-black;
          display: inline-block;
          vertical-align: top;
          width: 250px;
        }
        span {
          display: inline-block;
          vertical-align: top;
        }
        .icon-link-ext-alt {
          color: #bfbfbf;
          display: inline-block;
          font-size: 0.8rem;
          margin: 0 4px;
        }
      }
      .order-details-item,
      .counter-details-item {
        li {
          > strong {
            color: $all-black;
            display: inline-block;
            vertical-align: top;
            width: 250px;
          }
          > span {
            color: $mid-gray;
            display: inline-block;
            vertical-align: top;
            width: calc(100% - 250px);
          }
        }
        p {
          padding: 0 0 0.5rem;
        }

        // stacked
        li.stacked {
          padding-bottom: 5px;
          strong,
          span {
            display: block;
            width: 100%;
          }
        }

        ul.data-list.tender-order-detail-2 {
          .data-item {
            display: inline-block;
            min-width: 150px;
            padding: 0;
            vertical-align: top;

            &.order-created {
              border-bottom: 1px solid $hr;
              display: block;
              margin: 0 0 10px;
              padding: 0 0 10px;
              width: auto;
            }
            &.order-price,
            &.reserve-price,
            &.order-volume {
              padding-bottom: 20px;

              span {
                display: block;
                width: 100%;
              }
              .unit-detail span {
                margin: 5px 0;
              }
              .unit-detail span.index {
                position: relative;
                top: -14px;
              }
              .unit-detail span.index-tooltip {
                position: relative;
                top: -8px;
                height: 15px;
                margin-left: -6px;
              }
              span.icon-help-circled {
                display: inline-block;
                width: auto;
              }
            }

            .unit-detail {
              padding: 0;

              span.unit {
                vertical-align: text-bottom;
              }
            }
          }
        }

        ul.data-list.tender-order-detail-3 {
          .data-item {
            &.attachments {
              display: block;
              margin-bottom: 10px;

              .view-files {
                &:before {
                  color: #ec4004;
                  display: inline-block;
                  font-size: 1rem;
                  margin-right: 5px;
                  vertical-align: middle;
                }
              }
            }
          }
        }
      }
    }

    // product variants
    .tender-order-details {
      li.data-item.variants {
        dd.variant-entry {
          margin-bottom: 0;
        }
      }
    }

    .counter-details-item {
      span {
        display: inline-block;
        vertical-align: top;
        width: calc(100% - 150px);

        dd {
          margin-bottom: 0;
        }

        .index-tooltip {
          top: -6px !important;
          width: auto;
        }
      }
    }

    .counter-details-item + form,
    .counter-details-item + .tender-counter {
      margin-top: 20px;
    }

    .own-counter,
    .company-counter,
    .my-autobid {
      background: $own;
      border-radius: 100px;
      color: $white;
      display: inline-block;
      font-size: 0.65rem;
      line-height: 1;
      margin: 0 5px 10px 0;
      padding: 5px 10px;
      text-transform: uppercase;

      &:before {
        @extend %fontello;
        content: '\e819';
        display: inline-block;
        font-size: 0.75rem;
        margin: 0 5px 0 -2px;
      }
    }
    .company-counter {
      background: #2f2579;
    }
    .my-autobid {
      background: #0b73a9;
      &:before {
        content: '\f192';
      }
    }

    // tender counter
    .tender-counter,
    .tender-counter-history {
      strong:not(.all-black) {
        color: $all-black;
        display: inline-block;
        width: 150px;
      }
      .eta-from {
        color: $all-black;
        display: inline-block;
        width: auto !important;
      }
      .popover-link {
        margin-bottom: 4px;
      }
      .select-location-warning {
        display: inline-block;
        width: 300px !important;
      }
    }

    .tender-counter {
      .ant-form {
        .nui-alert.bid-price-info {
          .variant-equivalent {
            .unit-detail {
              padding-top: 8px;

              @include max-screen($max-medium) {
                display: block;
              }
              span.index {
                display: block;
              }
            }
          }
        }
      }
    }

    .tender-order-history {
      @include max-screen($max-xsmall) {
        margin: 0 0 5px;
      }
      strong {
        color: $all-black;
        display: inline-block;
        width: 150px;
      }
    }

    .total-counter {
      display: block;
      .all-black {
        vertical-align: sub;
      }
      .unit-detail.price-ticker {
        font-size: 0.9rem;
        vertical-align: sub;

        span.unit {
          vertical-align: sub;
        }
      }
    }

    // bids (counters)
    .tender-counter,
    .tender-counter-orders,
    .tender-order-history,
    .tender-counter-history {
      // counter
      .data-list {
        .rank {
          border: 0;
          font-size: 0.9rem;
          padding: 0;

          .rank-number {
            position: relative;
            right: auto;
            top: auto;
          }
        }
      }
      .data-item,
      .counter-item,
      .order-item {
        &.order-created {
          display: block;
          font-size: 0.8rem;
          margin-bottom: 5px;

          strong {
            @extend %regular;
            color: $mid-gray;
            padding-right: 5px;
            width: auto !important;
          }
        }
        &.order-price,
        &.order-volume {
          display: inline-block;
          padding: 5px 0 15px;
          vertical-align: top;
          min-width: 150px;

          span {
            display: block;
            width: 100%;
          }
          span.icon-help-circled {
            display: inline-block !important;
            width: auto;
          }
          .unit-detail span {
            display: block;
            white-space: normal;
            width: auto;
          }
        }
        .unit-detail {
          padding: 0;

          span.unit {
            vertical-align: text-bottom;
          }
          span.index {
            position: relative;
            top: -10px;
          }
        }
        &.trade-volume,
        &.trade-value {
          > span {
            display: inline-block;
          }
          .unit-detail {
            font-size: 0.9rem;

            span {
              line-height: 0;
              top: 0;
              vertical-align: middle;
            }
          }
        }
        &.order-value {
          > span {
            display: inline-block;
          }
          .unit-detail {
            font-size: 0.9rem;

            span:not(.index) {
              line-height: 0;
              top: 0;
              vertical-align: middle;
              white-space: nowrap;
            }
            span.index {
              display: block;
              top: -7px;
              white-space: nowrap;
            }
          }
        }
      }
    }

    // tender counter orders list
    .tender-counter-orders-list,
    .tender-order-history > ul,
    .tender-counter-history > ul {
      > li {
        border-bottom: 1px solid $lightest-gray;
        margin-bottom: 15px;
        padding-bottom: 15px;

        &:last-child {
          border-bottom: 0;
        }
      }
    }

    .tender-order-buyers {
      ul.order-buyers-list {
        > li {
          border-bottom: 1px solid $hr;
          ul.data-list {
            li.data-item {
              display: inline-block;
              padding: 5px 0;
              vertical-align: top;
              width: 30%;

              strong {
                color: $all-black;
                display: inline-block;
                width: 90px;
              }
              strong + span {
                white-space: nowrap;
              }
              &:first-child {
                width: 70%;
              }
              &:first-child {
                strong {
                  display: none;
                }
              }
              &:nth-child(3) {
                font-size: 0.8rem;
                margin: -10px 0 0;
                padding: 0;
                width: 70%;

                strong {
                  color: $all-black;
                  margin-right: 5px;
                  width: auto;
                }
                strong + span {
                  color: $light-gray;
                }
              }

              span > .unit-detail {
                font-size: 0.9rem;
                padding: 0;
                text-align: right;
                white-space: nowrap;

                span {
                  line-height: 0;
                  top: 0;
                  vertical-align: middle;
                }
                h4 {
                  display: none;
                }
              }
            }
          }
        }
      }
    }

    // auto-bid/offer
    .tender-counter {
      .ant-form {
        .form-item-name {
          &-autobid_price {
            margin-right: 10px;
            max-width: 220px;
            vertical-align: top;
            white-space: nowrap;
            width: 100%;

            @include min-screen($min-medium) {
              display: inline-block;
              margin-right: 10px;
              max-width: 215px;
              vertical-align: top;
              width: calc(50% - 5px);
            }
            .ant-form-item {
              &-children {
                .ant-input-number {
                  width: 100%;
                }
              }
              &-control.has-error {
                @include max-screen($max-small) {
                  white-space: pre-line;
                }
              }
            }
          }
          &-autobid {
            display: inline-block;
            height: auto;
            max-width: 100%;
            margin-left: auto;
            overflow: visible;
            padding: 10px !important;
            text-align: justify;
            top: 33px;
            width: 160px;

            @include max-screen($max-medium) {
              right: 10px;
              width: 140px;
            }
            @include max-screen($max-small) {
              top: 0;
              width: 215px;
            }
            .ant-form-item {
              &-label {
                display: inline-block;
                height: auto;
                position: absolute;
                right: 0;
                text-align: center !important;
                top: 8px;
                width: max-content;

                @include max-screen($max-medium) {
                  right: -14px;
                }
                span.show-help {
                  margin-left: 1px;
                  vertical-align: middle;

                  @include max-screen($max-medium) {
                    margin-left: -1px;
                  }
                }
              }
              &-control-wrapper {
                height: auto;
                top: -12px !important;
                width: max-content;
              }
            }
          }
        }
        .alert-fee {
          margin-bottom: 25px;
        }
        .pallets-request {
          margin-bottom: 25px;
        }
      }
    }

    // form
    .tender-counter,
    .tender-counter-orders {
      .ant-form {
        transition: all 0.4s ease;
        -webkit-animation: routing 1.5s;
        animation: routing 1.5s;
        max-width: 440px;

        .ant-row {
          padding: 0;
        }
      }

      .ant-form-item {
        margin-bottom: 15px;
        width: 440px;
      }

      .nui-form.order-form .form-item-name-price,
      .nui-form.order-form .form-item-name-volume,
      .nui-form.order-form .form-item-name-size,
      .nui-form.order-form .form-item-name-group {
        display: block;
        width: 220px;
      }

      .nui-form.order-form .form-item-name-price,
      .nui-form.order-form .form-item-name-volume {
        @include min-screen($min-medium) {
          display: inline-block;
          margin-right: 10px;
          max-width: 215px;
          vertical-align: top;
          width: calc(50% - 5px);
        }
      }

      .nui-form.order-form .form-item-name-volume {
        @include min-screen($min-medium) {
          margin-right: 0;
        }
      }
    }
  }
}

// rank for mobile
.rolodex {
  .tender-order-ticket {
    .rank {
      @include max-screen($max-small) {
        position: absolute;
        right: 2px;
        top: 14px;
        width: auto;
      }
    }
    &:hover,
    &.active {
      .rank {
        @include max-screen($max-small) {
          right: 22px;
        }
      }
    }
  }
}

// mobile detail area
.rolodex .rolodex-column.rolodex-content {
  .close-detail-area {
    background: none;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    color: $lightest-gray;
    display: none;
    padding: 0;
    position: fixed;
    right: 38px;
    top: 30px;
    z-index: 2002;
    font-size: 1.1rem;
  }
  @include max-screen($max-small) {
    background: $light-panel;
    height: 100vh;
    right: -100vw;
    max-width: 100%;
    padding: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 2001;

    .content {
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      padding: 20px;
      position: relative;

      .tab-content .sticky-button {
        padding-bottom: 0;
      }
    }
  }
}

body.detail-area-open {
  @include max-screen($max-small) {
    overflow: hidden;

    .main-content {
      z-index: 1000;
    }
  }

  // detail area
  .main-content .tenders2 {
    @include max-screen($max-small) {
      z-index: 2000;

      .rolodex .rolodex-column.rolodex-content {
        -webkit-transform: translate(-100vw, 0);
        transform: translate(-100vw, 0);
        transition: transform 0.6s ease;

        .close-detail-area {
          display: block;
        }
      }
    }
  }
}

// dev mode
.dev-mode {
  .rolodex {
    .rolodex-column.rolodex-wheel,
    .rolodex-column.rolodex-content {
      @include min-screen($min-medium) {
        top: 100px;
      }
    }

    // 15/09/2021 fix
    .rolodex-column.rolodex-content {
      @include min-screen($min-medium) {
        max-height: calc(100vh - 100px);
      }
    }
  }
}

// modal content
.nui-modal {
  .data-list {
    list-style: none;
    margin: 0;
    padding: 0;

    li.data-item {
      strong {
        color: $all-black;
        display: inline-block;
        vertical-align: top;
        width: 200px;
      }
      span {
        display: inline-block;
        width: calc(100% - 200px);
      }
    }
    li.data-item.stacked {
      padding-bottom: 5px;

      strong,
      span {
        display: block;
        width: 100%;
      }
    }
    li.data-item.order-volume,
    li.data-item.order-price {
      display: inline-block;
      padding-bottom: 10px;
      vertical-align: top;
      width: 200px;

      span {
        display: block;
        width: auto;
      }

      span.icon-help-circled {
        display: inline-block !important;
        width: auto;
      }
    }

    li.data-item.order-price,
    li.data-item.order-volume {
      span.icon-help-circled {
        display: inline-block !important;
        width: auto;
      }
      span {
        .unit-detail {
          span.unit {
            display: inline-block;

            .all-black {
              width: auto !important;
            }
          }
          span.index {
            display: inline-block;
            position: relative;
            top: -10px;

            strong {
              display: inline;
            }
          }
        }
      }
    }
  }
}

// Finalise tenders
.tenders2 {
  //.view-tender,
  .tender-view {
    .tender-trades {
      .tender2-trades-header {
        padding: 0 20px;

        h2 {
          @extend %light;
          color: $mid-gray;
          margin: 10px 0;
          padding: 0;
        }
        @include min-screen($min-medium) {
          padding: 0 40px;
        }
      }
      .tender-trades-summary {
        padding: 10px 20px 20px;

        @include min-screen($min-small) {
          padding: 10px 20px 0;
        }
        @include min-screen($min-medium) {
          padding: 10px 40px 0 40px;
        }
      }
      .tender2-summary {
        padding: 0 0 20px;

        @include min-screen($min-small) {
          border-right: 1px solid $hr;
          display: inline-block;
          margin-right: 20px;
          padding-right: 20px;
          vertical-align: top;
          width: 320px;
          @include min-screen($min-medium) {
            width: 300px;
          }
        }
        ul.data-list {
          li {
            strong {
              color: $all-black;
              display: inline-block;
              vertical-align: top;
              width: 140px;
            }
            span {
              display: inline-block;
              vertical-align: top;
            }

            .unit-detail {
              font-size: 0.9rem;
              line-height: 1.5rem;
              padding: 0;

              span.unit {
                line-height: 1;
                top: 0;
                vertical-align: middle;
              }
            }
          }
        }

        // tbd trades
        .trade-tbd {
          border-top: 1px solid $hr;
          display: block !important;
          margin-top: 5px;
          padding-top: 5px;

          .tbd {
            @extend %bold;
            color: $all-black;
          }
          .show-help {
            position: relative;
            top: 3px;
          }
        }
      }

      .finaliseme {
        @include min-screen($min-small) {
          display: inline-block;
          vertical-align: top;
        }
      }
    }

    // table
    table {
      .td-content {
        .unit-detail {
          font-size: 0.9rem;
          line-height: 1.2rem;

          span {
            line-height: 1.2rem;
            top: 0;
          }
        }
      }
    }

    .column-total-value {
      .show-help {
        position: relative;
        top: 3px;
      }
    }

    .column-view-order {
      min-width: 340px;
      width: 340px;

      .td-content {
        min-height: 45px;
        padding-right: 85px !important;
        position: relative;

        > span {
          padding: 0;
        }
        a span.nowrap {
          display: inline-block;
          line-height: 0;
          margin: 0;
          padding: 0;
          vertical-align: middle;
        }
        span.small {
          margin: 0;
          padding: 0;
          position: absolute;
          right: 10px;
          top: 10px;

          a {
            @extend %regular;
            @extend %nd;
            background: var(--secondary-color);
            border-radius: 3px;
            color: $white;
            display: inline-block;
            font-size: 0.7rem;
            padding: 3px 6px;
          }
        }
      }
    }
  }

  // restore fucked up trades layout
  .page-route.tender-trades {
    margin-left: -20px;
    width: calc(100% + 40px);

    @include min-screen($min-medium) {
      margin-left: -40px;
      width: calc(100% + 80px);
    }
  }
}

// tender wizard
.tenders2 {
  .wizard.showing-summary .wizard-summary {
    height: 100%;
  }
  .wizard-content {
    padding-bottom: 20px;

    .form-item-name-method {
      .ant-form-item-label,
      .ant-form-item-control-wrapper {
        display: inline-block;
        vertical-align: top;
      }
      .ant-form-item-label {
        label {
          margin: 0 20px 0 0;
          padding: 8px 0;
          position: relative;
          top: -1px;
        }
      }
    }

    .ant-form-item + .tender-form {
      margin-top: 15px;
    }

    .form-item-name-start,
    .form-item-name-finish {
      @include min-screen($min-medium) {
        max-width: 49%;
      }
    }

    .form-item-name-startprice,
    .form-item-name-reserve_price,
    .form-item-name-volume {
      display: inline-block;
      margin-right: 2%;
      vertical-align: top;
      width: 48%;

      .ant-input-number {
        width: 100%;
      }
    }

    .ant-form-item-control {
      width: 100% !important;
    }

    .form-item-name-volume {
      margin-right: 0;
    }

    .upload-attachments {
      label,
      .nui-files-wrapper {
        display: block;
        max-width: none;
        width: 100%;
      }
      label {
        position: relative;

        &:after {
          position: absolute;
          right: 0;
          top: 5px;
        }
      }
    }

    .tender-edit-step.orders {
      .ant-form-item:first-child {
        .nui-field-holder.cb-set {
          margin-top: -10px;
        }
      }
    }

    .tender-edit-step.orders {
      .ant-row {
        padding-left: 0;
        padding-right: 0;
      }
      .nui-alert {
        margin: 20px 0;
      }
    }
  }

  .wizard dl.summary-list {
    // todo: refactor to reuse
    span.status {
      @include pill($pill-color);
      border-radius: 100px;
      display: inline-block;
      font-size: 0.6rem;
      margin: 0;
      min-width: 75px;
      text-align: center;
      white-space: nowrap;

      &.active {
        background: $tender-active;
        border-color: $tender-active;
        color: $white;
      }
      &.not-started,
      &.unpublished {
        @include tender-not-started;
        &:before {
          background: $lightest-gray;
        }
      }
      &.published {
        background: $tender-published;
        border-color: $tender-published;
        color: $white;
      }
      &.finished {
        background: $tender-finished;
        border-color: $tender-finished;
        color: $white;
      }
      &.finalised {
        background: $tender-finalised;
        border-color: $tender-finalised;
        color: $white;
      }
      &.cancelled,
      &.stopped {
        background: $tender-cancelled;
        border-color: $tender-cancelled;
        color: $white;
      }
    }

    span.d-entry strong {
      color: $all-black;
      width: 60px;
    }
    span.d-value {
      display: inline-block;
      text-align: left;

      .unit-detail {
        display: inline-block;
        font-size: 0.8rem;
        line-height: 1.1rem;
        padding: 0;

        span.unit {
          line-height: 1;
          position: relative;
          top: 0;
        }
      }
    }
    dt {
      font-size: 0.9rem;
      padding-bottom: 5px;
    }
  }
}

.page-route.tender-new {
  h1 {
    padding-right: 85px;
  }
  .wizard-content {
    transition: none;
  }
}

// participants list
.tender-edit-step.participants {
  .toggle-all {
    border-bottom: 1px solid $hr;
    padding: 0 0 15px;

    button {
      margin-right: 15px;
    }
  }
}

.participant-list {
  list-style: none;
  margin: 0;
  padding: 0;

  > li.participant-entry {
    border-top: 1px solid $hr;
    padding: 5px 0 6px;

    &:first-child {
      border: 0;
    }
    ul.data-list {
      list-style: none;
      margin: 0;
      padding: 0 50px 0 0;
      position: relative;

      li.participant-data.toggle {
        display: inline-block;
        vertical-align: middle;
        width: 55px;
      }
      li.participant-data.name {
        display: inline-block;
        vertical-align: middle;
      }
      li.participant-data.country {
        display: block;
        position: absolute;
        right: 0;
        top: 0;

        span.country-code > span {
          background: $white;
          border: 1px solid $lightest-gray;
          border-radius: 100px;
          color: $all-black;
          display: inline-block;
          font-size: 0.65rem;
          line-height: 1.2rem;
          padding: 0 10px;
          text-align: center;
          text-transform: uppercase;
          white-space: nowrap;
        }
      }
      li.participant-data.vat,
      li.participant-data.web {
        display: block;
        font-size: 0.8rem;
        padding-left: 55px;

        strong {
          color: $all-black;
          display: inline-block;
          margin-right: 5px;
        }
      }
      li.participant-data.reason {
        display: inline-block;
        font-size: 0.8rem;
        padding-left: 55px;

        span.icon-attention {
          font-size: 18px;
          position: absolute;
          right: 48px;
          top: 0;
        }
        strong {
          display: none;
        }
      }
    }
  }
}

// sticky counter
.sticky-counter {
  display: none;
}

.tender-item.viewing .sticky-counter {
  background: #f9f9f9;
  border-bottom: 1px solid transparent;
  box-shadow: none;
  display: block;
  line-height: 40px;
  margin-left: -20px;
  margin-bottom: -1px;
  min-height: 40px;
  padding: 0 20px;
  position: sticky;
  top: 76px;
  transition: box-shadow border 0.6s ease;

  &:after {
    background: $hr;
    content: ' ';
    display: block;
    height: 1px;
    left: 0;
    position: relative;
    transition: 0.6s all ease;
    width: 100%;
  }
  @include min-screen($min-medium) {
    top: 81px;
  }
  @include min-screen($min-large) {
    top: 79px;
  }
  width: calc(100% + 40px);
  z-index: 11;
  .tender-timer {
    &:before {
      top: 10px;
    }
  }
  .tender2-status {
    padding: 0 90px 0 0;

    .status {
      top: 8px;
    }
  }
  @include min-screen($min-medium) {
    margin-left: -40px;
    padding: 0 40px;
    width: calc(100% + 80px);
  }

  // bid summary
  .tender-timer {
    margin-bottom: 0;
    white-space: nowrap;
    width: 310px;

    @include min-screen($min-medium) {
      margin-right: 20px;
    }
    @include min-screen($min-large) {
      width: calc(50% - 87px);
    }
    @include min-screen($min-xlarge) {
      width: calc(33.3% - 51px);
    }
  }

  @-webkit-keyframes fadengrow {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes fadengrow {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .tender-ranking-totals {
    display: inline-block;
    position: relative;
    top: 1px;
    vertical-align: middle;

    @include max-screen($max-small) {
      display: block;
      font-size: 0.7rem;
      line-height: 1;
      padding-left: 40px;
    }
    strong {
      display: inline-block;
      margin: 0 10px 0 0;
      vertical-align: middle;

      &:first-letter {
        text-transform: uppercase;
      }
      @include max-screen($max-small) {
        padding-bottom: 10px;
      }
    }
    span.leading,
    span.losing {
      display: inline-block;
      margin: 0 10px 0 0;
      vertical-align: middle;

      @include max-screen($max-small) {
        padding-bottom: 10px;
      }

      &:before {
        background: $ok;
        border-radius: 50%;
        content: ' ';
        display: inline-block;
        height: 8px;
        margin: 0 5px 0 0;
        position: relative;
        top: -1px;
        vertical-align: middle;
        width: 8px;
      }
    }
    span.losing {
      margin: 0;

      &:before {
        background: $error;
        -webkit-animation: fadengrow 1s ease infinite;
        animation: fadengrow 1s ease infinite;
      }
    }
  }
}

.stick {
  .tender-item.viewing .sticky-counter {
    border-bottom: 1px solid $hr;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);

    &:after {
      display: none;
    }
  }
}

.tender-item.viewing .tender-item-header {
  .tender2-status {
    display: none;
  }
}

.sticky-counter + .tender-item-header + .tender-orders {
  .rolodex {
    .rolodex-wheel,
    .rolodex-content {
      @include min-screen($min-medium) {
        top: 120px;
      }
    }
  }
}

.dev-mode {
  .tender-item.viewing .sticky-counter {
    top: 100px;
    @include min-screen($min-medium) {
      top: 105px;
    }
    @include min-screen($min-large) {
      top: 102px;
    }
    @include max-screen($max-small) {
      top: 110px;
    }
  }

  .sticky-counter + .tender-item-header + .tender-orders {
    .rolodex {
      .rolodex-wheel,
      .rolodex-content {
        @include min-screen($min-medium) {
          top: 140px;
        }
      }
    }
  }
}

// autobid stuff
.fieldset.autobid {
  max-width: 215px;
  overflow: visible;

  .ant-form-item {
    margin-bottom: 0 !important;
  }
  p.smaller {
    padding: 5px 0 15px;
    width: 200%;
  }
  width: calc(50% - 5px);
  .ant-input-number {
    width: 100%;
  }
}

// product variants
.rolodex .rolodex-column.rolodex-content {
  .form-item-name-variant {
    border-bottom: 1px solid $hr;
    padding-bottom: 15px !important;
  }

  .nui-alert {
    .unit-detail {
      font-size: inherit;
      padding: 0;

      span.unit {
        position: relative;
        top: 0;
      }
    }
  }

  .variants-list {
    dd.variant-entry {
      margin: 0;
      padding-bottom: 5px;

      span {
        color: $mid-gray;
        display: inline-block;
        line-height: 1.1;
        margin-left: 5px;
        vertical-align: middle;
        width: auto;

        .unit-detail {
          display: inline-block;
          font-size: inherit;
          line-height: 1.1;
          padding: 0 2px;
          vertical-align: middle;

          span.unit {
            color: $mid-gray;
            font-size: 0.8rem;
            margin: 0;
            position: relative;
            top: 0;
            vertical-align: baseline;
          }
          span.index {
            display: block;
            top: 0;
          }
        }
      }
    }
  }

  .data-list.product-details + .order-details-item {
    .variants-list {
      dd.variant-entry {
        span {
          .unit-detail {
            span.unit {
              top: 0;
            }
          }
        }
      }
    }
  }

  // double check
  li.data-item.rank {
    span {
      font-size: 0.7rem;
      position: relative;
      top: -2px;
      vertical-align: middle;
      width: 18px !important;
    }
  }
}

.nui-alert {
  &.bid-price-info {
    margin-bottom: -25px;

    .variant-equivalent {
      border-bottom: 1px solid $hr;
      margin-bottom: 9px;
      padding-bottom: 9px;
    }
  }
}

.form-item-name-variant + .nui-alert.bid-price-info {
  margin-top: -20px;
}

// Attributes in tooltip
.ant-tooltip-content {
  p {
    @extend %bold;
    color: $all-black;
    margin: 0;
    padding: 0;
  }
  ul.order-attributes {
    border-top: 1px solid $hr;
    list-style: none;
    margin: 5px 0 0;
    padding: 5px 0 0;

    li.attribute {
      margin: 0;
      padding: 0;

      strong {
        @extend %bold;
        color: $all-black;
        padding-right: 5px;
      }
    }
  }
}

// next price hint
.page-route.tender-counter {
  .nui-alert.bid-price-info {
    .min-bid {
      .clm {
        display: inline-block;
        width: 50%;

        &.ttl {
          vertical-align: top;

          strong {
            padding: 0 20px 0 0;
          }
        }

        &.price {
          .unit-detail {
            .unit {
              display: inline;
            }
            .index {
              display: block;
            }
          }
        }
      }
    }
  }
}

.page-route {
  &.tender-order-details {
    .order-details-item {
      ul.data-list.tender-order-detail-3 {
        li.data-item.variants {
          span {
            .variants-list {
              .variant-entry {
                span {
                  .unit-detail {
                    span.unit,
                    span.index {
                      vertical-align: bottom;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &.tender-counter-orders {
    ul.tender-counter-orders-list {
      li {
        ul.data-list.counter-details-item {
          li.data-item.variant {
            span {
              .variants-list {
                .variant-entry {
                  span {
                    .unit-detail {
                      span.index,
                      span.unit {
                        top: 0;
                      }
                      span.unit {
                        vertical-align: bottom;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &.tender-order-history {
    ul {
      li.order-item {
        .order-details-item {
          ul.data-list.tender-order-detail {
            &-2 {
              li.data-item.order-price {
                span {
                  .unit-detail {
                    span.index {
                      strong.all-black {
                        width: auto;
                      }
                    }
                  }
                }
              }
            }
            &-3 {
              li.data-item.variants {
                span {
                  .variants-list {
                    .variant-entry {
                      span {
                        .unit-detail {
                          span.unit,
                          span.index {
                            top: 0;
                          }
                          span.unit {
                            vertical-align: bottom;
                          }
                          span.index {
                            strong.all-black {
                              width: auto;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

span.index-tooltip {
  position: relative;
  top: -6px;
  height: 15px;
  margin-left: -6px;
}
