// fontello icons

.icon-minus-circled:before {
  content: '\e704';
} /* '' */
.icon-attach:before {
  content: '\e800';
} /* '' */
.icon-heart:before {
  content: '\e801';
} /* '' */
.icon-ok:before {
  content: '\e802';
} /* '' */
.icon-cancel:before {
  content: '\e803';
} /* '' */
.icon-info-circled:before {
  content: '\e804';
} /* '' */
.icon-help-circled:before {
  content: '\e805';
} /* '' */
.icon-lock:before {
  content: '\e806';
} /* '' */
.icon-tags:before {
  content: '\e807';
} /* '' */
.icon-export:before {
  content: '\e808';
} /* '' */
.icon-pencil:before {
  content: '\e809';
} /* '' */
.icon-edit:before {
  content: '\e80a';
} /* '' */
.icon-print:before {
  content: '\e80b';
} /* '' */
.icon-chat:before {
  content: '\e80c';
} /* '' */
.icon-attention:before {
  content: '\e80d';
} /* '' */
.icon-attention-circled:before {
  content: '\e80e';
} /* '' */
.icon-cog:before {
  content: '\e80f';
} /* '' */
.icon-calendar:before {
  content: '\e810';
} /* '' */
.icon-login:before {
  content: '\e811';
} /* '' */
.icon-logout:before {
  content: '\e812';
} /* '' */
.icon-clock:before {
  content: '\e813';
} /* '' */
.icon-block:before {
  content: '\e814';
} /* '' */
.icon-search:before {
  content: '\e815';
} /* '' */
.icon-globe:before {
  content: '\e816';
} /* '' */
.icon-leaf:before {
  content: '\e817';
} /* '' */
.icon-chart-bar:before {
  content: '\e818';
} /* '' */
.icon-hammer:before {
  content: '\e819';
} /* '' */
.icon-eye:before {
  content: '\e81a';
} /* '' */
.icon-eye-off:before {
  content: '\e81b';
} /* '' */
.icon-pin:before {
  content: '\e81c';
} /* '' */
.icon-lock-open:before {
  content: '\e81d';
} /* '' */
.icon-box:before {
  content: '\e81e';
} /* '' */
.icon-archive:before {
  content: '\e81f';
} /* '' */
.icon-arrows-cw:before {
  content: '\e820';
} /* '' */
.icon-inbox:before {
  content: '\e821';
} /* '' */
.icon-arrow-combo:before {
  content: '\e822';
} /* '' */
.icon-down-dir:before {
  content: '\e823';
} /* '' */
.icon-left-dir:before {
  content: '\e824';
} /* '' */
.icon-right-dir:before {
  content: '\e825';
} /* '' */
.icon-up-dir:before {
  content: '\e826';
} /* '' */
.icon-floppy:before {
  content: '\e827';
} /* '' */
.icon-cancel-1:before {
  content: '\e828';
} /* '' */
.icon-check:before {
  content: '\e829';
} /* '' */
.icon-clipboard:before {
  content: '\e82a';
} /* '' */
.icon-key:before {
  content: '\e82b';
} /* '' */
.icon-user:before {
  content: '\e82c';
} /* '' */
.icon-tag:before {
  content: '\e82d';
} /* '' */
.icon-picture:before {
  content: '\e82e';
} /* '' */
.icon-plus-circled:before {
  content: '\e82f';
} /* '' */
.icon-flag:before {
  content: '\e830';
} /* '' */
.icon-flag-circled:before {
  content: '\e831';
} /* '' */
.icon-attention-filled:before {
  content: '\e832';
} /* '' */
.icon-resize-full-1:before {
  content: '\e833';
} /* '' */
.icon-th-list:before {
  content: '\e834';
} /* '' */
.icon-briefcase:before {
  content: '\e835';
} /* '' */
.icon-asterisk:before {
  content: '\e836';
} /* '' */
.icon-wrench:before {
  content: '\e837';
} /* '' */
.icon-home:before {
  content: '\e838';
} /* '' */
.icon-compass:before {
  content: '\e839';
} /* '' */
.icon-resize-full:before {
  content: '\e83a';
} /* '' */
.icon-resize-small:before {
  content: '\e83b';
} /* '' */
.icon-spin6:before {
  content: '\e83c';
} /* '' */
.icon-spin4:before {
  content: '\e83d';
} /* '' */
.icon-cancel-circled:before {
  content: '\e83e';
} /* '' */
.icon-resize-small-1:before {
  content: '\e83f';
} /* '' */
.icon-warehouse:before {
  content: '\e840';
} /* '' */
.icon-truck:before {
  content: '\e841';
} /* '' */
.icon-group:before {
  content: '\e842';
} /* '' */
.icon-stop:before {
  content: '\e843';
} /* '' */
.icon-mobile:before {
  content: '\e844';
} /* '' */
.icon-monitor:before {
  content: '\e845';
} /* '' */
.icon-ok-circled2:before {
  content: '\e846';
} /* '' */
.icon-ok-circled:before {
  content: '\e847';
} /* '' */
.icon-folder-open:before {
  content: '\e848';
} /* '' */
.icon-doc:before {
  content: '\e849';
} /* '' */
.icon-book:before {
  content: '\e84a';
} /* '' */
.icon-plus:before {
  content: '\e84b';
} /* '' */
.icon-attach-circled:before {
  content: '\e84c';
} /* '' */
.icon-comment:before {
  content: '\e84d';
} /* '' */
.icon-play:before {
  content: '\e84e';
} /* '' */
.icon-stop-1:before {
  content: '\e84f';
} /* '' */
.icon-pause:before {
  content: '\e850';
} /* '' */
.icon-list-alt:before {
  content: '\e851';
} /* '' */
.icon-check-empty:before {
  content: '\f096';
} /* '' */
.icon-twitter:before {
  content: '\f099';
} /* '' */
.icon-facebook:before {
  content: '\f09a';
} /* '' */
.icon-certificate:before {
  content: '\f0a3';
} /* '' */
.icon-filter:before {
  content: '\f0b0';
} /* '' */
.icon-menu:before {
  content: '\f0c9';
} /* '' */
.icon-sort:before {
  content: '\f0dc';
} /* '' */
.icon-sort-down:before {
  content: '\f0dd';
} /* '' */
.icon-sort-up:before {
  content: '\f0de';
} /* '' */
.icon-mail-alt:before {
  content: '\f0e0';
} /* '' */
.icon-linkedin:before {
  content: '\f0e1';
} /* '' */
.icon-exchange:before {
  content: '\f0ec';
} /* '' */
.icon-download-cloud:before {
  content: '\f0ed';
} /* '' */
.icon-upload-cloud:before {
  content: '\f0ee';
} /* '' */
.icon-bell-alt:before {
  content: '\f0f3';
} /* '' */
.icon-doc-text:before {
  content: '\f0f6';
} /* '' */
.icon-plus-squared:before {
  content: '\f0fe';
} /* '' */
.icon-angle-left:before {
  content: '\f104';
} /* '' */
.icon-angle-right:before {
  content: '\f105';
} /* '' */
.icon-angle-up:before {
  content: '\f106';
} /* '' */
.icon-angle-down:before {
  content: '\f107';
} /* '' */
.icon-circle-empty:before {
  content: '\f10c';
} /* '' */
.icon-circle:before {
  content: '\f111';
} /* '' */
.icon-code:before {
  content: '\f121';
} /* '' */
.icon-help:before {
  content: '\f128';
} /* '' */
.icon-info:before {
  content: '\f129';
} /* '' */
.icon-ellipsis:before {
  content: '\f141';
} /* '' */
.icon-ellipsis-vert:before {
  content: '\f142';
} /* '' */
.icon-minus-squared:before {
  content: '\f146';
} /* '' */
.icon-minus-squared-alt:before {
  content: '\f147';
} /* '' */
.icon-ok-squared:before {
  content: '\f14a';
} /* '' */
.icon-link-ext-alt:before {
  content: '\f14c';
} /* '' */
.icon-doc-inv:before {
  content: '\f15b';
} /* '' */
.icon-doc-text-inv:before {
  content: '\f15c';
} /* '' */
.icon-thumbs-up-alt:before {
  content: '\f164';
} /* '' */
.icon-apple:before {
  content: '\f179';
} /* '' */
.icon-android:before {
  content: '\f17b';
} /* '' */
.icon-box-1:before {
  content: '\f187';
} /* '' */
.icon-dot-circled:before {
  content: '\f192';
} /* '' */
.icon-plus-squared-alt:before {
  content: '\f196';
} /* '' */
.icon-bank:before {
  content: '\f19c';
} /* '' */
.icon-cubes:before {
  content: '\f1b3';
} /* '' */
.icon-file-pdf:before {
  content: '\f1c1';
} /* '' */
.icon-file-word:before {
  content: '\f1c2';
} /* '' */
.icon-file-excel:before {
  content: '\f1c3';
} /* '' */
.icon-file-powerpoint:before {
  content: '\f1c4';
} /* '' */
.icon-file-image:before {
  content: '\f1c5';
} /* '' */
.icon-file-video:before {
  content: '\f1c8';
} /* '' */
.icon-paper-plane:before {
  content: '\f1d8';
} /* '' */
.icon-circle-thin:before {
  content: '\f1db';
} /* '' */
.icon-sliders:before {
  content: '\f1de';
} /* '' */
.icon-trash:before {
  content: '\f1f8';
} /* '' */
.icon-chart-area:before {
  content: '\f1fe';
} /* '' */
.icon-chart-pie:before {
  content: '\f200';
} /* '' */
.icon-chart-line:before {
  content: '\f201';
} /* '' */
.icon-ship:before {
  content: '\f21a';
} /* '' */
.icon-heartbeat:before {
  content: '\f21e';
} /* '' */
.icon-sticky-note:before {
  content: '\f249';
} /* '' */
.icon-clone:before {
  content: '\f24d';
} /* '' */
.icon-user-circle:before {
  content: '\f2bd';
} /* '' */
