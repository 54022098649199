﻿// nui alert
.nui-alert {
  background: $alert-bg;
  border-radius: 3px;
  border: 1px solid $alert-color;
  line-height: 1.3rem;
  padding: 10px 15px;
  position: relative;

  hr {
    background: $alert-color;
  }

  strong {
    color: $all-black;
  }

  h2,
  h3,
  h4 {
    font-size: 1rem;
  }

  p,
  ul,
  ol {
    list-style: none;
    margin: 0 0 20px;
    padding: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &.nui-alert-error,
  &.error {
    background: $alert-error-bg;
    border-color: $alert-error-color;

    hr {
      background: $alert-error-color;
    }
  }
  &.nui-alert-warning,
  &.warning {
    background: $alert-warning-bg;
    border-color: $alert-warning-color;

    hr {
      background: $alert-warning-color;
    }
  }
  &.nui-alert-success,
  &.success {
    background: $alert-success-bg;
    border-color: $alert-success-color;

    hr {
      background: $alert-success-color;
    }
  }
}

// icons
.nui-alert {
  &.has-icon {
    padding-left: 40px;
    position: relative;

    &:before {
      @extend %fontello;
      font-size: 1.25rem;
      left: 11px;
      margin: 0;
      position: absolute;
      top: 10px;
    }
  }

  &.nui-alert-info,
  &.info {
    background: $alert-bg;
    border-color: $alert-color;

    hr {
      background: $alert-color;
    }
    &.has-icon {
      &:before {
        color: $link;
        content: '\e804';
      }
    }
  }
  &.nui-alert-error,
  &.error {
    background: $alert-error-bg;
    border-color: $alert-error-color;

    hr {
      background: $alert-error-color;
    }
    &.has-icon {
      &:before {
        color: $error;
        content: '\e80e';
      }
    }
  }
  &.nui-alert-warning,
  &.warning {
    background: $alert-warning-bg;
    border-color: $alert-warning-color;

    hr {
      background: $alert-warning-color;
    }
    &.has-icon {
      &:before {
        color: $warning;
        content: '\e80d';
      }
    }
  }
  &.nui-alert-success,
  &.success {
    background: $alert-success-bg;
    border-color: $alert-success-color;

    hr {
      background: $alert-success-color;
    }
    &.has-icon {
      &:before {
        color: $ok;
        content: '\e802';
      }
    }
  }
  &.nui-alert-help,
  &.help {
    background: #ffffd1;
    border-color: #f8e798;

    hr {
      background: #f8e798;
    }
    // has icon
    &.has-icon {
      &:before {
        color: $light-gray;
        content: '\e805';
      }
    }
  }
}

.nui-banner {
  font-size: 0.8rem;
  line-height: 1.2rem;
  margin: 10px;
  padding-right: 35px;
  position: relative;

  h4 {
    font-size: 0.9rem;
    span.date {
      @extend %regular;
      color: $light-gray;
      font-size: 90%;
      line-height: 1.2em;
    }
  }
  .nui-button.closeme {
    background: none;
    border: 0;
    box-shadow: none;
    color: $light-gray;
    font-size: 1.5rem;
    padding: 0;
    position: absolute;
    top: 10px;
    right: 10px;

    &:active,
    &:focus,
    &:hover {
      background: none;
      box-shadow: none;
    }
  }
}

// ...and, since we're still using ant alerts here and there:
.ant-alert.ant-alert-with-description .ant-alert-message {
  @extend %bold;
  color: $all-black;
  font-size: 1rem;

  .ant-btn {
    margin-top: 10px;
  }
}

// buttons as links in nui-alerts
.nui-alert {
  .nui-button.nui-simple.inline {
    display: inline !important;
    font-size: inherit;
    line-height: inherit;
    padding: 0;
    white-space: nowrap;
  }
}
