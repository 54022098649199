﻿// nui buttons
button.nui-button,
html input[type='button'].nui-button,
input[type='reset'].nui-button,
input[type='submit'].nui-button {
  -webkit-appearance: button;
  cursor: pointer;

  &:active,
  &:hover,
  &:focus {
    outline: none;
  }
}

button.nui-button[disabled],
html input.nui-button[disabled] {
  cursor: default;
}

button.nui-button::-moz-focus-inner,
input.nui-button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.nui-button {
  background: var(--link);
  border-color: inherit;
  border: 0;
  border-radius: 4px;
  box-shadow: none;
  color: $white;
  display: inline-block;
  font-size: 1rem;
  height: auto;
  line-height: 1.2rem;
  max-width: 100%;
  min-height: 1px;
  overflow: hidden;
  padding: 8px 24px;
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  text-transform: none;
  transition: all 0.2s ease;
  white-space: nowrap;

  &:hover {
    box-shadow: 0 0 500px rgba(0, 0, 0, 0.15) inset;
    color: $white;
  }
  &:active,
  &:focus {
    box-shadow: 0 0 500px rgba(0, 0, 0, 0.25) inset;
    color: $white;
    outline: none;
  }
  &.nui-rounded {
    border-width: 0;
    border-radius: 100px;
  }
  &.nui-small,
  &.nui-simple.nui-small,
  &.nui-button-link.nui-small {
    font-size: 0.8rem;
    padding: 5px 12px;
    [class^='icon-']:before,
    [class*=' icon-']:before {
      font-size: 0.8rem;
    }
  }
  &.nui-reverse.nui-small {
    padding: 4px 12px;
  }
  &[disabled],
  &.disabled,
  &[disabled]:active,
  &.disabled:active,
  &[disabled]:focus,
  &.disabled:focus {
    background-color: #bbb !important;
    border-color: rgba(0, 0, 0, 0.1) !important;
    color: $white;
    opacity: 1;
    -webkit-text-fill-color: inherit;
  }
}

.nui-button {
  &.nui-bid {
    background: var(--bid);
    &.disabled {
      opacity: 0.5;
    }
  }
  &.nui-offer {
    background: var(--offer);
    &.disabled {
      opacity: 0.5;
    }
  }
  &.nui-primary {
    background: var(--primary-color);
    border-color: inherit;
  }
  &.nui-secondary {
    background: var(--secondary-color);
    border-color: inherit;
  }
  &.nui-warning {
    background: var(--highlight);
    border-color: inherit;
  }
  &.nui-error {
    background: var(--error);
    border-color: inherit;
  }
  &.nui-reverse {
    background: $white;
    border: 1px solid $lightest-gray;
    color: var(--link);
    padding: 7px 24px;

    &:active,
    &:hover,
    &:focus {
      background: $white;
      border-color: $light-gray;
      box-shadow: none;
    }
  }
  &.nui-simple,
  &.nui-button-link {
    @extend %dd;
    @extend %regular;
    background: transparent;
    border: 0;
    color: var(--link);
    padding: 7px 24px;

    span {
      @extend %dd;
    }
    &:active,
    &:focus,
    &:hover {
      @extend %nd;
      background: transparent;
      border: 0;
      box-shadow: none;
      color: $all-black;

      span {
        @extend %nd;
      }
    }
  }
  &.nui-beveled {
    box-shadow: 0 -2px 0 rgba(0, 0, 0, 0.1) inset;

    &:active,
    &:hover,
    &:focus {
      box-shadow: 0 -2px 0 rgba(0, 0, 0, 0.1) inset;
    }
  }
  &.nui-button-link {
    border: 0;
    display: inline;
    font-size: inherit;
    line-height: inherit;
    padding: 0;
  }
}

// icon buttons
table td {
  a.nui-button.nui-button-icon,
  button.nui-button.nui-button-icon {
    min-height: 1px;
  }
}

a.nui-button.nui-button-icon,
button.nui-button.nui-button-icon {
  background: none;
  border: 0;
  box-shadow: none;
  min-height: 1px;
  padding: 0;

  &:active,
  &:hover,
  &:focus {
    background: none;
    border: 0;
    box-shadow: none;
  }
  &[disabled],
  &.disabled,
  &[disabled]:active,
  &.disabled:active,
  &[disabled]:focus,
  &.disabled:focus {
    span {
      opacity: 0.5;
    }
  }
  &.nui-reverse {
    span {
      background: $white;
      border: 1px solid $lightest-gray;
      box-shadow: 0 -2px 0 rgba(0, 0, 0, 0.1) inset;
      color: $light-gray;
    }
    &:active,
    &:hover,
    &:focus {
      span {
        background: $white;
        border-color: $light-gray;
        box-shadow: none;

        &:before {
          color: $all-black;
        }
      }
    }
  }
  &.nui-secondary {
    span {
      background: var(--link);
      border: 1px solid var(--link);
      box-shadow: 0 -2px 0 rgba(0, 0, 0, 0.15) inset;
      color: $white;
    }
    &:active,
    &:hover,
    &:focus {
      span {
        box-shadow: 0 0 500px rgba(0, 0, 0, 0.15) inset;
      }
    }
  }

  span {
    border-radius: 4px;
    display: block;
    font-size: 1rem;
    height: 32px;
    line-height: 32px;
    margin: 0;
    overflow: hidden;
    padding: 0;
    text-align: center;
    transition: 0.2s all ease;
    width: 32px;
  }

  // edit/delete
  span.icon-trash,
  span.icon-plus {
    background: $white;
    border: 1px solid $lightest-gray;
    box-shadow: 0 -2px 0 rgba(0, 0, 0, 0.1) inset;
    color: $light-gray;
  }
  span.icon-plus {
    line-height: 30px;
  }
  span.icon-pencil,
  span.icon-plus.primary {
    background: var(--link);
    border: 1px solid var(--link);
    box-shadow: 0 -2px 0 rgba(0, 0, 0, 0.15) inset;
    color: $white;
  }

  &:active,
  &:hover,
  &:focus {
    span.icon-trash {
      background: $white;
      border-color: $light-gray;
      box-shadow: none;

      &:before {
        color: $all-black;
      }
    }

    span.icon-pencil,
    span.icon-plus.primary {
      box-shadow: 0 0 500px rgba(0, 0, 0, 0.15) inset;

      &:before {
        color: $white;
      }
    }
  }
}

// button set
.button-set {
  .nui-button {
    display: block;
    margin-bottom: 10px;
    margin-right: 0;
    width: 100%;

    @include min-screen($min-xsmall) {
      display: inline-block;
      margin-right: 10px;
      vertical-align: middle;
      width: calc(50% - 5px);

      &:nth-child(2n) {
        margin-right: 0;
      }
    }
    @include min-screen($min-small) {
      width: auto;
    }
  }
}

.button-set.trio {
  .nui-button {
    margin-bottom: 10px;
  }
  .nui-button:nth-child(2) {
    margin-right: 0;
  }
  @include min-screen($min-small) {
    .nui-button:nth-child(2) {
      margin-right: 10px;
    }
    .nui-button:last-child,
    button:nth-child(3) {
      margin-right: 0;
    }
  }
}

.button-set.trio.trio-for-small {
  .nui-button {
    font-size: 0.9rem;
    margin-bottom: 10px;

    @include max-screen($max-xsmall) {
      display: inline-block;
      float: none;
      width: calc(33.3% - 7px);
    }
  }
  .nui-button:nth-child(2) {
    margin-right: 10px;
  }
  .nui-button:last-child,
  button:nth-child(3) {
    margin-right: 0;
  }
}

// cancel all orders, in order filter section
.has-dd-holder-reverse {
  background: $white;
  border: 1px solid $lightest-gray;
  border-radius: 4px;
  box-shadow: 0 -2px 0 rgba(0, 0, 0, 0.1) inset;
  display: inline-block;
  position: relative;

  &:hover {
    border-color: $mid-gray;
  }

  &.cancel-all {
    padding-top: 33px;
    position: absolute;
    right: 165px;
    top: 10px;
    width: 125px;
    z-index: 10;

    @include min-screen($min-small) {
      right: calc(100% - 440px);
      top: 12px;
    }
    @include min-screen($min-medium) {
      right: calc(100% - 508px);
      top: 22px;
      width: 160px;
    }

    button.cancel-all {
      font-size: 0.8rem;
      height: 33px;
      left: 0;
      position: absolute;
      text-align: left;
      top: 0;
      width: 100%;

      span {
        display: none;
      }

      span.dd-arrow {
        display: inline-block;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 0;
        vertical-align: middle;

        &:after {
          @include arrow(14, 10);
          border-top-color: $light-gray;
          transform: scale(0.8, 0.8);
          -webkit-transform: scale(0.8, 0.8);
        }
        &:before {
          border-left: 1px solid rgba(0, 0, 0, 0.25);
          content: ' ';
          height: 22px;
          position: absolute;
          right: 30px;
          top: 5px;
          width: 1px;
        }
      }
      @include min-screen($min-medium) {
        span {
          display: inline-block;
        }
      }
    }

    span.icon-cancel-circled {
      &:before {
        color: $error !important;
        font-size: 0.9rem;
        margin: 0 2px 0 0;
      }
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0 0 2px;

    li {
      font-size: 0.8rem;
      .nui-button.nui-button-link {
        background: none;
        border: 0 !important;
        box-shadow: none !important;
        display: block;
        line-height: 1.2rem;
        margin: 0;
        padding: 4px 10px;
        text-align: left;
        white-space: normal;
        width: 100%;

        &:hover {
          background: #f2f2f2;
          color: $all-black;
        }
      }
      button.nui-button-link {
        @extend %nd;
      }
    }
  }
  .nui-button:not(.nui-button-link) {
    background: none !important;
    border: 0 !important;
    box-shadow: none !important;
    margin: 0;
    padding-left: 10px !important;
    padding-right: 0 !important;
    text-align: left;
  }
}
