// warehouses
.add-warehouse {
  a.button {
    @include min-screen($min-medium) {
      margin-top: 10px;
    }
  }
}

.view-warehouses {
  .col-default {
    width: 80px;

    .ant-row.ant-form-item {
      padding: 0;
      .ant-form-item-control {
        line-height: inherit;
        text-align: center;
      }
    }
  }
}

.warehouses-form,
.customer-details-form,
.partners-form {
  .ant-alert {
    &.max {
      max-width: 1024px;
    }
  }
  .wizard {
    counter-reset: wizard-counter;

    hr.dashed {
      border-top-color: $lightest-gray;
    }
    .wizard-step {
      @include min-screen($min-medium) {
        display: inline-block;
        vertical-align: top;
        width: 250px;
      }
      h2 {
        @extend %bold;
        color: $all-black;
        font-size: 1rem;
        margin: 0;
        padding: 0;
        // add counter
        counter-increment: wizard-counter;
        padding-left: 35px;
        position: relative;

        &:before {
          @extend %regular;
          color: $light-gray;
          content: counter(wizard-counter) '.';
          display: inline-block;
          font-size: 1rem;
          left: 0;
          padding: 0 5px;
          position: absolute;
          text-align: right;
          vertical-align: middle;
          width: 20px;
        }
      }
      p {
        color: $light-gray;
        font-size: 0.7rem;
        line-height: 1rem;
        margin: 0;
        padding-left: 35px;
      }
      h2,
      p {
        @include min-screen($min-medium) {
          max-width: 250px;
        }
      }
    }
    .wizard-content {
      max-width: 1024px;

      @include min-screen($min-medium) {
        display: inline-block;
        padding-left: 20px;
        vertical-align: top;
        width: calc(100% - 250px);
      }
    }
  }
  .edit-customer-details {
    @include min-screen($min-medium) {
      margin-left: -15px;
    }
  }
}

// wizard content block
.wizard-step-block {
  padding-top: 20px;

  &.disabled {
    .wizard-step h2 {
      color: $light-gray;
    }
  }

  // content
  .wizard-content {
    .ant-row,
    .nui-form {
      padding: 0;

      .ant-form-item-children {
        input {
          height: 44px;
        }
      }
    }

    .ant-row.ant-form-item {
      padding: 0 0 15px;
      vertical-align: top;

      @include min-screen($min-small) {
        width: 400px;
      }
    }

    .ant-row.ant-form-item {
      &.form-item-name-street,
      &.form-item-name-suburb,
      &.form-item-name-city,
      &.form-item-name-state,
      &.form-item-name-country,
      &.form-item-name-zip {
        @include min-screen($min-xlarge) {
          display: inline-block;
          margin-right: 10px;
        }
      }

      &.form-item-name-suburb,
      &.form-item-name-state {
        @include min-screen($min-xlarge) {
          margin-right: 0;
          width: 280px;
        }
      }

      &.form-item-name-zip {
        @include min-screen($min-xlarge) {
          margin-right: 0;
          width: 120px;
        }
      }

      &.form-item-name-default {
        padding: 15px 0;
        .ant-form-item-label,
        .ant-form-item-control-wrapper {
          display: inline-block;
          margin: 0 20px 0 0;
          width: auto;
        }
      }
    }

    // form fields, will need to redo this after removing the antichrist
    .ant-form-item-label {
      display: block;
      line-height: 1;
      margin: 0 0 8px;
      text-align: left;

      label:after {
        display: none;
      }
    }

    .ant-select {
      width: 100%;
    }
    .ant-select .ant-select-selection--single {
      background: $white;
      border: 1px solid $lightest-gray;
      border-radius: 3px;
      display: block;
      height: 44px;
      line-height: 38px;
      outline: none;
      width: 100%;

      &:active,
      &:focus {
        box-shadow: none !important;
        outline: 0 !important;
      }

      span.ant-select-arrow {
        i {
          @include arrow(3, 0);
          -webkit-transform: scale(0.8, 0.8);
          transform: scale(0.8, 0.8);

          svg {
            display: none;
          }
        }
      }
      .ant-select-selection__rendered {
        line-height: 44px;
      }
    }

    // error styling
    .has-error .ant-select .ant-select-selection--single {
      border-color: $error;
    }

    .has-error
      .ant-select
      .ant-select-selection--single
      span.ant-select-arrow
      i {
      border-top-color: $error;
    }

    .ant-select-focused.ant-select-open .ant-select-selection--single {
      span.ant-select-arrow {
        i {
          -webkit-transform: scale(0.8, 0.8) rotate(180deg);
          transform: scale(0.8, 0.8) rotate(180deg);
        }
      }
    }
  }
}

.wizard-step-block:last-of-type hr {
  display: none;
}

.wizard-step-block:first-of-type {
  padding-top: 0;
}
