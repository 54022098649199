﻿// breadcrumbs
.h1bc {
  @include min-screen($min-medium) {
    display: inline-block;
    width: 66%;
  }
}

.nui-bc {
  display: none;
  list-style: none;
  margin: 0;
  opacity: 0;
  padding: 0;

  li {
    display: inline-block;
    position: relative;

    &:after {
      content: '/';
      display: inline-block;
      color: $light-gray;
      padding: 0 5px;
      vertical-align: middle;
    }
    &:last-child {
      @include max-screen($max-large) {
        display: none;
      }
      &:after {
        display: none;
      }
    }
  }
  li.active {
    display: inline-block;
    max-width: 270px;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: top;
    white-space: nowrap;

    a {
      @extend %nd;
      @extend %bold;
      color: $all-black;
      line-height: 1;
      padding: 5px 0 2px;
      pointer-events: none;
    }
  }
  @include min-screen($min-medium) {
    display: inline-block;
    opacity: 1;
    padding: 15px 0;
    position: absolute;
    right: 40px;
  }
}

.nui-bc a {
  color: var(--link);
  cursor: pointer;
  text-decoration: underline;
  transition: all 0.2s;

  &:active,
  &:focus,
  &:hover {
    color: var(--link-hover);
    text-decoration: none;
  }
}

button.configuration-links {
  overflow: visible;
  text-overflow: none;
}

.open-configuration-dd {
  padding-right: 10px;
  position: relative;

  &:before {
    @include arrow(8, 22);
    right: -10px;
    top: 8px;
    transform: scale(0.9, 0.9);
    -webkit-transform: scale(0.9, 0.9);

    @include max-screen($max-small) {
      right: 4px;
    }
  }
}

.nui-bc ul.configuration-options {
  background: $white;
  border: 1px solid $lightest-gray;
  border-radius: 3px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
  font-size: 0.8rem;
  list-style: none;
  margin: 0;
  min-width: 150px;
  padding: 5px 10px;
  position: absolute;
  right: -20px;
  top: 30px;
  z-index: 30;

  &:before {
    @include arrow(-7, 25);
    border-top-color: $lightest-gray;
    transform: scale(0.9, 0.9) rotate(180deg);
    -webkit-transform: scale(0.9, 0.9) rotate(180deg);
    display: none;
  }
  @include min-screen($min-medium) {
    left: -11px;
    right: auto;

    &:before {
      left: 11px;
      right: auto;
    }
  }
  > li {
    display: block;

    &:after {
      display: none;
    }
    a {
      display: block;
      &:after {
        display: none;
      }
    }
    &.selected {
      a {
        @extend %nd;
        @extend %bold;
        color: $all-black;
      }
    }
  }
}

.nui-bc > li.active {
  a {
    @extend %nd;
    @extend %regular;
    color: $mid-gray;
  }
}
