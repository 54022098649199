// popover styles
.nui-popover.nui-popover-zoom-enter,
.nui-popover.nui-popover-zoom-leave {
  display: block;
}
.nui-popover-zoom-enter,
.nui-popover-zoom-appear {
  opacity: 0;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
  animation-play-state: paused;
}
.nui-popover-zoom-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.6, -0.3, 0.74, 0.05);
  animation-play-state: paused;
}
.nui-popover-zoom-enter.nui-popover-zoom-enter-active,
.nui-popover-zoom-appear.nui-popover-zoom-appear-active {
  animation-name: nuiToolTipZoomIn;
  animation-play-state: running;
}
.nui-popover-zoom-leave.nui-popover-zoom-leave-active {
  animation-name: nuiToolTipZoomOut;
  animation-play-state: running;
}
@keyframes nuiToolTipZoomIn {
  0% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(0, 0);
  }
  100% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
  }
}
@keyframes nuiToolTipZoomOut {
  0% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(0, 0);
  }
}
.nui-popover {
  background: #ffffe6;
  border: 1px solid $lightest-gray;
  border-radius: 3px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
  font-size: 0.8rem;
  line-height: 1.2rem;
  max-width: 280px;
  opacity: 1;
  overflow: hidden;
  padding: 5px 10px;
  position: absolute;
  top: 0;
  transition: 0.4s all ease;
  visibility: visible;
  width: auto;
  z-index: 1070;
}
.nui-popover-hidden {
  display: block;
  opacity: 0;
  transition: 0.4s all ease;
  z-index: -1;
}
.nui-popover-inner {
  background: none;
  color: $mid-gray;
  display: block;
  font-size: 0.8rem;
  padding-left: 20px;
  position: relative;
  text-align: left;
  text-decoration: none;

  &:before {
    @extend %fontello;
    color: $light-gray;
    content: '\e804';
    display: block;
    font-size: 1rem;
    left: -3px;
    margin: 0;
    position: absolute;
    top: 2px;
  }
}
.nui-popover-arrow,
.nui-popover-arrow-inner {
  display: none;
}
