//// slick slider default css
.slick-slider {
  display: block;
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  display: block;
  overflow: hidden;
  position: relative;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  display: block;
  position: relative;
  top: 0;
  left: 0;
  transition: none;
}
.slick-track:before,
.slick-track:after {
  display: table;

  content: '';
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-arrow.slick-hidden {
  display: none;
}

// marketplace & orders slider
ul.slides-container {
  list-style: none;
  margin: 0;
  padding: 0;
}

.slides-container,
.slides-container * {
  transition: none;
}

.offers-bids-details,
.post-trade-lists,
.list-box {
  .slick-slider {
    height: 80px;
    overflow: hidden;

    .slick-list {
      height: 80px;

      img {
        cursor: zoom-in;
        height: 80px;
        max-width: none;
        transition: 0.4s transform ease;
        width: auto !important;

        &:hover {
          transform: scale(1.5);
        }
      }
      .slick-slide {
        display: inline-block;
        height: 80px;
        overflow: hidden;
        margin: 0 2px;
        padding: 0;
        transition: none;
        width: auto !important;
      }
    }
  }

  .slick-arrow {
    background: rgba(255, 255, 255, 0.75);
    border: 0;
    border-radius: 0;
    box-shadow: none;
    color: $all-black;
    cursor: pointer;
    display: block;
    height: 80px;
    line-height: 78px;
    opacity: 0;
    padding: 0;
    position: absolute;
    top: 0;
    width: 20px;
    -webkit-transform: none;
    transform: none;
    transition: 0.2s all ease;
    z-index: 1001;

    span {
      display: block;

      &:before {
        font-size: 1.25rem;
        margin: 0;
      }
    }
    &.slick-prev {
      left: -20px;
    }
    &.slick-next {
      right: -20px;
    }
  }

  .slick-slider {
    &:hover {
      .slick-arrow {
        opacity: 1;

        &.slick-prev {
          left: 0;
        }
        &.slick-next {
          right: 0;
        }
      }
    }
  }
}
