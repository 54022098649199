// navbar
.sticky-nav {
  @include min-screen($min-medium) {
    position: var(--sticky-nav-position);
    position: -webkit-sticky;
    top: 0;
    z-index: 3;
  }
  @include max-screen($max-small) {
    padding-top: 34px;
  }
}

.navbar {
  background: var(--navbar);
  min-height: 65px;
  min-width: 320px;
  padding: 15px 20px;
  position: relative;
  transition: opacity 0.6s ease;
  width: 100vw;
  z-index: 2;

  @include min-screen($min-medium) {
    padding: 15px 40px;
  }

  // placeholders
  ::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.5);
  }

  :-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
  ::-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.5);
  }

  .button-set,
  .navigation,
  .navigation nav,
  .navigation ul,
  .navigation li {
    @include min-screen($min-medium) {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .button-set {
    transition: 0.2s all linear;

    a {
      display: inline-block;
      padding-left: 12px;
      padding-right: 12px;
      &.disabled,
      &[disabled],
      &.readonly,
      &[readonly] {
        opacity: 0.5;
      }
    }
    &.lcol {
      min-width: 1px;
    }
    @include max-screen($max-small) {
      margin-left: -15px;
      text-align: center !important;

      a {
        margin: 0 5px;
        padding-left: 0;
        padding-right: 0;
        width: 30%;
      }
    }
    @include max-screen($max-xxsmall) {
      a {
        font-size: 0.8rem;
        left: -10px;
        position: relative;
      }
    }
    @include min-screen($min-medium) {
      text-align: left;

      a {
        margin: 0 10px 0 0;
        padding-left: 24px;
        padding-right: 24px;
      }
    }
    a {
      margin-bottom: 0;
    }
  }

  .navigation {
    position: relative;

    // dashboard
    a.icon-clipboard,
    a.icon-chart-bar {
      @extend %nd;
      color: $navbar-link;
      display: inline-block;
      font-size: 0;
      vertical-align: middle;

      &.selected {
        color: $navbar-link-active;
      }
      @include min-screen($min-medium) {
        border-right: 1px solid rgba(255, 255, 255, 0.3);
        margin-right: 19px;
        width: 32px;
      }
      &:before {
        font-size: 1.1rem;
        left: -5px;
        position: relative;
        top: 5px;
      }

      @include max-screen($max-small) {
        left: 10px;
        margin: 0;
        position: absolute;
        top: 2px;
        width: auto;

        &:before {
          font-size: 1.25rem;
        }
      }
    }

    .secondary-holder {
      display: inline-block;
      left: 10px;
      position: relative;
      top: -2px;
      vertical-align: middle;
      z-index: 2;
    }

    button.show-more {
      background: none;
      border: 1px solid rgba(255, 255, 255, 0.25);
      border-radius: 3px;
      color: $navbar-link;
      display: inline-block;
      font-size: 0;
      padding: 0;
      position: relative;
      top: 2px;
      vertical-align: middle;

      @include min-screen($min-large) {
        margin-left: 10px;
      }
      &:active,
      &:focus,
      &:hover {
        border: 1px solid rgba(255, 255, 255, 0.5);
        color: $navbar-link-active;
      }
      &:before {
        font-size: 1.5rem;
        position: relative;
        top: 1px;
        vertical-align: middle;
      }
    }
    nav {
      padding: 10px 0 12px;
      ul {
        li {
          @include min-screen($min-medium) {
            padding: 0 0 0 10px;

            a {
              display: inline-block;
            }
          }
          @include min-screen($min-large) {
            padding: 0 0 0 15px;
          }
        }
      }
      &.main {
        ul li a span {
          @extend %regular;

          &:before {
            content: '\e82c';
          }
        }
        ul li a.icon-bank,
        ul li a.icon-hammer {
          @extend %bold;

          .activity {
            &:before {
              @extend %fontello;
              content: '\e82c';
              opacity: 0.8;
            }
          }
        }
        ul li.marketplace-link,
        ul li.tenders-link {
          position: relative;

          @include min-screen($min-large) {
            min-width: 100px;
          }
          .activity-status {
            @extend %regular;
            bottom: -0.3rem;
            color: $white;
            display: none;
            font-size: 0.6rem;
            line-height: 0.6rem;
            margin: 0;
            opacity: 0.75;
            position: absolute;
            white-space: nowrap;
            width: 100%;

            @include min-screen($min-large) {
              display: block;
            }
          }
          .activity-status + a {
            @include min-screen($min-large) {
              position: relative;
              top: -0.3rem;
            }
          }
        }
        @include min-screen($min-medium) {
          ul {
            li {
              a {
                &.icon-bank,
                &.icon-hammer {
                  font-size: 0;
                  min-width: 45px;
                  position: relative;
                  text-align: center;
                  top: 3px;

                  &:before {
                    font-size: 1.2rem;
                    margin: 0 10px 0 0;
                  }
                }
                .activity {
                  background: $online;
                  border-radius: 4px;
                  color: $white;
                  display: inline-block;
                  font-size: 0.7rem;
                  font-style: normal;
                  line-height: 0.7rem;
                  margin: 0;
                  padding: 3px 6px 4px 2px;
                  position: absolute;
                  right: -10px;
                  text-align: left;
                  top: -20px;
                  z-index: 2;
                }
              }
            }
            li.marketplace-link {
              @include min-screen($min-medium) {
                border-right: 1px solid rgba(255, 255, 255, 0.25);
              }
            }
            li.tenders-link {
              @include min-screen($min-medium) {
                margin-right: 30px;
              }
            }
            li.nav-small {
              a {
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }
        }
        @include min-screen($min-large) {
          ul {
            li {
              a {
                &.icon-bank,
                &.icon-hammer {
                  font-size: inherit;
                  top: auto;

                  &:before {
                    display: none;
                  }
                }
                &.icon-bank {
                  padding-right: 15px;
                }
                span.activity {
                  position: relative;
                  right: auto;
                  top: -2px;
                  vertical-align: middle;
                }
              }
            }
          }
        }

        li.navsmall {
          display: none;

          @include min-screen($min-large) {
            display: inline-block;
          }
          a {
            text-overflow: ellipsis;
            white-space: nowrap;

            @include min-screen($min-medium) {
              font-size: 0.9rem;
            }
          }
          a.admin {
            @extend %bold;
            @include min-screen($min-medium) {
              background: $white;
              border-radius: 3px;
              color: var(--navbar);
              font-size: 0.65rem;
              line-height: 1;
              margin-left: 30px;
              padding: 8px 10px;
              position: relative;
              text-transform: uppercase;
              top: 0.5px;
            }
          }
        }
      }

      &.secondary {
        position: absolute;
        top: 0;
        width: 200px;

        ul {
          li {
            a {
              font-size: 0.9rem;
            }
          }
        }
        &.open {
          background: $white;
          border-radius: 3px;
          box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
          height: auto;
          overflow: auto;
          margin: 55px 0 0 -40px;
          padding: 0;

          ul {
            display: block;
            position: relative;

            &:before {
              border-bottom: 7px solid $white;
              border-left: 6px solid transparent;
              border-right: 6px solid transparent;
              content: '';
              display: none;
              height: 0;
              left: 15px;
              position: absolute;
              top: -6px;
            }
            li {
              border-bottom: 1px solid #dedede;
              display: block;
              padding: 0;

              &:last-child {
                border: 0;
              }
              a {
                color: var(--link);
                display: block;
                padding: 5px 10px;

                &:before {
                  color: $light-gray;
                }
                &:hover {
                  background: rgba(0, 0, 0, 0.05);
                  color: $all-black;
                  &:before {
                    color: $all-black;
                  }
                }
              }
              a.selected,
              a.selected:hover {
                background: rgba(0, 0, 0, 0.05);
                color: $all-black;
              }
            }
          }
        }
      }

      ul {
        li {
          color: $navbar-color;

          a {
            color: $navbar-link;
            &:active,
            &:focus,
            &:hover,
            &.selected {
              color: $navbar-link-active;
            }
          }
        }
      }

      // dd on large
      &.secondary {
        transition: none;

        @include min-screen($min-medium) {
          margin: 55px 0 0 -40px;
          right: -160px;
          top: -10px;

          &.open {
            height: auto;
            overflow: visible;
          }
        }
      }
    }
  }
}

.stick {
  .navbar {
    opacity: 0.95;
  }
}

.help-section {
  display: inline-block;
  position: absolute;
  right: 30px;
  top: 25px;
  vertical-align: middle;

  @include min-screen($min-medium) {
    right: 36px;
  }

  .icon-set {
    display: inline-block;
    vertical-align: top;
  }

  button.config-label {
    background: 0;
    border: 0;
    color: $navbar-link;
    cursor: pointer;
    display: none;
    font-size: 0.8rem;
    margin-left: -145px;
    margin-top: 4px;
    padding: 0;

    @include min-screen($min-medium) {
      display: inline-block;
    }
  }

  .icon-cog {
    @extend %nd;
    background: none !important;
    border: 0;
    color: $navbar-link;
    display: inline-block;
    font-size: 0.8rem;
    margin: 0 0 0 10px;
    padding-right: 40px;
    position: absolute;
    right: 24px;
    top: 0;
    transition: none;
    vertical-align: middle;
    white-space: nowrap;

    span {
      display: none;
    }
    @include min-screen($min-medium) {
      top: 4px;

      span {
        display: inline-block;
      }
    }
    @include min-screen($min-large) {
      top: 2px;
    }
    @include max-screen($max-small) {
      padding-right: 0;
      right: 10px;
      top: -4px;
    }
    &:before {
      font-size: 1.35rem;
      margin: 0;
      position: absolute;
      right: 5px;
      top: 2px;

      @include min-screen($min-medium) {
        top: 3px;
      }
      @include min-screen($min-large) {
        top: 3px;
      }
    }
    &:after {
      display: none;
    }
  }

  // dd config
  nav.dd-config {
    background: $white;
    border-radius: 3px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    height: 0;
    margin: 55px 0 0 -40px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    right: -10px;
    top: -10px;
    width: 200px;

    @include max-screen($max-small) {
      right: 10px;
    }
    ul {
      li {
        position: relative;

        &.dd-header,
        &.dd-header:hover {
          @extend %bold;
          background: var(--link);
          border-radius: 3px 3px 0 0;
          color: $white;
          display: none;
          font-size: 0.9rem;
          padding: 5px 10px;
        }
        a {
          font-size: 0.9rem;
        }
      }
    }
    &.open {
      height: auto;
      overflow: visible;

      ul {
        display: block;
        position: relative;
        &:before {
          border-bottom: 7px solid $white;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          content: '';
          display: block;
          height: 0;
          left: 154px;
          position: absolute;
          top: -6px;

          @include max-screen($max-small) {
            left: auto;
            right: 17px;
          }
        }
        li {
          display: block;

          a {
            color: var(--link);
            display: block;
            padding: 3px 10px;

            &.selected {
              background: rgba(0, 0, 0, 0.05);
              color: $all-black;
            }
            &:hover {
              background: none;
              color: $all-black;
            }
          }
          &:hover {
            background: rgba(0, 0, 0, 0.05);
          }
        }
      }
    }
  }
}

.icon-menu {
  color: $lightest-gray;
  font-size: 0;
  left: 5px;
  position: fixed;
  top: 27px;
  z-index: 7;

  &:before {
    font-size: 1.5rem;
  }
}

// update main and 2nd nav for mobile
@include max-screen($max-small) {
  .navigation nav.main,
  .navigation .secondary-holder {
    height: 0;
    overflow: hidden;
    position: absolute;
    top: -1000px;
    width: 200px;
  }
}

// mobile nav
.mobile-nav {
  background: var(--mobile-nav-background);
  height: 70px;
  padding: 0;

  ul {
    align-items: flex-end;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: inline-flex;
      flex: 1;
      min-width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;

      .box {
        @extend %nd;
        @extend %bold;
        color: rgba(255, 255, 255, 0.75);
        display: block;
        font-size: 0.75rem;
        height: 100%;
        margin: 0 auto;
        overflow: hidden;
        padding: 15px 10px 10px;
        position: relative;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;

        &.selected {
          background: rgba(0, 0, 0, 0.2);
          color: $white;
        }
        span {
          &:before {
            display: block;
            font-size: 1.4rem;
            font-weight: normal;
            margin: 2px auto 0;
            position: relative;
          }
          em.notification,
          em.activity {
            @extend %regular;
            background: $highlight;
            border-radius: 100px;
            color: $white;
            display: none;
            font-size: 0.8rem;
            font-style: normal;
            left: 51%;
            line-height: 0.8rem;
            padding: 2px 6px 3px;
            position: absolute;
            text-align: center;
            top: 10px;
            z-index: 2;
          }
          em.activity {
            background: $online;
            border: 0;
            border-radius: 4px;
            display: inline-block;
            left: auto;
            min-width: 30px;
            padding: 3px 6px 4px 3px;
            right: 4px;
            top: 4px;

            &:before {
              @extend %fontello;
              content: '\e82c';
              opacity: 0.8;
            }
          }
          &.new {
            em.notification {
              display: inline-block;
            }
          }
        }
      }
      &:hover {
        background: rgba(0, 0, 0, 0.1);
        .box {
          color: $white;
        }
      }
      &:active,
      &:focus {
        background: rgba(0, 0, 0, 0.3);
        .box {
          color: $white;
        }
      }
      &.selected {
        background: rgba(0, 0, 0, 0.2);
        .box {
          color: $white;
        }
      }
    }
  }
  @include min-screen($min-medium) {
    display: none;
  }
}

// disable order buttons on tender pages
.page-tenders {
  .navbar {
    .button-set {
      a.button {
        background-color: #bbb !important;
        border-color: rgba(0, 0, 0, 0.1) !important;
        color: $white;
        cursor: default;
        opacity: 0.5;
        pointer-events: none;
        -webkit-filter: grayscale(100%);
        -moz-filter: grayscale(100%);
        filter: grayscale(100%);
        -webkit-text-fill-color: $white;
      }
    }
  }
}

// language switcher
.language-switcher {
  display: none;
  margin-left: 15px;
  position: relative;
  top: 5px;
  vertical-align: top;

  @include min-screen($min-medium) {
    display: inline-block;
  }

  span.icon-globe {
    @extend %bold;
    @extend %nd;
    color: rgba(255, 255, 255, 0.6);
    display: inline-block;
    font-size: 0.9rem;
    margin: 0;
    text-transform: uppercase;
    vertical-align: middle;

    &:before {
      font-size: 1.1rem;
      margin: 0 8px 0 0;
    }
  }

  .current-language {
    @extend %bold;
    color: rgba(255, 255, 255, 0.6);
    display: inline-block;
    font-size: 0.8rem;
    padding-right: 18px;
    text-decoration: none !important;
    width: 48px;
  }

  span.arrow {
    border-left: 1px solid rgba(255, 255, 255, 0.6);
    display: inline-block;
    line-height: 0.9;
    margin-left: 5px;
    padding-left: 10px;
    position: relative;
    top: -1px;
    vertical-align: middle;

    &:after {
      @include arrow(3, 0);
      border-top-color: rgba(255, 255, 255, 0.6);
      -webkit-transform: scale(0.8, 0.8);
      top: 1px;
      transform: scale(0.8, 0.8);
    }
  }

  .nui-button.toggleme {
    @extend %nd;
    display: inline-block;
    padding: 0;
    position: relative;

    &:hover {
      span.icon-globe,
      .current-language,
      span.arrow {
        color: $white;
      }
    }
  }

  .change-lang {
    border-bottom: 1px solid $hr;
    display: block;
    font-size: 0.9rem;
    margin: 5px 10px;
    padding: 0 0 5px;
    position: relative;

    .closeme {
      @extend %nd;
      color: $light-gray;
      font-size: 1.75rem;
      position: absolute;
      right: 0;
      top: 0;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      &:hover {
        color: $all-black;
      }
    }
  }
  .dd-list {
    background: $white;
    border-radius: 3px;
    height: 0;
    margin: 42px 0 0 10px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    user-select: none;
    -webkit-user-select: none;
    width: 200px;

    ul li .nui-button,
    ul li.selected .nui-button {
      @extend %regular;
    }
    ul {
      li {
        display: block;
        padding-right: 40px;
        position: relative;

        &.selected {
          &:before {
            @extend %fontello;
            color: $ok !important;
            content: '\e802';
            font-size: 1rem;
            position: absolute;
            right: 7px;
            top: 4px;
          }
        }
        .nui-button {
          @extend %regular;
          font-size: 0.8rem;
          position: relative;
          text-decoration: none !important;

          span {
            @extend %bold;
            color: $all-black;
            display: inline-block;
            font-size: 0.8rem;
            text-transform: uppercase;
            width: 25px;
          }
        }
      }
    }
    &.open {
      @include min-screen($min-medium) {
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
        height: auto;
        overflow: visible;
        padding: 0;

        ul {
          display: block;
          position: relative;
          &:before {
            border-bottom: 7px solid $white;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            content: '';
            display: none;
            height: 0;
            left: 15px;
            position: absolute;
            top: -6px;
          }
          li {
            display: block;
            padding: 0;

            .nui-button {
              background: none !important;
              box-shadow: none !important;
              color: var(--link);
              display: block;
              padding: 2px 10px;
              text-decoration: none !important;

              span {
                text-decoration: none !important;
              }
              &:before {
                color: $light-gray;
              }
              &:hover {
                background: rgba(0, 0, 0, 0.025);
                color: $all-black;
                &:before {
                  color: $all-black;
                }
              }
            }
            &.selected {
              .nui-button,
              .nui-button:hover {
                background: none !important;
                color: $all-black;

                &:before {
                  color: $all-black;
                }
              }
            }
          }
        }
      }
    }
  }
}

// if language-switcher
.help-section.has-lang-switcher {
  .icon-cog {
    @include min-screen($min-medium) {
      padding-right: 5px;
      position: relative;
      right: auto;
      top: 4px;

      &:before {
        font-size: 1.2rem;
        margin: 0;
        position: relative;
        top: 3px;
      }
    }
    &:hover {
      color: $white;
    }
  }
}
