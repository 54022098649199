// nui upload files (multiple)
.nui-upload {
  background: $white;
  border: 1px solid $lightest-gray;
  border-radius: 3px;
  box-shadow: none;
  cursor: pointer;
  margin-bottom: 10px;
  margin-top: 8px;
  min-height: 65px;
  outline: none;
  padding: 20px;
  text-align: center;
  [class^='icon-'],
  [class*=' icon-'] {
    color: $light-gray;
    display: inline-block;
    font-size: 0.8rem;
    position: relative;
    top: -2px;
    transition: 0.1s all ease;

    &:before {
      color: rgba(0, 0, 0, 0.25);
      display: block;
      font-size: 2.25rem;
      margin: 10px auto 0;
      position: relative;
      top: 3px;
      transition: 0.2s all ease;
    }
  }
  &:hover {
    border-color: $mid-gray;
    [class^='icon-'],
    [class*=' icon-'] {
      color: $all-black;
      font-size: 0.85rem;

      &:before {
        color: var(--link);
        margin-bottom: 5px;
        margin-top: 5px;
        transform: scale(1.3, 1.3);
        -webkit-transform: scale(1.3, 1.3);
      }
    }
  }
}

.file-list {
  list-style: none;
  margin: -10px 0 0;
  padding: 0;

  li {
    border-bottom: 1px solid $hr;
    padding: 10px 0 10px 25px;
    position: relative;

    .progress {
      background-color: $ok;
      bottom: -1px;
      height: 3px;
      float: none;
      left: 0;
      margin: 0;
      max-width: 100%;
      position: absolute;
      transition: 0.4s all linear;
    }
    .progress[style='width: 100%;'] {
      height: 0;
    }
    span.icon-file-pdf {
      color: #ec4004;
      font-size: 1.1rem;
      left: 0;
      position: absolute;
      top: 9px;

      &:before {
        margin: 0;
      }
    }
    button.nui-button-link {
      max-width: calc(100% - 46px);
    }
    button.nui-button-icon {
      position: absolute;
      right: 0;
      top: 6px;
    }
  }
}

// integrated file list
.file-section {
  .file-list {
    background: $white;
    border-radius: 0 0 3px 3px;
    border: 1px solid $lightest-gray;
    border-top: 0;
    margin-top: -15px;
    padding: 5px 10px;
    position: relative;
    z-index: 10;

    li {
      font-size: 0.8rem;
      padding: 6px 0 6px 20px;

      span.icon-file-pdf {
        font-size: 0.9rem;
        top: 6px;
      }
      .nui-button-icon {
        right: -4px;
        top: 1px;
        transform: scale(0.8);
        -webkit-transform: scale(0.8);
      }
    }
    li:first-child {
      border-top: 1px dashed $lightest-gray;
    }
    li:last-child {
      border-bottom: 0;
    }
  }
  &:hover {
    .nui-upload,
    .file-list {
      border-color: $mid-gray;
    }
  }
}

// new upload module in order form
.upload-attachments {
  .nui-fieldset {
    label {
      display: block;
      padding-right: 60px;
      position: relative;
      width: 100%;

      &:after {
        color: $ok;
        content: 'Optional';
        display: inline-block;
        font-size: 0.7rem;
        margin: 0;
        position: absolute;
        right: 0;
        text-transform: uppercase;
        top: 5px;
      }
    }

    .input-holder {
      min-height: 100px;
      width: 100%;

      input[type='file'] {
        cursor: pointer;
        min-height: 100px;
      }
      input[type='file'].inputfile + .dummy {
        border: 1px solid $lightest-gray;
        color: $light-gray;
        font-size: 0.8rem;
        min-height: 100px;
        text-align: center;
        z-index: 11;

        &:before {
          color: rgba(0, 0, 0, 0.25);
          display: block;
          font-size: 2.25rem;
          margin: 10px auto 0;
          position: relative;
          top: 3px;
          transition: 0.2s all ease;
        }
      }

      &:hover {
        input[type='file'].inputfile + .dummy {
          color: $all-black;
          font-size: 0.85rem;
        }
        input[type='file'].inputfile + .dummy:before {
          color: var(--link);
          margin-bottom: 5px;
          margin-top: 10px;
          transform: scale(1.3, 1.3);
          -webkit-transform: scale(1.3, 1.3);
        }
      }
    }

    // attachments list
    .document-list-box {
      border: 0;
      display: block;
      margin: -5px 0 0;
      padding: 0;
      position: relative;
      width: 100%;
      z-index: 13;
    }

    ul.file-list {
      margin: -6px 0 0;
      padding: 5px 10px 0;

      li,
      li:first-child {
        min-height: 34px;
        padding-left: 6px;
        padding-top: 6px;
      }
    }
  }
}

.nui-fieldset {
  &:hover {
    // file fields
    .nui-files-wrapper {
      .inputfile + .dummy {
        border-color: $mid-gray !important;
      }
      ul.file-list {
        border-color: $mid-gray;
      }
    }
  }
}

// in tender modal
.wizard-content {
  .upload-attachments {
    label {
      @include min-screen($min-large) {
        display: inline-block;
        padding-right: 0;
        vertical-align: top;
        width: 29.16666667%;

        &:after {
          display: inline-block;
          margin-left: 5px;
          position: relative;
          top: 0;
        }
      }
    }
    .nui-files-wrapper {
      max-width: 100%;
      width: 100%;

      @include min-screen($min-large) {
        display: inline-block;
        max-width: 480px;
        vertical-align: top;
        width: 58.33333333%;
      }
      .input-holder {
        display: block;
        float: none;
        width: 100%;
      }
    }
  }
}

.nui-modal {
  .file-list {
    border: 0;
    margin: 0;

    li,
    li:first-child {
      border: 0;
      padding: 2px 0;

      span.file-link {
        display: block;
        font-size: inherit;
        padding-left: 25px;
        position: relative;

        button[disabled] {
          background: none !important;
          color: $light-gray;
          cursor: not-allowed;
        }
        &:before {
          font-size: 1rem;
          left: 0;
          margin: 0;
          position: absolute;
          top: 4px;
        }
      }
      span.icon-file-pdf {
        top: auto;
        &:before {
          color: #e12025;
        }
      }
      span.icon-file-excel {
        &:before {
          color: #4ea216;
        }
      }
      span.icon-file-image {
        &:before {
          color: #666;
        }
      }
      span.icon-doc,
      span.icon-doc-text {
        &:before {
          color: #888;
        }
      }
      span.icon-file-word {
        &:before {
          color: #2b579a;
        }
      }
    }
  }
}
