// alternative marketplace view/marketdepth
.main-content {
  background: linear-gradient(
    to top,
    white 0%,
    white 50%,
    #f9f9f9 50%,
    #f9f9f9 100%
  );
}
.h1-tabs {
  h1 {
    border-bottom: 1px solid $lightest-gray;
    margin: 0 0 10px;
  }

  // updated 2021
  h1 span {
    color: $mid-gray;
    display: inline-block;
    font-size: 1.5rem;
    margin: 0 10px;
    padding: 0 0 10px;
    position: relative;

    &:first-child {
      margin-left: 0;
    }

    &:first-child {
      &:after {
        border-right: 1px solid $lightest-gray;
        content: ' ';
        display: block;
        height: 26px;
        position: absolute;
        right: -11px;
        top: 7px;
        width: 1px;

        @include min-screen($min-medium) {
          top: 9px;
        }
      }
    }
    &:only-child {
      &:after {
        display: none;
      }
    }

    a {
      color: var(--link);
      @extend %nd;
      &:hover {
        color: $dark-gray;
      }
    }
    &:before {
      background: transparent;
      border: 0;
      bottom: 0;
      content: ' ';
      display: block;
      height: 0;
      left: 0;
      margin-left: 0;
      position: absolute;
      right: auto;
      top: auto;
      transform: none;
      -webkit-transform: none;
      transition: height 0.2s ease;
      width: 100%;
      z-index: 0;
    }
    &:hover {
      &:before {
        background: $lightest-gray;
        height: 4px;
      }
      span:not(.notification) {
        color: $all-black;
      }
    }
    &:active {
      &:before {
        height: 6px;
      }
    }
    &.active,
    &.active:hover {
      &:before {
        background: var(--link);
        height: 4px;
      }
      span:not(.notification) {
        color: $mid-gray;
      }
      &:hover {
        span {
          color: $all-black;
        }
      }
    }

    &.active:active {
      &:before {
        height: 6px;
      }
    }
    &.active {
      a {
        color: $mid-gray;
        &:hover {
          color: $dark-gray;
        }
      }
    }
  }
  span:only-child {
    &:before {
      display: none;
    }
  }
}

.h1-tabs + .marketplace {
  @include max-screen($max-small) {
    margin-top: 0;
  }
}

.market {
  .currency-exchange {
    position: relative;
    top: -6px;
  }
  .full-page-content {
    .generic-table {
      &.md-table {
        .nui-table-content {
          .nui-table-body table {
            tr.nui-table-row.selected {
              td {
                box-shadow: 0 -5px 0 rgba(0, 0, 0, 0.035) inset;

                &.shaded-right {
                  z-index: 4px !important;
                }
              }
              &:hover {
                td.shaded-right {
                  z-index: 4px !important;
                }
              }
            }
            thead.nui-table-thead {
              tr:first-child {
                th {
                  background: $white;
                }
              }
              tr:last-child {
                th {
                  background: #f1f1f1;
                }
              }
            }
          }
        }
      }
    }
  }
}

// md table rows td bg colour
.market {
  .full-page-content .generic-table table.table.light,
  .full-page-content table,
  .full-page-content .generic-table .nui-table-content .nui-table-body table {
    tbody {
      tr,
      tr:hover {
        &:nth-child(odd),
        &:nth-child(even) {
          td {
            background: #fafbfe;
          }
        }
      }

      tr.nui-table-expanded-row,
      tr.nui-table-expanded-row:hover {
        &:nth-child(odd),
        &:nth-child(even) {
          td {
            background: #eef1f8;
          }
        }
      }
    }
  }
}

// table contents
.market {
  .nui-table-thead {
    th.col-brand {
      text-align: center;

      span.icon-picture {
        display: inline-block;
        &:before {
          color: $light-gray;
          font-size: 1.2rem;
          margin: 0;
          position: relative;
          top: 4px;
        }
      }
    }
  }
  .full-page-content .generic-table .nui-table-content .nui-table-body {
    .nui-table-expanded-row {
      td.first-child {
        padding: 0 !important;
        position: relative;
      }
    }
    .col-details {
      padding: 10px;

      .nui-table-row-expand-icon {
        display: inline-block;
        height: 25px;
        line-height: 25px;
        margin: 0;
        padding: 0 5px 0 20px;
        right: 6px;
        width: auto;

        &:after {
          color: var(--link);
          content: 'Details';
          display: inline-block !important;
          font-size: 0.8rem;
        }
        &:before {
          left: 5px;
          margin-top: 0;
          right: auto;
        }
      }
    }
    .col-brand {
      max-width: 90px;
      min-width: 90px;
      text-align: center;
      width: 90px;

      .td-content {
        text-align: center;
      }
      .td-content > img {
        cursor: zoom-in;
        max-height: 50px;
        max-width: 70px;
        mix-blend-mode: multiply;
      }
    }
    .col-product {
      @include min-screen($min-medium) {
        max-width: none;
        min-width: 1px;
        white-space: nowrap;
        width: auto;
      }
      @include min-screen($min-large) {
        width: 440px;
      }
      .col-product-name {
        margin-right: 10px;

        @include min-screen($min-medium) {
          display: inline-block;
          margin-right: 10px;
          vertical-align: top;
          white-space: normal;
          width: 200px;
        }
        @include min-screen($min-xlarge) {
          width: 300px;
        }
        h2 {
          @extend %regular;
          font-size: 0.9rem;
          margin: 0;
          padding: 0;
          white-space: normal;
        }
        p {
          @extend %bold;
          color: $all-black;
          font-size: 0.7rem;
          margin: 0;
          line-height: 0.9rem;
          padding: 2px 0 0;
        }
      }
      .col-product-price,
      .col-product-volume {
        display: inline-block;
        margin-right: 10px;
        padding-bottom: 10px;
        vertical-align: top;
        width: calc(50% - 10px);

        .product-price,
        .product-volume {
          @extend %bold;
          color: $all-black;
          display: inline-block;
          font-size: 1.3rem;
          margin-right: 5px;
          vertical-align: top;
        }
        .product-unit {
          @extend %regular;
          color: $light-gray;
          display: block;
          font-size: 0.7rem;
          line-height: 0.9rem;
          padding-top: 5px;
          white-space: normal;
        }
        @include min-screen($min-medium) {
          margin-right: 10px;
          width: 85px;
        }
      }
      .col-product-price {
        .product-unit {
          min-width: 65px;
        }
      }
      .col-product-type {
        @include min-screen($min-medium) {
          display: inline-block;
          vertical-align: top;
        }
      }
    }

    td.col-product {
      .col-product-type {
        span {
          display: inline-block;
          font-size: 0.6rem;
          padding: 0;
          width: 75px;
        }
      }
    }

    .col-loading {
      min-width: 200px;
    }

    th.col-product {
      .col-product-name,
      .col-product-price,
      .col-product-volume {
        padding-bottom: 0;
      }
      .col-product-price,
      .col-product-volume {
        @include max-screen($max-small) {
          margin-right: 5px;
          width: auto;
        }
      }
    }

    span.tiny-label {
      @extend %regular;
      color: $light-gray;
      display: block;
      font-size: 0.7rem;
      margin-top: -4px;
      white-space: nowrap;
    }

    a.sortem {
      @extend %nd;
      color: $all-black;
      display: block;
      height: 100%;
      padding-right: 35px;
      width: 100%;

      &:before {
        color: rgba(0, 0, 0, 0.25);
        font-size: 1rem;
        position: absolute;
        right: 0;
        text-align: left;
        top: 15px;
        width: 15px;
        z-index: 2;
      }
      &.up,
      &.down {
        &:after {
          @extend %fontello;
          color: $mid-gray;
          content: '\f0de';
          font-size: 1rem;
          position: absolute;
          right: 0;
          text-align: left;
          top: 15px;
          width: 15px;
          z-index: 3;
        }
      }
      &.down {
        &:after {
          content: '\f0dd';
        }
      }
    }

    tr + tr {
      th {
        a.sortem {
          &:before {
            top: 8px;
          }
          &.up,
          &.down {
            &:after {
              top: 8px;
            }
          }
        }
      }
    }

    // expanded section
    .offers-bids-details {
      left: 40px;
      width: calc(100vw - 100px);
      position: sticky;
      position: -webkit-sticky;
      z-index: 10;

      @include min-screen($min-medium) {
        margin-top: -10px;
        max-width: calc(100vw - 60px);
      }
      .table-box-three {
        padding-left: 0;
        .nui-tabs .nui-tabs-nav-wrap {
          margin-bottom: 10px;
        }
      }

      .table-box-three {
        .unit-details {
          margin-top: 0;

          h2.details {
            border-bottom: 1px solid $lightest-gray;
            margin-bottom: 5px;
            padding-bottom: 15px;
            @include max-screen($max-small) {
              padding-top: 0;
            }
          }
        }

        ul.loading-details {
          li.stacked {
            strong,
            span {
              display: block;
              width: auto;
            }
          }
          li.auto {
            strong {
              min-width: 32%;
              width: auto;
            }
            span {
              width: auto;
            }
          }
          li.reversed {
            strong {
              white-space: nowrap;
              width: auto;
            }
            span {
              float: right;
              text-align: right;
              width: auto;
              word-break: break-word;
            }
          }
        }

        ul.personal-details li.reversed {
          span {
            max-width: 80%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }

      @include max-screen($max-small) {
        .table-box-three {
          max-width: calc(100vw - 45px);
          .box {
            max-width: 100%;
          }
        }
      }

      .table-box-three {
        &:first-child {
          @include min-screen($min-medium) {
            margin-right: 20px;
            width: calc(48.5% - 20px);
          }
          @include min-screen($min-large) {
            margin-left: 20px;
            width: calc(45% - 40px);
          }
          @include min-screen($min-xlarge) {
            margin-right: 0;
            width: calc(45% - 20px);
          }
        }
        &:last-child {
          .box:last-child {
            @include min-screen($min-large) {
              padding-right: 0;
            }
          }
        }
      }
    }
  }
}

// alt, sticky product name
html .market .full-page-content {
  .md-table {
    tbody,
    thead {
      tr {
        position: relative;

        .col-product-name-alt {
          left: 0;
          min-width: 240px;
          max-width: 300px;
          position: sticky !important;
          position: -webkit-sticky !important;
          z-index: 4 !important;

          &.shaded-right {
            z-index: 4px !important;
          }
          @include max-screen($max-small) {
            box-shadow: none !important;
            left: auto !important;
            position: relative;
          }
          ul.legend-list {
            bottom: 7px;
            margin: 0;
            position: absolute;
            right: 7px;
            -webkit-transform: scale(0.9);
            transform: scale(0.9);

            li {
              margin: 0 0 0 5px;
              a {
                cursor: pointer;
              }
            }
          }
          h2 {
            @extend %regular;
            color: $mid-gray;
            font-size: 0.9rem;
            line-height: 1.2rem;
            margin: 0 0 4px;
            padding: 0;
            white-space: normal;
          }
          p {
            @extend %bold;
            color: $all-black;
            font-size: 0.7rem;
            line-height: 0.9rem;
            margin: 2px 0 0;
            padding: 0;
          }

          @-webkit-keyframes blinky {
            0% {
              opacity: 1;
            }
            50% {
              opacity: 0;
            }
          }

          @keyframes blinky {
            0% {
              opacity: 1;
            }
            50% {
              opacity: 0;
            }
          }

          p.expiration {
            @extend %regular;
            color: darken($light-gray, 10%);
            margin: 6px 0 0 !important;

            &:before {
              margin: 0 3px 0 0;
            }
            &.closing-day {
              color: $warning;
            }
            &.closing-hour {
              color: $error;
            }
            &.closing-now {
              color: $error;
              &:before {
                -webkit-animation: blinky 0.75s ease infinite;
                animation: blinky 0.75s ease infinite;
              }
            }
          }
        }
        .col-product-price-alt,
        .col-product-volume-alt {
          .product-price,
          .product-volume {
            @extend %bold;
            color: $all-black;
            font-size: 1.3rem;
          }

          .product-unit {
            @extend %regular;
            color: $light-gray;
            display: block;
            font-size: 0.7rem;
            line-height: 0.9rem;
            padding-top: 5px;
            white-space: normal;
          }
        }
        .col-product-price-alt {
          .product-unit {
            min-width: 65px;
          }
        }
        .col-product-type-alt {
          width: 100px;

          span.type-bid,
          span.type-offer {
            background: $bid;
            border: 1px solid $bid;
            border-radius: 100px;
            color: $white;
            display: inline-block;
            font-size: 0.6rem;
            line-height: 1.2rem;
            padding: 0;
            position: relative;
            text-align: center;
            text-transform: uppercase;
            white-space: nowrap;
            width: 75px;

            &:before {
              left: auto;
              right: -24px;
            }

            span.broker {
              background: #f4f6fd;
              border-radius: 50%;
              content: ' ';
              cursor: help;
              display: block;
              height: 16px;
              position: absolute;
              right: -8px;
              top: -6px;
              transition: 0.2s all linear;
              width: 16px;
              z-index: 3;

              &:before {
                background: $white;
                border-radius: 50%;
                border: 1px solid #0d8abc;
                content: ' ';
                cursor: help;
                display: block;
                height: 12px;
                position: absolute;
                right: 2px;
                top: 2px;
                transition: 0.2s all linear;
                width: 12px;
                z-index: 2;
              }
              &:after {
                background: #0d8abc;
                border-radius: 50%;
                content: ' ';
                cursor: help;
                display: block;
                height: 6px;
                position: absolute;
                right: 5px;
                top: 5px;
                transition: 0.2s all linear;
                width: 6px;
                z-index: 3;
              }
            }

            &:hover {
              span.broker {
                transform: scale(1.3);
                -webkit-transform: scale(1.3);
              }
            }
          }
          span.type-bid {
            background: $bid;
            border: 1px solid $bid;

            &.counteronly {
              background: lighten($bid, 52%);
              color: $bid;
            }
          }
          span.type-offer {
            background: $offer;
            border: 1px solid $offer;

            &.counteronly {
              background: lighten($offer, 48%);
              color: $offer;
            }
          }

          span.type-bid,
          span.type-offer {
            &.disabled {
              background: $light-gray;
              border-color: $light-gray;
              cursor: not-allowed;

              &:before {
                background: $light-gray;
              }

              .private-icon {
                background-color: inherit;
              }
            }
          }
        }
      }
    }
    tbody {
      tr {
        td.col-product-price-alt,
        td.col-product-volume-alt,
        td.col-product-type-alt {
          background: #f4f6fd !important;
        }
      }
    }
  }
}

span.qrts {
  @extend %regular;
  color: $light-gray;
  display: inline-block;
  font-size: 0.7rem;
  left: 2px;
  line-height: 1;
  margin: 0 0 0 2px;
  padding: 0 5px;
  position: relative;
  top: -3px;

  &.border {
    border-top: 1px solid $lightest-gray;
    padding-top: 6px;
    top: -6px !important;
  }
}

// show prices in drawer
.market {
  .full-page-content {
    .ant-table-content,
    .nui-table-content {
      .ant-table-body,
      .nui-table-body {
        .table-wrapper {
          overflow: hidden;
        }
        table {
          .ant-drawer-left.ant-drawer-open,
          .ant-drawer-right.ant-drawer-open {
            &.price-drawer {
              height: 100%;
              left: -50px;
              max-width: 100vw;
              top: 0;
              width: 100vw;

              .ant-drawer-wrapper-body {
                @include max-screen($max-small) {
                  overflow: auto;
                }
              }

              @include min-screen($min-medium) {
                left: 0;
              }

              .ant-drawer-content-wrapper {
                max-width: 100%;
                position: relative;
                width: 100% !important;

                @include min-screen($min-medium) {
                  max-width: 510px;
                }

                // customer price table
                table.table.generic-table.customer-prices {
                  thead,
                  tbody {
                    tr,
                    tr:hover {
                      td,
                      td:first-child {
                        background: #f8f6f4;
                        border: 0;
                        box-shadow: none;
                        padding: 5px 10px !important;
                      }
                    }
                    tr,
                    tr:hover {
                      th {
                        @extend %bold;
                        border: 0;
                        border-bottom: 1px solid $hr;
                        border-top: 1px solid $hr;
                        color: $all-black;
                        padding: 10px;
                      }
                    }
                  }
                }
              }
              .unit-detail {
                background: $white;
                border-bottom: 1px solid $hr;
                margin-bottom: 10px;
                padding: 10px;
                position: sticky;
                position: -webkit-sticky;
                top: 0;
                width: 100%;
                z-index: 3;
              }
            }
          }
        }
      }
    }
  }
}

// show prices in nui-modal
.nui-modal-content {
  .price-drawer {
    left: 0;

    .ant-drawer-content {
      border-radius: 3px;
      @include min-screen($min-medium) {
        border-radius: 3px 0 0 3px;
      }
    }
    .ant-drawer-content-wrapper {
      border-radius: 3px;
      max-width: calc(100vw - 80px);
      width: 510px !important;
    }
    .ant-drawer-mask {
      opacity: 0;
    }

    // customer price table
    table.customer-prices {
      border-top: 1px solid $hr;
      margin: 0;
      thead,
      tbody {
        tr,
        tr:hover {
          td,
          td:first-child {
            background: #f8f6f4;
            border: 0;
            box-shadow: none;
            padding: 5px 10px !important;
          }
        }
        tr,
        tr:hover {
          th {
            @extend %bold;
            border: 0;
            border-bottom: 1px solid $hr;
            border-top: 1px solid $hr;
            color: $all-black;
            padding: 10px;
          }
        }
      }
    }
  }
}

// warehouse dropdown(s)
.market {
  .full-page-content {
    .generic-table {
      &.md-table {
        margin-top: 12px;
      }
    }
  }
}

.h1-tabs {
  margin: -10px 0 0;
  position: relative;
  transition: none;
  z-index: 10000;

  @include min-screen($min-medium) {
    margin: -10px 0 -15px;
    position: relative;

    &:after {
      border-bottom: 1px solid $lightest-gray;
      bottom: 9.5px;
      content: ' ';
      display: block;
      height: 0;
      left: 40px;
      position: absolute;
      visibility: visible;
      width: calc(100% - 80px);
    }
    h1 {
      border-bottom: 0;
      display: inline-block;
    }
  }
}

#content {
  .dds {
    display: inline-block;
    margin-top: 1px;
    vertical-align: top;

    .showing {
      @extend %bold;
      color: $all-black;
      font-size: 0.7rem;
      left: -145px;
      margin: 0;
      position: relative;
      transition: none;
      display: none; // hiding it for now
    }
    @include min-screen($min-medium) {
      .showing {
        margin: -10px 0 0 10px;
        min-width: 170px;
      }
    }
  }
  a.show-dd-items {
    font-size: 0.8rem;
    margin: 1px 10px -16px 0;
    padding: 5px 30px 5px 10px;
    width: 120px;
    white-space: nowrap;

    @include min-screen($min-medium) {
      display: inline-block;
      margin: 1px 0 0 15px;
    }
  }
}

#content .h1-tabs {
  .dds {
    a.show-dd-items {
      @include min-screen($min-medium) {
        margin-top: 7px;
      }
    }
  }
}

ul.ant-dropdown-menu {
  li.ant-dropdown-menu-item {
    color: var(--link);

    &:hover {
      background: #f6f6f6;
    }
  }
}

// offer and bid types
.market {
  .type-bid,
  .type-offer {
    position: relative;

    &.my {
      &:after {
        background: $own;
        border-radius: 50%;
        border: 2px solid $white;
        content: ' ';
        display: block;
        height: 10px;
        position: absolute;
        right: -5px;
        top: -3px;
        transition: 0.2s all linear;
        width: 10px;
        z-index: 1;
      }
    }
    &:hover {
      &.my {
        &:after {
          transform: scale(1.5, 1.5);
          -webkit-transform: scale(1.5, 1.5);
        }
      }
    }
    &.private,
    &.private-group {
      margin-right: 20px;

      .private-icon {
        background: $bid;
        border-radius: 50%;
        color: $white;
        font-size: 0.6rem;
        height: 20px;
        line-height: 20px;
        padding: 0;
        position: absolute;
        right: -25px;
        text-align: center;
        top: 0;
        transition: 0.2s all ease;
        width: 20px;
      }
    }
  }

  .type-offer {
    &.private {
      .private-icon {
        background: $offer;
      }
    }
  }
}

// legenda
.market .legenda {
  @extend %regular;

  @include min-screen($min-medium) {
    float: right;
    top: -1px;
  }
  .exp {
    color: $mid-gray;
    display: inline-block;
    font-size: 0.65rem;
  }
  .legenda-item {
    display: inline-block;
    margin-right: 10px;

    @include min-screen($min-medium) {
      margin-left: 10px;
    }
    .my {
      background: $own;
      border-radius: 50%;
      content: ' ';
      display: inline-block;
      height: 6px;
      margin: 0 5px 0 0;
      vertical-align: middle;
      width: 6px;
    }
    .private {
      @extend %bold;
      background: #e6e6e6;
      border-radius: 50%;
      color: $dark-gray;
      display: inline-block;
      font-size: 0.6rem;
      height: 20px;
      line-height: 20px;
      margin: 0 5px 0 0;
      padding: 0;
      text-align: center;
      text-transform: uppercase;
      vertical-align: middle;
      width: 20px;
    }
  }
}

// open offer/bid
html .market,
html .nui-modal {
  td {
    span.openordertype {
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 100px;
      color: $mid-gray;
      display: inline-block;
      font-size: 0.6rem;
      line-height: 1.2rem;
      padding: 0 12px;
      text-transform: uppercase;
      white-space: nowrap;
    }
  }
}

// market icons
.market-icons {
  float: right;
}
// uploaded documents icon
html .is-bundle {
  @extend %nd;
  color: #ba4c6e;
  display: inline-block;
  font-size: 1.2rem;
  height: 19px;
  line-height: 19px;
  margin: 0 0 0 5px;
  text-align: center;
  vertical-align: middle;
  width: 19px;
  z-index: 2;

  &:active,
  &:focus,
  &:hover {
    color: #ba4c6e;
  }
  &:before {
    margin: 0;
  }
}

html .has-comment {
  @extend %nd;
  background: #efa02f;
  border-radius: 50%;
  color: $white;
  display: inline-block;
  font-size: 0.7rem;
  height: 19px;
  line-height: 18px;
  margin: 0 0 0 5px;
  text-align: center;
  vertical-align: middle;
  width: 19px;
  z-index: 2;

  &:active,
  &:focus,
  &:hover {
    color: $white;
  }
  &:before {
    margin: 0;
  }
}

html .has-counters {
  @extend %nd;
  background: var(--link);
  border-radius: 50%;
  color: $white;
  display: inline-block;
  font-size: 0.7rem;
  height: 19px;
  line-height: 19px;
  margin: 0 0 0 5px;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 19px;
  z-index: 2;

  &:active,
  &:focus,
  &:hover {
    color: $white;
  }
  &:before {
    margin: 0;
  }
  span.my-counter {
    background: #c68508;
    border: 2px solid $white;
    border-radius: 50%;
    content: ' ';
    display: block;
    height: 10px;
    position: absolute;
    right: -4px;
    top: -4px;
    width: 10px;
    z-index: 2;
  }
  span.broker {
    background: #0d8abc;
    border: 2px solid $white;
    border-radius: 50%;
    content: ' ';
    display: block;
    height: 10px;
    position: absolute;
    right: -4px;
    top: -4px;
    width: 10px;
    z-index: 2;
  }
}

html .has-qa {
  @extend %nd;
  background: #586195;
  border-radius: 50%;
  color: $white;
  display: inline-block;
  font-size: 0.45rem;
  height: 19px;
  line-height: 19px;
  margin: 0 0 0 5px;
  text-align: center;
  vertical-align: middle;
  width: 19px;
  z-index: 2;

  &:active,
  &:focus,
  &:hover {
    color: $white;
  }
  &:before {
    margin: 0;
  }
}

// counter info
.col-product-price-alt {
  white-space: nowrap;
  .td-content {
    position: relative;
  }
}

.best-counter {
  @extend %nd;
  @extend %bold;
  background: $white;
  border: 1px solid #c3c8d4;
  border-radius: 3px;
  color: var(--link);
  display: inline-block;
  font-size: 0.6rem;
  height: 18.5px;
  line-height: 0.8rem;
  min-width: 40px;
  padding: 4px 8px 3px 8px;
  position: relative;
  right: auto;
  text-align: center;
  top: -4px;

  &:active,
  &:focus,
  &:hover {
    color: var(--link);
  }
  &.my {
    color: #c68508;
    &:active,
    &:focus,
    &:hover {
      color: #c68508;
    }
  }
}

.best-counter + .product-unit {
  margin-top: -2px;
}
.tag-index {
  @extend %nd;
  @extend %bold;
  background: $white;
  border: 1px solid var(--offer);
  border-radius: 3px;
  display: inline-block;
  font-size: 0.6rem;
  height: 18.5px;
  line-height: 0.8rem;
  min-width: 40px;
  padding: 4px 8px;
  position: relative;
  right: auto;
  text-align: center;
  text-transform: uppercase;
  top: 0;
  vertical-align: top;
}

.flex-tags {
  align-items: flex-start;
  display: flex;
  gap: 5px !important;

  .product-price {
    flex-grow: 2;
  }
  .best-counter {
    top: auto;
  }
}

.counter-info-box {
  background: rgba(0, 0, 0, 0.025);
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.07);
  margin: 10px 0;
  padding: 10px;

  .half {
    display: inline-block;
    vertical-align: top;
    width: 50%;
  }
  h3 {
    font-size: 1rem;
  }
  h4 {
    @extend %regular;
    color: var(--highlight);
    font-size: 0.8rem;
    margin: 0;
  }
  strong.etd,
  span.price {
    @extend %bold;
    color: $all-black;
    display: block;
    font-size: 1rem;
  }
  span {
    @extend %regular;
    color: $mid-gray;
    display: inline-block;
    font-size: 0.7rem;
    position: relative;

    &.price-unit {
      display: block;
      margin-top: -2px;
    }
  }
  strong.etd,
  span.price {
    @extend %bold;
    color: $all-black;
    display: block;
    font-size: 1.05rem;
  }
}

// counters
html,
.offers-bids-details .table-box-three {
  .list-counter,
  .list-my-counter {
    background: rgba(255, 255, 255, 0.3);
    border: 1px solid $hr;
    border-radius: 3px;
    margin: 10px 0;
    padding: 10px;
    position: relative;

    .nui-form {
      margin-top: 10px;

      .ant-form-item {
        padding: 0;
        .ant-form-item-label label {
          @extend %bold;
          color: $all-black;
          font-size: 0.8rem;
        }
      }
    }

    .nui-alert {
      font-size: 0.8rem;
      h4 {
        font-size: 0.9rem;
        margin: 0;
      }
    }

    textarea#reason {
      height: auto;
      min-height: 0;
      overflow: hidden !important;
      transition: height 0.8s ease !important;
    }

    .my-counter-tag {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      display: none;
      margin: -10px 0 10px;

      span {
        @extend %bold;
        color: $light-gray;
        display: inline-block;
        font-size: 0.55rem;
        text-transform: uppercase;

        &:before {
          background: $own;
          border-radius: 50%;
          content: ' ';
          display: inline-block;
          height: 7px;
          margin: 0 5px 0 0;
          width: 7px;
        }
      }
    }
    .withdrawn,
    .traded,
    .oversubscribed,
    .declined {
      @extend %bold;
      color: $warning;
      font-size: 0.55rem;
      position: absolute;
      right: 9px;
      text-transform: uppercase;
      top: 3px;
    }
    .traded {
      color: $ok;
    }
    .oversubscribed,
    .declined {
      color: $error;
    }
    .half {
      display: inline-block;
      vertical-align: top;
      width: 50%;
    }
    span.price {
      @extend %bold;
      color: $all-black;
      display: inline-block;
      font-size: 1.15rem;
      margin-right: 5px;
    }
    span.price-unit {
      @extend %regular;
      color: $light-gray;
      display: inline-block;
      font-size: 0.7rem;
      white-space: nowrap;
    }
    .division-name,
    .broker-name,
    .location,
    .callofftime,
    .reference {
      font-size: 0.8rem;
      line-height: 1.4rem;

      span {
        color: $light-gray;
        display: inline-block;
        line-height: 1.2rem;
        vertical-align: top;
        width: calc(100% - 100px);
      }

      strong {
        color: $all-black;
        display: inline-block;
        line-height: 1.2rem;
        vertical-align: top;
        width: 90px;
      }
    }
    .date-submitted {
      color: $light-gray;
      font-size: 0.7rem;
      line-height: 1.2rem;
      margin-top: -2px;
      margin-bottom: 5px;
    }
  }
  .list-my-counter {
    .my-counter-tag {
      display: inline-block;
      white-space: nowrap;
    }
  }
}

.mkt-row-counters {
  .counter-list,
  .data-list {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
  }

  .counter-form {
    border-bottom: 1px solid $hr;
    margin: 0 0 10px;
    padding: 0 0 10px;
  }

  .counter-list {
    margin: 0;
    padding: 0;
  }

  .nui-alert {
    font-size: 0.8rem;

    h4 {
      font-size: 0.9rem;
      margin: 0;
    }

    &.error-oversubscribed {
      margin-bottom: 0 !important;
    }
  }

  .data-list {
    .data-item {
      & > strong,
      & > span {
        display: inline-block;
        font-size: 0.8rem;
        line-height: 1.2rem;
        vertical-align: top;
      }
      & > strong {
        color: $all-black;
        width: 200px;
      }
      & > span {
        color: $light-gray;
        width: calc(100% - 200px);
      }
    }
  }

  .unchanged-list-items {
    .data-item:not(.changed-list-item) {
      & > * {
        color: $light-gray;
        display: inline-block;
        font-size: 0.8rem;
        line-height: 1.2rem;
        vertical-align: top;
      }
    }
    .data-item.changed-list-item > span,
    .data-item.changed-list-item > strong {
      color: $error;
    }
  }

  .counter-item {
    border-bottom: 1px solid $hr;
    margin: 0 0 10px;
    padding: 0 0 10px;
    position: relative;

    .attachments {
      padding: 5px 0;

      .file-list {
        li {
          padding: 5px 0;
          &.title {
            padding: 0;
          }
        }
      }
    }

    .expiry,
    .updateddt {
      color: $all-black;
      font-size: 0.7rem;
      line-height: 1.2rem;
      margin-top: -2px;
      margin-bottom: 5px;
    }

    .expiry {
      color: $warning;
    }

    .counter-status {
      @extend %bold;
      color: $warning;
      font-size: 0.55rem;
      position: absolute;
      right: 9px;
      text-transform: uppercase;

      &.traded {
        color: $ok;
      }
      &.untradeable,
      &.cancelled,
      &.oversubscribed,
      &.declined {
        color: $error;
      }
    }

    .status {
      display: inline-block;
      margin: 0 0 10px;
      position: relative;
      white-space: nowrap;
      width: 100%;

      .tag {
        @extend %bold;
        border-bottom: 1px solid $light-gray;
        color: $all-black;
        display: inline-block;
        font-size: 0.55rem;
        margin-right: 10px;
        text-transform: uppercase;

        &:before {
          border-radius: 50%;
          content: ' ';
          display: inline-block;
          height: 7px;
          margin: 0 5px 0 0;
          width: 7px;
        }
        &.broker-counter:before {
          background: #0d8abc;
        }
        &.my-counter:before {
          background: $own;
        }
      }
    }

    .unit-detail {
      display: inline-block;
      font-size: 1.15rem;
      margin: 0;
      padding: 0;
      width: 50%;

      .unit {
        font-size: 0.7rem;
      }
      .index {
        font-size: 0.7rem;
        & > * {
          display: inline-block;
        }
      }
    }

    .button-set {
      button {
        margin: 10px 10px 0 0;
      }
    }
  }
}

// counter box in expanded section
.offers-bids-details .table-box .counters,
.offers-bids-details .table-box-three .counters {
  h2 {
    border-bottom: 0;
    padding-bottom: 5px;
  }
  .button-set .nui-button {
    width: auto;
  }
}

// counters 2021
html,
.offers-bids-details .table-box-three {
  .respond-alert {
    background: rgba(255, 255, 255, 0.3);
    border: 1px solid $hr;
    border-radius: 3px;
    margin: 10px 0;
    padding: 10px;
    position: relative;

    label {
      @extend %bold;
      color: $all-black;
      font-size: 0.8rem;
    }
    p {
      color: $light-gray;
      font-size: 0.8rem;
      white-space: normal;
    }
  }
  .counter-list-total {
    .list-counter,
    .list-my-counter {
      background: none;
      border: 0;
      border-bottom: 1px solid $hr;
      border-radius: 0;
      margin: 0 0 10px;
      padding: 0 0 10px;

      .my-counter-tag {
        border-bottom-color: $light-gray;
        span {
          color: $all-black;
        }
      }
    }
  }
}

html .offers-bids-details .table-box-three,
html .nui-modal {
  .counter-list-total {
    .list-my-counter {
      .attachments {
        ul.file-list {
          border-bottom: 1px solid $hr;
          margin: 0 0 10px;

          li {
            border: 0;
            border-top: 1px solid $hr;
            padding: 5px 0;
          }
        }
      }
    }
  }
}

// comment section
.offers-bids-details .table-box .comments,
.offers-bids-details .table-box-three .comments {
  ul.loading-details {
    p {
      padding: 0;
    }
  }
}

// trade status
.status-viewonly,
.status-tradeable,
.status-nottradeable,
.status-sfctradeable {
  @extend %nd;
  background: $white;
  border: 1px solid #c3c8d4;
  border-radius: 3px;
  color: $ok;
  display: inline-block;
  font-size: 0.55rem;
  line-height: 1;
  padding: 5px 5px 4px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  top: -2px;
  white-space: nowrap;
  width: 100%;
}

.status-viewonly {
  color: $error;
}

.status-nottradeable {
  color: $light-gray;
  cursor: not-allowed;
  pointer-events: none;
}

.status-sfctradeable {
  color: var(--link);
}

// col brand // product name sticky positions
html .market .full-page-content .md-table tbody tr td.col-brand,
html .market .full-page-content .md-table thead tr th.col-brand {
  left: 0;
  position: sticky !important;
  position: --webkit-sticky !important;
  z-index: 2;
}

html
  .market
  .full-page-content
  .md-table
  tbody
  tr
  td.col-brand
  + td.col-product-name-alt,
html
  .market
  .full-page-content
  .md-table
  thead
  tr
  th.col-brand
  + th.col-product-name-alt {
  left: 90px;
  position: sticky;
  position: --webkit-sticky;
  z-index: 2;

  @include max-screen($max-small) {
    box-shadow: none !important;
    left: auto !important;
    position: relative;
  }
}

// view only expanded row display
html
  .market
  .full-page-content
  .md-table
  tbody
  tr.viewonly
  + tr.nui-table-expanded-row {
  .offers-bids-details {
    .table-box-three:first-child {
      @include min-screen($min-large) {
        width: calc(33% - 40px);
      }
      @include min-screen($min-xlarge) {
        .unit-details {
          width: calc(100% - 20px);
        }
      }
    }

    .table-box-three:last-child {
      @include min-screen($min-large) {
        width: 66%;
      }
    }
  }
}

// new market depth in modal
.nui-modal {
  .market .full-page-content .generic-table {
    .nui-table-content {
      .nui-table-body {
        .col-details .nui-table-row-expand-icon {
          margin: 10px;
        }
        table {
          tbody tr td {
            padding: 0 !important;
          }
          thead tr + tr th {
            padding: 5px 10px !important;
          }
        }

        .offers-bids-details {
          left: 20px;
          margin-top: 0;
          max-width: calc(100vw - 100px);

          .table-box-three:first-child {
            @include min-screen($min-large) {
              margin-left: 0;

              .unit-details h2.details {
                padding-top: 10px;
              }
            }
          }
          .table-box-three:last-child {
            .box {
              @include min-screen($min-large) {
                padding-top: 5px;
              }
            }
          }
        }
      }
    }
  }
}

// details and product column overrides

// .full-page-content .generic-table .nui-table-content .nui-table-body table tbody tr td
html
  .market
  .full-page-content
  .md-table
  .nui-table-content
  .nui-table-body
  table
  tbody
  tr,
html
  .market
  .full-page-content
  .md-table
  .nui-table-content
  .nui-table-body
  table
  thead
  tr {
  position: relative;

  .col-details,
  th:first-child {
    left: 0;
    min-width: 99px;
    padding-left: 20px !important;
    position: sticky !important;
    position: -webkit-sticky !important;
    z-index: 6;

    @include min-screen($min-medium) {
      min-width: 119px;
      padding-left: 40px !important;
    }
    @include max-screen($max-small) {
      box-shadow: 2px 0 2px rgba(0, 0, 0, 0.05) !important;
    }
  }
  .col-brand {
    left: 99px;
    min-width: 90px;
    position: sticky;
    position: -webkit-sticky;
    width: 90px;
    z-index: 4;

    @include min-screen($min-medium) {
      left: 119px;
    }
    @include max-screen($max-small) {
      box-shadow: none !important;
      left: auto !important;
      position: relative;
    }
  }
  .col-product-name-alt {
    left: 99px;
    @include min-screen($min-medium) {
      left: 119px;
    }
    @include max-screen($max-small) {
      box-shadow: none !important;
      left: auto !important;
      position: relative;
    }
  }
  .col-brand + .col-product-name-alt {
    left: 189px;
    @include min-screen($min-medium) {
      left: 209px;
    }
    @include max-screen($max-small) {
      box-shadow: none !important;
      left: auto !important;
      position: relative;
    }
  }
  &:hover,
  &.selected {
    .col-product-name-alt {
      @include max-screen($max-small) {
        box-shadow: 0 -5px 0 rgba(0, 0, 0, 0.035) inset !important;
      }
    }
  }
}

// in modal, on marketplace
html .nui-modal.full {
  .market
    .full-page-content
    .md-table
    .nui-table-content
    .nui-table-body
    table
    tbody
    tr,
  .market
    .full-page-content
    .md-table
    .nui-table-content
    .nui-table-body
    table
    thead
    tr {
    .col-product-name-alt {
      left: 110px;
      @include min-screen($min-medium) {
        left: 130px;
      }
      @include max-screen($max-small) {
        box-shadow: none !important;
        left: auto !important;
        position: relative;
      }
    }

    &:hover,
    &.selected {
      .col-product-name-alt {
        @include max-screen($max-small) {
          box-shadow: 0 -5px 0 rgba(0, 0, 0, 0.035) inset !important;
        }
      }
    }
  }
}

.market {
  .nui-table-row {
    z-index: 0;
  }
  .nui-table-expanded-row {
    z-index: 1;
  }
}
