// partners
.partners-form {
  padding-bottom: 10px;

  .wizard {
    .ant-row {
      margin: 0;
    }

    .ant-row.ant-form-item {
      &.form-item-name-buyer,
      &.form-item-name-consignee {
        padding: 0;

        .ant-form-item-label,
        .ant-form-item-control-wrapper {
          display: inline-block;
          margin: 0 20px 0 0;
          overflow: visible;
          width: auto;
        }
        .ant-form-item-label {
          width: 120px;
        }
        label:after {
          right: -280px;
          top: 8px;
        }
      }

      &.form-item-name-consignee {
        padding: 0 0 20px;
      }

      &.form-item-name-name,
      &.form-item-name-phone,
      &.form-item-name-email,
      &.form-item-name-buyer,
      &.form-item-name-consignee,
      &.form-item-name-street,
      &.form-item-name-city,
      &.form-item-name-country,
      &.form-item-name-port {
        @include min-screen($min-xlarge) {
          width: 400px;
        }
      }

      &.form-item-name-companyno,
      &.form-item-name-zip {
        width: 250px;
      }

      &.form-item-name-phone {
        .ant-form-item-label,
        .ant-form-item-control-wrapper {
          overflow: visible;
        }
        &:after {
          border-top: 1px dashed $lightest-gray;
          clear: both;
          content: ' ';
          display: block;
          height: 1px;
          margin: 40px 0 20px;
          visibility: visible;
          width: 100vw;

          @include min-screen($min-medium) {
            margin-left: -270px;
            width: calc(100vw - 100px);
          }
        }
      }
    }

    .ant-row.ant-form-item {
      &.form-item-name-street,
      &.form-item-name-suburb,
      &.form-item-name-city,
      &.form-item-name-state,
      &.form-item-name-country,
      &.form-item-name-zip {
        @include min-screen($min-xlarge) {
          display: inline-block;
          margin-right: 10px;
        }
      }

      &.form-item-name-state:after,
      &.form-item-name-country:before,
      &.form-item-name-suburb:after,
      &.form-item-name-city:before {
        @include min-screen($min-xlarge) {
          clear: both;
          content: ' ';
          display: block;
          height: 1px;
          margin: 0 0 0 -270px;
          width: 100vw;
        }
      }

      &.form-item-name-suburb,
      &.form-item-name-state {
        @include min-screen($min-xlarge) {
          margin-right: 0;
          width: 280px;
        }
      }

      &.form-item-name-zip {
        @include min-screen($min-xlarge) {
          margin-right: 0;
          width: 120px;
        }
      }
    }

    .wizard-step-block {
      .nui-form {
        @include min-screen($min-small) {
          width: 400px;
        }

        @include min-screen($min-xlarge) {
          width: 960px;
        }

        @include min-screen($min-medium) {
          margin-left: 270px;

          .wizard-step {
            float: left;
            margin-left: -270px;
          }
        }
      }
    }
  }
}
