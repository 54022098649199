// toast styles

// variables
$nui-namespace: 'Toastify';
$nui-toast-width: 320px;
$nui-toast-background: $white;
$nui-toast-min-height: 32px;
$nui-toast-max-height: 800px;

$nui-mobile: 'only screen and (max-width : 480px)';

// progress bar
@keyframes #{$nui-namespace}__trackProgress {
  0% {
    transform: scaleX(0);
    -webkit-transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
    -webkit-transform: scaleX(1);
  }
}

.#{$nui-namespace}__progress-bar {
  animation: #{$nui-namespace}__trackProgress linear 1 forwards;
  -webkit-animation: #{$nui-namespace}__trackProgress linear1 forwards;
}

.#{$nui-namespace}__progress-bar {
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 5px;
  bottom: 0;
  height: 5px;
  left: 0;
  opacity: 1;
  position: absolute;
  transform-origin: left;
  -webkit-transform-origin: left;
  width: 100%;

  &--animated {
    animation: #{$nui-namespace}__trackProgress linear 1 forwards;
    -webkit-animation: #{$nui-namespace}__trackProgress linear 1 forwards;
  }

  &--controlled {
    transition: transform 0.2s;
  }

  &--rtl {
    right: 0;
    left: initial;
    transform-origin: right;
  }

  &--default {
    background: $ok;
  }

  &--dark {
    background: $ok;
  }

  &--info {
    background: $ok;
  }

  &--success {
    background: #aed416;
  }

  &--warning {
    background: #f5a814;
  }
  &--error {
    background: #f5a814;
  }
}

// toast container
.#{$nui-namespace}__toast-container {
  box-sizing: border-box;
  color: $white;
  padding: 5px;
  position: fixed;
  transform: translate3d(0, 0, 1000000px);
  -webkit-transform: translate3d(0, 0, 1000000px);
  width: $nui-toast-width;
  z-index: 1000000;
  &--top-left {
    left: 0;
    top: 0;
  }
  &--top-center {
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
  }
  &--top-right {
    right: 0;
    top: 0;
  }
  &--bottom-left {
    bottom: 0;
    left: 0;
  }
  &--bottom-center {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
  }
  &--bottom-right {
    bottom: 0;
    right: 0;
  }
}

@media #{$nui-mobile} {
  .#{$nui-namespace}__toast-container {
    left: 10px;
    margin: 0;
    padding: 0;
    width: 100vw;
    &--top-left,
    &--top-center,
    &--top-right {
      top: 10px;
      transform: translateX(0);
      -webkit-transform: translateX(0);
    }
    &--bottom-left,
    &--bottom-center,
    &--bottom-right {
      bottom: 10px;
      transform: translateX(0);
      -webkit-transform: translateX(0);
    }
    &--rtl {
      right: 10px;
      left: initial;
    }
  }
}

// toast
.#{$nui-namespace}__toast {
  background: rgb(35, 52, 68);
  border-radius: 3px;
  box-sizing: border-box;
  cursor: pointer;
  direction: ltr;
  margin: 5px;
  max-height: $nui-toast-max-height;
  overflow: hidden;
  position: relative;
  &:before {
    @extend %fontello;
    content: '\e804';
    font-size: 1.35rem;
    left: 9px;
    position: absolute;
    top: 18px;
  }
  &-body {
    @extend %regular;
    font-size: 0.8rem;
    line-height: 1.2rem;
    padding: 20px 35px 20px 45px;
    position: relative;
    a,
    a:active,
    a:focus,
    a:hover {
      color: $white;
    }
    h3 {
      color: $white;
      font-size: 0.8rem;
    }
    ul {
      margin: -10px 0 0 5px;
      padding: 0 0 0 10px;
    }
    .nui-button {
      background: none;
      border: 1px solid rgba(255, 255, 255, 0.25);
      box-shadow: none;
      color: $white;
      &.nui-primary {
        background: $white;
        color: rgb(35, 52, 68);
      }
    }
  }
  &--dark {
    &:before {
      content: '\e804';
    }
  }
  &--default {
    &:before {
      content: '\e804';
    }
  }
  &--info {
    &:before {
      content: '\e804';
    }
  }
  &--success {
    background: #459225;
    &:before {
      content: '\e802';
    }
  }
  &--warning {
    background: $warning;
    &:before {
      content: '\e80d';
    }
  }
  &--error {
    background: $error;
    &:before {
      content: '\e80e';
    }
  }
}

@media #{$nui-mobile} {
  .#{$nui-namespace}__toast {
    margin-bottom: 0;
  }
}

// close button
.#{$nui-namespace}__close-button {
  background: transparent;
  border: 0;
  color: $white;
  cursor: pointer;
  outline: none;
  padding: 0;
  position: absolute;
  right: 7px;
  top: 5px;
  & > svg {
    fill: currentColor;
    height: 16px;
    width: 14px;
  }
}

// animation (slide)
@mixin transform {
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}

@keyframes #{$nui-namespace}__slideInRight {
  from {
    transform: translate3d(110%, 0, 0);
    -webkit-transform: translate3d(110%, 0, 0);
    visibility: visible;
  }
  to {
    @include transform;
  }
}

@keyframes #{$nui-namespace}__slideInLeft {
  from {
    transform: translate3d(-110%, 0, 0);
    -webkit-transform: translate3d(-110%, 0, 0);
    visibility: visible;
  }
  to {
    @include transform;
  }
}

@keyframes #{$nui-namespace}__slideInUp {
  from {
    transform: translate3d(0, 110%, 0);
    -webkit-transform: translate3d(0, 110%, 0);
    visibility: visible;
  }
  to {
    @include transform;
  }
}

@keyframes #{$nui-namespace}__slideInDown {
  from {
    transform: translate3d(0, -110%, 0);
    -webkit-transform: translate3d(0, -110%, 0);
    visibility: visible;
  }
  to {
    @include transform;
  }
}

@keyframes #{$nui-namespace}__slideOutRight {
  from {
    @include transform;
  }
  to {
    transform: translate3d(110%, 0, 0);
    -webkit-transform: translate3d(110%, 0, 0);
    visibility: hidden;
  }
}

@keyframes #{$nui-namespace}__slideOutLeft {
  from {
    @include transform;
  }
  to {
    transform: translate3d(-110%, 0, 0);
    -webkit-transform: translate3d(-110%, 0, 0);
    visibility: hidden;
  }
}

@keyframes #{$nui-namespace}__slideOutDown {
  from {
    @include transform;
  }
  to {
    transform: translate3d(0, 500px, 0);
    -webkit-transform: translate3d(0, 500px, 0);
    visibility: hidden;
  }
}

@keyframes #{$nui-namespace}__slideOutUp {
  from {
    @include transform;
  }
  to {
    transform: translate3d(0, -500px, 0);
    -webkit-transform: translate3d(0, -500px, 0);
    visibility: hidden;
  }
}

.#{$nui-namespace}__slide-enter {
  &--top-left,
  &--bottom-left {
    animation-name: #{$nui-namespace}__slideInLeft;
  }
  &--top-right,
  &--bottom-right {
    animation-name: #{$nui-namespace}__slideInRight;
  }
  &--top-center {
    animation-name: #{$nui-namespace}__slideInDown;
  }
  &--bottom-center {
    animation-name: #{$nui-namespace}__slideInUp;
  }
}

.#{$nui-namespace}__slide-exit {
  &--top-left,
  &--bottom-left {
    animation-name: #{$nui-namespace}__slideOutLeft;
  }
  &--top-right,
  &--bottom-right {
    animation-name: #{$nui-namespace}__slideOutRight;
  }
  &--top-center {
    animation-name: #{$nui-namespace}__slideOutUp;
  }
  &--bottom-center {
    animation-name: #{$nui-namespace}__slideOutDown;
  }
}

// animation (bounce)
@mixin timing-function {
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

@keyframes #{$nui-namespace}__bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    @include timing-function;
  }
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    transform: translate3d(10px, 0, 0);
  }
  90% {
    transform: translate3d(-5px, 0, 0);
  }
  to {
    transform: none;
  }
}

@keyframes #{$nui-namespace}__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

@keyframes #{$nui-namespace}__bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    @include timing-function;
  }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }
  75% {
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    transform: translate3d(5px, 0, 0);
  }
  to {
    transform: none;
  }
}

@keyframes #{$nui-namespace}__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

@keyframes #{$nui-namespace}__bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    @include timing-function;
  }
  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  75% {
    transform: translate3d(0, 10px, 0);
  }
  90% {
    transform: translate3d(0, -5px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes #{$nui-namespace}__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }
  40%,
  45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

@keyframes #{$nui-namespace}__bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    @include timing-function;
  }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }
  75% {
    transform: translate3d(0, -10px, 0);
  }
  90% {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: none;
  }
}

@keyframes #{$nui-namespace}__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }
  40%,
  45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.#{$nui-namespace}__bounce-enter {
  &--top-left,
  &--bottom-left {
    animation-name: #{$nui-namespace}__bounceInLeft;
  }
  &--top-right,
  &--bottom-right {
    animation-name: #{$nui-namespace}__bounceInRight;
  }
  &--top-center {
    animation-name: #{$nui-namespace}__bounceInDown;
  }
  &--bottom-center {
    animation-name: #{$nui-namespace}__bounceInUp;
  }
}

.#{$nui-namespace}__bounce-exit {
  &--top-left,
  &--bottom-left {
    animation-name: #{$nui-namespace}__bounceOutLeft;
  }
  &--top-right,
  &--bottom-right {
    animation-name: #{$nui-namespace}__bounceOutRight;
  }
  &--top-center {
    animation-name: #{$nui-namespace}__bounceOutUp;
  }
  &--bottom-center {
    animation-name: #{$nui-namespace}__bounceOutDown;
  }
}
