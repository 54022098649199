// main
:before,
:after {
  box-sizing: border-box;
}

* {
  text-align: left; // need this for safari
}

// small, medium and large loading icons
.nui-loading.tiny,
.nui-loading.small,
.nui-loading.medium,
.nui-loading.large,
.nui-loading.huge {
  border-radius: 50%;
  border: 2px solid transparent;
  border-left-color: var(--loader);
  border-top-color: var(--loader);
  display: block;
  height: 40px;
  margin: auto;
  position: relative;
  width: 40px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  z-index: 1001;

  &:before {
    bottom: 2px;
    border-radius: 50%;
    border: 2px solid transparent;
    border-left-color: var(--loader-before);
    border-top-color: var(--loader-before);
    content: '';
    left: 2px;
    position: absolute;
    right: 2px;
    top: 2px;
    -webkit-animation: spin 2.5s linear infinite;
    animation: spin 2.5s linear infinite;
  }
  &:after {
    bottom: 6px;
    border-radius: 50%;
    border: 2px solid transparent;
    border-left-color: var(--loader-after);
    border-top-color: var(--loader-after);
    content: '';
    left: 6px;
    position: absolute;
    right: 6px;
    top: 6px;
    -webkit-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
  }

  &.reverse {
    border-left-color: $white;
    border-top-color: $white;

    &:before,
    &:after {
      border-left-color: $white;
      border-top-color: $white;
    }
  }

  &.duo-rings {
    &:after {
      display: none;
    }
  }

  &.solo-ring {
    &:before,
    &:after {
      display: none;
    }
  }
}

.nui-loading.tiny {
  height: 18px;
  width: 18px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;

  &:before,
  &:after {
    display: none;
  }
}

.nui-loading.small {
  &:after {
    display: none;
  }
}

.nui-loading.medium {
  height: 50px;
  width: 50px;
}

.nui-loading.large {
  height: 60px;
  width: 60px;
}

.nui-loading.huge {
  height: 80px;
  width: 80px;
}

// remove the bloody ant background for the loading icon!!!
.ant-spin-container.ant-spin-blur {
  display: none !important;
}

// global stuff
body {
  -webkit-tap-highlight-color: transparent;
}
::selection {
  background: var(--link);
  color: $white;
}
::-moz-selection {
  background: var(--link);
  color: $white;
}
img::selection {
  background: transparent;
}
img::-moz-selection {
  background: transparent;
}

* {
  transition: all 0.2s linear;
}

.clearfix:after {
  content: ' ';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.main-content.privy-content {
  background: url(#{$assets-path}/img/signinbg.jpg) no-repeat top center;
  background-attachment: fixed;
  background-size: cover;
  padding: 40px 0;
}

@include min-screen($min-medium) {
  .main-content.privy-content {
    padding: 20vh 0;
  }
}

.main-content.privy-content + footer {
  background: $white;
  border-bottom: 50px solid $white;
}

.privy {
  margin: 0 auto;
  max-width: 600px;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
  font-size: 16px;
  height: 100%;
  overflow-x: hidden;

  &.panel-open,
  &.panelopen {
    overflow: hidden !important;
  }

  // loading icon
  .ant-spin {
    color: var(--link);
    display: block;
    margin: 5px auto 20px;
  }

  .ant-dropdown-trigger {
    background: $white;
    border: 1px solid $lightest-gray;
    color: var(--link);
    max-width: calc(100vw - 290px);
    overflow: hidden;
    padding-right: 30px;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:active,
    &:focus,
    &:hover {
      color: $all-black;
    }
    @include min-screen($min-medium) {
      max-width: calc(100vw - 250px);
    }
    @include min-screen($min-large) {
      margin-left: 20px;
    }
    span {
      @extend %regular;
      font-size: 0.8rem;
    }
    &:active,
    &:focus,
    &:hover {
      border-color: $light-gray;
    }
    &:after {
      border-top: 6px solid rgba(0, 0, 0, 0.25);
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      content: ' ';
      display: block;
      margin-top: -2px;
      pointer-events: none;
      position: absolute;
      right: 12px;
      top: 50%;
      transition: all 0.2s linear;
      z-index: 2;
    }
  }

  .ant-dropdown-trigger > .arrow {
    opacity: 0;
  }

  #account-area .ant-dropdown {
    // fix for marketplace
    @include min-screen($min-medium) {
      left: auto !important;
      right: 0;
    }
  }
}

html body {
  background: $body;
  color: $mid-gray;
  @extend %regular;
  font-size: 1rem;
  height: 100%;
  line-height: 1.5em;
  max-width: 100vw;
  min-width: 100%;
  padding: 76px 0 0;
  transition: none;

  @include min-screen($min-medium) {
    padding: 0;
  }
}

html.no-touch,
html.no-touch body {
  @extend %regular;
}

body.reverse {
  background: $white;
  footer.site-footer {
    background: $white;
  }
}

header + div > div {
  @include max-screen($max-small) {
    height: auto !important;
  }
}

.ant-row,
.nui-row {
  margin: 0 auto;
  padding: 0 20px;
  position: relative;

  &:after {
    @extend %clearfix;
  }
  @include min-screen($min-medium) {
    padding: 0 40px;
  }
  @include min-screen($min-large) {
    max-width: none;
  }
  // collapsed rows
  &.collapse {
    padding: 0;
    h1 {
      padding-left: 20px;
      @include min-screen($min-medium) {
        padding-left: 40px;
      }
    }
    table thead tr th:first-child,
    table tbody tr td:first-child {
      padding-left: 20px !important;
      @include min-screen($min-medium) {
        padding-left: 40px !important;
      }
    }
    ul.ant-pagination {
      padding-left: 20px;
      position: sticky;
      position: -webkit-sticky;

      @include min-screen($min-medium) {
        padding-left: 40px;
      }
    }
  }
}

.ant-affix {
  @include max-screen($max-small) {
    position: relative !important;
    z-index: 2;
  }
}

.gutter-table {
  margin-left: -8px;
  margin-right: -8px;
}

.gutter-row {
  padding-left: 8px;
  padding-right: 8px;
}

.main-content {
  background: $white;
  font-size: 0.9rem;
  min-height: 480px;
  min-width: 320px;
  padding: 20px 0;
  position: relative;
  z-index: 1;

  @include min-screen($min-medium) {
    min-height: 1px;
  }
  .main-content {
    padding: 0;
  }
}

[class^='equal'],
[class*=' equal'] {
  @include max-screen($max-small) {
    height: auto !important;
  }
}

.bg-section {
  background: $bg-section;
  padding: 20px 0;

  @include min-screen($min-large) {
    padding: 40px 0;
  }
}

h1 {
  @extend %light;
  color: $h1;
  font-size: 2rem;
  line-height: 2.25rem;
  margin: 0 0 1.5rem;

  @include min-screen($min-medium) {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
  span.smaller {
    display: inline-block;
    font-size: 1.2rem;
    vertical-align: middle;
  }
}

h2,
h3,
h4,
h5,
h6 {
  @extend %bold;
  color: $h2;
  font-size: 1.25rem;
  margin: 0 0 1em;

  &.light {
    @extend %light;
  }
  .tag {
    background: darken($lightest-gray, 5%);
    border-radius: 3px;
    color: $white;
    display: inline-block;
    font-size: 0.6rem;
    line-height: 1;
    margin: 0 5px;
    padding: 5px 10px;
    text-transform: uppercase;
    vertical-align: middle;
  }
}

html.no-touch,
html.no-touch body {
  h1 {
    @extend %light;
  }
  h2,
  h3,
  h4,
  h5,
  h6 {
    @extend %bold;
    &.light {
      @extend %light;
    }
  }
}

h3,
h4,
h5,
h6 {
  color: $h3;
  font-size: 1.15rem;
  margin: 0 0 10px;
}

h4,
h5,
h6 {
  font-size: 1.125rem;
  margin: 0 0 2px;
}

p,
address {
  font-size: inherit;
  margin: 0;
  padding: 0 0 1rem;

  &.smaller {
    font-size: 90%;
  }
}

.freightaddress {
  font-size: inherit;
  padding: 0 0 1rem;
}

.etawithetd {
  strong,
  span {
    display: block;
    font-size: inherit;
  }
}

p + h2 {
  margin-top: 20px;
}

h2.step {
  left: 30px;
  position: relative;

  @include min-screen($min-medium) {
    left: auto;
  }
  &:before {
    @extend %bold;
    background: var(--primary-color);
    border-radius: 50%;
    color: $white;
    content: ' ';
    display: inline-block;
    font-size: 0.9rem;
    height: 20px;
    left: -30px;
    line-height: 18px;
    margin: 0;
    position: absolute;
    text-align: center;
    top: 4px;
    width: 20px;
  }
  &.step1:before {
    content: '1';
  }
  &.step2:before {
    content: '2';
  }
  &.step3:before {
    content: '3';
  }
  &.step4:before {
    content: '4';
  }
  &.step5:before {
    content: '5';
  }
  &.step6:before {
    content: '6';
  }
  &.step7:before {
    content: '7';
  }
  &.step8:before {
    content: '8';
  }
  &.step9:before {
    content: '9';
  }

  &:after {
    color: $mid-gray;
    content: 'Step';
    display: none;
    font-size: 0.9rem;
    font-weight: normal;
    left: -65px;
    position: absolute;
    top: 1px;

    @include min-screen($min-medium) {
      display: inline-block;
    }
  }
}

address {
  font-style: normal;
}

* .unit {
  @extend %regular;
  color: $light-gray;
  font-size: 0.7rem;
  white-space: nowrap;
}

ul,
ol {
  margin: 0 0 20px;
}

hr {
  background: $hr;
  border: 0;
  clear: both;
  height: 1px;
  margin: 20px 0;

  &.darker {
    background: $lightest-gray;
  }
  &.dashed {
    background: none;
    border-top: 1px dashed $hr;
    box-shadow: none;
  }
  &.white {
    background: $white;
    box-shadow: none;
  }
  &.blank {
    background: none;
    box-shadow: none;
  }
  &.vertical {
    box-shadow: none;
    height: inherit;
    margin: 0 auto;
    width: 1px;
  }
  &.sm10-md20 {
    margin-top: 10px;
    @include min-screen($min-medium) {
      margin-top: 20px;
    }
  }
}

.lcol {
  @include min-screen($min-medium) {
    min-width: 200px;
  }
  @include min-screen($min-large) {
    min-width: 300px;
  }
}

// pdf files in table
table {
  a.icon-file-pdf {
    display: block;
    padding-bottom: 5px;
    padding-left: 26px;
    position: relative;

    &:before {
      color: #e12025;
      font-size: 1rem;
      left: 0;
      position: absolute;
      top: 3px;
    }
  }
}

// ant tooltip
.ant-tooltip {
  transition: none;
  .ant-tooltip-content {
    .ant-tooltip-arrow {
      display: none;
      &:before {
        background: none;
        border-top: 6px solid $light-gray;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        bottom: 7px;
        display: block;
        height: 0;
        transform: none;
        width: 0;
      }
    }
    .ant-tooltip-inner {
      border: 1px solid $lightest-gray;
      background-color: $focus;
      border-radius: 3px;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
      color: $dark-gray;
      font-size: 0.7rem;
      line-height: 1rem;
      min-height: 20px;
      padding: 5px 9px;

      &:first-letter {
        text-transform: uppercase;
      }
    }
  }
}

// numbered sections
div.numbered-sections {
  counter-reset: section-counter;
  .numbered {
    counter-increment: section-counter;
    padding-left: 20px;
    position: relative;

    &:before {
      color: $mid-gray;
      content: counter(section-counter) '.';
      display: inline-block;
      font-size: 0.8rem;
      left: 0;
      line-height: 19px;
      margin: 0;
      position: absolute;
      text-align: right;
      top: 5px;
      vertical-align: middle;
    }
    &.outside {
      @include min-screen($min-small) {
        padding-left: 0;
        &:before {
          left: -20px;
        }
      }
    }
  }
}

// column layout
.column-layout {
  .col-md-2 {
    @include min-screen($min-medium) {
      display: inline-block;
      vertical-align: top;
      width: 48%;
    }
  }

  .col-xl-3 {
    @include min-screen($min-xlarge) {
      display: inline-block;
      vertical-align: top;
      width: 32%;
    }
  }

  .col-xl-4 {
    @include min-screen($min-xlarge) {
      display: inline-block;
      vertical-align: top;
      width: 24%;
    }
  }
}

// document file list
.document-list-box ul {
  list-style: none;
  margin: 0;
  padding: 0;

  h4 {
    border-bottom: 1px solid $hr;
    padding-bottom: 10px;
    font-size: 0.9rem;
  }
  li {
    margin: 0;
    padding: 5px 0;
    position: relative;

    // button as link
    button {
      @extend %dd;
      background: none;
      border: 0;
      color: var(--link);
      line-height: 1;
      padding: 0;

      &:hover {
        @extend %nd;
        color: $all-black;
      }
    }

    &:first-child {
      padding-top: 0;
    }
    .delete-file {
      background: none;
      border: 0;
      color: $mid-gray;
      font-size: 1rem;
      position: absolute;
      right: 0;
      top: 5px;

      &:disabled {
        opacity: 0.25;
      }
    }
  }
}

.col-md-2 {
  .document-list-box ul {
    @include min-screen($min-medium) {
      margin-left: 20px;
    }
  }
}

.nav-add-item {
  a.button {
    @include min-screen($min-medium) {
      margin-top: 10px;
    }
  }
}

.customer-filters {
  .dropcheck {
    background: $white;
    border-radius: 3px;
    border: 1px solid $lightest-gray;
    box-sizing: border-box;
    color: $all-black;
    height: 44px;
    padding: 5px 10px;
    position: relative;
    transition: none;
    vertical-align: top;
    width: 25%;
    z-index: 3;
    margin-top: 5px;

    .check-field {
      margin: 0;

      .nui-check-fieldset {
        margin: 0;
      }
      &.option-all {
        .nui-label {
          @extend %bold;
          color: $all-black;
        }
      }
    }

    .showoptions {
      background: none;
      border: 0;
      box-shadow: none;
      display: block;
      height: 42px;
      padding-left: 10px;
      position: absolute;
      right: 0;
      text-align: left;
      top: 0;
      width: 100%;
      z-index: 5;

      span.total {
        color: $light-gray;
        display: inline-block;
        font-size: 0.9rem;
        max-width: 100%;
        overflow: hidden;
        padding-right: 10px;
        padding-top: 3px;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      span.arrow {
        @include arrow(18, 15);
      }
    }

    .options {
      height: 0;
      overflow: hidden;
      transition: none;

      &.visible {
        background: $white;
        border-radius: 0 0 3px 3px;
        border: 1px solid $lightest-gray;
        border-top: 0;
        box-sizing: border-box;
        box-shadow: 0 1px 0 $lightest-gray inset;
        color: $all-black;
        height: auto;
        left: -1px;
        max-height: 300px;
        overflow-y: auto;
        padding: 0;
        position: absolute;
        top: 40px;
        vertical-align: top;
        width: calc(100% + 2px);
        z-index: 3;

        &:before {
          background: $white;
          content: ' ';
          display: block;
          height: 3px;
          left: 0;
          top: -1px;
          width: 10px;
        }
        &:after {
          background: $white;
          content: ' ';
          display: block;
          height: 3px;
          position: absolute;
          right: 0;
          top: -1px;
          width: 10px;
          z-index: 5;
        }
      }
      .check-field {
        padding: 5px 10px;

        .nui-label {
          display: block;
        }
        &:hover {
          background: rgba(0, 0, 0, 0.05);
        }
      }
    }
  }
}

// 403/404 etc
.error-wrapper {
  margin: 10px auto;
  @include min-screen($min-large) {
    width: 80%;
  }
  @include min-screen($min-xxlarge) {
    max-width: 60%;
  }
}

.error-code {
  @extend %light;
  border-right: 1px solid $alert-error-color;
  color: $error;
  display: inline-block;
  font-size: 1.8rem;
  padding: 0;
  position: relative;
  text-align: center;
  transition: none;
  vertical-align: top;
  width: 80px;

  &:before {
    @extend %bold;
    color: $all-black;
    content: 'Error code';
    display: block;
    font-size: 0.6rem;
    margin: 0 0 -5px;
  }
}

.error-code + .error-message {
  border-left: 1px solid $alert-error-color;
  color: $error;
  display: inline-block;
  left: -1px;
  padding: 0 0 0 10px;
  position: relative;
  transition: none;
  vertical-align: top;
  width: calc(100% - 80px);

  @include min-screen($min-large) {
    max-width: 75%;
  }
}

// 404 page
.error-code-large {
  text-align: center;

  p {
    border-bottom: 1px solid $hr;
    display: inline-block;
    margin: 0 auto;
    padding: 30px 0;
    position: relative;
    text-align: center;
    z-index: 1;

    &:first-child {
      @include min-screen($min-medium) {
        max-width: 60%;
      }
    }
    &.code {
      @extend %bold;
      border: 0;
      color: rgba(0, 0, 0, 0.1);
      font-size: 35vw;
      line-height: 25vw;
      padding: 0;
      pointer-events: none;
      top: 30px;
      z-index: 2;

      span {
        background: $bg-section;
        color: $all-black;
        display: block;
        font-size: 0.9rem;
        left: calc(50% - 80px);
        line-height: 1rem;
        position: absolute;
        text-align: center;
        text-shadow: none;
        top: -39px;
        white-space: nowrap;
        width: 160px;
      }
    }
  }
}

.ant-empty,
.ant-empty-image,
.ant-empty-description {
  text-align: center;
}

// updated page header display
.full-page-header {
  background: #f9f9f9;
  border-bottom: 1px solid $hr;
  margin: -30px 0 0;
  padding: 10px 20px;

  @include min-screen($min-medium) {
    margin: -20px 0 0;
    padding: 10px 40px;
  }
  h1 {
    margin: 5px 0 15px;
    @include min-screen($min-medium) {
      max-width: calc(100% - 200px);
    }
  }
  @include min-screen($min-medium) {
    padding: 10px 40px 20px;
    h1 {
      margin: 5px 0 20px;
    }
  }

  // search in page header
  .search-fieldset {
    width: 100%;
    @include min-screen($min-medium) {
      display: inline-block;
      margin-right: 5px;
      width: 270px;
    }
    @include min-screen($min-xlarge) {
      width: 25%;
    }
  }
}

.full-page-content.dark {
  background: #f9f9f9;
  border-bottom: 1px solid $hr;

  .customer-table {
    overflow-x: auto;
    overflow-y: hidden;
    padding: 0;
  }
}

.nui-modal.full {
  .full-page-content.dark {
    background: none;
    border-bottom: 0;

    .customer-table {
      padding-right: 20px;
    }
  }
}

.full-page-header + .full-page-header {
  margin: -21px 0 0;
  @include min-screen($min-medium) {
    margin: -21px 0 0;
  }
}

.ant-calendar-input-wrap {
  display: none;
}

// icon messaging, full page
.full-page-messaging {
  background: #f9f9f9;
  border-bottom: 1px solid $hr;
  position: relative;
  bottom: 10px;

  &.b-0 {
    border: 0;
    margin-top: -5px;

    @include min-screen($min-medium) {
      margin-top: -20px;
    }
  }
  .bordered {
    border: 0;
    color: $light-gray;
    line-height: 1.2rem;
    margin: 10px auto;

    &:before {
      border-radius: 50%;
      border: 5px solid #c9cacf;
      color: #c9cacf;
      font-size: 2.5rem;
      display: block;
      height: 80px;
      line-height: 70px;
      margin: 0 auto 10px;
      text-align: center;
      width: 80px;
    }
  }
}

// servers
@keyframes slideleft {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

.server {
  color: $white;
  display: block;
  font-size: 0.6rem;
  height: 25px;
  line-height: 29px;
  margin: -1px 0 0;
  overflow: hidden;
  position: sticky;
  position: -webkit-sticky;
  text-transform: uppercase;
  top: 76px;
  white-space: nowrap;
  width: 100%;
  z-index: 2;

  @include min-screen($min-medium) {
    top: 80px;
  }
  @include min-screen($min-large) {
    top: 77px;
  }

  span {
    display: inline-block;
    margin: 0 4px;
  }

  span.closeme {
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.25) inset;
    color: $white;
    cursor: pointer;
    font-size: 0.7rem;
    height: 25px;
    line-height: 25px;
    margin: 0;
    position: absolute;
    right: 0;
    text-align: center;
    transition: 0.2s all linear;
    width: 25px;
    z-index: 2;

    &:before {
      margin: 0;
    }
    &:hover {
      box-shadow: 0 0 50px rgba(0, 0, 0, 0.5) inset;
    }
  }

  > div div {
    max-width: fit-content;
    position: relative;
    transition: 2s all ease;
    animation: slideleft 40s infinite linear;
    -webkit-animation: slideleft 40s infinite linear;
    will-change: transform;

    &:hover {
      animation-play-state: paused;
      -webkit-animation-play-state: paused;
    }
  }

  &.development,
  &.sandbox {
    background: #1a9f9d;
    background-image: linear-gradient(to right, #1a9f9d, #901db2);

    span.closeme {
      background: #901db2;
    }
  }
  &.training {
    background: #fa1038;
    background-image: linear-gradient(to right, #fa1038, #0b83b5);

    span.closeme {
      background: #0b83b5;
    }
  }
  &.demo {
    background: #396297;
    background-image: linear-gradient(to right, #396297, #1a9f9d);

    span.closeme {
      background: #1a9f9d;
    }
  }
}

.dev-mode .thead-container {
  @include min-screen($min-medium) {
    top: 99px;
  }
}

// external product link
table,
.market,
.marketplace,
.tender-list {
  .col-product,
  .col-buyer,
  .col-product-small,
  .product-details h2 a,
  .col-product-name-alt h2 a,
  .m-product {
    span.icon-link-ext-alt {
      @extend %nd;
      color: darken($lightest-gray, 5%);
      display: inline-block;
      font-size: 0.8rem;
      margin: 0 4px;
      vertical-align: text-top;

      &:before {
        margin: 0;
      }
    }
    &:hover {
      span.icon-link-ext-alt {
        color: var(--link);
      }
    }
  }
}

.show-help {
  @extend %nd;
  cursor: help;
  display: inline-block;
  font-size: 0;
  margin: 0;
  vertical-align: middle !important;

  &:before {
    @extend %fontello;
    color: rgba(0, 0, 0, 0.25);
    content: '\e805';
    font-size: 1.2rem;
    margin: 0;
  }
  &:hover {
    &:before {
      color: rgba(0, 0, 0, 0.5);
    }
  }
}

thead {
  th {
    .show-help {
      left: 4px;
      position: relative;
      top: 4px;
    }
  }
}
