// marketplace
.marketplace {
  margin: 20px 0 -20px 0;

  // timeline
  &.timeline {
    .time-header {
      background-color: #f9f9f9;
      border-bottom: 1px solid #d9d9d9;
      border-top: 1px solid #d9d9d9;
      position: absolute;
      top: 0;
      transition: none;
      width: 100%;
      z-index: 3;

      .product-col-width {
        padding: 10px 0;
        @include min-screen($min-small) {
          box-shadow: 2px 0 2px rgba(0, 0, 0, 0.2);
          padding: 10px 10px 10px 0;
        }
      }
    }

    .product,
    .m-product {
      background: $white;
      border-bottom: 1px solid $hr;
      border-right: 1px solid $hr;
      box-shadow: 2px 0 2px rgba(0, 0, 0, 0.1), 0 1px 0 $hr inset;
      height: 121px;
      padding: 0 0 0 30px;
      position: relative;
      top: -1px;
      z-index: 2;

      h2 {
        @extend %regular;
        font-size: 0.9rem;
        line-height: 1.2rem;
        margin: 0;
        white-space: normal;
      }
      p {
        margin: 0;
        max-width: 250px;
        padding: 0;

        &.unit {
          color: $mid-gray;
          font-size: 0.7rem;
        }
        &.division {
          @extend %bold;
          color: $all-black;
          font-size: 0.8rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .product-info {
        display: inline-block;
        padding: 0 0 0 20px;
        text-align: left;
        vertical-align: top;
        width: 75%;
        width: calc(100% - 90px);

        @include min-screen($min-medium) {
          padding-left: 13px;
        }
        .product-details {
          display: inline-block;
          text-align: left;
          vertical-align: top;
          width: 100%;
        }
      }
      .totals {
        border-left: 1px solid $hr;
        display: block;
        float: right;
        font-size: 0.8rem;
        height: 100%;
        padding: 5px 0;
        position: relative;
        white-space: nowrap;
        width: 38px;

        .current {
          border-bottom: 1px solid $hr;
          height: calc(100% - 32px);
          position: relative;

          .total-bids,
          .total-offers {
            position: absolute;
            top: 1px;

            a.show-totals {
              border: 1px solid $lightest-gray;
              border-radius: 3px;
              color: $bid;
              display: inline-block;
              font-size: 0.75rem;
              height: 24px;
              left: 6px;
              line-height: 22px;
              padding: 0;
              position: relative;
              right: auto;
              text-align: center;
              top: 0;
              transition: none;
              width: 24px;

              &:active,
              &:focus,
              &:hover {
                background: $bid;
                border: 1px solid $bid;
                color: $white;
              }
            }
          }
          .total-offers {
            top: 30px;

            a.show-totals {
              color: $offer;
              &:active,
              &:focus,
              &:hover {
                background: $offer;
                border: 1px solid $offer;
                color: $white;
              }
            }
          }
        }
        .last {
          height: 35px;
          position: relative;
          text-align: center;

          span {
            position: absolute;
            top: 5px;
          }
        }
        span {
          color: $light-gray;
          display: inline-block;
          height: 28px;
          left: -53px;
          position: absolute;
          text-align: right;
          width: 40px;

          @include min-screen($min-small) {
            left: -50px;
          }
        }
        strong {
          color: $all-black;
          display: inline-block;
          text-align: center;
          width: 30px;
        }
      }

      a.expand {
        color: $lightest-gray;
        font-size: 0;
        left: 10px;
        position: absolute;
        top: 8px;

        &:before {
          font-size: 1.2rem;
        }
      }
      &:hover {
        a.expand {
          color: $mid-gray;
        }
      }

      .brand {
        img {
          height: 40px;
          max-width: none;
        }
      }
      a.place-bid {
        @include pill($bid);
        border-color: $lightest-gray;
        height: 24px;
        line-height: 23px;

        &:hover {
          border-color: $bid;
        }
        &:active,
        &:focus,
        &:hover {
          background: $bid;
          border-color: $bid;
          color: $white;
        }
      }
      a.place-offer {
        @include pill($offer);
        border-color: $lightest-gray;
        height: 24px;
        line-height: 23px;

        &:active,
        &:focus,
        &:hover {
          background: $offer;
          border-color: $offer;
          color: $white;
        }
      }

      .actions {
        bottom: 8px;
        position: absolute;
        width: 250px;

        @include min-screen($min-medium) {
          width: 295px;
        }
      }

      .legend-list {
        display: inline-block;
        list-style: none;
        margin: 0 10px 0 0;
        padding: 0;

        li {
          display: inline-block;
          margin: 0 3px 0 0;
          padding: 0;
          vertical-align: top;
        }
      }
    }

    .time-content {
      table.marketplace-table {
        border-collapse: collapse;

        .data {
          border-bottom: 1px solid $hr;
          border-right: 1px solid $hr;
          height: 120px;
          width: $cell-width;
        }
        .empty {
          background: $empty;
          &.no-trades {
            .data {
              width: $cell-width-small;
            }
          }
        }

        .trade {
          background: $white;
          &.empty {
            background: $empty;
          }
        }

        td:hover {
          a.bid.ghost,
          a.offer.ghost {
            opacity: 1;
          }
        }

        .last {
          text-align: center;
          vertical-align: top;

          a {
            background: none;
            border: 1px solid $lightest-gray;
            color: $light-gray;
            top: 6px;

            &:hover {
              border: 1px solid $light-gray;
            }
          }
        }

        thead {
          @include min-screen($min-medium) {
            background: repeating-linear-gradient(
              to bottom,
              #f9f9f9 0,
              #f9f9f9 60px,
              $hr 60px,
              $hr 61px,
              #f9f9f9 61px,
              #f9f9f9 100px
            );
            box-shadow: 0 1px 0 $hr inset, 0 -1px 0 $hr inset;
          }
          height: 100px;
        }

        thead th {
          background: $white !important;
          @extend %light;
          height: 100px;
          min-width: $cell-width-small;
          text-align: left;

          &.empty {
            background: transparent;
          }
          font-weight: normal;
          padding: 0;
          position: sticky;
          position: -webkit-sticky;
          top: 0;
          vertical-align: top;
          white-space: nowrap;
          z-index: 3;

          &:first-child {
            position: sticky;
            left: 0;
            z-index: 10;
          }

          .filter-container {
            background: $white;
            border-right: 1px solid $hr;
            border-top: 1px solid $hr;
            box-shadow: 2px 0 2px -1px rgba(0, 0, 0, 0.1);
            height: 60px;
            padding: 9px 0 0 20px;
            position: relative;
            width: 100%;
            z-index: 4;

            @include min-screen($min-medium) {
              padding-left: 40px;
            }
          }

          .year,
          .year_month {
            border-top: 1px solid $hr;
            color: darken($light-gray, 10%);
            font-size: 0;
            @extend %light;
            height: 60px;
            line-height: 70px;
            padding-left: 15px;
            position: relative;
            text-align: left;

            span {
              color: $all-black;
              font-size: 0.95rem;
              left: 12px;
              position: absolute;
              top: 7px;
              z-index: 2;
            }
            strong {
              color: $light-gray;
              display: none;
              font-size: 0.6rem;
              left: 12px;
              position: absolute;
              top: -14px;
            }
            &:before {
              content: '20';
              font-size: 0;
            }
          }
          .year_month {
            strong {
              display: block;
            }
          }
          .month,
          .week {
            background: #f1f1f1;
            border-bottom: 1px solid $hr;
            border-top: 1px solid $hr;
            border-right: 1px solid $hr;
            color: $all-black;
            @extend %bold;
            height: 40px;
            line-height: 40px;
            padding-left: 15px;
            text-align: left;
          }
          &.dec {
            .year {
              border-right: 1px solid $hr;
            }
          }
          &.jan {
            .year {
              font-size: 1.5rem;
              strong {
                display: block;
              }
            }
          }
          &.apr,
          &.jul,
          &.oct {
            .year {
              color: $light-gray;
              font-size: 1rem;
              strong {
                display: block;
              }
            }
          }
          &.mar,
          &.jun,
          &.sep {
            .year {
              &:before {
                border-right: 1px dashed darken($hr, 10%);
                bottom: 0;
                content: ' ';
                height: 40px;
                position: absolute;
                right: 0;
                width: 1px;
              }
            }
          }
          &.empty {
            .year {
              padding-left: 0;
              text-align: center;
            }
            .month {
              padding-left: 0;
              text-align: center;

              span {
                display: none;
              }
            }
          }
        }

        thead th:last-child {
          .year_month {
            overflow: hidden;
          }
        }

        tbody {
          @include min-screen($min-medium) {
            background: repeating-linear-gradient(
              to bottom,
              $empty 0,
              $empty 81px,
              darken($hr, 15%) 81px,
              $empty 82px,
              $empty 119px,
              darken($hr, 15%) 119px,
              $white 120px,
              $white 129px,
              darken($hr, 5%) 129px,
              $hr 130px
            );
          }
        }

        thead th.dummy-th {
          @include min-screen($min-medium) {
            width: 2000px;
          }
        }
        tbody td.dummy-td {
          @include min-screen($min-medium) {
            background: none;
            width: 2000px;

            .data {
              border-right: 0;
            }
          }
        }

        tbody th {
          background: $white;
          box-shadow: 0 1px 0 $hr inset;
          font-weight: normal;
          left: 0;
          padding: 0;
          position: sticky;
          position: -webkit-sticky;
          vertical-align: top;
          white-space: nowrap;
          z-index: 2;
        }

        td {
          background: $empty;
          cursor: grab;
          padding: 0;

          &:active,
          &:focus {
            cursor: grabbing;
          }
          &:after {
            background: $white;
            border-bottom: 1px solid $hr;
            content: ' ';
            display: block;
            height: 10px;
            width: 100%;
          }
        }
        td.spacing {
          background: $white;
          display: none !important;
          height: auto;

          div {
            border-bottom: 1px solid $hr;
            padding: 5px 0;
          }
        }

        tr:last-child {
          td,
          th {
            border-bottom: 0;
          }
        }
      }

      table.marketplace-table tbody > tr:last-child > td {
        &:after {
          border-bottom: 0;
        }
      }
    }
  }

  // main left column width
  .product-col-width {
    @include min-screen($min-small) {
      width: $product-col-width;
    }
  }

  // sorting (coming soon)
  .sort {
    background: #f9f9f9;
    border-bottom: 1px solid $hr;
    border-top: 1px solid $hr;
    border-right: 1px solid $hr;
    box-shadow: 2px 0 2px rgba(0, 0, 0, 0.1);
    height: 40px;
    padding: 5px 0 0 10px;
    position: relative;
    z-index: 3;

    @include min-screen($min-medium) {
      padding-left: 40px;
    }
    span {
      color: $all-black;
      @extend %bold;
      vertical-align: middle;
    }
    @include min-screen($min-small) {
      display: inline-block;
      vertical-align: top;
    }
    ul {
      display: inline-block;
      list-style: none;
      margin: 0 10px;
      padding: 0;
      vertical-align: middle;

      li {
        display: inline-block;

        a {
          @include pill($pill-color);
          background: lighten($pill-color, 5%);
          border: 0;
          color: $pill-reverse;
          height: 26px;
          line-height: 26px;
          margin: 0 2px 0 0;
          padding-right: 24px;
          position: relative;

          em {
            font-size: 0;
            font-style: normal;
            text-transform: none;
          }
          &:before,
          &:after {
            @extend %fontello;
            color: $white;
            content: '\f0dc';
            font-size: 0.8rem;
            margin: 0;
            opacity: 0.65;
            position: absolute;
            right: 5px;
            top: 5px;
          }
          &:after {
            content: '\f0de';
            opacity: 1;
          }
          &.reverse {
            &:after {
              content: '\f0dd';
              opacity: 1;
            }
          }
          &:hover {
            background: $pill-color;
            border: 0;
          }
          &.selected {
            background: darken($pill-color, 20%);
            border: 0;
          }
        }
      }
    }
  }
}

html .nui-modal {
  .offers-bids-details {
    .nui-tabs .nui-tabs-nav-wrap {
      margin-bottom: 10px;
      padding: 10.5px 0 0;

      .nui-tabs-tab {
        font-size: 1.2rem !important;
        padding: 6px 20px 21px 0;
      }
    }
  }
  td {
    .offers-bids-details {
      .nui-tabs .nui-tabs-nav-wrap {
        margin-bottom: 10px;

        .nui-tabs-tab {
          padding: 6px 14px 17px 0;
        }
      }
    }
  }
  // sticky position
  .offers-bids-details {
    left: 15px;
    margin-top: -10px;
    max-width: calc(100vw - 120px);
    position: sticky;
    position: -webkit-sticky;
    z-index: 10;

    @include min-screen($min-medium) {
      margin-top: -20px;
      max-width: calc(100vw - 100px);
    }
    .table-box-three {
      padding-left: 0;

      &:first-child {
        @include min-screen($min-medium) {
          margin-right: 20px;
          width: calc(48.5% - 20px);
        }
        @include min-screen($min-large) {
          margin-left: 20px;
          width: calc(45% - 40px);
        }
        @include min-screen($min-xlarge) {
          margin-right: 0;
          width: calc(45% - 20px);
        }
      }
      &:last-child {
        .box:last-child {
          @include min-screen($min-large) {
            padding-right: 0;
          }
        }
      }
    }

    .table-box-three {
      .unit-details {
        margin-top: 0;

        h2.details {
          border-bottom: 1px solid $lightest-gray;
          margin-bottom: 5px;
          padding-bottom: 15px;

          @include max-screen($max-small) {
            padding-top: 0;
          }
        }
      }
      ul.loading-details {
        li.stacked {
          strong,
          span {
            display: block;
            width: auto;
          }
        }
        li.auto {
          strong {
            min-width: 32%;
            width: auto;
          }
          span {
            width: auto;
          }
        }
        li.reversed {
          strong {
            white-space: nowrap;
            width: auto;
          }
          span {
            float: right;
            text-align: right;
            width: auto;
            word-break: break-word;
          }
        }
      }
      ul.personal-details li.reversed,
      ul.details li.reversed {
        span {
          max-width: 80%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    @include max-screen($max-small) {
      .table-box-three {
        max-width: calc(100vw - 45px);

        .box {
          max-width: 100%;
        }
      }
    }
  }
}

button.toggle {
  @include expand;
  background: $white;
  left: 15px;
  top: 10px;
  z-index: 2;
  display: none;
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);

  &.close-all {
    transform: rotate(0);
    -webkit-transform: rotate(0);
    &:before {
      left: 2px;
      top: 5px;
    }
  }

  @include max-screen($max-xsmall) {
    display: block;
  }
}

// market depth
// ============

// market depth table in modal
div.market-depth-productname-row {
  border-bottom: 1px solid $lightest-gray;
}

// market depth details section
.offers-bids-details {
  max-width: calc(100vw - 127px);

  .view-prices {
    background: none;
    border: 0;
    border-radius: 0;
    box-shadow: none !important;
    display: block;
    margin: 5px 0 0;
    padding: 0;
    text-align: left;
    width: 100%;

    @include min-screen($min-xlarge) {
      margin-bottom: 10px;
    }
    span {
      @extend %dd;
      color: var(--link);
      font-size: 0.8rem;
    }
    &:hover {
      span {
        @extend %nd;
        color: $all-black;
      }
    }
  }

  @include max-screen($max-small) {
    left: 12px;
    position: sticky;
    position: -webkit-sticky;
  }
  @include min-screen($min-large) {
    max-width: none;
  }

  .table-box,
  .table-box-three {
    padding: 10px;
    vertical-align: top;
    white-space: normal;

    @include max-screen($max-small) {
      max-width: calc(100vw - 120px);
    }

    .box {
      @include max-screen($max-small) {
        max-width: calc(100vw - 120px);
      }
      padding: 10px 0;
    }
    .button-set {
      .button {
        margin: 0 10px 10px 0;
      }
    }
    @include min-screen($min-medium) {
      display: inline-block;
      padding-right: 20px;
      width: 48.5%;
    }
    @include min-screen($min-large) {
      .box {
        display: inline-block;
        padding: 10px 20px 0 0;
        vertical-align: top;
        width: 48.5%;

        &:only-child {
          padding-right: 0;
          width: 75%;
        }
      }
    }
    h2 {
      @extend %light;
      border-bottom: 1px solid $lightest-gray;
      color: $light-gray;
      display: block;
      font-size: 1.1rem;
      margin: 0;
      padding: 5px 0 15px;
    }

    .comments {
      margin: 10px 0;
      .ant-alert {
        padding: 0;
      }
      .ant-alert-message {
        display: block;
        max-height: 100px;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 15px;
      }
      p {
        padding: 0;
      }
    }
    strong {
      color: $all-black;
      display: block;
    }
    .totals strong {
      display: inline-block;
      margin-right: 5px;
    }
    ul.loading-details,
    ul.personal-details,
    ul.details {
      list-style: none;
      margin: 0;
      padding: 0 0 10px;

      li {
        border-bottom: 1px solid $lightest-gray;
        padding: 5px 0;

        ul.legend-list li {
          border: 0;
        }
        &:last-child {
          border-bottom: 0;
        }
        strong,
        span {
          display: inline-block;
          position: relative;
          vertical-align: top;
          width: 32%;

          &.icon-ok:before {
            color: $ok;
          }
          &.icon-cancel:before {
            color: $cancel;
          }
          &.block {
            display: block;
            width: 100%;
          }
        }
        span {
          width: 66%;
        }
        ul.legend-list {
          display: inline-block;
          width: 66%;

          li {
            border: 0;
            display: inline-block;
            padding: 0;
            position: relative;
            vertical-align: top;
          }
        }
      }

      li.auto {
        strong {
          min-width: 32%;
          width: auto;
        }
        span {
          width: auto;
        }
      }

      ul.shipping-details {
        display: block;
        list-style: none;
        margin: 0;
        padding: 0;
        width: 100%;

        li {
          border: 0;
          padding: 0;

          span {
            display: block;
            width: 100%;
          }
        }
      }
    }

    ul.loading-details,
    ul.personal-details,
    ul.details {
      li.stacked {
        strong,
        span {
          display: block;
          width: auto;
        }
      }
      li.auto {
        strong {
          min-width: 32%;
          width: auto;
        }
        span {
          width: auto;
        }
      }
      li.reversed {
        &:after {
          @extend %clearfix;
        }
        strong {
          white-space: nowrap;
          width: auto;
        }
        span {
          float: right;
          text-align: right;
          width: auto;
        }
      }
    }
  }
  .table-box-three {
    .form-item-name-exportdocs {
      .ant-form-item-label {
        .ant-form-item-required {
          span.popover-link {
            position: absolute;
            right: 0;
          }
        }
      }
    }
    padding-right: 0;

    .box {
      &:only-child {
        padding-right: 0;
        width: 100%;
      }
    }
    .trade-options {
      .currency-exchange {
        display: block;
        padding-left: 0;
        padding-top: 6px;
        width: 100%;

        strong {
          display: inline-block;
          margin-right: 5px;
        }
        span.label,
        strong {
          font-size: 14px;
        }
      }
    }
    @include min-screen($min-medium) {
      .trade-options {
        vertical-align: top;
      }
    }
    @include min-screen($min-large) {
      &:first-child {
        width: 45%;
      }
      &:last-child {
        width: 55%;
      }
    }
    .unit-details {
      h2.details {
        border-bottom: 0;
        padding-bottom: 0;
        padding-top: 15px;
      }
      @include min-screen($min-medium) {
        vertical-align: top;
      }
    }
    @include min-screen($min-xlarge) {
      .unit-details {
        display: inline-block;
        margin-right: -1px;
        width: 30%;
      }
      .trade-options {
        display: inline-block;
        margin-right: 20px;
        padding-left: 20px;
        width: calc(70% - 20px);
      }
    }

    @include min-screen($min-xxlarge) {
      .unit-details {
        width: 30%;
      }
      .trade-options {
        width: calc(70% - 40px);
      }
    }
  }
}

// price for warehouse
.warehouse-price {
  background: $alert-bg;
  border: 1px solid $alert-color;
  border-radius: 3px;
  display: inline-block;
  vertical-align: top;
  margin-right: 2%;
  padding: 0 11px;
  width: 49%;
}

h3.market-depth-productname {
  margin: 0;
  padding: 10px 0;

  span {
    @extend %light;
    color: $mid-gray;
    font-size: 1rem;
  }
}

.ant-modal,
.nui-modal {
  .generic-table {
    &.market-depth {
      .nui-form {
        .ant-form-item {
          padding: 0 0 10px;
        }
      }

      .ant-table-content,
      .nui-table-content {
        .ant-table-body,
        .nui-table-body {
          table {
            button.button,
            button.nui-button {
              line-height: 1.2rem;
              min-height: 1px;
              padding: 6px 24px;
            }
            ul.legend-list {
              li {
                padding: 0;
              }
            }

            span.openordertype {
              white-space: nowrap;

              &.bid {
                color: $bid;
              }
              &.offer {
                color: $offer;
              }
            }

            span.openordertypedetails {
              color: $light-gray;
              display: inline-block;
              font-size: 0.7rem;
              text-transform: uppercase;
            }

            thead {
              th {
                border-right: 0;
              }
            }
            tbody {
              tr td {
                background: $white;
                border-color: $hr;
                border-right: 0;
              }
              tr:hover {
                td {
                  background-color: $body;
                }
              }
              tr.selected,
              tr.selected:hover {
                td {
                  background: $focus;
                }
              }
              tr.ant-table-expanded-row,
              tr.ant-table-expanded-row:hover,
              tr.nui-table-expanded-row,
              tr.nui-table-expanded-row:hover {
                td {
                  background: #f8f6f4 !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

// bid message
.full-page-content
  .generic-table
  .nui-table-content
  .nui-table-body
  table
  tbody
  tr
  td,
.offers-bids-details .unit-details {
  .bid-message {
    margin: 20px 0 10px;
    max-width: 100%;

    @include min-screen($min-medium) {
      margin: 20px 0 10px;
    }
    .button,
    button.button,
    .nui-button,
    button.nui-button {
      @extend %regular;
      border-radius: 3px;
      font-size: 0.6rem;
      display: inline-block;
      height: 24px;
      line-height: 23px;
      margin: 0 5px 5px 0;
      padding: 0 10px !important;
      text-transform: uppercase;
      vertical-align: top;

      &:last-child {
        margin-right: 0;
      }
    }
    p.desc {
      border-top: 1px dashed $lightest-gray;
      font-size: 0.7rem;
      line-height: 1rem;
      padding-bottom: 10px;
      padding-right: 5px;
      padding-top: 10px;
    }
    a.place-bid,
    a.place-offer {
      @extend %nd;
      background: $white;
      border: 1px solid $lightest-gray;
      border-radius: 3px;
      color: $bid;
      display: inline-block;
      font-size: 0.6rem;
      height: 24px;
      line-height: 24px;
      margin: 0 5px 0 0;
      padding: 0 10px;
      text-align: center;
      text-transform: uppercase;
      transition: 0.2s all linear;

      &:hover {
        background: $bid;
        border-color: $bid;
        color: $white;
      }
    }

    a.place-offer {
      color: $offer;

      &:hover {
        background: $offer;
        border-color: $offer;
      }
    }
  }
}

// unit details in market depth
.unit-detail {
  @extend %bold;
  color: $all-black;
  display: inline-block;
  font-size: 1.25rem;
  line-height: 1.25rem;
  padding: 5px 20px 5px 0;
  vertical-align: top;

  @include min-screen($min-xlarge) {
    display: block;
  }
  h4 {
    @extend %regular;
    color: var(--highlight);
    font-size: 0.8rem;
    margin: 0;
  }
  span {
    @extend %regular;
    color: $light-gray;
    display: inline-block;
    font-size: 0.7rem;
    position: relative;
    top: -2px;
  }
}

// available currencies
.unit-detail {
  &.available-currencies {
    h4 {
      @extend %bold;
      color: $all-black;
      padding-bottom: 5px;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        margin: 0 5px 0 0;
        vertical-align: top;

        button,
        button:active,
        button:focus {
          @extend %regular;
          background: $white;
          border: 1px solid $hr;
          border-radius: 3px;
          box-shadow: none;
          color: var(--link);
          font-size: 0.7rem;
          line-height: 1;
          margin: 0;
          padding: 4px 6px;
          text-transform: uppercase;

          &.active,
          &:hover {
            background: var(--link);
            border-color: var(--link);
            box-shadow: none;
            color: $white;
          }
        }
      }
    }
  }
}

.form-item-name-currency {
  display: inline-block;
  vertical-align: bottom;
  width: 49%;
}
.currency-exchange {
  display: inline-block;
  padding-bottom: 12px;
  padding-left: 10px;
  position: relative;
  top: -1px;
  vertical-align: bottom;
  width: 49%;

  span {
    display: block;
    font-size: 0.8rem;
  }
  strong {
    color: $all-black;
    font-size: 0.8rem;
  }
}

.ant-form-item + .ant-form-item + .currency-exchange {
  padding-left: 0;
}

// show prices in drawer
.ant-table-content,
.nui-table-content {
  .ant-table-body,
  .nui-table-body {
    table {
      .ant-drawer-left.ant-drawer-open,
      .ant-drawer-right.ant-drawer-open {
        &.price-drawer {
          height: calc(100% + 20px);
          left: 0;
          max-width: calc(100vw - 62px);
          overflow: hidden;
          top: 0;
          width: calc(100% + 20px);

          .ant-drawer-wrapper-body {
            @include max-screen($max-small) {
              overflow: hidden;
            }
          }

          .ant-drawer-content-wrapper {
            max-width: 100%;
            width: 100% !important;
            position: relative;

            @include min-screen($min-medium) {
              max-width: 600px;
            }
          }

          h2.light {
            font-size: 1.25rem;
            margin-bottom: 12px;
            padding-left: 10px;
            padding-right: 10px;
          }

          .unit-detail {
            @include min-screen($min-medium) {
              background: $white;
              border-bottom: 1px solid $hr;
              margin-bottom: 10px;
              padding: 10px 0;
              position: sticky;
              position: -webkit-sticky;
              top: 0;
              width: 100%;
              z-index: 3;
            }
          }

          .ant-drawer-header {
            border: 0;
            padding: 0;

            .ant-drawer-title {
              display: none;
            }
            .anticon.anticon-close {
              @extend %regular;
              outline: none;
              position: absolute;
              right: 20px;
              top: 30px;
              transform: rotate(45deg);
              -webkit-transform: rotate(45deg);
              text-align: center;
              z-index: 2;

              &:after {
                color: $light-gray;
                content: '+';
                display: block;
                font-size: 2rem;
              }
              i.anticon,
              svg {
                display: none;
              }
              transform: rotate(45deg);
              -webkit-transform: rotate(45deg);
            }
          }
          .ant-drawer-body {
            color: $mid-gray;
            padding: 10px;

            .unit-detail {
              padding-bottom: 10px;
            }
          }
        }
      }
    }
  }
}

.market-depth.expand-table {
  overflow: hidden;

  tr,
  tr:hover {
    table.table.generic-table.customer-prices {
      border: none;
      max-width: calc(100% - 20px);
      position: relative;

      @include min-screen($min-medium) {
        max-width: 100%;
      }
      th {
        @extend %bold;
        background: none;
        border-bottom: 1px solid $hr;
        border-top: 1px solid $hr;
        color: $all-black;
        padding: 10px;
      }
      tbody {
        tr,
        tr:hover {
          td {
            background: none;
            border: 0 !important;
            padding: 5px 10px;

            span.tag {
              color: #aaa;
              color: $warning;
              font-size: 0.75rem;
              text-transform: uppercase;
            }
            &.col-desc {
              width: 200px;
            }
          }
        }
      }
    }
  }
}

body.mp.mpfull {
  .help-section {
    @include min-screen($min-xlarge) {
      right: -75px;
    }
  }
  .config-links > div > button {
    @include max-screen($max-large) {
      display: none;
    }
  }
}

// table in weeks or months, display volume
.view-options {
  padding: 0 20px 10px;
  white-space: nowrap;

  @include max-screen($max-small) {
    padding: 0;
    position: absolute;
    right: 20px;
    top: 15px;
    z-index: 10001;
  }
  @include min-screen($min-medium) {
    padding: 0 40px 15px;
  }
  @include min-screen($min-large) {
    position: absolute;
    right: 0;
    top: 17px;
    z-index: 13000;
  }

  // weeks, months
  .table-display-options {
    display: inline-block;
    margin-right: 10px;
    vertical-align: top;
    white-space: nowrap;

    span.label {
      @extend %bold;
      color: $all-black;
      display: none;
      font-size: 0.8rem;

      @include min-screen($min-xsmall) {
        display: inline-block;
        padding-right: 10px;
      }
    }
    button.nui-button {
      @extend %bold;
      background: $white;
      border: 1px solid $lightest-gray;
      border-radius: 0;
      color: $all-black;
      display: inline-block;
      font-size: 0.75rem;
      height: 32px;
      margin-left: -1px;
      padding: 5px 10px;
      position: relative;
      z-index: 2;

      @include max-screen($max-small) {
        font-size: 0;
        &:first-letter {
          font-size: 0.75rem;
        }
      }

      &:first-child {
        border-radius: 3px 0 0 3px;
      }

      &:last-child {
        border-radius: 0 3px 3px 0;
      }
      &.active {
        background: var(--link);
        border-color: var(--link);
        color: $white;
        pointer-events: none;
        z-index: 2;
      }
    }
  }

  // display volume in marketplace
  .toggle-volume {
    background: $white;
    border: 1px solid #ccc;
    border-radius: 3px;
    display: inline-block;
    height: 32px;
    vertical-align: top;
    width: 99px;
    z-index: 2;

    @include max-screen($max-small) {
      width: 66px;
    }
    .fieldset {
      margin: 2px 0 10px 5px;
      padding: 3px;

      .nui-check-fieldset {
        border: 0;
        margin: -3px 0 0 0;
        padding: 0 5px 0 0;
        position: relative;
      }
      label {
        @extend %bold;
        color: $mid-gray;
        display: inline-block;
        font-size: 0.75rem;
        user-select: none;
        vertical-align: middle;

        span.ant-checkbox {
          top: 0;
        }
      }
    }
  }
}

// new scrolling in divs

.favourites-container {
  margin-top: 10px;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 3001;

  @include min-screen($min-medium) {
    top: 78px;
  }
  .favourites {
    left: 10px;
    margin-top: 10px;
    position: absolute;

    @include min-screen($min-medium) {
      left: 40px;
    }
  }
}

.thead-container {
  height: 100px;
  border-top: 0;
  overflow: hidden;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 3000;

  @include min-screen($min-medium) {
    top: 78px;
  }

  // gradient bg
  background: repeating-linear-gradient(
    to bottom,
    $white 0,
    $white 60px,
    $hr 60px,
    $hr 61px,
    #f9f9f9 61px,
    #f9f9f9 100px
  );
  box-shadow: 0 1px 0 $hr inset, 0 -1px 0 $hr inset;
}

.tbody-container {
  background: repeating-linear-gradient(
    to bottom,
    $empty 0,
    $empty 81px,
    darken($hr, 15%) 81px,
    $empty 82px,
    $empty 119px,
    darken($hr, 15%) 119px,
    $white 120px,
    $white 129px,
    darken($hr, 5%) 129px,
    $hr 130px
  );
  box-shadow: 0 -1px 0 $white inset;
  overflow-x: auto;
}

// marketplace mobile /  smaller left column
@include max-screen($max-xsmall) {
  .marketplace {
    &.timeline {
      .time-content {
        table.marketplace-table thead tr th:nth-child(1),
        table.marketplace-table tbody tr th:nth-child(1),
        table.marketplace-table tbody tr td:nth-child(1),
        table.marketplace-table thead tr th .filter-container,
        .product.product-col-width {
          min-width: 77px;
          width: 77px !important;
        }
      }
      .sort.product-col-width {
        width: 77px;
      }
      .sort {
        ul {
          left: 67px;
        }
        &:after {
          right: 5px;
        }
      }
    }
  }

  .marketplace.timeline {
    .time-content {
      table.marketplace-table {
        .product,
        .m-product {
          .totals {
            .current .total-bids,
            .current .total-offers,
            .last {
              span {
                font-size: 0;

                &:first-letter {
                  font-size: 0.8rem;
                }
              }
            }
          }
        }
      }
    }
  }
}

// filter summary
div.filter-summary {
  h2 {
    color: $all-black;
    font-size: 0.9rem;
    line-height: 1.25em;
    margin: 3px 0 2px;
    padding: 0;

    span.is-filtered {
      @extend %regular;
      color: $light-gray;
      display: inline-block;
      font-size: 0.7rem;

      &:before {
        @extend %fontello;
        color: $ok;
        content: '\e847';
        font-size: 0.9rem;
      }
    }
  }
  h3,
  h3 > span {
    @extend %regular;
    color: $light-gray;
    display: block;
    font-size: 0.7rem !important;
    line-height: 1.25em;
    margin: 0;
    padding: 0;
    white-space: nowrap;

    button {
      @extend %dd;
      background: none;
      border: 0;
      color: var(--link);
      display: inline-block;
      font-size: 0.7rem;
      line-height: 1.25em;
      padding: 0;
      position: relative;
      top: -1.5px;
      vertical-align: middle;

      &:hover {
        @extend %nd;
        color: $all-black;
      }
    }
  }
  h3 > span {
    display: inline-block;
    vertical-align: middle;
  }
}

// customer prices
.customer-prices-form {
  h2 {
    margin-top: -25px;
    margin-bottom: 15px;
    padding: 10px 20px 0;

    @include min-screen($min-medium) {
      padding: 10px 40px 0;
    }
  }
  .wrapper {
    background: #eee;
    padding: 10px 20px 0;

    @include min-screen($min-medium) {
      padding: 10px 40px 0;
    }
  }
  .ant-form-item-control {
    max-width: 300px;
    @include min-screen($min-medium) {
      max-width: none;
    }
  }
  .ant-form-item {
    .ant-form-item-label label span.normal {
      display: inline-block;
    }
    @include min-screen($min-medium) {
      display: inline-block;
      padding-right: 20px;
      vertical-align: top;
      width: 33.3%;

      .ant-input-number {
        width: 100%;
      }
    }
  }
}

// in modal
.nui-modal {
  .customer-prices-form {
    h2 {
      border-bottom: 1px solid $hr;
      margin-bottom: 0;
      margin-top: -15px;
      padding-bottom: 15px;
      padding-left: 0;
      padding-right: 0;
    }
    .wrapper {
      background: none;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

html .nui-modal.full {
  .customer-prices-form {
    .ant-form {
      padding: 0;
    }
  }
}

// show in days
.marketplace {
  &.show-in-days {
    .empty,
    .no-trades {
      min-width: 50px;
      width: 50px;

      div {
        min-width: 50px;
      }
    }
    thead th:not(:first-child) {
      min-width: 50px !important;

      div {
        min-width: 50px !important;
        padding-left: 5px !important;
      }
    }
    thead {
      th.showing-week {
        position: relative;
        z-index: 4 !important;

        &:before {
          border-left: 1px dashed $lightest-gray;
          bottom: 40px;
          content: ' ';
          display: block;
          height: 42px;
          left: -1px;
          position: absolute;
          width: 1px;
          z-index: 4;
        }
        div {
          > strong,
          > span {
            left: 8px !important;
          }
        }
      }
    }
  }

  &.show-in-weeks {
    thead th:not(:first-child) {
      div {
        padding-left: 13px !important;
      }
    }
  }

  &.show-in-days,
  &.show-in-weeks,
  &.show-in-months {
    thead {
      th + th {
        div:last-child {
          font-size: 0.7rem !important;
        }
      }
      th.showing-month {
        z-index: 4 !important;
      }

      th.showing-month {
        span.show-if-last {
          display: none;
        }
      }
      th:last-child {
        span.show-month {
          display: none;
        }
        span.show-if-last {
          display: block;
        }
      }
    }
  }
}

// offers and bids
.marketplace {
  .current,
  .last {
    position: relative;

    a,
    span.monthly-bids,
    span.monthly-offers {
      @extend %nd;
      border-radius: 3px;
      color: $white;
      display: block;
      font-size: 0.8rem;
      height: 24px;
      line-height: 24px;
      margin: 0;
      padding-right: 10px;
      position: absolute;
      right: 5px;
      text-align: right;
      top: 5px;
      width: calc(100% - 10px - 29px);
    }
  }
  .current {
    border-bottom: 1px solid $hr;
    height: 82px;
    vertical-align: top;

    a.bid,
    a.open-bid,
    a.own-bid,
    .monthly-bids {
      background: $bid;
      top: 5px;

      &:active,
      &:focus,
      &:hover {
        background: darken($bid, 10%);
        box-shadow: none;
      }
    }
    a.type.bid,
    a.type.offer {
      &:before {
        color: $white;
        content: 'B';
        display: block;
        left: 7px;
        opacity: 0.5;
        position: absolute;
      }
      &:hover {
        &:before {
          opacity: 0.75;
        }
      }

      span.broker {
        background: #f4f6fd;
        border-radius: 50%;
        content: ' ';
        cursor: help;
        display: block;
        height: 16px;
        position: absolute;
        right: -8px;
        top: -6px;
        transition: 0.2s all linear;
        width: 16px;
        z-index: 3;

        &:before {
          background: $white;
          border-radius: 50%;
          border: 1px solid #0d8abc;
          content: ' ';
          cursor: help;
          display: block;
          height: 12px;
          position: absolute;
          right: 2px;
          top: 2px;
          transition: 0.2s all linear;
          width: 12px;
          z-index: 2;
        }
        &:after {
          background: #0d8abc;
          border-radius: 50%;
          content: ' ';
          cursor: help;
          display: block;
          height: 6px;
          position: absolute;
          right: 5px;
          top: 5px;
          transition: 0.2s all linear;
          width: 6px;
          z-index: 3;
        }
      }

      &:hover {
        span.broker {
          transform: scale(1.3);
          -webkit-transform: scale(1.3);
        }
      }
    }

    a.type.offer {
      &:before {
        content: 'O';
      }
    }

    a.type.offer:not(:only-child),
    a.bid:not(:only-child) {
      &:before {
        display: none;
      }
    }

    a.type.bid,
    a.type.open-bid,
    a.type.own-bid,
    span.monthly-bids {
      background: $bid;
      border-radius: 100px;

      &:active,
      &:focus,
      &:hover {
        background: darken($bid, 10%);
        box-shadow: none;
      }
    }

    a.type.offer,
    a.type.open-offer,
    a.type.own-offer,
    span.monthly-offers {
      background: $offer;
      border-radius: 100px;
      top: 34px;

      &:active,
      &:focus,
      &:hover {
        background: darken($offer, 10%);
        box-shadow: none;
      }
    }
    .monthly-bids,
    .monthly-offers {
      box-shadow: none;
    }

    a.bid.past,
    a.bid.past:active,
    a.bid.past:focus,
    a.bid.past:hover {
      background: none;
      border: 1px solid $lightest-gray;
      box-shadow: none;
      color: $bid;

      &:hover {
        border: 1px solid $light-gray;
      }
    }
    a.offer.past,
    a.offer.past:active,
    a.offer.past:focus,
    a.offer.past:hover {
      background: none;
      border: 1px solid $lightest-gray;
      box-shadow: none;
      color: $offer;

      &:hover {
        border: 1px solid $light-gray;
      }
    }

    a.bid.ghost,
    a.bid.ghost:active,
    a.bid.ghost:focus,
    a.bid.ghost:hover,
    a.offer.ghost,
    a.offer.ghost:active,
    a.offer.ghost:focus,
    a.offer.ghost:hover {
      @extend %bold;
      background: $white;
      border: 1px solid $lightest-gray;
      color: $lightest-gray;
      font-size: 1.3rem;
      line-height: 23px;
      opacity: 0;
      padding-left: 0;
      padding-right: 0;
      text-align: center;
      transition: 0.2s all linear;

      &:before {
        display: none;
      }
      &:hover {
        background: $bid;
        border: 1px solid $bid;
        box-shadow: none;
        color: $white;
        opacity: 0.5;
      }
      &:active,
      &:focus {
        background: darken($bid, 10%);
        border: 1px solid darken($bid, 10%);
        opacity: 1;
      }
    }

    a.offer.ghost,
    a.offer.ghost:active,
    a.offer.ghost:focus,
    a.offer.ghost:hover {
      top: 34px;

      &:hover {
        background: $offer;
        border: 1px solid $offer;
        color: $white;
        opacity: 0.5;
      }

      &:active,
      &:focus {
        background: darken($offer, 10%);
        border: 1px solid darken($offer, 10%);
        opacity: 1;
      }
    }

    span.own-offer,
    span.own-bid {
      &:after {
        background: $highlight;
        border-radius: 50%;
        border: 2px solid $white;
        content: ' ';
        cursor: help;
        display: none;
        height: 10px;
        position: absolute;
        right: -3px;
        top: -3px;
        transition: 0.2s all linear;
        width: 10px;
        z-index: 1;
      }
    }

    a.own-offer,
    a.own-bid {
      span.own-offer,
      span.own-bid {
        &:after {
          display: block;
        }
      }
      &:hover {
        span.own-offer,
        span.own-bid {
          &:after {
            transform: scale(1.5, 1.5);
            -webkit-transform: scale(1.5, 1.5);
          }
        }
      }
    }
  }

  // open orders
  .current {
    a.open-offer,
    a.open-bid {
      font-size: 0.6rem;
      @extend %bold;
      padding-left: 10px;
      padding-right: 10px;
      text-align: right;
      text-transform: uppercase;

      &:before {
        @extend %fontello;
        border: 1px solid transparent;
        background: $white;
        border-radius: 50%;
        content: ' ';
        display: block;
        height: 8px;
        left: 6px;
        position: absolute;
        top: 8px;
        transition: 0.2s all ease-in;
        width: 8px;
        z-index: 1;
      }
      &:after {
        border: 1px solid $white;
        border-radius: 50%;
        content: ' ';
        display: block;
        height: 8px;
        left: 8px;
        position: absolute;
        top: 8px;
        transition: 0.2s all ease-out;
        width: 8px;
        z-index: 1;
      }
      &:hover {
        &:before {
          color: $white;
          opacity: 0.5;
          transform: scale(0, 0);
          -webkit-transform: scale(0, 0);
          transition: transform 0.8s ease;
          -webkit-transition: -webkit-transform 0.8s ease;
        }
        &:after {
          opacity: 0.75;
          transform: scale(1.5, 1.5);
          -webkit-transform: scale(1.5, 1.5);
          transition: transform 0.8s ease;
          -webkit-transition: -webkit-transform 0.8s ease;
        }
      }
    }

    // size and position
    &.has-open-offer {
      .offer {
        width: calc(100% - 68px);
      }
      .open-offer {
        left: 34px;
        right: auto;
        transition: 0.2s all ease-out;

        span:not(.own-offer) {
          transition: none;
        }
        &:hover {
          width: calc(100% - 39px);
          z-index: 3;
        }
      }
      .offer + .open-offer {
        font-size: 0;
        left: 34px;
        padding: 0;
        right: auto;
        width: 24px;

        span:not(.own-offer) {
          transition: none;
        }
        &:before {
          font-size: 0.5rem;
        }
        &:hover {
          font-size: 0.6rem;
          padding-left: 10px;
          padding-right: 10px;
          width: calc(100% - 39px);
          z-index: 3;

          &:before {
            font-size: 0.5rem;
          }
        }
      }
    }
    &.has-open-bid {
      .bid {
        width: calc(100% - 68px);
      }
      .open-bid {
        left: 34px;
        right: auto;
        transition: 0.2s all ease-out;

        span:not(.own-bid) {
          transition: none;
        }
        &:hover {
          width: calc(100% - 39px);
          z-index: 3;
        }
      }
      .bid + .open-bid {
        font-size: 0;
        left: 34px;
        padding: 0;
        right: auto;
        width: 24px;

        span:not(.own-bid) {
          transition: none;
        }
        &:before {
          font-size: 0.5rem;
        }
        &:hover {
          font-size: 0.6rem;
          padding-left: 10px;
          padding-right: 10px;
          width: calc(100% - 39px);
          z-index: 3;

          &:before {
            font-size: 0.5rem;
          }
        }
      }
    }
  }
}

// monthly totals
.marketplace {
  .time-content {
    table.marketplace-table {
      .current {
        .monthly-bids,
        .monthly-offers {
          background: $white;
          color: $mid-gray;
          cursor: default;
          @extend %bold;
          left: 5px;
          width: 24px;

          &:hover {
            background: $white;
            color: $all-black;
          }
        }
      }

      td.no-trades {
        .current {
          a.bid.ghost,
          a.bid.ghost:active,
          a.bid.ghost:focus,
          a.bid.ghost:hover,
          a.offer.ghost,
          a.offer.ghost:active,
          a.offer.ghost:focus,
          a.offer.ghost:hover {
            left: 5px;
            width: calc(100% - 10px);
          }
        }
      }
    }
  }
}

.marketplace.timeline2 .time-content {
  border-bottom: 1px solid $hr;
  overflow-x: auto;
  width: 100vw;
}

.marketplace.timeline .time-content {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @include max-screen($max-small) {
    overflow-x: auto;

    table thead th {
      top: 0;
    }
  }
}

// marketplace mobile / tablet
.marketplace.timeline {
  // tablet
  @include max-screen($max-small) {
    .product-col-width {
      width: 320px;
    }
    .time-content table.marketplace-table .product {
      padding: 0;
    }
    .time-content {
      .scroll-container {
        thead {
          th:nth-child(1) {
            width: 320px;
          }
        }
        tbody {
          tr {
            td:nth-child(1) {
              width: 320px;
            }
          }
        }
      }
      table.marketplace-table {
        .product {
          a.place-bid,
          a.place-offer {
            display: none;
          }
          .brand {
            img {
              display: none;
            }
          }
        }
      }
    }
  }

  // mobile
  @include max-screen($max-xsmall) {
    .scroll-container .filter-container {
      background: #f9f9f9;
      margin: 0;

      .product-selector,
      .filter {
        display: none;
      }
    }

    .filter-container {
      &.m-filter-container {
        margin: 0;
        padding: 10px;
        position: relative;
        z-index: 10000;

        .filter {
          position: relative;
          right: auto;
          top: auto;
          width: 170px;

          a.open-panel {
            font-size: inherit;
            &:before {
              font-size: 0;
            }
          }
        }
      }
    }

    .sort {
      padding: 0;
      position: relative;
      width: 77px;

      span {
        cursor: pointer;
        display: block;
        padding: 7px 0 0 12px;
        height: 100%;
      }
      &:after {
        color: $lightest-gray;
        content: '\f141';
        display: none; // for now, until we add sorting!
        font-family: 'fontello';
        font-size: 1.25rem;
        line-height: 40px;
        pointer-events: none;
        position: absolute;
        right: 10px;
        top: 0;
        width: 1em;
      }
      ul {
        background: $white;
        left: 120px;
        overflow: hidden;
        padding: 6px 0;
        position: absolute;
        top: 0;
        transition: all 0.8s ease;
        width: 0;
        z-index: 2;

        li {
          a {
            font-size: 0.65rem;
            em {
              font-size: 0;
            }
          }
          &.close-sorting {
            span {
              background: $white;
              border: 1px solid $lightest-gray;
              border-radius: 3px;
              color: $light-gray;
              display: inline-block;
              font-size: 0;
              height: 26px;
              line-height: 26px;
              padding: 0 5px;
              position: relative;

              &:before {
                font-size: 1rem;
                height: 26px;
                line-height: 23px;
              }
            }
          }
        }
      }
      &.open {
        ul {
          padding: 6px;
          width: $max-xsmall;
        }
      }
    }
    .time-content {
      .scroll-container {
        thead {
          th:nth-child(1) {
            width: 77px;
          }
        }
        tbody {
          tr {
            td {
              position: relative;
            }
            td:nth-child(1) {
              width: 77px;
            }
          }
        }
      }
      table.marketplace-table {
        .product {
          &.product-col-width {
            width: 77px;
          }
          .product-info {
            display: none;
          }
        }
      }
    }

    // product info on separate row
    .time-content {
      table.marketplace-table {
        tr.xsmall {
          display: table-row;
          max-width: 100vw;
          width: 100vw;

          td {
            background: $white;
            display: block;
            height: 100px;
          }
          .m-product {
            background: #f9f9f9;
            border-bottom: 1px solid $hr;
            border-top: 1px solid $hr;
            box-shadow: none;
            height: auto;
            left: 0;
            padding: 10px 10px 10px 40px;
            position: absolute;
            white-space: nowrap;
            width: 100vw;
            z-index: 1;

            .product-info {
              display: block;
              padding: 0 0 0 10px;
              width: calc(100vw - 50px);
            }
            h2 {
              max-width: none;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            p.division,
            p.unit {
              display: inline-block;
              padding: 0 5px 0 0;
              vertical-align: middle;
            }
            .totals {
              border: 0;
              display: none;
              float: none;
              padding-left: 10px;

              .current,
              .last,
              .total-bids,
              .total-offers,
              span {
                border: 0;
                display: inline-block;
                height: auto;
                left: auto;
                position: relative;
                text-align: left;
                top: auto;
                width: auto;
              }
            }
          }
        }

        // and toggle the table data
        tr.xsmall {
          td {
            display: block;
            height: 65px;
            left: 0 !important;
            position: -webkit-sticky;
            position: sticky;
            z-index: 2;

            &.open {
              height: 40px;
            }
          }
          .m-product {
            button.toggle {
              @include expand;
              background: $white;
              left: 15px;
              top: 13px;

              &.open {
                transform: rotate(-90deg);
                -webkit-transform: rotate(-90deg);
                &:before {
                  left: 2px;
                  top: 5px;
                }
              }
            }
          }
          &.min {
            td {
              background: #f2f2f2;
              height: 45px;
            }
            .m-product {
              background: #f2f2f2;
              height: 45px;

              p.division,
              p.unit,
              ul.legend-list {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

// show volume as well
.marketplace {
  .current .bid,
  .current .offer,
  .last a {
    &:not(.open-bid),
    &:not(.monthly-bids),
    &:not(.weekly-bids),
    &:not(.open-offer),
    &:not(.monthly-offer),
    &:not(.weekly-offers) {
      &.show-volume {
        width: calc(100% - 10px);
        z-index: 1;

        &:before {
          display: block;
        }
        span.vol {
          display: inline;
        }
        & + .open-bid,
        & + .open-offer {
          opacity: 0;
          pointer-events: none;
        }
      }
    }
  }
}
