// favourites (marketplace)
.market {
  h1 {
    border-bottom: 1px solid $hr;
    margin: 0 0 10px;
    padding-bottom: 10px;

    @include min-screen($min-medium) {
      border: 0;
      display: inline-block;
      margin: -5px 5px 5px;
      padding: 0;
    }
  }
  .h1-tabs {
    @include min-screen($min-medium) {
      margin: -10px 0 -10px;
    }
    h1 {
      padding-bottom: 0;
    }
  }
}

.product-selector {
  display: inline-block;
  margin: 1px 10px 0 0;
  position: relative;
  vertical-align: top;
  width: auto;
  z-index: 11;

  @include min-screen($min-medium) {
    margin: 2px 0 0 15px;
  }

  button.open-panel {
    @include inputbox;
    @extend %nd;
    color: var(--link);
    font-size: 0.8rem;
    min-height: 1px;
    padding: 5px 20px;
    position: relative;
    transition: none;
    width: 120px;
    z-index: 11;

    span.arrow {
      @include arrow(14, 8);
      border-top: 6px solid rgba(0, 0, 0, 0.25);
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      display: inline-block;

      &:after {
        display: none;
      }
    }

    span.show-if-filtered,
    span.show-if-filtered:hover {
      @extend %regular;
      border: 0;
      color: $light-gray;
      display: none;
      font-size: 0.7rem;
      left: -8px;
      margin: 0;
      padding: 0;
      position: relative;

      &:before {
        @extend %fontello;
        color: $ok;
        content: '\e847';
        font-size: 0.9rem;
        margin: -3px 0 0 -2px;
        vertical-align: middle;
      }
    }
    &.open {
      span {
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
      }
    }
  }

  .panel {
    @include inputbox;
    border-radius: 3px;
    height: 0;
    left: 0;
    margin-top: -1px;
    min-height: 0;
    opacity: 0;
    overflow: hidden;
    padding: 44px 0 0;
    position: absolute;
    top: 0;
    width: auto;
    z-index: 3;

    > fieldset.parent-all {
      transition: none;
      .check-fieldset.all,
      .check-fieldset.parent {
        > .nui-check-fieldset label.nui-label {
          @extend %bold;
          color: $all-black;
        }
      }
    }
  }
}

// is filtered
.product-selector {
  &.is-filtered {
    button.open-panel span.show-if-filtered {
      display: inline-block;
    }
  }
}

// opened
.product-selector {
  &.opened {
    .filter-message {
      border-bottom: 1px solid $hr;
      border-top: 1px solid $hr;
      font-size: 0.7rem;
      margin: -10px 0 9px;
      padding: 10px 0;

      p {
        line-height: 1rem;
        padding: 0 0 0 20px;
        position: relative;

        &:before {
          color: $lightest-gray;
          font-size: 1rem;
          left: 0;
          margin: 0;
          position: absolute;
          top: 0;
        }
      }
    }
    button.open-panel {
      @extend %bold;
      background: none;
      border: 0;
      border-radius: 0;
      box-shadow: none;
      color: $all-black;
      position: relative;
      top: 2px;
      width: auto;

      span.arrow {
        left: calc(100vw - 72px);
        right: auto;
        @include min-screen($min-medium) {
          left: 395px;
        }
      }
    }
    .panel {
      box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.15);
      height: auto;
      max-width: calc(100vw - 50px);
      opacity: 1;
      width: calc(100vw - 50px);

      @include min-screen($min-medium) {
        width: 420px;
      }
      > fieldset.parent-all {
        border-bottom: 1px solid $hr;
        margin: -9px 0 10px;
        max-height: calc(100vh - 455px);
        overflow: hidden;
        overflow-y: auto;
        padding: 0 10px;

        @include min-screen($min-medium) {
          max-height: calc(100vh - 340px);
        }
      }
      .fieldset.all,
      .fieldset.parent {
        border-top: 1px solid $hr;
        margin: 0;
        padding: 5px 0 0;

        span {
          color: $all-black;
          @extend %bold;
        }
      }
      .fieldset.parent {
        > .nui-check-fieldset {
          margin: 0;
        }
      }
      .fieldset:nth-child(2) {
        border-top: 0;
      }
      .fieldset.child {
        padding: 10px 10px 10px 25px;
        position: relative;

        &:before {
          border-left: 1px solid $hr;
          content: ' ';
          display: block;
          height: calc(100% - 28px);
          left: 8px;
          position: absolute;
          top: 0;
          width: 1px;
          z-index: 2;
        }
        span {
          color: $light-gray;
          @extend %regular;

          &:hover {
            color: $all-black;
          }
        }
        .check-fieldset {
          position: relative;

          &:before {
            border-left: 0;
            border-top: 1px solid $hr;
            content: ' ';
            display: block;
            height: 1px;
            left: -17px;
            position: absolute;
            top: 10px;
            width: 12px;
            z-index: 2;
          }
        }
      }
    }
  }
}

.dev-mode {
  .product-selector {
    &.opened {
      .panel {
        > fieldset.parent-all {
          max-height: calc(100vh - 480px);

          @include min-screen($min-medium) {
            max-height: calc(100vh - 365px);
          }
        }
      }
    }
  }
}

// checkboxes in product selector
.product-selector {
  .nui-check-fieldset {
    margin-bottom: 5px;

    label.nui-label {
      font-size: 0.8rem;
    }
  }
}

.h1-tabs {
  .product-selector {
    @include min-screen($min-medium) {
      margin-top: 7px;
    }
  }
}

// wrapping checkboxes
.product-selector {
  .ant-checkbox {
    vertical-align: top;
  }
  .ant-checkbox + span {
    line-height: 1.35rem;
    overflow: auto;
    position: relative;
    top: -2px;
    white-space: normal;
    vertical-align: top;
  }
}
