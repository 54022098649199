﻿// login
html .nui-login {
  background: $white;
  height: 100%;
  padding: 0;
  text-align: center;
  width: 100%;

  * {
    text-align: center;
  }
  .server {
    position: fixed;
    top: 0;
  }
  .shell {
    background: url(#{$assets-path}/img/signinbg.jpg) no-repeat top center;
    background-attachment: fixed;
    background-size: cover;
    margin: 0;
    min-height: 420px;
    padding: 0;
    position: relative;
    text-align: center;

    h1 {
      font-size: 2rem;
      margin: 0;
      padding-bottom: 40px;
      text-align: center;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      background: $white;
      background-image: none;
    }

    @supports (-ms-ime-align: auto) {
      background: $white;
      background-image: none;
    }

    text-align: center;
    transition: all 0.2s ease;
    width: 100%;

    &:before {
      content: ' ';
      display: inline-block;
      height: calc(100vh - 150px);
      vertical-align: middle;
      margin-left: -0.5em;
      width: 1px;
    }
  }
  .nui-login-form {
    display: inline-block;
    margin: 0 auto;
    max-width: 480px;
    text-align: center;
    vertical-align: middle;
    width: 100%;

    .token-text {
      font-size: 0.9rem;
      line-height: 1.2rem;
      margin-bottom: 20px;
    }
    img {
      display: block;
      margin: 0 auto 30px;
      mix-blend-mode: multiply;
      max-height: 200px;
      max-width: 70%;
      padding: 0 20px;

      @include min-screen($min-xsmall) {
        padding: 0 40px;
      }
      @include min-screen($min-medium) {
        max-width: 300px;
        padding: 0;
      }
    }
    .ant-row.ant-form-item {
      margin: 0 auto;
      .ant-form-item-control {
        margin-bottom: 20px;
        input[type='email'] {
          background: none;
          border-radius: 0;
          border-color: transparent;
          border-bottom-color: $hr;
          margin: 0 auto;
          max-width: 320px;
          min-height: 1px;
          padding: 10px;
          text-align: center;
          transition: all 0.2s linear;

          &:active,
          &:focus {
            background: $white;
            border-radius: 3px;
            border-color: $hr;
            max-width: 90%;
            padding: 10px;
          }
        }
        input.token,
        input#token {
          background: $white;
          border-radius: 3px;
          border-color: $hr;
          margin-bottom: 10px;
          max-width: 90%;
          padding: 10px;
          text-align: center;
        }
      }
      &.focused {
        .ant-form-item-control {
          input[type='email'] {
            background: $white;
            border-radius: 3px;
            border-color: $hr;
            max-width: 90%;
            padding: 10px;
          }
        }
      }
    }
    button[type='button'] {
      height: 40px;
      line-height: 1.35rem;
      margin: 0 auto;

      &:before {
        font-size: 1.1rem;
        left: -10px;
        position: relative;
        vertical-align: middle;
      }
    }
  }

  &.dev-mode {
    .nui-login-form {
      padding-top: 40px;
    }
  }

  .ant-form-item-control-wrapper {
    .ant-form-item-children-icon {
      display: none;
    }
  }

  .ant-form-item-control-wrapper
    .ant-form-item-control.has-error
    .ant-form-explain {
    margin: 0 auto;
    max-width: 320px;
    padding: 0;

    &:before {
      position: relative;
      top: 0;
    }
  }

  .nui-login-footer {
    font-size: 0.8rem;
    height: 150px;
    padding: 30px 0 0;
    text-align: center;
    width: 100%;

    img {
      display: inline-block;
      margin: 0 10px 0 0;
      max-width: 30px;
      opacity: 0.75;
      vertical-align: middle;
    }
    strong {
      color: $all-black;
      padding-right: 5px;
    }
    ul {
      border-top: 1px solid $lightest-gray;
      margin-top: 10px;
      padding-left: 0;
      padding-top: 10px;

      li {
        display: inline-block;
        &:before {
          content: '|';
          display: inline-block;
          margin: 0 5px;
          vertical-align: middle;
        }
        &:first-child {
          &:before {
            display: none;
          }
        }
      }
    }
  }

  button.inline-button {
    @extend %dd;
    background: none;
    border: 0;
    color: var(--link);
    line-height: inherit;
    padding: 0;

    &:active,
    &:focus,
    &:hover {
      @extend %nd;
      color: $all-black;
    }
  }
}
