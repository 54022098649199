// nui tabs
@-webkit-keyframes newqa {
  0% {
    border-color: #a6a8ad;
    -webkit-animation-timing-function: ease;
  }
  50% {
    border-color: #eef1f8;
    -webkit-animation-timing-function: ease;
  }
  100% {
    border-color: #a6a8ad;
    -webkit-animation-timing-function: ease;
  }
}

@keyframes newqa {
  0% {
    border-color: #a6a8ad;
    animation-timing-function: ease;
  }
  50% {
    border-color: #eef1f8;
    animation-timing-function: ease;
  }
  100% {
    border-color: #a6a8ad;
    animation-timing-function: ease;
  }
}

.nui-tabs {
  .nui-tabs-bar {
    border-bottom: 0;
    margin: 0 0 10px;
  }

  .nui-tabs-nav-operations,
  .nui-tabs-ink-bar {
    display: none !important;
  }

  .nui-tabs-nav {
    span {
      background: #a6a8ad;
      border-radius: 50px;
      color: $white;
      display: inline-block;
      font-size: 0.7rem;
      left: 3px;
      line-height: 0.7rem;
      padding: 2px 5px 3px;
      position: relative;
      top: -10px;
      z-index: 2;
    }
  }

  .nui-tabs-nav-wrap {
    border-bottom: 1px solid $lightest-gray;
    margin: 0 0 20px;
    overflow: auto;
    padding: 7px 0 0;

    .nui-tabs-nav-list {
      overflow: hidden;

      .nui-tabs-tab {
        @extend %light;
        color: var(--link);
        cursor: pointer;
        display: inline-block;
        font-size: 1rem;
        margin: 0 20px 0 0;
        padding: 10px 0;
        position: relative;

        &:active,
        &:focus,
        &:hover {
          @extend %nd;
          color: $all-black;
          text-decoration: none;
        }

        &:before {
          display: none;
        }

        &:after {
          background: transparent;
          border: 0;
          bottom: 0;
          content: ' ';
          display: block;
          height: 0;
          left: 0;
          margin-left: 0;
          position: absolute;
          right: auto;
          top: auto;
          transform: none;
          -webkit-transform: none;
          transition: height 0.4s ease;
          width: 100%;
          z-index: 0;
        }

        &:active,
        &:hover {
          &:after {
            background: $lightest-gray;
            height: 4px;
          }
        }

        &:active,
        &:active:hover {
          &:after {
            height: 6px;
          }
        }

        @include min-screen($min-small) {
          font-size: 1.1rem;
          margin-right: 14px;
          padding: 6px 0 17px 0;
        }

        .nui-tabs-tab-btn {
          margin-bottom: -2px;
          outline: none;
          padding-top: 2px;
        }
      }

      // active
      .nui-tabs-tab {
        &.nui-tabs-tab-active {
          @extend %nd;
          color: $all-black;
          font-weight: normal;

          &:after {
            background: var(--link);
            height: 4px;
          }
          &:hover {
            &:after {
              background: var(--link);
              height: 4px;
            }
          }
          &:active,
          &:active:hover {
            &:after {
              height: 6px;
            }
          }
        }
      }
    }
  }
}

.nui-tabs-tabpane {
  .nui-alert {
    margin-bottom: 10px !important;
  }
}

.nui-tabs-tabpane,
.nui-tabs-tabpane.nui-tabs-tabpane-active {
  outline: none !important;
}

// as used on the dashboard
.full-page-header .nui-tabs-nav {
  nav {
    overflow: hidden;

    a {
      @extend %nd;
      @extend %light;
      color: var(--link);
      cursor: pointer;
      display: inline-block;
      font-size: 1rem;
      margin: 0 20px 0 0;
      padding: 10px 0;
      position: relative;

      &:active,
      &:focus,
      &:hover {
        @extend %nd;
        color: $all-black;
        text-decoration: none;
      }

      &:before {
        display: none;
      }

      &:after {
        background: transparent;
        border: 0;
        bottom: 0;
        content: ' ';
        display: block;
        height: 0;
        left: 0;
        margin-left: 0;
        position: absolute;
        right: auto;
        top: auto;
        transform: none;
        -webkit-transform: none;
        transition: height 0.4s ease;
        width: 100%;
        z-index: 0;
      }

      &:active,
      &:hover {
        &:after {
          background: $lightest-gray;
          height: 4px;
        }
      }

      &:active,
      &:active:hover {
        &:after {
          height: 6px;
        }
      }

      @include min-screen($min-small) {
        font-size: 1.1rem;
        margin-right: 14px;
        padding: 6px 0 17px 0;
      }
    }

    a.active {
      @extend %nd;
      color: $all-black;
      font-weight: normal;

      &:after {
        background: var(--link);
        height: 4px;
      }
      &:hover {
        &:after {
          background: var(--link);
          height: 4px;
        }
      }

      &:active,
      &:active:hover {
        &:after {
          height: 6px;
        }
      }
    }
  }
}
