// print css
@media print {
  .print-only {
    display: block !important;
  }

  * {
    box-shadow: none !important;
    float: none !important;
    overflow: visible !important;
  }

  html,
  body,
  form {
    height: auto !important;
  }

  body {
    line-height: 16px !important;
  }

  a[href^='javascript:']:after,
  a[href^='#']:after,
  a[href='']:after {
    content: '';
  }

  a.button[href]:after {
    content: '' !important;
  }

  html {
    .sticky-nav {
      position: relative !important;
      top: auto !important;
    }
    .sticky-wrapper {
      height: auto !important;
    }
    body {
      padding: 10px !important;
    }

    * {
      box-shadow: none !important;
      min-width: 0 !important;
      max-width: none !important;
      white-space: normal !important;
    }

    table thead tr th:first-child,
    table tbody tr td:first-child {
      border-left: 0 !important;
    }

    table thead tr th:last-child,
    table tbody tr td:last-child {
      border-right: 0 !important;
    }

    table thead {
      page-break-before: always !important;
    }

    table,
    table thead tr th,
    table tbody tr td,
    .chart-container {
      page-break-inside: avoid !important;
    }

    .full-page-content table.table tbody tr td:first-child {
      padding-left: 10px !important;
    }
    .full-page-content table.table tbody tr td:last-child {
      padding-right: 10px !important;
    }
    .no-print,
    header.header .acc,
    footer.footer,
    .mobile-nav,
    .navbar,
    .icon-menu,
    .button,
    .goup,
    .filter-container *,
    .tenders .filter,
    .product-filter,
    .filter-section .nui-field-holder,
    .filter-section .nui-fieldset,
    .search-fieldset,
    .column-edit-product,
    .column-del-product,
    .add-product-link,
    .tenders ul.tender-list li a.expand,
    .favourites,
    .table-display-options,
    .toggle-volume,
    .solution-switcher-alt b,
    .solution-switcher-alt i,
    iframe,
    .server,
    .dashboard-tabs,
    .dashboard-header,
    .ant-tooltip,
    .ant-pagination,
    .button,
    .nui-button,
    .has-dd-holder,
    .has-dd-holder-reverse,
    hr {
      display: none !important;
    }

    th .button.icon-sort,
    th .nui-button.icon-sort {
      display: block !important;
    }

    .full-page-header {
      background: none !important;
      border: 0 !important;
      margin: 0 !important;
    }

    header.header {
      height: auto !important;
      position: relative !important;
    }

    .full-page-header h1 {
      margin: -20px 0 20px !important;
    }

    .chart.pie-chart,
    .chart-legend {
      display: inline-block !important;
      margin: 40px 0 !important;
      vertical-align: top !important;
      width: 44vw !important;
    }

    .chart.pie-chart {
      margin-right: 4vw !important;
    }

    table.table.tender-table tbody tr td .td-content .status-message {
      display: block !important;
      margin: auto;
    }
  }

  .detail-lists {
    ul li,
    ul li.auto,
    ul li.stacked {
      strong {
        display: inline-block !important;
        width: 250px !important;
      }
      span {
        display: inline-block !important;
        width: calc(100% - 250px) !important;
      }
    }
  }
}
