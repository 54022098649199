// solution picker
.solution-picker {
  display: inline-block;

  > button {
    height: auto;
    overflow: visible;

    span.solution {
      @extend %regular;
      display: inline-block;
      font-size: 0.8rem;
      margin: 0 0 0 25px;
      position: relative;

      &:before {
        @include arrow(9, 0);
        left: -15px;
        right: auto;
        top: 8px;
        transform: scale(0.75, 0.75);
        -webkit-transform: scale(0.75, 0.75);
      }

      &:after {
        border-left: 1px solid rgba(0, 0, 0, 0.25);
        content: ' ';
        display: inline-block;
        height: 24px;
        left: -22px;
        position: absolute;
        width: 1px;
      }

      @include min-screen($min-medium) {
        margin: -5px 0 0 25px;
      }
    }
  }

  nav {
    position: static;

    @include min-screen($min-medium) {
      position: relative;
    }
    ul {
      background: $white;
      border: 1px solid $lightest-gray;
      border-radius: 3px;
      box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
      font-size: 0.8rem;
      left: 0;
      min-width: 275px;
      position: absolute;
      top: 12px;
      z-index: 10;
      @include max-screen($max-small) {
        left: 20px;
        right: 20px;
        top: 65px;
      }
      &:before {
        border-bottom: 7px solid $lightest-gray;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        content: ' ';
        display: none;
        height: 0;
        left: 20px;
        position: absolute;
        top: -7px;
        width: 0;

        @include max-screen($max-small) {
          display: none;
        }
      }
      li {
        border-radius: 3px;
        display: block;
        margin: 1px;
        min-height: 35px;
        padding: 5px 10px;
        position: relative;

        > button {
          background: none;
          border-radius: 0;
          box-shadow: none;
          display: block;
          font-size: 0.9rem;
          height: auto;
          padding: 0;
          text-align: left;
          user-select: none;
          width: 100%;

          &:active,
          &:focus,
          &:hover {
            box-shadow: none;
          }
          figure {
            display: inline-block;
            margin: 0;
            padding: 0;
            text-align: center;
            vertical-align: middle;
            width: 50px;
          }
          img {
            display: inline-block;
            margin-right: 5px;
            max-height: 24px;
            max-width: 40px;
            mix-blend-mode: darken;
            vertical-align: middle;
          }
          span.solution-name {
            @extend %dd;
            color: var(--link);
            display: inline-block;
            font-size: 0.9rem;
            vertical-align: middle;
          }
        }

        &:hover {
          background: lighten($link, 55%);

          span.solution-name {
            @extend %nd;
          }
        }
        &.selected {
          background: lighten($link, 50%);

          span.solution-name {
            @extend %nd;
            @extend %bold;
            color: $all-black;
          }
        }
      }
    }
  }
}
