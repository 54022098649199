﻿// nui row-toggle
.nui-row-toggle {
  background: $white;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  color: var(--link);
  display: inline-block;
  font-size: 0.8rem;
  height: 25px;
  line-height: 25px;
  margin: 0;
  padding: 0 5px 0 20px;
  position: relative;
  width: auto;

  span.nui-row-toggle-arrow {
    border-top: 7px solid #999;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    content: ' ';
    display: block;
    height: 0;
    left: 5px;
    margin-top: 3px;
    pointer-events: none;
    position: absolute;
    right: auto;
    top: 6px;
    width: 0;
    z-index: 2;
    font-size: 1rem;
    transition: all 0.2s linear;
    transform: scale(0.8, 0.8);
    -webkit-transform: scale(0.8, 0.8);
  }

  &.open {
    span.nui-row-toggle-arrow {
      margin-top: 2px;
      transform: scale(0.8, 0.8) rotate(180deg);
      -webkit-transform: scale(0.8, 0.8) rotate(180deg);
    }
  }
}

// consolidation accordion
.company-header {
  .nui-row-toggle {
    background: none;
    border: 0;
    box-shadow: none;
    height: 100%;
    left: 0;
    padding: 0;
    position: absolute;
    top: 0;
    width: 100%;

    span.nui-row-toggle-arrow {
      @include arrow(0, 0);
      left: 25px;
      margin: 0;
      right: auto;
      top: 20px;
      transform: none;
      -webkit-transform: none;

      @include min-screen($min-medium) {
        left: 45px;
      }
    }
    &.open {
      span.nui-row-toggle-arrow {
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
      }
    }
  }
}
