// modal styles
.ant-modal-mask {
  z-index: 1003;
}
.ant-modal-wrap {
  z-index: 1004;

  .ant-modal-confirm-body {
    .anticon-question-circle {
      display: none;
    }
    span.ant-modal-confirm-title {
      @extend %bold;
    }
    .ant-modal-confirm-content {
      @extend %regular;
      color: $light-gray;
      margin-left: 0 !important;
    }
  }
  .ant-modal-confirm-btns {
    border-top: 1px solid $hr;
    float: none;
    padding-top: 15px;

    button.ant-btn {
      background: var(--secondary-color);
      border-color: inherit;
      margin: 0 10px 0 0;
    }
    button.ant-btn.ant-btn-primary {
      background: var(--primary-color);
      border-color: inherit;
      float: left;
    }
  }
}

.modal-header button.modal-close {
  background: none;
  border: 0;
  box-shadow: none;
  color: #999;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 2rem;
  font-weight: 300;
  margin: 0;
  position: absolute;
  right: 0;
  text-align: center;
  text-decoration: none;
  top: -8px;
  transition: 0.2s all linear;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  z-index: 10000;

  &:active,
  &:focus,
  &:hover {
    background: none;
    color: $all-black;
    transform: rotate(135deg) scale(1.2, 1.2);
    -webkit-transform: rotate(135deg) scale(1.2, 1.2);
    transition: transform 0.6s ease;
    -webkit-transition: -webkit-transform 0.6s ease;
  }
}

.modal-footer {
  button,
  a.button {
    line-height: 1.2rem;
    min-height: 1px;
  }
  button.modal-close {
    @extend %nd;
    @extend %bold;
    background: $white;
    border: 1px solid $lightest-gray;
    border-radius: 3px;
    color: var(--link);
    font-size: 1rem;
    height: auto;
    padding: 8px 24px;
    text-align: center;

    &:active,
    &:hover,
    &:focus {
      background: $white;
      border-color: $light-gray;
    }
  }
}

html .modal-header {
  h2 {
    @extend %light;
  }
}

// nui modal
.ant-modal,
.ant-modal.nui-modal {
  .ant-modal-content {
    background: $modal-bg;
    border-radius: 3px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
    font-size: 0.9rem;
    padding: 20px;
    transition: all 0.2s linear;

    button.ant-modal-close svg {
      display: none;
    }
    button.ant-modal-close i:after {
      box-shadow: none;
      color: $light-gray;
      content: '+';
      display: block;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 2rem;
      font-weight: 300;
      margin: 0;
      position: absolute;
      right: 20px;
      text-align: center;
      text-decoration: none;
      top: 30px;
      transition: 0.2s all linear;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      z-index: 10000;
    }

    button.ant-modal-close i {
      &:active,
      &:focus,
      &:hover {
        &:after {
          background: none;
          color: $all-black;
          transform: rotate(135deg) scale(1.2, 1.2);
          -webkit-transform: rotate(135deg) scale(1.2, 1.2);
          transition: transform 0.6s ease;
          -webkit-transition: -webkit-transform 0.6s ease;
        }
      }
    }

    .ant-modal-header,
    .ant-modal-body,
    .ant-modal-footer {
      padding: 0;
      color: $mid-gray;
      background: none;
      border: 0;
      border-radius: 0;
    }
    .ant-modal-header {
      padding-bottom: 20px;

      .ant-modal-title {
        @extend %light;
        color: $h2;
        font-size: 1.5rem;
        line-height: 1.75rem;

        @include min-screen($min-medium) {
          font-size: 1.75rem;
          line-height: 2rem;
        }
      }
    }
    .ant-modal-body {
      min-height: 2rem;
    }
    .ant-modal-footer {
      border-top: 1px solid $hr;
      padding-top: 20px;
    }
  }
}
