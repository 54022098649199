// list boxes

///////// list-boxes in expanded table row ( orders and trades tables, new marketplace and marketdepth)
.nui-table-expanded-row,
.ant-table-expanded-row {
  // expanded content
  .list-box {
    display: inline-block;
    padding: 10px 20px 0 0;
    vertical-align: top;
    width: 30vw;

    @include min-screen($min-xlarge) {
      width: calc(25vw - 50px);
    }

    &:first-child {
      @include min-screen($min-xlarge) {
        margin-left: 0;
      }
    }
    &:last-child {
      margin-right: 0;
    }
    @include max-screen($max-small) {
      display: block;
      left: 20px;
      padding: 10px 0 0;
      position: sticky;
      position: -webkit-sticky;
      vertical-align: top;
      width: calc(100vw - 50px);
    }
    h2 {
      @extend %light;
      border-bottom: 1px solid $lightest-gray;
      color: $all-black;
      display: block;
      font-size: 1.2rem;
      margin: 0;
      padding: 5px 0 15px;
    }
    ul {
      list-style: none;
      margin: 0 0 20px;
      padding: 0 0 10px;

      li > ul {
        margin: 0;
        padding: 0;
      }
      li {
        border-bottom: 1px solid $lightest-gray;
        padding: 5px 0;

        &:last-child {
          border-bottom: 0;
        }
        strong,
        span {
          display: inline-block;
          position: relative;
          vertical-align: top;
          width: 32%;
        }
        span {
          width: 66%;
        }
        strong {
          color: $all-black;
        }
      }
    }

    ul.documents-list {
      li {
        border: 0;
        padding: 5px 0 0 25px;
        position: relative;
      }
      strong,
      span {
        width: auto;
      }
    }

    &.list-multiple {
      ul {
        padding: 10px 0 0;

        li {
          border-bottom: 0;
          padding: 0 0 2px;
        }
      }
      ul + ul {
        border-top: 1px solid $hr;
      }
      span {
        display: block;
        padding-bottom: 10px;
        width: 100%;
      }
      strong {
        display: block;
        width: 100%;
      }
    }

    .docs-list {
      strong,
      span {
        width: 48%;
        span {
          width: auto;
        }
      }
    }
  }

  // updated flex list-boxes
  .list-boxes {
    h2 + ul {
      margin-top: -1px;
    }
    @include min-screen($min-medium) {
      align-items: stretch;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      left: 40px;
      width: calc(100vw - 100px);
      position: sticky;
      position: -webkit-sticky;

      .list-box {
        display: block;
        max-width: calc(50vw - 50px);
        width: 100%;
        &:before,
        &:after {
          display: block;
        }
      }
    }

    @include min-screen($min-large) {
      .list-box:nth-last-child(3):first-child,
      .list-box:nth-last-child(3):first-child ~ .list-box {
        max-width: calc(33vw - 30px);
      }

      .list-box:nth-last-child(4):first-child,
      .list-box:nth-last-child(4):first-child ~ .list-box,
      .list-box:nth-last-child(5):first-child,
      .list-box:nth-last-child(5):first-child ~ .list-box,
      .list-box:nth-last-child(6):first-child,
      .list-box:nth-last-child(6):first-child ~ .list-box,
      .list-box:nth-last-child(7):first-child,
      .list-box:nth-last-child(7):first-child ~ .list-box,
      .list-box:nth-last-child(8):first-child,
      .list-box:nth-last-child(8):first-child ~ .list-box {
        max-width: calc(25vw - 30px);
      }
    }

    @include min-screen($min-xxlarge) {
      .list-box:nth-last-child(5):first-child,
      .list-box:nth-last-child(5):first-child ~ .list-box {
        max-width: calc(20vw - 30px);
      }

      .list-box:nth-last-child(6):first-child,
      .list-box:nth-last-child(6):first-child ~ .list-box,
      .list-box:nth-last-child(7):first-child,
      .list-box:nth-last-child(7):first-child ~ .list-box,
      .list-box:nth-last-child(8):first-child,
      .list-box:nth-last-child(8):first-child ~ .list-box {
        max-width: calc(25vw - 30px);
      }
    }
  }
  .bid-message {
    margin: 0px 8px 20px;
    max-width: 100%;

    @include min-screen($min-medium) {
      margin: 0px 8px 20px;
    }
    .button,
    button.button,
    .nui-button,
    button.nui-button {
      @extend %regular;
      border-radius: 3px;
      font-size: 0.6rem;
      display: inline-block;
      height: 24px;
      line-height: 23px;
      margin: 0 5px 5px 0;
      padding: 0 10px !important;
      text-transform: uppercase;
      vertical-align: top;

      &:last-child {
        margin-right: 0;
      }
    }
    p.desc {
      border-top: 1px dashed $lightest-gray;
      font-size: 0.7rem;
      line-height: 1rem;
      padding-bottom: 5px !important;
      padding-right: 5px;
      padding-top: 5px !important;
      max-width: calc(20vw - 30px);
      width: 100%;
    }
    a.place-bid,
    a.place-offer {
      @extend %nd;
      background: $white;
      border: 1px solid $lightest-gray;
      border-radius: 3px;
      color: $bid;
      display: inline-block;
      font-size: 0.6rem;
      height: 24px;
      line-height: 24px !important;
      margin: 0 5px 0 0;
      padding: 0 10px;
      text-align: center;
      text-transform: uppercase;
      transition: 0.2s all linear;

      &:hover {
        background: $bid;
        border-color: $bid;
        color: $white;
      }
    }

    a.place-offer {
      color: $offer;

      &:hover {
        background: $offer;
        border-color: $offer;
      }
    }
  }
}

// generic styles
.list-box {
  display: block;
  margin: 0;
  padding: 0;

  @include min-screen($min-small) {
    display: inline-block;
    vertical-align: top;
    width: 50%;
  }

  h2 {
    @extend %light;
    margin: 10px 0 5px;
    padding: 0 0 10px 0;
  }

  ul {
    border-top: 1px solid $hr;
    display: block;
    list-style: none;
    margin: 0;
    padding: 20px 10px 20px 0;

    ol,
    ul {
      border: 0;
      list-style: none;
      margin: 0;
      padding: 0;

      span {
        @extend %regular;
      }
      span:not([class^='icon-']) {
        color: $mid-gray;
      }
    }
    li {
      line-height: 1.2rem;
      padding: 4px 20px 4px 0;
      -webkit-column-break-inside: avoid;
      page-break-inside: avoid;
      break-inside: avoid;

      strong,
      span {
        display: inline-block;
        position: relative;
        vertical-align: top;
        width: 140px;

        &.block {
          display: block;
          width: 100%;
        }
      }
      span {
        width: calc(100% - 140px);
      }
      span.nowrap.valign-m {
        width: auto;

        span.icon-link-ext-alt {
          color: #bfbfbf;
          &:hover {
            color: var(--link);
          }
        }
      }
      span.icon-ok,
      span.icon-block,
      span.icon-clock {
        vertical-align: middle;
        white-space: nowrap;
        width: auto;
      }
      strong {
        color: $all-black;
        padding-right: 5px;

        span {
          width: auto;
        }
      }
      span.legend {
        float: right;
        margin: 2px 5px;
        pointer-events: none;
        width: 18px;
      }
      a.document {
        display: block;
        margin-right: 20px;
        padding-bottom: 2px;
        padding-left: 30px;
        position: relative;
        word-break: break-all;

        span.legend {
          left: 0;
          margin: 0;
          position: absolute;
          top: 2px;
        }
      }
    }
  }

  ol {
    margin: 0;
    padding: 0 0 0 15px;

    li {
      @extend %bold;
      color: $all-black;
      padding: 2px 0;

      address {
        @extend %regular;
        font-style: normal;
      }
    }
  }

  .nui-upload-docs {
    padding: 20px 0;

    @include min-screen($min-xlarge) {
      padding: 20px 20px 20px 0;
    }
  }
}

// styled lists
.list-box {
  ul.docs {
    li {
      a {
        display: block;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      a + a {
        margin-top: 10px;
      }
    }
  }

  ul li.auto {
    @include min-screen($min-small) {
      span {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: auto;
      }
    }
  }

  ul li.stacked {
    margin-bottom: 5px;

    address {
      padding-bottom: 0;
    }
    strong,
    span,
    span ul li {
      display: block;
      width: 100%;
    }
    strong {
      margin-bottom: 2px;
    }
    span ul {
      column-count: 1;
      -ms-column-count: 1;
      -moz-column-count: 1;
      -o-column-count: 1;
      -webkit-column-count: 1;
    }
  }

  ul li.auto {
    strong,
    span {
      width: auto;
    }
    strong {
      min-width: 140px;
    }
  }

  ul li.reversed {
    strong {
      width: auto;
    }
    span {
      float: right;
      text-align: right;
      width: auto;
    }
  }

  ul.personal-details li.reversed,
  ul.details li.reversed {
    span {
      max-width: 80%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  ul li.ordered-list {
    position: relative;

    strong {
      position: relative;
      width: 25px;
      z-index: 2;
    }
    span {
      width: calc(100% - 25px);
    }
    span.default {
      background: #f9f9f9;
      color: $ok;
      display: block;
      height: 15px;
      left: -3px;
      position: absolute;
      top: 4px;
      width: 15px;
      z-index: 3;
      &:before {
        margin: 0;
      }
    }
    span.default + strong {
      color: transparent;
    }
    span.default + strong + span {
      color: $ok;
    }
  }
}

.nui-modal-content {
  .list-box ul li.ordered-list span.default {
    background: #f2f2f2;
  }
}

// double list-box
.list-box.double {
  width: 100%;

  ul {
    position: relative;
    @include min-screen($min-small) {
      column-count: 2;
      -ms-column-count: 2;
      -moz-column-count: 2;
      -o-column-count: 2;
      -webkit-column-count: 2;

      &.nocols {
        column-count: 1;
        -ms-column-count: 1;
        -moz-column-count: 1;
        -o-column-count: 1;
        -webkit-column-count: 1;
      }
    }
    @include min-screen($min-xlarge) {
      padding-right: 0;
      li {
        padding-right: 20px;
      }
    }
  }
}

// xlarge
.list-box {
  @include min-screen($min-xlarge) {
    display: inline-block;
    width: 25%;

    &.double {
      width: 50%;
    }
  }
}

// list-box.has-unit box
.list-box.has-unit-box {
  @include min-screen($min-medium) {
    border-bottom: 1px solid $hr;
    margin-bottom: 5px;
    overflow: hidden;
  }
  @include min-screen($min-xlarge) {
    border-bottom: 0;
    margin-bottom: 0;
  }
  .unit-box {
    background: #f2f2f2;
    margin-bottom: 20px;
    padding: 10px 20px;
    position: relative;

    @include min-screen($min-medium) {
      border: 0;
      border-top: 1px solid $hr;
      display: inline-block;
      margin: 0 0 -980px !important;
      padding: 21px 20px;
      padding-bottom: 1000px !important;
      vertical-align: top;
      width: 35%;
    }
  }
  .unit-box + ul {
    column-count: 1;
    -ms-column-count: 1;
    -moz-column-count: 1;
    -o-column-count: 1;
    -webkit-column-count: 1;

    @include min-screen($min-medium) {
      display: inline-block;
      padding-left: 20px;
      vertical-align: top;
      width: 65%;
    }
  }

  .value-item {
    display: inline-block;
    max-width: 300px;
    padding: 0 0 10px;
    vertical-align: top;
    width: 50%;

    @include min-screen($min-medium) {
      display: block;
      width: 100%;
    }
    .item-label {
      color: #ec4004;
      display: block;
      font-size: 0.8rem;
    }
    strong {
      color: $all-black;
      display: inline-block;
      font-size: 1.6rem;
    }
    .bid-message {
      max-width: 100%;
      margin-top: 20px;

      @include min-screen($min-medium) {
      }
      .button,
      button.button,
      .nui-button,
      button.nui-button {
        @extend %regular;
        border-radius: 3px;
        font-size: 0.6rem;
        display: inline-block;
        height: 24px;
        line-height: 23px;
        margin: 0 5px 5px 0;
        padding: 0 10px !important;
        text-transform: uppercase;
        vertical-align: top;

        &:last-child {
          margin-right: 0;
        }
      }
      p.desc {
        border-top: 1px dashed $lightest-gray;
        font-size: 0.75rem !important;
        strong {
          font-size: 0.75rem !important;
        }
        line-height: 1rem;
        padding-bottom: 5px !important;
        padding-right: 5px;
        padding-top: 5px !important;
        max-width: calc(20vw - 30px);
        width: 100%;
      }
      a.place-bid,
      a.place-offer {
        @extend %nd;
        background: $white;
        border: 1px solid $lightest-gray;
        border-radius: 3px;
        color: $bid;
        display: inline-block;
        font-size: 0.6rem;
        height: 24px;
        line-height: 24px !important;
        margin: 0 5px 0 0;
        padding: 0 10px;
        text-align: center;
        text-transform: uppercase;
        transition: 0.2s all linear;

        &:hover {
          background: $bid;
          border-color: $bid;
          color: $white;
        }
      }

      a.place-offer {
        color: $offer;

        &:hover {
          background: $offer;
          border-color: $offer;
        }
      }
    }

    .value {
      @extend %bold;
      color: $all-black;
      display: inline-block;
      font-size: 0.8rem;
      white-space: nowrap;

      &.total {
        font-size: 1rem;
      }
    }
    .cur-unit,
    .index-unit,
    .index-name {
      color: $light-gray;
      display: inline-block;
      font-size: 0.8rem;
    }
    .index-name {
      display: block;

      strong {
        color: $all-black;
        font-size: 0.8rem;
      }
    }
  }

  .value-item.wp-50:only-child {
    width: 100% !important;
  }

  .value-items.thirds {
    .value-item {
      width: 33%;

      @include min-screen($min-medium) {
        width: 100%;
      }
    }
  }
}

// list-boxes in last trade
.last-trade {
  .list-box.has-unit-box {
    border-bottom: 0;

    .unit-box {
      background: none;
      border-bottom: 1px solid $hr;
      margin: 0;
      padding: 0;

      .value-item {
        display: inline-block;
        width: 50%;
      }
      hr {
        opacity: 0;
      }
      @include min-screen($min-medium) {
        border-right: 1px solid $hr;
        display: block;
        margin: 0 20px 0 0;
        padding: 10px 20px 10px 0;
        width: auto;
      }
    }
    .unit-box + ul {
      border-top: 0;
      padding-left: 0;
      width: calc(100% - 20px);

      @include min-screen($min-medium) {
        padding-top: 0;
      }
    }
  }
  .list-box.has-unit-box + .list-box {
    @include min-screen($min-medium) {
      h2,
      p,
      ul {
        padding-left: 20px;
      }
    }
  }

  // in modal
  .detail-lists {
    .list-box {
      width: 100%;

      h2 {
        color: $mid-gray;
        font-size: 1.2rem;
        margin: 0 0 10px;
        padding: 0;
      }
      ul {
        column-count: 1;
        -ms-column-count: 1;
        -moz-column-count: 1;
        -o-column-count: 1;
        -webkit-column-count: 1;
      }
      .list-box {
        display: block;
        width: 100%;
      }
      @include min-screen($min-medium) {
        width: 50%;
      }
    }

    ul.trade-details-list.type-list {
      padding-bottom: 14px;
      padding-top: 10px;
      li {
        margin-bottom: 0 !important;
      }
    }
    .list-box.loading-extra {
      margin-top: 21px;

      > .list-box > h2 {
        margin: -5px 0 14px;
      }
    }
  }
}

// list layouts
.detail-lists {
  h2 {
    a.editme {
      @extend %regular;
      border-left: 1px solid $lightest-gray;
      display: inline-block;
      font-size: 0.9rem;
      line-height: 1;
      margin: 0 0 0 10px;
      padding-left: 10px;
      position: relative;
      vertical-align: middle;
    }
  }

  .list-box {
    ul.order-attributes {
      border-top: 0;
      padding-top: 0;
    }
  }

  @include min-screen($min-large) {
    overflow: hidden;
    .list-box:not(.has-unit-box) {
      ul {
        margin-bottom: -980px !important;
        padding-bottom: 1000px !important;
      }
    }
  }
  // one-double/one
  &.single-double-single {
    .list-box:not(.double) {
      display: block;
      width: 100%;

      @include min-screen($min-large) {
        display: inline-block;
        vertical-align: top;
        width: 25%;
      }
    }

    .list-box.double {
      display: block;
      width: 100%;

      @include min-screen($min-large) {
        display: inline-block;
        vertical-align: top;
        width: 50%;

        ul {
          border-left: 1px solid #d9d9d9;
          border-right: 1px solid #d9d9d9;
          padding-left: 20px;
          padding-right: 20px;
          padding-bottom: 1000px !important;
          margin-bottom: -980px !important;
        }
        h2 {
          padding-left: 20px;
        }
      }
    }

    .list-box.double + .list-box:not(.double) {
      h2,
      p,
      ul {
        @include min-screen($min-large) {
          padding-left: 20px;
        }
      }
    }
  }

  // double + one
  .list-box.double + .list-box:not(.double) {
    h2,
    ul,
    ul li {
      padding-left: 0;
      padding-right: 0;
    }
    @include min-screen($min-small) {
      ul li {
        padding-right: 20px;
      }
    }
    @include min-screen($min-xlarge) {
      h2,
      p {
        padding-left: 20px;
      }
      ul {
        padding-left: 20px;
        padding-right: 0;
        ul {
          border: 0;
          margin: 0;
          padding: 0;
        }
      }
    }
  }

  // double + one + one
  .list-box.double + .list-box:not(.double) + .list-box:not(.double) {
    h2,
    p,
    ul,
    ul li {
      padding-left: 0;
      padding-right: 0;
    }
    @include min-screen($min-small) {
      ul li {
        padding-right: 20px;
      }
    }
    @include min-screen($min-xlarge) {
      h2,
      p,
      ul {
        padding-left: 20px;
        padding-right: 0;
      }
    }
  }

  // borders and padding
  .list-box {
    &.double {
      @include min-screen($min-xlarge) {
        &:first-of-type,
        &:nth-of-type(3n) {
          ul {
            border-right: 1px solid $hr;
            padding-right: 20px;

            ul {
              border: 0;
              margin: 0;
              padding: 0;
            }
          }
        }
        &:nth-of-type(2n),
        &:nth-of-type(4n) {
          ul {
            margin-left: -1px;
            border-left: 1px solid $hr;
            padding-left: 20px;

            ul {
              border: 0;
              margin: 0;
              padding: 0;
            }
          }
          h2 {
            padding-left: 20px;
          }
        }
        &:last-child {
          ul {
            border-left: 0 !important;
            border-right: 0 !important;
          }
        }
      }
    }
  }

  // full width list-box
  .list-box {
    &.full {
      display: block;
      width: 100%;

      ul {
        column-count: 2;
        -webkit-column-count: 2;
        column-fill: balance;
        -webkit-column-fill: balance;
        margin: 0 !important;
        padding: 10px 0 !important;

        @include min-screen($min-small) {
          column-count: 4;
          -webkit-column-count: 4;
        }
        @include min-screen($min-medium) {
          column-count: 8;
          -webkit-column-count: 8;
        }
        li.ordered-list {
          display: block;
          width: 100%;
        }
      }
    }
  }
}

.list-box-container.block {
  @include max-screen($max-large) {
    .list-box {
      h2 {
        border-bottom: 1px solid $hr !important;
      }
    }
  }
}

// new, in list-box-container
.list-box-container:not(.block) {
  overflow: hidden;

  h2 {
    border-bottom: 1px solid $hr;
  }
  p {
    line-height: 1.25rem;
  }
  ul {
    border-top: 0 !important;
  }

  // medium
  @include min-screen($min-medium) {
    position: relative;

    h2,
    ul {
      border: 0 !important;
      padding-left: 20px !important;
    }

    ul li ul {
      padding-left: 0 !important;
    }

    &:after {
      border-top: 1px solid $hr;
      content: ' ';
      display: block;
      height: 0;
      left: 0;
      position: absolute;
      top: 49px;
      width: 100%;
    }
  }

  // comments inside a span resulting in an abomination, to be fixed later
  ul li.stacked span p {
    padding-left: 0 !important;
  }

  // xlarge
  @include min-screen($min-xlarge) {
    &:before {
      border-left: 1px solid $hr;
      content: ' ';
      display: block;
      height: 100%;
      left: 50%;
      position: absolute;
      top: 49px;
      width: 0;
    }
  }

  // new layouts in list-box-container
  .list-box.double + .list-box.double,
  .list-box.double.has-unit-box + .list-box:not(.double) {
    h2 {
      @include min-screen($min-medium) {
        border-bottom: 1px solid $hr !important;
      }
      @include min-screen($min-xlarge) {
        border-bottom: 0 !important;
      }
    }
  }

  .list-box:not(.double):nth-child(3),
  .list-box:not(.double):nth-child(4) {
    h2 {
      @include min-screen($min-medium) {
        border-bottom: 1px solid $hr !important;
      }
      @include min-screen($min-xlarge) {
        border-bottom: 0 !important;
      }
    }
  }
}

// icon file lists
.detail-lists .list-box ul.documents-list,
html ul.documents-list {
  list-style: none;
  padding-left: 0;

  li {
    padding-left: 25px !important;
    position: relative;

    a span {
      @extend %dd;
      width: auto !important;

      &:hover {
        @extend %nd;
      }
    }
    span.icon-file-pdf,
    span.icon-file-word,
    span.icon-file-excel,
    span.icon-file-image,
    span.icon-doc,
    span.icon-doc-text {
      left: 0;
      position: absolute !important;
      width: auto !important;
    }
    span.icon-file-pdf {
      &:before {
        color: #e12025;
      }
    }
    span.icon-file-excel {
      &:before {
        color: #4ea216;
      }
    }
    span.icon-file-image {
      &:before {
        color: #666;
      }
    }
    span.icon-doc,
    span.icon-doc-text {
      &:before {
        color: #888;
      }
    }
    span.icon-file-word {
      &:before {
        color: #2b579a;
      }
    }
  }
}
