﻿// buttons
button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: button;
  cursor: pointer;
  &:active,
  &:hover,
  &:focus {
    outline: none;
  }
}

button[disabled],
html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}
.ant-btn.ant-btn-primary,
.ant-btn.ant-btn-primary:not(.ant-dropdown-trigger),
.ant-btn:not(.ant-btn-link),
.ant-btn.button,
%button,
.button,
input[type='button'].button,
input[type='submit'].button,
input[type='reset'].button {
  background: var(--link);
  border-width: 1px;
  border-color: inherit;
  border: 0;
  border-radius: 4px;
  box-shadow: none;
  color: $white;
  display: inline-block;
  font-size: 1rem;
  height: auto;
  line-height: 1.2rem;
  max-width: 100%;
  min-height: 1px;
  overflow: hidden;
  padding: 8px 24px;
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  text-transform: none;
  transition: all 0.2s linear;
  vertical-align: middle;
  white-space: nowrap;
  @extend %bold;

  &.small {
    font-size: 0.9rem;
    padding: 4px 12px 5px;
  }
  &.tiny {
    font-size: 0.8rem;
    padding: 3px 9px 4px;
  }
  &:hover {
    box-shadow: 0 0 500px rgba(0, 0, 0, 0.15) inset;
    color: $white;
  }
  &:active,
  &:focus {
    box-shadow: 0 0 500px rgba(0, 0, 0, 0.25) inset;
    color: $white;
    outline: none;
  }
  &.bid {
    background: var(--bid);
    &.disabled {
      opacity: 0.5;
    }
  }
  &.offer {
    background: var(--offer);
    &.disabled {
      opacity: 0.5;
    }
  }
  &.primary {
    background: var(--primary-color);
    border-color: inherit;
  }
  &.secondary {
    background: var(--secondary-color);
    border-color: inherit;
  }
  &.warning {
    background: var(--highlight);
    border-color: inherit;
  }
  &.error {
    background: $error;
    border-color: inherit;
  }
  &.reverse {
    background: $white;
    border: 1px solid $lightest-gray;
    color: var(--link);
    padding: 7px 24px;

    &:active,
    &:hover,
    &:focus {
      background: $white;
      border-color: $light-gray;
      box-shadow: none;
    }
  }
  &.simple,
  &.buttonlink {
    @extend %dd;
    @extend %regular;
    background: transparent;
    border: 1px solid transparent;
    color: var(--link);
    padding: 7px 24px;

    &:active,
    &:focus,
    &:hover {
      @extend %nd;
      background: transparent;
      border: 1px solid transparent;
      box-shadow: none;
      color: $all-black;
    }
  }
  &.buttonlink {
    border: 0;
    display: inline;
    font-size: inherit;
    line-height: inherit;
    padding: 0;

    &:active,
    &:focus,
    &:hover {
      @extend %nd;
      background: none;
    }
  }
  &.rounded {
    border-width: 0;
    border-radius: 100px;
  }
  &[disabled],
  &.disabled,
  &[disabled]:active,
  &.disabled:active,
  &[disabled]:focus,
  &.disabled:focus {
    background-color: #bbb !important;
    border-color: rgba(0, 0, 0, 0.1) !important;
    color: $white;
    opacity: 1;
    -webkit-text-fill-color: inherit;
  }
}

table td {
  .button:not(.small) {
    line-height: 36px;
    min-height: 40px;
    margin-bottom: 0;
    padding-bottom: 0;
    padding-top: 0;
    vertical-align: middle;
  }
  .button-set .button {
    line-height: 1.2rem;
    min-height: 1px;
    padding-bottom: 8px;
    padding-top: 8px;
  }
}

.button-set {
  @include max-screen($max-xsmall) {
    text-align: left !important;
  }
  &:after {
    @extend %clearfix;
  }
  white-space: nowrap;
  &.wrap {
    white-space: normal;
  }
  &.right {
    float: none;

    .button:last-child {
      float: right;
    }
    @include min-screen($min-small) {
      float: right;
    }
  }
  span {
    display: inline-block;
  }
  .button {
    display: block;
    margin-bottom: 10px;
    margin-right: 0;
    vertical-align: middle;
    width: 100%;

    @include max-screen($max-xsmall) {
      float: none;
    }

    @include min-screen($min-xsmall) {
      display: inline-block;
      margin-right: 10px;
      width: calc(50% - 5px);

      &:nth-child(2n) {
        margin-right: 0;
      }
    }
    @include min-screen($min-small) {
      width: auto;
    }
  }

  .button {
    &.icon-pencil,
    &.icon-trash {
      padding-left: 30px;
      padding-right: 15px;
      position: relative;

      &:before {
        font-size: 1rem;
        left: 5px;
        position: absolute;
        top: 9px;
      }
    }
    &.icon-eye,
    &.icon-trash {
      position: relative;

      &:before {
        font-size: 1rem;
      }
    }
    &.reverse {
      &:before {
        color: $mid-gray;
        opacity: 1;
      }
    }
  }
}

a,
button {
  span.icon-eye,
  span.icon-trash {
    &:before {
      color: $mid-gray;
    }
  }
}

.button-set.trio {
  .button {
    margin-bottom: 10px;
  }
  .button:nth-child(2) {
    margin-right: 0;
  }
  @include min-screen($min-small) {
    .button:nth-child(2) {
      margin-right: 10px;
    }
    .button:last-child,
    button:nth-child(3) {
      margin-right: 0;
    }
  }
}

.ant-modal {
  .button-set {
    .button {
      @include min-screen($min-xsmall) {
        margin-bottom: 0;
        width: auto;
      }
    }
  }
}

.touch {
  .button {
    &.simple {
      @extend %nd;
      text-decoration: underline;

      &:hover {
        @extend %nd;
        text-decoration: none;
      }
    }
  }
}

// simple button in alert
#neworder .ant-drawer-content-wrapper .wrapper {
  .ant-alert,
  .ant-alert-success,
  .ant-alert-error,
  .ant-alert-warning {
    .button.simple,
    .button.ant-btn-link.simple {
      span {
        @extend %dd;

        &:hover {
          @extend %nd;
        }
      }
      &:active,
      &:focus,
      &:hover {
        background: transparent;
        border-color: transparent;
      }
    }
  }

  span.ant-alert-message {
    @extend %bold;
    color: $all-black;
  }
}
