// table styles
html {
  .ant-table table {
    border-collapse: collapse;

    tr:last-child {
      td {
        border-bottom: 1px solid $hr;
      }
    }
    tbody tr td {
      padding: 5px 10px;

      &.ant-table-column-has-sorters {
        padding-right: 32px;
      }
    }
    td.col-loading,
    th.col-loading {
      border-right: 1px solid $hr;
    }
  }

  .ant-table-tbody > tr.ant-table-row-hover,
  .ant-table-tbody > tr:hover {
    &:nth-child(even) {
      > td {
        background: inherit;
        box-shadow: 0 -1px 0 #d9d9d9 inset, 0 1px 0 #d9d9d9,
          0 -5px 0 rgba(0, 0, 0, 0.035) inset;

        &:first-child {
          background: $white;
        }
      }
    }
  }
}

// generic table
.generic-table {
  .ant-spin-nested-loading {
    .ant-spin-spinning {
      top: 100px;
    }
  }

  .ant-table-title > h3 {
    margin: 5px 0 15px;

    span {
      @extend %light;
      color: $mid-gray;
      font-size: 1rem;
    }
  }

  .ant-table-title {
    border-radius: 0;
    padding: 10px 0 0;
    top: auto;
  }

  .ant-table-content {
    .ant-table-body {
      table {
        background: $white;
        border-collapse: collapse;
        border-radius: 3px;
        border: 1px solid $lightest-gray;
        margin: 0;
        padding: 5px;
        width: 100%;

        thead.ant-table-thead {
          th {
            @extend %bold;
            background: rgba(20, 20, 20, 0.1);
            border-bottom: 1px solid $lightest-gray;
            border-right: 1px solid $lightest-gray;
            color: $all-black;
            padding: 10px;
            white-space: nowrap;

            // filter icon
            .anticon-filter {
              background: $lightest-gray;
              border: 0;
              border-radius: 50%;
              color: $white;
              display: block;
              height: 20px;
              margin-top: 10px;
              padding: 0;
              right: 10px;
              width: 20px;

              &:after {
                display: none;
              }
              &:hover {
                background: darken($lightest-gray, 5%);
                color: $white;
              }
            }
            .ant-table-filter-selected.anticon-filter {
              background: $ok;
            }

            .ant-table-column-sorter
              .ant-table-column-sorter-inner
              .ant-table-column-sorter-up,
            .ant-table-column-sorter
              .ant-table-column-sorter-inner
              .ant-table-column-sorter-down {
              &.off {
                color: $light-gray;
              }
              &.on {
                color: $all-black;
              }
            }
          }
        }

        // tbody
        tr {
          td {
            border-bottom: 1px solid $hr;
            border-right: 1px solid $hr;
            overflow: visible;
            padding: 10px;
            vertical-align: top;

            span.icon-block {
              color: $error;
              cursor: not-allowed;
              display: inline-block;
            }
            span.icon-ok {
              color: $ok;
              display: inline-block;
            }
            &.col-id {
              white-space: nowrap;
            }
            // expand icon
            .ant-table-row-expand-icon {
              @include expand;
              cursor: pointer;
              display: inline-block;
              left: auto;
              margin: 0 10px 0 0;
              position: relative;
              top: -1px;
              vertical-align: middle;

              &:before {
                cursor: pointer;
                left: 5px;
                right: auto;
                transition: all 0.2s linear;
              }
              &.ant-table-row-collapsed {
                &:after {
                  content: ' ';
                }
              }
              &.ant-table-row-expanded {
                &:after {
                  content: ' ';
                }
                &:before {
                  top: 4px;
                  transform: rotate(180deg) scale(0.8, 0.8);
                  -webkit-transform: rotate(180deg) scale(0.8, 0.8);
                }
              }
            }
            &.ant-table-column-has-sorters {
              padding-right: 32px;
            }
          }
        }

        tr:nth-child(odd),
        tr:not(.more-details):nth-child(odd):hover {
          td {
            background: $generic-table-td-odd;
          }
        }
        tr:nth-child(even),
        tr:nth-child(even):hover {
          td {
            background: $generic-table-td-even;
          }
        }
        tr:hover {
          td {
            box-shadow: 0 -5px 0 rgba(0, 0, 0, 0.035) inset;
          }
        }

        tr:nth-child(odd),
        tr:nth-child(odd):hover,
        tr:nth-child(even),
        tr:nth-child(even):hover {
          td {
            &:first-child {
              background: $white;
            }
          }
        }
      }
    }
  }
}

// generic table in modal
.modal-table {
  .ant-table-content {
    .ant-table-body {
      table {
        background: $white;
        border-collapse: collapse;
        border-radius: 3px;
        border: 1px solid $lightest-gray;
        margin: 0;
        padding: 5px;
        width: 100%;

        thead.ant-table-thead {
          th {
            @extend %bold;
            background: rgba(20, 20, 20, 0.1);
            border-bottom: 1px solid $lightest-gray;
            border-right: 1px solid $lightest-gray;
            color: $all-black;
            padding: 10px;
            white-space: nowrap;

            span.ant-table-column-title > span {
              @extend %regular;
              color: $mid-gray;
              display: block;
              font-size: 0.7rem;
            }
          }
        }

        // tbody
        tr {
          td {
            border-bottom: 1px solid $hr;
            border-right: 1px solid $hr;
            overflow: visible;
            padding: 10px;
            vertical-align: top;

            span.icon-block {
              color: $error;
              cursor: not-allowed;
            }
            span.icon-ok {
              color: $ok;
            }
          }
        }

        tr:nth-child(odd),
        tr:not(.more-details):nth-child(odd) {
          td {
            background: #f9f9f9;
          }
          &:hover {
            td {
              background: darken(#f9f9f9, 2%);
            }
          }
        }
        tr:nth-child(even) {
          td {
            background: #f2f2f2;
          }
          &:hover {
            td {
              background: darken(#f2f2f2, 2%);
            }
          }
        }
        tr:hover {
          td {
            box-shadow: 0 -5px 0 rgba(0, 0, 0, 0.035) inset;
          }
        }
      }
    }
  }
}

// product tables
.generic-table {
  .ant-table-small > .ant-table-content > .ant-table-body {
    margin: 0;
  }
}

.ant-table-small {
  border: 0;
  border-radius: 3px;
}

.ant-table-without-column-header {
  .ant-table-title {
    @extend %bold;
    background: rgba(20, 20, 20, 0.1);
    border: 1px solid $lightest-gray;
    border-bottom: 0;
    border-radius: 3px 3px 0 0;
    color: $all-black;
    margin-bottom: -1px;
    padding: 10px;
  }
}

// rowspan of 2
.generic-table .ant-table-content .ant-table-body table thead.ant-table-thead,
.ant-table-thead {
  tr + tr {
    th {
      background: rgba(20, 20, 20, 0.15);
      border-top: 1px solid $white;
      font-size: 0.75rem;
      padding: 5px 10px;
    }
  }
}

// expand button with text
.generic-table .ant-table-content .ant-table-body table tr td.expand-text {
  width: 1%;

  .ant-table-row-expand-icon {
    display: inline-block;
    height: 25px;
    line-height: 23px;
    margin: 0;
    padding: 0 5px 0 20px;
    width: auto;

    &:after {
      color: var(--link);
      content: 'Details';
      display: inline-block;
      font-size: 0.8rem;
    }
    &:before {
      left: 5px;
      margin-top: 3px;
      right: auto;
    }
  }
}

// expanded row

.generic-table.expand-table {
  .ant-table-content .ant-table-body {
    tr,
    tr:nth-child(even),
    tr:nth-child(odd) {
      &.ant-table-expanded-row {
        td,
        td:first-child {
          background: #eef1f8;
          border-bottom: 1px solid $hr;
          box-shadow: 0 -10px 0 $white inset, 0 -11px 0 $hr inset,
            0 2px 2px rgba(0, 0, 0, 0.05) inset;
          position: relative;
        }

        td:first-child {
          &:before {
            background: $white;
            bottom: 0;
            content: ' ';
            display: block;
            height: 10px;
            left: -1px;
            position: absolute;
            width: 1px;
            z-index: 2;
          }
        }

        td:last-child {
          &:after {
            background: $white;
            bottom: 0;
            content: ' ';
            display: block;
            height: 10px;
            position: absolute;
            right: -1px;
            width: 1px;
            z-index: 2;
          }
        }
      }
    }
  }
}

.generic-table.expand-table.market-depth {
  .ant-table-content .ant-table-body {
    tr,
    tr:nth-child(even),
    tr:nth-child(odd) {
      &.ant-table-expanded-row {
        td,
        td:first-child {
          border-bottom: 1px solid $hr;
          box-shadow: none;
          position: relative;

          &:before,
          &:after {
            display: none;
          }
        }
      }
      &:last-child {
        td,
        td:first-child {
          border-bottom: 0;
        }
      }
    }
  }
}

// bordered table wrapper (for scrolling)
.bordered,
.generic-table.bordered {
  table,
  .ant-table-content .ant-table-body table {
    border-left: 0;
    border-right: 0;

    thead,
    tbody {
      tr {
        th:first-child,
        td:first-child {
          border-left: 0;
        }
        th:last-child,
        td:last-child {
          border-right: 0;
        }
      }
    }
  }
}

// global table sorters
.ant-table-thead {
  tr {
    th {
      .ant-table-column-sorter {
        .ant-table-column-sorter-inner {
          .ant-table-column-sorter-up.on,
          .ant-table-column-sorter-down.on {
            color: $all-black;
          }
        }
      }
    }
  }
}

.ant-table-thead > tr > th .ant-table-column-sorter {
  display: none;
}
.ant-table-thead
  > tr
  > th.ant-table-column-has-sorters
  .ant-table-column-sorter {
  display: table-cell;
}

.ant-table-thead
  > tr
  > th
  .ant-table-header-column
  .ant-table-column-sorters:hover::before {
  background: inherit !important;
}

// light tables, pagination
.full-page-content {
  .ant-pagination {
    display: inline-block;
    left: 0;
    overflow: visible;
    padding-left: 20px !important;
    padding-right: 20px !important;
    position: sticky;
    position: -webkit-sticky;
    white-space: nowrap;
    width: 0;

    @include min-screen($min-medium) {
      padding-left: 40px !important;
      padding-right: 40px !important;
    }
  }
}

// alignments
table {
  thead {
    th.align-right span.ant-table-header-column {
      display: block;
      text-align: right;
      width: 100%;
    }
  }
}

.full-page-content .generic-table table.table.light,
.full-page-content table,
.full-page-content .generic-table .ant-table-content .ant-table-body table {
  border: 0;
  border-collapse: separate;
  border-spacing: 0;
  padding: 0;
  width: 100%;

  thead,
  thead.ant-table-thead {
    tr,
    tr:last-child {
      th {
        background: $white;
        border-right: 1px solid $hr;
        border-radius: 0 !important;
        border-top: 1px solid $hr;
        position: relative;
        white-space: nowrap;

        &:first-child {
          padding-left: 20px;

          @include min-screen($min-medium) {
            padding-left: 40px;
          }
        }
        &:last-child {
          padding-right: 20px;

          a.sortem {
            border-right: 0;
          }
          @include min-screen($min-medium) {
            padding-right: 40px;
          }
        }

        &.col-participant {
          padding-left: 80px;

          @include min-screen($min-medium) {
            padding-left: 90px;
          }
          &.viewer {
            padding-left: 20px !important;

            @include min-screen($min-medium) {
              padding-left: 40px !important;
            }
          }
        }

        &.col-country-code {
          text-align: center;
        }

        // country code
        span.icon-globe {
          color: $lightest-gray;
          font-size: 1.3rem;
        }
      }
    }
    tr + tr:last-child {
      th {
        @extend %regular;
        border-top: 0;
        color: $mid-gray;
        font-size: 0.7rem;
      }
    }
  }
  tbody {
    tr,
    tr:hover {
      td {
        border: 0;
        border-bottom: 1px solid $hr;
        border-right: 1px solid $hr;
        border-radius: 0 !important;
        padding: 0;
        vertical-align: top;

        .td-content {
          line-height: 1.2rem;
          padding: 10px;

          .td-content {
            padding: 0;
          }
        }
        p,
        address {
          margin: 0;
          padding: 0;
        }
        &.col-note {
          .td-content {
            max-width: 300px;
            min-width: 200px;
          }
        }
        &.col-address {
          .td-content {
            max-width: 500px;
          }
        }
        &.col-country-code {
          max-width: 70px;
          text-align: center;
          width: 70px;

          .td-content {
            max-width: 70px;
            text-align: center;
          }
          span.country-code {
            @extend %bold;
            color: $all-black;
            cursor: help;
            font-size: 0.75rem;
            text-transform: uppercase;

            // display as tag
            background: $white;
            border: 1px solid $lightest-gray;
            border-radius: 100px;
            display: inline-block;
            font-size: 0.65rem;
            line-height: 1.2rem;
            padding: 0 10px;
            text-align: center;
            text-transform: uppercase;
            white-space: nowrap;
          }
        }
        &.col-participant {
          min-width: 280px;

          .td-content {
            max-width: 420px;
            min-width: 280px;
            padding-left: 60px;
            position: relative;

            span.block {
              word-break: break-all;
            }
            button {
              left: 0;
              position: absolute;
            }
          }
          &.viewer {
            padding-left: 20px !important;

            .td-content {
              padding-left: 0;
            }
            @include min-screen($min-medium) {
              min-width: 240px;
              width: 240px;
              padding-left: 40px !important;

              .td-content {
                width: 240px;
              }
            }
          }
        }
        &.col-accepted-by-them {
          span.icon-ok,
          span.icon-block {
            display: block;
            margin-bottom: 5px;
          }
        }
        &.col-status {
          button {
            margin: 5px 0;
            width: 100%;
          }
        }
        &.col-address,
        &.col-comment {
          .td-content {
            min-width: 250px;
          }
        }
        &.col-rev {
          text-align: right;
          .td-content {
            text-align: right;
          }
        }
        &:first-child {
          padding-left: 15px !important;

          @include min-screen($min-medium) {
            padding-left: 30px !important;
          }
        }
        &:last-child {
          padding-right: 15px !important;

          @include min-screen($min-medium) {
            padding-right: 30px !important;
          }
        }

        span.smaller {
          color: $light-gray;
          line-height: 1.2rem;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        span.divider {
          color: $lightest-gray;
        }
      }

      // alternation
      &:nth-child(odd) {
        td {
          background: #fafbfe;
        }
        &:hover {
          td {
            box-shadow: 0 -5px 0 rgba(0, 0, 0, 0.035) inset;
          }
        }
      }
      &:nth-child(even) {
        td {
          background: #f6f8fd;
        }
        &:hover {
          td {
            box-shadow: 0 -5px 0 rgba(0, 0, 0, 0.035) inset;
          }
        }
      }

      &.ant-table-expanded-row:hover {
        td,
        td:first-child {
          box-shadow: 0 -10px 0 $white inset, 0 -11px 0 $hr inset,
            0 2px 2px rgba(0, 0, 0, 0.05) inset;
        }
      }
    }
  }

  // stick-em
  thead tr th.col-participant,
  tbody tr td.col-participant {
    box-shadow: 2px 0 2px rgba(0, 0, 0, 0.05);
    left: 0;
    position: sticky !important;
    position: -webkit-sticky !important;
    z-index: 2;
  }
  tbody tr:hover td.col-participant {
    box-shadow: 2px 0 2px rgba(0, 0, 0, 0.05),
      0 -5px 0 rgba(0, 0, 0, 0.035) inset !important;
  }

  // light version
  &.light {
    thead {
      tr,
      tr:last-child {
        th {
          background: $white;
        }
      }
    }
  }
}

// light tables in modal
html .ant-modal .ant-modal-content {
  .full-page-content {
    .table-wrapper {
      table.light {
        border: 0;
        border-left: 1px solid $hr;

        thead tr th {
          border-bottom: 1px solid $hr;
          color: $all-black;

          // change this if we add .th-content!
          span.icon-globe {
            position: absolute;
            top: 5px;
          }
        }
        tbody tr td {
          padding: 0 !important;
        }
      }
    }
  }
}

// ant table sorters
.ant-table-column-sorter {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 2;
  height: 20px;
  padding-right: 5px;

  .ant-table-column-sorter-inner-full {
    margin: 0 !important;
  }
}

.ant-table-column-has-sorters {
  padding-right: 35px !important;

  &.ant-table-align-right {
    padding-right: 35px !important;
  }
  &.ant-table-column-has-filters {
    .ant-table-header-column {
      padding-right: 29px;
    }
    i.anticon-filter {
      margin-right: 28px;
    }
  }
}

.market-depth {
  .ant-table-column-sorter {
    border-right: 1px solid $lightest-gray;
  }
}

// products table

.full-page-content .generic-table.products-table {
  .ant-table-content .ant-table-body {
    table {
      tbody {
        tr td {
          padding: 10px !important;

          &:first-child {
            padding-left: 20px !important;
            @include min-screen($min-large) {
              padding-left: 40px !important;
            }
          }
          &:last-child {
            padding-right: 20px !important;
            @include min-screen($min-large) {
              padding-right: 40px !important;
            }
          }
        }
      }
    }
  }
}

// products table in modal
html .ant-modal-body {
  .full-page-header {
    background: none;
    margin: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .full-page-content {
    .ant-table-content .ant-table-body table {
      border-left: 1px solid $hr;
    }
  }
}
