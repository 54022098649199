#account {
  #banners {
    &.mode-list-archived {
      .banner-control-bar {
        .banner-active-switch {
          .banner-status {
            color: $light-gray;
            font-size: 0.8rem;
            margin-left: 0;
          }
        }
      }
      .banner-card {
        border-left: 4px solid rgba(0, 0, 0, 0.05);
        background: #ebebeb;
        display: flex;
        flex-direction: column;
        position: relative;

        .banner-card-content {
          padding: 0.5em 1em;
        }
      }
    }
    overflow: visible;
    &.mode-list-current,
    &.mode-list-archived {
      .ant-form.ant-form-horizontal {
        display: none;
      }
    }
    &.mode-new,
    &.mode-edit {
      & > form {
        display: flex;
        flex-direction: column;
        gap: 8px;
        justify-content: space-evenly;
        &* {
          // Prevent ugly form spring effect.
          transition: none;
        }
        & > .ant-row {
          width: 100%;
        }
      }
    }
    display: flex;
    flex-direction: column;

    .banner-list {
      display: flex;
      flex-direction: column;
      gap: 1.5em;
      margin-bottom: 40px; // Space for Support button
    }
    .banners-tab-bar {
      align-items: center;
      display: flex;
      justify-content: space-around;
      overflow: hidden;
      position: relative;
      width: 100%;

      button {
        position: absolute;
        right: 0;
        top: 10px;
        text-overflow: clip;
      }
      .nui-tabs-nav {
        width: 100%;
      }
    }

    .banner-card {
      border-left: 4px solid var(--link);
      background: $white;
      display: flex;
      flex-direction: column;
      position: relative;

      .banner-card-content {
        border-left-color: $light-gray;
        color: $all-black;
        padding: 0.5em 1em;

        table,
        td {
          padding-right: 22px;
        }
        .banner-card-preview-status {
          display: flex;

          .nui-status-badge {
            border-radius: 100px;
            border: 1px solid #c3c8ca;
            float: right;
            font-size: 0.65rem;
            flex-shrink: 1;
            line-height: 1.2rem;
            margin-top: 0.2rem;
            padding: 0 10px;
            position: absolute;
            right: 10px;
            text-transform: uppercase;
            top: 8px;
            user-select: none;
            width: auto !important;
            white-space: nowrap;

            &.scheduled {
              background-color: $white;
              border-color: $status-scheduled;
              color: $status-scheduled;
            }
            &.draft {
              background-color: $white;
              border-color: $status-draft;
              color: $status-draft;
            }
            &.live {
              background-color: $white;
              border-color: $status-live;
              color: $status-live;
            }
            &.expired {
              background-color: $white;
              border-color: $status-expired;
              color: $status-expired;
            }
            &.paused {
              background-color: $white;
              border-color: $status-paused;
              color: $status-paused;
            }
          }
        }
      }
      &.live {
        background-color: $white;
      }
    }

    .banner-preview {
      direction: ltr;
      width: 100%;

      .nui-user-content {
        color: $light-gray;
        margin: 12px 0 0 0;
        padding: 0 0 6px 0;
        width: 100%;

        p {
          padding-bottom: 0;
        }
      }
      .banner-subject {
        display: inline-block;
        font-size: 1rem;
        max-width: 80%;
        @extend %bold;
      }
      .banner-content {
        margin: 0 0 1em 0;
      }
    }
    .banner-control-bar {
      display: flex;
      justify-content: space-between;
      padding-top: 20px;

      .banner-active-switch {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        .banner-status {
          color: $light-gray;
          font-size: 0.8rem;
          margin-left: 12px;
        }
      }
      .banner-action-bar {
        display: flex;
        justify-content: center;

        .banner-actions {
          align-items: center;
          display: flex;
          gap: 0.5em;

          .banner-action-divider {
            color: $light-gray;
          }

          .banner-action {
            margin: 0;
            padding: 0;
          }
        }
      }
    }
  }
}

.nui-modal-overlay {
  .nui-modal.small {
    .nui-modal-content {
      .banner-action-confirm-title-span {
        color: $h2;
      }
    }
  }
}
