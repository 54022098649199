// mixins

// z-index stack
@for $z from 0 to 50 {
  .stack-#{$z} {
    z-index: $z !important;
  }
}

// modal scroll body height
@for $sb from 0 to 325 {
  .sb-#{$sb} {
    max-height: calc(100vh - #{$sb}px);
    min-height: 33vh;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    z-index: 10;
  }
}

// modal scroll body height, for small only
@for $sb from 0 to 325 {
  .sb-sm-#{$sb} {
    @include max-screen($max-small) {
      max-height: calc(100vh - #{$sb}px);
      min-height: 33vh;
      overflow-x: hidden;
      overflow-y: auto;
      position: relative;
      z-index: 10;
    }
  }
}

// width
@for $w from 0 to 485 {
  .w-#{$w} {
    max-width: 100%;
    width: $w + px !important;
  }
}

// width for medium and up only
@for $wm from 0 to 205 {
  .wm-#{$wm} {
    max-width: 100%;
    @include min-screen($min-medium) {
      width: $wm + px !important;
    }
  }
}

// width for large and up only
@for $wl from 0 to 205 {
  .wl-#{$wl} {
    max-width: 100%;
    @include min-screen($min-large) {
      width: $wl + px !important;
    }
  }
}

// width for xlarge and up only
@for $wxl from 0 to 205 {
  .wxl-#{$wxl} {
    max-width: 100%;
    @include min-screen($min-xlarge) {
      width: $wxl + px !important;
    }
  }
}

// width in percentage
@for $wp from 0 to 100 {
  .wp-#{$wp} {
    max-width: 100%;
    width: #{$wp + '%'} !important;
  }
}

.w-auto {
  width: auto !important;
}

// padding
@for $p from 0 to 205 {
  .p-#{$p} {
    padding: $p + px !important;
  }
}

@for $pt from 0 to 205 {
  .pt-#{$pt} {
    padding-top: $pt + px !important;
  }
}

@for $pr from 0 to 205 {
  .pr-#{$pr} {
    padding-right: $pr + px !important;
  }
}

@for $pb from 0 to 205 {
  .pb-#{$pb} {
    padding-bottom: $pb + px !important;
  }
}

@for $pl from 0 to 205 {
  .pl-#{$pl} {
    padding-left: $pl + px !important;
  }
}

// margin
@for $m from -100 to 205 {
  .m-#{$m} {
    margin: $m + px !important;
  }
}

@for $mt from -100 to 205 {
  .mt-#{$mt} {
    margin-top: $mt + px !important;
  }
}

@for $mr from -100 to 205 {
  .mr-#{$mr} {
    margin-right: $mr + px !important;
  }
}

@for $mb from -100 to 205 {
  .mb-#{$mb} {
    margin-bottom: $mb + px !important;
  }
}

@for $ml from -100 to 205 {
  .ml-#{$ml} {
    margin-left: $ml + px !important;
  }
}

@mixin skip-pointer-events($mode: all) {
  pointer-events: none;
  * {
    pointer-events: $mode;
  }
}
