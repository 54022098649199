// dashboard
#root {
  background-color: #f9f9f9;
}

.ant-calendar-year-panel-body .ant-calendar-year-panel-year,
.ant-calendar-year-panel-body .ant-calendar-month-panel-month,
.ant-calendar-month-panel-body .ant-calendar-year-panel-year,
.ant-calendar-month-panel-body .ant-calendar-month-panel-month {
  &:active,
  &:focus,
  &:hover {
    color: $white;
  }
}

.dashboard {
  svg {
    overflow: visible;
  }

  .ant-calendar-picker {
    input:focus {
      box-shadow: none !important;
    }
  }

  .filter-field-section {
    .date-field {
      width: 300px;

      .ant-calendar-picker {
        width: 100%;
      }
    }
    @include max-screen($max-small) {
      display: block;
      width: 100%;

      .date-field,
      .filter-section {
        display: block;
        margin: 0 0 10px;
        padding: 0;
        width: 100% !important;
      }
    }
  }

  .full-page-header {
    position: relative;

    h1 {
      display: inline-block;
      margin: 10px 20px 10px 0;
      vertical-align: middle;
    }
    h1 + .show-dd-items {
      display: inline-block;
      margin: 10px 20px 10px 0;
      vertical-align: middle;

      @include max-screen($max-small) {
        margin: 0;
        position: absolute;
        right: 10px;
        top: 20px;
      }
    }
  }

  .inline-block h2 {
    min-width: 195px;
  }

  .filter-section {
    &.show-on-db {
      margin: 0 0 20px;

      @include min-screen($min-medium) {
        margin: 10px 0;
      }
    }
  }

  .chart-block-header {
    h2 .icon-help-circled {
      display: inline-block;
      margin-left: 5px;
    }
  }

  h2,
  .inline-block h2 {
    &.show-on-db {
      font-size: 1rem;
      min-width: 1px;
    }
  }

  h2 {
    color: $all-black;
    font-size: 1.1rem;
    text-transform: lowercase;

    &:first-letter {
      text-transform: uppercase;
    }
    span.icon-help-circled {
      &:before {
        color: rgba(0, 0, 0, 0.25);
      }
    }
  }
  .chart-container {
    width: 100%;

    .chart-block {
      height: 300px;
      width: 100%;
    }
    &.chart-left-large {
      @include min-screen($min-large) {
        display: inline-block;
        padding-right: 20px;
        vertical-align: top;
        width: 65%;
      }
    }
    &.active-user-chart,
    &.chart-right-small {
      padding: 40px 0;

      @include min-screen($min-large) {
        display: inline-block;
        padding: 0;
        vertical-align: top;
        width: 35%;
      }
      .nui-table {
        table {
          tbody {
            tr:nth-child(even) {
              td {
                background: $body;
              }
            }
          }
          .th-content,
          .td-content {
            font-size: 0.8rem;
            padding: 5px;
          }
        }
      }
    }

    .date-field {
      width: 300px;

      .ant-calendar-picker {
        max-width: 100%;
      }
    }
    .ant-calendar-picker-input {
      line-height: 34px;
      min-height: 44px;
      max-width: 100%;
    }

    &.company-activity-chart {
      padding-bottom: 40px;
      width: 100%;

      @include min-screen($min-large) {
        padding-right: 20px;
        width: 65%;
      }
    }
  }
}

// dashboard 2
.dashboard2,
.tender-report-content {
  .full-page-header {
    position: relative;
  }
  .full-page-header + .full-page-header.dashboard-header {
    margin: -16px 0 0;
    position: relative;

    @include min-screen($min-medium) {
      margin: -22px 0 0;
    }
  }
  .dashboard-tabs {
    border-bottom: 1px solid $hr;

    @include min-screen($min-small) {
      padding: 10px 0 0;
    }
  }
  .dashboard-filter {
    margin: 10px 0 0;
    transition: 0.2s all linear;

    @include min-screen($min-medium) {
      position: absolute;
      right: 40px;
      top: -50px;
      width: 530px;
    }
    @include min-screen($min-large) {
      top: 4px;
    }
    .nui-fieldset {
      margin: 0;

      @include min-screen($min-medium) {
        text-align: right;
        white-space: nowrap;
      }
      label {
        @extend %bold;
        color: $all-black;
        display: none;

        @include min-screen($min-large) {
          display: inline-block;
          margin: 0 10px 0 0;
          vertical-align: middle;
        }
      }
      .select-holder {
        @include min-screen($min-medium) {
          display: inline-block;
          max-width: 200px;
          vertical-align: middle;
        }
      }

      .input-holder.date-holder {
        @include min-screen($min-medium) {
          display: inline-block;
          max-width: 250px;
          vertical-align: middle;
        }
      }

      .input-holder.date-holder {
        margin: 10px 0 0;

        @include min-screen($min-medium) {
          margin: 0 0 0 10px;
        }
        span.ant-calendar-picker {
          display: block;
          width: 100%;
        }
        span.ant-input {
          display: block;
          height: 44px;
          padding-right: 40px;
          width: 100%;
        }
        span input {
          background: none;
          border: 0;
          display: inline-block;
          height: auto;
          max-width: 90px;
          min-height: 1px;
          padding: 0;
          position: relative;
          top: 4px;
          vertical-align: middle;
        }
        .ant-calendar-range-picker-separator {
          height: auto;
          line-height: 1;
          min-height: 1px;
          position: relative;
          top: 9px;
          vertical-align: top;
        }
      }
    }
  }
}

// charts
.dashboard2,
.tender-report-content {
  svg {
    overflow: visible;
  }

  .chart-container {
    display: block;
    width: 100%;

    @include min-screen($min-large) {
      display: inline-block;
      vertical-align: top;
    }
    .chart {
      height: 350px;
      width: 100%;
    }
    h3 {
      .icon-help-circled {
        color: rgba(0, 0, 0, 0.25);
      }
    }
    .nui-tooltip {
      background: $focus;
      border: 1px solid $lightest-gray;
      border-radius: 3px;
      box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
      color: $dark-gray;
      font-size: 0.7rem;
      line-height: 1.1rem;
      margin: 0;
      min-width: 150px;
      padding: 5px;

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
      }
    }
    .filter-section {
      display: block;
      margin: 20px 0;
      padding: 0;
      width: 100%;

      .dropcheck,
      .nui-fieldset.show-products {
        @include min-screen($min-medium) {
          width: 300px;
        }
      }
      .nui-fieldset.show-units + .dropcheck {
        display: inline-block;
        vertical-align: middle;
        width: calc(100% - 160px);

        @include min-screen($min-medium) {
          width: 300px;
        }
      }
      .nui-fieldset.show-units {
        display: inline-block;
        vertical-align: middle;
        width: 150px;

        @include min-screen($min-medium) {
          width: 200px;
        }
      }
    }
    ul.chart-legend {
      font-size: 0.8rem;
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        white-space: nowrap;

        span.color-block {
          display: inline-block;
          vertical-align: middle;
        }
        span.name {
          display: inline-block;
          vertical-align: middle;
          white-space: nowrap;
        }
      }
    }
  }

  // containers
  .chart-container {
    &.user-activity,
    &.historic-prices,
    &.trade-volumes,
    &.tender-bid-price,
    &.product-activity {
      @include min-screen($min-xlarge) {
        display: inline-block;
        margin-right: 20px;
        vertical-align: top;
        width: 50%;
      }
      @include min-screen($min-xxlarge) {
        width: 60%;
      }

      // checkboxes
      .checks-inline {
        padding-top: 10px;

        .nui-check-fieldset {
          display: inline-block;
          margin: 0 20px 0 0;
          vertical-align: top;
        }
      }
    }
    .filter-section {
      > label {
        @extend %bold;
        color: $all-black;
        display: block !important;
        margin: 0 0 8px;
      }
    }

    &.tender-bid-price {
      .chart-section {
        &.counter-price-chart {
          padding-top: 11px;
          position: relative;

          .filter-section {
            left: 110px;
            margin: 0;
            width: calc(100% - 118px);
            position: absolute;
            top: -43px;
          }
        }
      }
    }

    &.user-activity.unique {
      @include min-screen($min-xlarge) {
        .chart {
          margin-top: 30px;
        }
      }
    }

    &.pie-chart-container,
    &.table-chart-container {
      @include min-screen($min-xlarge) {
        display: inline-block;
        vertical-align: top;
        width: calc(50% - 20px);
      }
      @include min-screen($min-xxlarge) {
        width: calc(40% - 20px);
      }
      .chart.pie-chart {
        @include min-screen($min-small) {
          display: inline-block;
          vertical-align: top;
          width: 50%;
          transition: none !important;

          * {
            transition: none !important;
          }
        }
      }
      .chart-legend {
        @include min-screen($min-small) {
          display: inline-block;
          vertical-align: top;
          width: 50%;
        }
        table {
          width: 100%;

          th,
          td {
            font-size: 0.8rem;
            line-height: 1rem;
            padding: 5px;
            vertical-align: top;
          }
          th {
            color: $all-black;
            vertical-align: bottom;
          }
          td {
            border-top: 1px solid $hr;
          }
        }
      }
    }

    &.tender-report-info {
      margin: 0;
      padding: 0;
      width: 100%;

      ul.data-list {
        padding-bottom: 20px;
        @include min-screen($min-medium) {
          padding-bottom: 50px;
        }
        li {
          border-right: 1px solid $hr;
          display: inline-block;
          padding-bottom: 10px;
          padding-top: 10px;
          padding-right: 10px;
          text-align: center;
          width: calc(33% - 10px);

          &:first-child,
          &:nth-child(2),
          &:nth-child(3) {
            border-bottom: 1px solid $hr;
          }
          &:nth-child(3),
          &:last-child {
            border-right: 0;
            padding-right: 0;
          }
          @include min-screen($min-large) {
            width: calc(16.65% - 10px);

            &:first-child,
            &:nth-child(2),
            &:nth-child(3) {
              border-bottom: 0;
            }
            &:nth-child(3) {
              border-right: 1px solid $hr;
              padding-right: 10px;
            }
          }
        }
        strong {
          @extend %regular;
          color: #ec4004;
          display: block;
          font-size: 0.7rem;
          text-align: center;
          white-space: nowrap;

          @include min-screen($min-medium) {
            font-size: 0.8rem;
          }
        }
        strong + span {
          @extend %bold;
          color: $all-black;
          display: block;
          font-size: 1rem;
          line-height: 1rem;
          text-align: center;

          @include min-screen($min-medium) {
            font-size: 1.25rem;
            line-height: 1.25rem;
          }
        }
        .unit-detail {
          font-size: 1rem;
          line-height: 1rem;
          padding: 0;
          text-align: center;

          @include min-screen($min-medium) {
            font-size: 1.25rem;
            line-height: 1.25rem;
          }
          span.unit {
            color: $light-gray;
            margin: 0;
            position: relative;
            top: 0;
          }
        }
      }
    }

    &.table-chart-container {
      .table-wrapper {
        border: 1px solid $hr;
        max-height: 400px;
        padding: 0;
        overflow-y: auto;
      }
      table.table {
        background: $white;
        position: relative;
        width: 100%;
        th,
        td {
          border-bottom: 1px solid $hr;
          border-left: 1px solid $hr;
          padding: 3px 10px;
          vertical-align: top;

          &:first-child {
            border-left: 0;
          }
        }
        tr:last-child {
          td {
            border-bottom: 0;
          }
        }
        tr:nth-child(even) {
          td {
            background: #f6f6f6;
          }
        }
        th {
          color: $all-black;
          padding: 6px 10px;
        }
        thead {
          tr + tr {
            th {
              @extend %regular;
              background: #efefef;
              color: $mid-gray;
              font-size: 0.8rem;
              padding: 3px 10px;
            }
          }
        }
        tfoot {
          td {
            @extend %bold;
            border-top: 1px solid $hr;
            color: $all-black;
          }
        }
      }
    }
  }

  .trade-volume-containers {
    .chart-container.trade-volumes {
      display: block;
      width: 100%;
    }
    @include min-screen($min-xlarge) {
      display: inline-block;
      margin-right: 20px;
      vertical-align: top;
      width: 50%;
    }
    @include min-screen($min-xxlarge) {
      width: 60%;
    }
  }

  .chart-container.trade-volumes + .chart-container.trade-volumes {
    border-top: 1px solid $hr;
    margin-top: 20px;
    padding-top: 20px;
  }

  // last seen table
  .chart-container {
    &.last-seen,
    &.tender-report-participants {
      position: relative;

      @include min-screen($min-medium) {
        .search-fieldset {
          position: absolute;
          right: 0;
          top: -10px;
        }
        section {
          padding-top: 20px;
        }
      }
      @include min-screen($min-xlarge) {
        display: inline-block;
        vertical-align: top;
        width: calc(50% - 20px);
      }
      @include min-screen($min-xxlarge) {
        width: calc(40% - 20px);
      }

      .show-viewers {
        position: absolute;
        right: 0;
        top: 45px;

        label {
          display: inline-block;
          margin-right: 10px;
        }
        @include min-screen($min-medium) {
          right: 260px;
          top: 0;
        }
      }

      // table
      .table-wrapper {
        border-bottom: 1px solid $hr;
        border-top: 1px solid $hr;
        border-right: 1px solid $hr;
        border-left: 0;
        margin: 0;
        max-height: 350px;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 0;

        .nui-table-placeholder {
          background: $white;
          box-shadow: 1px 0 0 $hr inset;
          padding: 20px 0;
          text-align: center;
          text-transform: lowercase;

          &::first-letter {
            text-transform: uppercase;
          }
        }
      }
      table.table,
      .simple-table table {
        background: $white;
        border: 1px solid $hr;
        border-bottom: 0;
        border-top: 0;
        border-radius: 3px;
        border-right: 0;
        position: relative;
        width: 100%;

        thead,
        thead.nui-table-thead {
          tr {
            position: relative;
            th {
              @extend %bold;
              background: $white !important;
              border-bottom: 0;
              border-left: 1px solid $hr;
              box-shadow: 0 -1px 0 $hr inset;
              color: $all-black;
              font-weight: normal;
              padding: 5px;
              position: sticky;
              top: 0;
              z-index: 2;

              .th-content {
                padding: 0;
              }
              button {
                @extend %nd;
                @extend %bold;
                color: $all-black;
                font-size: 0.9rem;
                margin: 0;
                min-height: 0;
                padding: 0;
                text-align: left;
                width: 100%;

                &:before {
                  color: rgba(0, 0, 0, 0.25);
                  font-size: 1rem;
                  position: absolute;
                  right: 0;
                  text-align: left;
                  top: 8px;
                  width: 15px;
                  z-index: 2;
                }
                &.up,
                &.down {
                  &:after {
                    @extend %fontello;
                    color: $mid-gray;
                    content: '\f0de';
                    font-size: 1rem;
                    position: absolute;
                    right: 0;
                    text-align: left;
                    top: 8px;
                    width: 15px;
                    z-index: 3;
                  }
                }
                &.down {
                  &:after {
                    content: '\f0dd';
                  }
                }
              }
              &:first-child {
                border-left: 0;
                border-radius: 3px 0 0 0;
                max-width: 40px;
                text-align: center;
                width: 40px;
              }
              &:last-child {
                border-radius: 0 3px 0 0;
              }
            }
          }
        }
        tbody,
        tbody.nui-table-tbody {
          tr {
            &:first-child {
              td {
                border-top: 0;
              }
            }
            td {
              border-left: 1px solid $hr;
              border-top: 1px solid $hr;
              padding: 5px;

              .td-content {
                padding: 0;
              }
              &:first-child {
                border-left: 0;
                max-width: 40px;
                text-align: center;
                width: 40px;
              }
            }
            &:nth-child(odd) {
              td {
                background: #f6f6f6;
              }
            }
            &:last-child {
              td {
                &:first-child {
                  border-radius: 0 0 0 3px;
                }
                &:last-child {
                  border-radius: 0 0 3px 0;
                }
              }
            }
          }
        }
      }

      .simple-table table {
        thead.nui-table-thead,
        tbody.nui-table-tbody {
          tr {
            th:first-child,
            td:first-child {
              max-width: none;
              padding-left: 5px !important;
              text-align: left;
              width: auto;
            }
            th:last-child,
            td:last-child {
              padding-left: 5px !important;
            }
          }
        }
        tbody.nui-table-tbody {
          tr:last-child {
            td {
              border-bottom: 0 !important;
            }
          }
        }
      }
    }

    &.last-seen {
      th .nui-button.icon-sort {
        padding-right: 20px;
      }
    }
  }

  // orders table
  .full-page-content {
    &.tender-report-orders {
      .generic-table {
        margin-left: -20px;
        margin-bottom: 40px;
        width: calc(100% + 41px);

        @include min-screen($min-medium) {
          margin-left: -40px;
          width: calc(100% + 81px);
        }
      }
    }
  }
}

// last seen status
.last-seen-status {
  background: #e1e1e1;
  border-radius: 50%;
  display: inline-block;
  height: 8px;
  position: relative;
  width: 8px;

  &.gray {
    background: $light-gray;
  }
  &.red {
    background: $error;
  }
  &.amber {
    background: #ff6f3d;
  }
  &.green {
    background: $ok;
  }
}

.chart-legend {
  padding: 5px 0;

  .last-seen-status {
    margin-right: 1px;
    top: -1px;
  }
  .name {
    font-size: 0.7rem;
  }
}

.icon-tooltip {
  .last-seen-status {
    height: 6px;
    left: -15px;
    position: absolute;
    top: 5px;
    width: 6px;
  }
}

// Global commodity pricing tab
.global-comodity-pricing {
  width: 100%;

  .left-hand-panel {
    display: inline-block;
    margin-right: 20px;
    vertical-align: top;
    width: 100%;

    @include min-screen($min-xlarge) {
      display: inline-block;
      margin-right: 20px;
      vertical-align: top;
      width: 100%;
    }
    @include min-screen($min-xxlarge) {
      width: 40%;
    }
    .generic-table {
      max-height: 440px;
      overflow-y: auto;
      width: 100%;

      @include min-screen($min-xlarge) {
        width: 100%;
      }
      @include min-screen($min-xxlarge) {
        width: 100%;
      }

      .nui-table-content {
        .nui-table-body {
          table {
            background: #f9f9f9;

            border: none;
            white-space: nowrap;

            .nui-table-thead > tr,
            th,
            td {
              background: #f9f9f9;
              border: none;
              text-align: right;
            }
            th > * {
              text-align: right;

              span {
                position: relative;
                top: -10px;
              }
            }
            th.col-header-end-price > * {
              text-align: right;

              div {
                text-align: right;
              }
            }
            th.col-header-avg-price,
            th.col-header-price-diff {
              div {
                div {
                  text-align: right;
                }
              }
            }

            tr {
              th.col-header-product {
                text-align: start !important;

                &:hover {
                  background-color: transparent;
                }
                span {
                  position: relative;
                  top: -22.4px;
                }
              }
              th {
                padding-bottom: 0;

                span.subtext {
                  @extend %regular;
                  bottom: 10px;
                  font-size: 0.7rem;
                  position: relative;
                }

                th.col-header-end-price,
                th.col-header-avg-price,
                th.col-header-price-diff {
                  span.wow {
                    vertical-align: text-top;
                  }

                  span.popover-link {
                    margin-left: 3px;
                    position: relative;
                    top: 2px;
                    vertical-align: top;
                  }
                }
              }
            }

            tbody {
              tr {
                .col-header-product {
                  text-align: start;
                }
              }
              tr:hover {
                td {
                  background: $white;
                }
              }
            }
            .price-diff {
              text-align: end;

              .arrow {
                @include arrow(0, 0);
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                display: inline-block;
                position: relative;
                top: -2px;
                transform: scale(-0.8, -0.8);
                -webkit-transform: scale(0.8, 0.8);
              }

              &.positive {
                color: $ok;

                .arrow {
                  border-block-color: $ok;
                  transform: scale(-0.8, -0.8);
                  -webkit-transform: scale(-0.8, -0.8);
                }
              }
              &.negative {
                color: $warning;

                .arrow {
                  border-block-color: $warning;
                  transform: scale(-0.8, -0.8);
                  -webkit-transform: scale(0.8, 0.8);
                }
              }
              &.no-change {
                color: $mid-gray;
              }
            }
          }
          .col-header-product {
            background-color: transparent;

            &:hover {
              background-color: $white;
            }
          }
        }
      }
    }

    .viewing {
      display: inline-block;
      margin-bottom: 15px;
      width: 100%;

      .actions {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        position: relative;
        width: 100%;
        white-space: nowrap;

        button.nui-button {
          border: none;
          background: transparent;
          color: var(--link);
          display: inline;
          position: relative;

          &:disabled {
            color: $light-gray !important;
            background: transparent !important;
          }
          &.prev {
            &:before {
              @extend %fontello;
              content: '\f104';
            }
          }
          &.next {
            right: -10px;

            &:after {
              @extend %fontello;
              content: '\f105';
            }
          }
        }
      }
      div {
        padding: 4px 0;
        text-align: center;

        .viewing-text {
          @extend %bold;
          color: $all-black;
          white-space: nowrap;
        }
      }

      .viewing-range-group {
        color: var(--link);

        .viewing-range-radio-label {
          position: initial;
          transition-property: none;
          vertical-align: sub;
        }
        .ant-radio {
          position: relative;
          top: 3px;
        }
      }
    }
  }
}

.global-comodity-pricing {
  .index-pricing-graph {
    display: inline-block;
    margin-left: 20px;
    margin-right: 20px;
    vertical-align: top;
    width: 100%;

    @include min-screen($min-xlarge) {
      display: inline-block;
      margin-right: 20px;
      vertical-align: top;
    }
    @include min-screen($min-xxlarge) {
      width: 55%;
    }
    .chart {
      height: 350px;
      width: 100%;
    }
    .nui-tooltip {
      background: $focus;
      border: 1px solid $lightest-gray;
      border-radius: 3px;
      box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
      color: $dark-gray;
      font-size: 0.7rem;
      line-height: 1.1rem;
      margin: 0;
      min-width: 150px;
      padding: 5px;

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
      }
    }
    ul.chart-legend {
      font-size: 0.8rem;
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        white-space: nowrap;

        span.color-block {
          display: inline-block;
          vertical-align: middle;
        }
        span.name {
          display: inline-block;
          vertical-align: middle;
          white-space: nowrap;
        }
      }
    }

    .nui-fieldset {
      margin-left: auto;
      position: relative;
      top: 3.4px;
      width: 22%;

      @include max-screen($max-xlarge) {
        margin: 24px 0 48px auto !important;
        width: 22%;
      }
      @include max-screen($max-small) {
        margin-right: 24px !important;
      }
    }
  }
}
