﻿// forms -  wrapping it in a parent class, .nui-form, for now
.ant-form-item-label > label::after {
  display: none;
}

.ant-calendar-range .ant-calendar-in-range-cell::before {
  background: #e4f6fd !important;
}
.ant-input-number-focused {
  box-shadow: none !important;
  outline: none !important;
}

.ant-select-arrow {
  svg {
    color: $light-gray;
    height: 1rem;
    width: 1rem;
  }
}

.ant-select-selection {
  border-radius: 3px;
  font-size: 0.9rem;
}

.ant-select-lg .ant-select-selection--single {
  height: 42px;
}

.ant-select-lg .ant-select-selection__rendered {
  line-height: 44px;
}

.nui-form,
* {
  .ant-select-focused .ant-select-selection,
  .ant-select-selection:focus,
  .ant-select-selection:active {
    box-shadow: none;
  }

  .ant-form-item-control-wrapper
    .ant-form-item-control
    .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-active {
    background: #e4f6fd;
  }

  .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
    background: #e4f6fd;
  }

  .ant-form-item {
    span.ant-form-item-children {
      display: block;

      &:focus,
      .ant-input-number-input-wrap,
      .ant-input-number-focused,
      .ant-select-focused,
      .ant-input-focused {
        box-shadow: none !important;
        outline: none !important;
      }
    }

    border: 0;
    color: $all-black;
    margin: 0;
    max-width: 100%;
    padding: 0 0 15px;
    position: relative;

    .ant-form-item-label {
      display: block;
      line-height: inherit;
      padding: 0;

      label {
        color: $mid-gray;
        cursor: pointer;
        display: block;
        font-size: 0.9rem;
        margin: 0 0 8px;
        position: relative;
        text-align: left;
        white-space: normal;

        span.normal {
          color: darken($light-gray, 5%);
          display: block;
          font-size: 0.7rem;

          &.warning {
            color: #c68508;
          }
        }
        span.expiry-warning {
          color: $warning;
          display: inline-block;
          font-size: 0.8rem;
          margin-left: 7px;
        }
      }
      // remove the :colon from label
      > label:after {
        display: none;
      }
      .ant-form-item-required::before {
        // not using styling for required fields, only need to add optional, to optional fields
        display: none;
      }
      label:not(.ant-form-item-required) {
        padding-right: 60px;
        position: relative;

        &:after {
          color: $ok;
          content: 'Optional';
          display: inline-block;
          font-size: 0.7rem;
          margin: 0;
          position: absolute;
          right: 0;
          text-transform: uppercase;
          top: 5px;
        }
      }
    }
  }
}

// multiselect
.form-item-type-MultipleSelect {
  .ant-form-item-control-wrapper {
    position: relative;

    &:after {
      border-top: 6px solid rgba(0, 0, 0, 0.25);
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      content: ' ';
      display: block;
      margin-top: -2px;
      pointer-events: none;
      position: absolute;
      right: 12px;
      top: 20px;
      transition: all 0.2s linear;
      z-index: 2;
    }
    &:active {
      &:after {
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
      }
    }
    .ant-select-selection__rendered {
      padding-right: 25px;
    }
  }
}

// overrides specifically for order form - for now
.nui-form.order-form {
  // customer prices
  .nui-button.customer-prices {
    background: none !important;
    border: 0 !important;
    box-shadow: none !important;

    span {
      @extend %dd;
      font-size: 0.7rem;
      text-transform: uppercase;
    }
    &:hover {
      span {
        @extend %nd;
      }
    }
  }

  .form-item-name-counteronly {
    label.ant-radio-wrapper {
      display: block;
      padding: 10px 0 0 0;
    }
    span.popover-link {
      top: 10px;
    }
  }

  .form-item-name-price,
  .form-item-name-volume,
  .form-item-name-size,
  .form-item-name-group {
    display: inline-block;
    vertical-align: top;
    margin-right: 2%;
    width: 49%;

    .ant-input-number {
      width: 100%;
    }
  }
  .form-item-name-volume {
    margin-right: 0;
  }
  // totals
  .ant-form-item-control {
    .totals {
      line-height: 1.5rem;

      span.label {
        display: inline-block;
        padding-right: 10px;
        width: auto;
      }
    }
  }

  // inline radio buttons
  .form-item-type-Boolean
  //,
  //.form-item-name-incoterms
  {
    padding: 10px 0;

    .ant-form-item-label {
      display: inline-block;
      margin: 0;
      vertical-align: middle;
      width: 180px;
    }
    .ant-form-item-control-wrapper {
      display: inline-block;
      vertical-align: middle;
      width: auto;
    }
  }

  .form-item-type-Boolean .ant-form-item-label > label {
    margin: 0;
  }

  .form-item-type-Boolean + .form-item-type-Boolean {
    padding: 0;
    position: relative;
    top: -10px;
  }

  .form-item-name-incoterms {
    padding: 0 0 20px;
  }

  .form-item-vertical {
    .ant-form-item-label {
      display: block;
      width: 100%;
    }
  }

  .ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date:hover,
  .ant-calendar-range
    .ant-calendar-selected-start-date
    .ant-calendar-date:hover {
    background: var(--link);
  }

  .has-error {
    .ant-calendar-picker-icon::after,
    .ant-time-picker-icon::after,
    .ant-picker-icon::after,
    .ant-select-arrow,
    .ant-cascader-picker-arrow {
      color: $error;
    }
  }

  .nui-alert.alert-fee > strong {
    display: inline-block;
  }

  .alert-fee {
    margin-bottom: 15px;
  }

  .pallets-request {
    margin-bottom: 15px;
    blockquote {
      margin: 0 0 0em;
    }
  }
}

// end .nui-form
// =============

// global
html {
  .ant-form-item {
    .ant-form-item-label {
      label {
        color: $mid-gray;
        font-size: 0.9rem;
      }
    }
  }
}

.ant-input {
  border-radius: 3px !important;
}

.ant-input-disabled,
.ant-input-number-disabled,
.ant-select-disabled {
  border-color: $lightest-gray !important;
  * {
    border-color: $lightest-gray !important;
    color: $light-gray;
  }
}

// number
.ant-input-number {
  width: 130px;
}

// checkbox
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--checkbox);
  border-color: var(--checkbox);
}

// ant radio
.ant-radio-group {
  font-size: 0.9rem;

  legend {
    font-size: 0.9rem;
    &.inline {
      display: inline-block;
      float: left;
    }
  }
  .ant-radio-wrapper {
    font-size: 0.9rem;
    .ant-radio {
      font-size: 0.9rem;
      .ant-radio-inner {
        border-color: #d9d9d9;
        border-width: 2px;
        height: 18px;
        width: 18px;

        &:after {
          background-color: transparent;
          border-radius: 50%;
          display: block;
          height: 10px;
          left: 2px;
          top: 2px;
          width: 10px;
        }
      }
    }
    &:active,
    &:focus,
    &:hover {
      .ant-radio {
        .ant-radio-inner {
          border-color: var(--radio);
        }
      }
    }
    &.ant-radio-wrapper-checked {
      .ant-radio {
        .ant-radio-inner {
          border-color: var(--radio);

          &:after {
            background-color: var(--radio);
            border-radius: 50%;
            display: block;
            height: 10px;
            left: 2px;
            top: 2px;
            width: 10px;
          }
        }
      }
    }
    &.disabled,
    &.readonly,
    &.ant-radio-wrapper-checked.disabled,
    &.ant-radio-wrapper-checked.readonly {
      pointer-events: none;
      .ant-radio {
        .ant-radio-inner {
          border-color: $lightest-gray;
        }
      }
    }
    &.ant-radio-wrapper-checked.disabled,
    &.ant-radio-wrapper-checked.readonly {
      pointer-events: none;
      .ant-radio {
        .ant-radio-inner {
          border-color: $lightest-gray !important;
          &:after {
            background-color: $lightest-gray !important;
          }
        }
      }
    }
  }

  // radio solid
  &.ant-radio-group-solid {
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
      background: var(--radio);
      border-color: var(--radio);

      &:active,
      &:focus,
      &:hover {
        background: var(--radio);
        border-color: var(--radio);
        color: $white;
      }
    }
    .ant-radio-button-wrapper {
      border-radius: 3px !important;
      font-size: 0.8rem;
      margin: 5px 3px 0;
      padding: 0;
      text-align: center;

      width: calc(25% - 6px);
      &:active,
      &:focus,
      &:hover {
        background: rgba(0, 0, 0, 0.025);
        color: $all-black;
      }
    }
  }
}

.ant-radio.ant-radio-disabled {
  .ant-radio-inner {
    border-color: $lightest-gray !important;
  }
  &.ant-radio-checked {
    .ant-radio-inner:after {
      background-color: $lightest-gray !important;
    }
  }
}

.ant-form-item-control-wrapper {
  .ant-form-item-control {
    max-width: 100%;

    .ant-input,
    .ant-input-lg,
    .ant-input-number,
    .ant-select-selection,
    textarea {
      border: 1px solid $lightest-gray;
      color: $all-black;
      &:active,
      &:focus,
      &:hover {
        border: 1px solid $mid-gray;
        box-shadow: none;
        outline: 0;
      }
    }

    &.has-error {
      .ant-input,
      .ant-input-lg,
      .ant-input-number,
      .ant-select-selection,
      textarea {
        border: 1px solid $error !important;
        color: $error !important;
        &:active,
        &:focus,
        &:hover {
          border: 1px solid $error !important;
        }
      }
      .ant-form-explain {
        color: $error;
        font-size: 1;
        padding: 5px 0 0 15px;
        position: relative;

        &:before {
          border-bottom: 5px solid $error;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          content: ' ';
          display: inline-block;
          height: 0;
          left: 0;
          margin: 0 5px 0 0;
          position: absolute;
          top: 13px;
          vertical-align: middle;
          width: 0;
        }
      }
    }

    .ant-input-number-input {
      text-align: right;
      padding-right: 30px;

      &:active,
      &:focus,
      &:hover {
        box-shadow: none;
        outline: 0;
      }
    }

    // select
    .ant-select-dropdown-menu-item {
      line-height: inherit;
      white-space: normal;
      text-overflow: unset;

      &.ant-select-dropdown-menu-item-active {
        background: lighten($link, 55%);
      }
    }

    .ant-select-dropdown.ant-select-dropdown--multiple
      .ant-select-dropdown-menu-item {
      text-shadow: none;
    }

    .ant-select-dropdown.ant-select-dropdown--multiple
      .ant-select-dropdown-menu-item-selected,
    .ant-select-dropdown.ant-select-dropdown--multiple
      .ant-select-dropdown-menu-item-selected:hover {
      .ant-select-selected-icon {
        color: var(--link);
      }
    }

    .ant-select-lg
      .ant-select-selection--multiple
      .ant-select-selection__rendered
      li {
      font-size: 0.9rem;
      height: auto;
      line-height: 1.4rem;
    }

    .ant-select-selection--multiple .ant-select-selection__choice {
      padding: 4px 20px 4px 10px;
    }

    .ant-select-selection__choice__remove {
      top: 3px;
    }

    .ant-select-selection--multiple .ant-select-selection__choice__content {
      display: table-cell;
      position: relative;
      text-overflow: unset;
      white-space: normal;

      .ant-select-selection__choice__remove {
        position: absolute;
        right: 5px;
        top: 0;
      }
    }

    // picker
    .ant-calendar-picker-large {
      width: 100%;
    }

    textarea {
      font-size: 100%;
      min-height: 65px;
      resize: vertical;
    }
  }
}

// checkbox
.ant-checkbox-checked::after {
  border: 0;
}

label.ant-checkbox-wrapper,
.ant-checkbox-wrapper {
  .ant-checkbox {
    display: inline-block;

    input.ant-checkbox-input {
      min-height: 18px;
    }
    span.ant-checkbox-inner {
      border: 2px solid #d9d9d9;
      border-radius: 3px;
      height: 19px;
      line-height: 22px;
      margin-top: -2px;
      text-align: center;
      width: 19px;

      &:after {
        @extend %fontello;
        border: 0;
        bottom: 0;
        color: $white;
        content: '\e802';
        font-size: 0.8rem;
        left: 0;
        margin: 1px;
        position: absolute;
        right: 0;
        top: 0;
        transform: none;
        -webkit-transform: none;
      }
    }
    &.ant-checkbox-checked {
      span.ant-checkbox-inner {
        background-color: var(--checkbox);
        border-color: var(--checkbox);
      }
    }
    &.ant-checkbox-indeterminate {
      span.ant-checkbox-inner {
        background-color: $white;
        border-color: var(--checkbox);
        content: ' ';

        &:after {
          @extend %fontello;
          background: var(--checkbox);
          border: 0;
          bottom: auto;
          content: ' ';
          font-size: 0;
          height: 9px;
          left: 3px;
          margin: 0;
          right: auto;
          top: 3px;
          width: 9px;
        }
      }
    }
  }
  &:active,
  &:focus,
  &:hover {
    .ant-checkbox {
      span.ant-checkbox-inner {
        border: 2px solid var(--checkbox);
      }
    }
  }
  .ant-checkbox + span {
    @extend %regular;
    color: lighten($mid-gray, 15%);
    display: inline-block;
  }

  &:active,
  &:focus,
  &:hover {
    .ant-checkbox + span {
      color: $all-black;
    }
  }
  &.disabled,
  &.readonly {
    .ant-checkbox {
      span.ant-checkbox-inner {
        border-color: $lightest-gray;
        cursor: default;
      }
      &.ant-checkbox-checked {
        span.ant-checkbox-inner {
          background-color: $lightest-gray;
          border-color: $lightest-gray;
          cursor: default;
        }
      }
    }
  }
}

.ant-alert {
  .fieldset.check-fieldset {
    span {
      font-size: 0.9rem;
    }
  }
}

// calendar
.ant-calendar-range {
  .ant-calendar-range-middle {
    padding: 0;
  }
}

.ant-calendar-time-picker-select li:focus {
  color: var(--link);
}

.ant-calendar-picker-input.ant-input {
  border-color: $lightest-gray !important;
}

.ant-calendar-picker:focus .ant-calendar-picker-input:not(.ant-input-disabled),
.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: $mid-gray !important;
  box-shadow: none;
}

.ant-calendar-picker-icon {
  &:before {
    @extend %fontello;
    content: '\e810';
    font-size: 1.2rem;
    margin: -3px 0 0 -5px;
  }
  svg {
    display: none;
  }
}

.ant-calendar-picker-clear {
  background: $white;
  height: 25px;
  line-height: 25px;
  margin: -13px 0 0 0;
  text-align: center;
  width: 20px;

  &:before {
    @extend %fontello;
    content: '\e828';
    font-size: 0.7rem;
    margin: 0;
  }
  svg {
    display: none;
  }
}

.ant-calendar-header {
  .ant-calendar-my-select {
    display: block;
    text-align: center;
    a {
      color: $link;
      &:hover {
        color: $all-black;
      }
    }
  }
}

.ant-calendar-date {
  &:active,
  &:focus,
  &:hover {
    background: var(--link);
    border: 0;
    box-shadow: 0 0 100px rgba(0, 0, 0, 0.2);
    color: $white;
  }
}

.ant-calendar-tbody {
  .ant-calendar-today {
    .ant-calendar-date {
      @extend %regular;
      background: $white;
      border: 1px solid $lightest-gray;
      border-radius: 3px;
      color: $link;

      &:hover {
        background: $white;
        color: $all-black;
      }
    }
  }

  .ant-calendar-selected-date,
  .ant-calendar-selected-start-date,
  .ant-calendar-selected-end-date {
    .ant-calendar-selected-day,
    .ant-calendar-date {
      background: var(--link);
      border: 0;
      border-radius: 3px;
      color: $white;

      &:active,
      &:focus,
      &:hover {
        background: var(--link);
        border: 0;
        color: $white;
      }
    }
  }

  .ant-calendar-time-picker-select li:focus {
    @extend %bold;
    color: $link;
    font-weight: normal;
  }

  .ant-calendar-range {
    .ant-calendar-panel {
      .ant-calendar-in-range-cell::before {
        background: var(--link);
        box-shadow: 0 0 100px rgba(255, 255, 255, 0.5);
      }
    }
  }

  td.ant-calendar-cell {
    min-width: auto;
  }

  .ant-calendar-panel {
    .ant-calendar-cell {
      &:active,
      &:focus,
      &:hover {
        .ant-calendar-date {
          background: var(--link);
          box-shadow: 0 0 100px rgba(255, 255, 255, 0.5);
        }
      }
      &.ant-calendar-disabled-cell {
        &:active,
        &:focus,
        &:hover {
          .ant-calendar-date {
            background: #f5f5f5;
          }
        }
      }
    }
  }
}

.ant-calendar-footer-btn {
  .ant-calendar-ok-btn,
  .ant-calendar-ok-btn:active,
  .ant-calendar-ok-btn.active {
    @extend %nd;
    background: $link;
    border: 0;
    color: $white;

    &:hover {
      background: var(--link);
      box-shadow: 0 0 100px rgba(0, 0, 0, 0.2);
      color: $white;
    }
  }
}

// months and years
.ant-calendar-year-panel-body,
.ant-calendar-month-panel-body {
  .ant-calendar-year-panel-year,
  .ant-calendar-month-panel-month {
    color: $mid-gray;

    &:active,
    &:focus,
    &:hover {
      background: var(--link);
      box-shadow: 0 0 100px rgba(255, 255, 255, 0.5);
      color: $link;
    }
  }

  .ant-calendar-month-panel-selected-cell,
  .ant-calendar-year-panel-selected-cell {
    .ant-calendar-year-panel-year,
    .ant-calendar-month-panel-month {
      @extend %nd;
      background: $link;
      color: $white;
    }
  }
}

html .ant-dropdown-menu-item {
  color: $link;
  &.ant-dropdown-menu-item-selected {
    color: $all-black;
  }
}

// number input fields
.ant-input-number-handler-wrap {
  opacity: 1;
}

// small dd trigger
html .ant-dropdown-trigger {
  &.show-dd-items {
    border-radius: 3px;
    display: inline-block;
    font-size: 0.8rem;
    margin: 0 0 5px;
    padding: 5px 30px 5px 10px;
    text-decoration: none;

    @include min-screen($min-medium) {
      margin: 10px 0 5px;
    }
  }
}

//////////////////////////// nui form elements ////////////////////////////

.nui-fieldset {
  input:not([type='radio']),
  input:not([type='checkbox']),
  input:not([type='submit']),
  input:not([type='reset']),
  input:not([type='button']),
  input.inputfile + .dummy,
  select,
  textarea {
    background: $white;
    border-radius: 3px;
    border: 1px solid $lightest-gray;
    box-sizing: border-box;
    color: $all-black;
    min-height: 44px;
    padding: 5px 10px;
    vertical-align: top;
    width: 100%;
    &[readonly],
    &[disabled] {
      background: rgba(125, 125, 125, 0.05);
      box-shadow: none;
      pointer-events: none;
    }
    &:active,
    &:focus,
    &:hover {
      border: 1px solid $mid-gray !important; // need important to override bloody ant
      box-shadow: none;
      outline: 0;
    }
    &:invalid,
    &:invalid:active,
    &:invalid:focus,
    &:invalid:hover {
      background: $alert-error-bg;
      border-color: $alert-error-color;
      box-shadow: none;
    }
  }

  input[type='file'] {
    &.inputfile {
      + .dummy {
        color: $light-gray;
        height: 100%;
        line-height: 32px;
        pointer-events: none;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 2;

        &:before {
          color: var(--link);
          display: inline-block;
          font-size: 1.5rem;
          margin: 0 10px 0 0;
          vertical-align: middle;
        }
      }
    }
  }

  textarea {
    font-size: 100%;
    min-height: 84px;
    resize: vertical;
  }

  label,
  legend {
    color: $mid-gray;
    cursor: pointer;
    display: block;
    font-size: 0.9rem;
    margin: 0 0 8px;
    position: relative;

    &.inline {
      display: inline-block;
      margin: 8px 4px 8px 0;
      vertical-align: top;

      + .input-holder {
        display: inline-block;
        vertical-align: top;
      }
    }
    span.optional {
      color: $ok;
      display: block;
      float: right;
      font-size: 0.7rem;
      margin: 0;
      text-transform: uppercase;

      @include min-screen($min-small) {
        margin: 5px 0 0;
      }
    }
    a {
      display: block;
      float: right;
      font-size: 0.7rem;
      margin: 0;
      text-transform: uppercase;

      @include min-screen($min-small) {
        margin: 5px 0 0;
      }
    }
  }

  // wrappers
  .input-holder {
    position: relative;
    @include min-screen($min-medium) {
      &.tiny {
        width: 24%;
      }
      &.small {
        width: 36%;
      }
      &.medium {
        width: 42%;
      }
    }
  }
}

.nui-fieldset {
  .input-holder {
    &.has-errors {
      .dummy {
        border-color: $error;
      }
      + .error-list {
        color: $error;
        font-size: 0.9rem;
        list-style: none;
        margin: 0;
        padding: 5px 0 0 15px;
        position: relative;

        &:before {
          border-bottom: 5px solid $error;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          content: ' ';
          display: inline-block;
          height: 0;
          left: 0;
          margin: 0 5px 0 0;
          position: absolute;
          top: 13px;
          vertical-align: middle;
          width: 0;
        }
      }
    }
  }
}

// tender files
.tender-offer-documents {
  .nui-fieldset {
    padding-bottom: 20px;
    label {
      @include min-screen($min-small) {
        display: inline-block;
        width: 29.16666667%;
      }
    }
    .input-holder {
      max-width: 100%;
      width: 480px;

      .dummy {
        overflow: hidden;
        span {
          padding-right: 5px;
        }
      }
      @include min-screen($min-small) {
        display: inline-block;
        width: 300px;
      }
      @include min-screen($min-large) {
        width: 480px;
      }
    }

    ul.error-list {
      display: none;
    }

    // if errors
    .input-holder {
      &.has-errors {
        + ul.error-list {
          display: block;

          @include min-screen($min-small) {
            display: inline-block;
            margin-left: 29.16666667%;
            width: 300px;
          }
          @include min-screen($min-large) {
            width: 480px;
          }
        }
      }
    }

    .document-list-box {
      padding: 20px 0 0;
      ul {
        li {
          button.file-name {
            @extend %dd;
            background: none;
            box-shadow: none;
            color: var(--link);

            &:active,
            &:focus,
            &:hover {
              @extend %nd;
              box-shadow: none;
              color: $all-black;
            }
          }
          button.delete-file {
            font-size: 1rem;
            position: absolute;
            right: 0;
            top: 5px;

            &:disabled {
              opacity: 0.25;
            }
          }
        }
      }
      @include min-screen($min-small) {
        display: inline-block;
        margin-left: 29.16666667%;
        width: 300px;
      }
      @include min-screen($min-large) {
        width: 480px;
      }
    }
  }
}

// search
.search-fieldset {
  .nui-fieldset {
    .input-holder {
      position: relative;
      &:before {
        @extend %fontello;
        color: $lightest-gray;
        content: '\e815';
        font-size: 1.2rem;
        height: 40px;
        line-height: 40px;
        margin: 0;
        position: absolute;
        right: 5px;
        text-align: center;
        top: 2px;
        width: 30px;
      }

      input:not([type='radio']),
      input:not([type='checkbox']),
      input:not([type='submit']),
      input:not([type='reset']),
      input:not([type='button']) {
        padding-right: 40px;
      }
    }
  }
}

// additional currencies

.currencies {
  border-top: 1px solid $hr;
  margin: 0 0 10px;
  padding: 10px 0 0;

  h3 {
    font-size: 1rem;
    position: relative;

    &:after {
      @extend %regular;
      color: $ok;
      content: 'Optional';
      display: inline-block;
      font-size: 0.7rem;
      margin: 0;
      position: absolute;
      right: 0;
      text-transform: uppercase;
      top: 5px;
    }
  }
}

.currency-option {
  padding: 0 0 8px;

  span.ant-checkbox + span {
    color: $mid-gray;
    font-size: 0.9rem;
  }
  .ant-form-item {
    padding: 0;
    .ant-form-item-label {
      label {
        padding-left: 30px;
      }
      label:not(.ant-form-item-required) {
        padding-right: 0;
        &:after {
          display: none;
        }
      }
    }
    .ant-form-item-control-wrapper {
      left: 0;
      position: absolute;
      top: 3px;

      .ant-form-item-control {
        line-height: 18px;
      }
    }
  }
}

.currency-field {
  .ant-form-item {
    display: inline-block;
    vertical-align: top;
    margin-right: 2%;
    width: 49%;

    .ant-input-number {
      width: 100%;
    }
  }
  .currency-exchange {
    display: inline-block;
    padding-left: 10px;
    vertical-align: top;
    width: 49%;

    span {
      display: block;
      font-size: 0.8rem;
    }
    strong {
      color: $all-black;
      font-size: 0.8rem;
    }
    button {
      @extend %dd;
      @extend %regular;
      background: none;
      border: 0;
      border-radius: 0;
      border-left: 1px solid $light-gray;
      color: var(--link);
      display: inline-block;
      font-size: 0.8rem;
      margin: -2px 0 0 5px;
      padding: 0 0 0 5px;
      vertical-align: middle;

      &:hover {
        @extend %nd;
        color: $all-black;
      }
    }
  }
}

// ant form fields in a table
table {
  // for now only the edit customer table
  .ant-input-number {
    border-color: $lightest-gray;
    border-radius: 3px;

    &:hover {
      border-color: $mid-gray;
      box-shadow: none !important;
    }
  }

  .ant-input-number-input-wrap,
  .ant-input-number-focused,
  .ant-select-focused,
  .ant-input-focused {
    border-color: $light-gray !important;
    box-shadow: none !important;
    outline: none !important;
  }

  .ant-input-number-input-wrap {
    border: 0;
  }

  &.edit-buyers-table {
    .td-content {
      .nui-check-fieldset {
        margin: 8px 0 0 0 !important;
        padding: 0;

        input + label.nui-label {
          display: block;
          margin: -18px 0 0;
          padding: 0;
        }
      }
      .nui-fieldset {
        margin: 0;
        padding: 0;

        .select-holder {
          .ant-select-arrow {
            display: none;
          }
          .ant-select {
            width: 100%;
          }
          .ant-select-selection {
            border-radius: 3px;
            height: auto;
            line-height: 1.2rem;
            min-height: 1px;
            padding: 8px 36px 8px 12px;
            text-align: left;

            &:hover {
              border-color: $mid-gray;
            }
          }
          .ant-select-focused {
            .ant-select-selection {
              border-color: $lightest-gray;
            }
          }
          .ant-select-selection__rendered {
            margin-right: 0;
            margin-left: 0;
            line-height: 1.2rem;
          }
        }
        .input-holder {
          input {
            line-height: 1.2rem;
            min-height: 1px;
            padding: 8px 12px;
          }
          .ant-input-number-handler-wrap {
            border-radius: 0 3px 3px 0;
          }
          .ant-input-number {
            input {
              border: 0 !important;
              min-height: 1px;
            }
          }
          &:active,
          &:hover {
            .ant-input-number-handler-wrap {
              border-color: $mid-gray !important;
            }
          }
        }
      }
      .button-set .nui-button,
      .nui-button {
        margin-bottom: 0;
      }
    }
  }
}
