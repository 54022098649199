// helpers and utility classes
i.normal {
  font-style: normal;
}

b,
strong,
.bold {
  @extend %bold;
}

.auto-height {
  height: auto !important;
}

.smaller {
  font-size: 90%;
  line-height: 1.5em;
}

.larger {
  font-size: 110%;
  line-height: 1.5em;
}

.inline {
  display: inline-block !important;
}

.block {
  display: block !important;
  width: 100% !important;
}

.notr {
  transition: none;
}

.error {
  color: $error;
  span.red-arrow:before {
    border-bottom: 5px solid $error;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    content: ' ';
    display: inline-block;
    height: 0;
    margin: 0 5px 0 0;
    vertical-align: middle;
    width: 0;
  }
}

html h2 span.icon-file-pdf {
  &:before {
    color: #ec4004;
    margin-left: 0;
  }
}

.valign-m,
.td-valign-m td {
  vertical-align: middle !important;
}
.valign-t,
.td-valign-t td {
  vertical-align: top !important;
}

.valign-b,
.td-valign-b td {
  vertical-align: bottom !important;
}

.nowrap {
  white-space: nowrap;
}

.notextwrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.left {
  float: left;
}
.right {
  float: right;
}
.align-left {
  text-align: left;
}
.justify {
  text-align: justify;
}
.align-right {
  text-align: right;
}
.align-center {
  text-align: center;
}

// align for medium
.align-left-for-medium {
  @include min-screen($min-medium) {
    text-align: left;
  }
}

.align-center-for-medium {
  @include min-screen($min-medium) {
    text-align: center;
  }
}

.align-right-for-medium {
  @include min-screen($min-medium) {
    text-align: right;
  }
}

// float breakpoints
.left-for-small {
  @include min-screen($min-small) {
    float: left;
  }
}
.left-for-medium,
.float-left-for-medium {
  @include min-screen($min-medium) {
    float: left;
  }
}
.left-for-large,
.float-left-for-large {
  @include min-screen($min-large) {
    float: left;
  }
}

.left-for-xlarge,
.float-left-for-xlarge {
  @include min-screen($min-xlarge) {
    float: left;
  }
}

.right-for-small {
  @include min-screen($min-small) {
    float: right;
  }
}
.right-for-medium,
.float-right-for-medium {
  @include min-screen($min-medium) {
    float: right;
  }
}
.right-for-large,
.float-right-for-large {
  @include min-screen($min-large) {
    float: right;
  }
}

.right-for-xlarge,
.float-right-for-xlarge {
  @include min-screen($min-xlarge) {
    float: right;
  }
}

.relative {
  position: relative;
}
.static {
  position: static;
}
.overflow-hidden {
  overflow: hidden;
}

// font sizes and other related stuff
.fs--8 {
  font-size: 0.8rem;
  .unit-detail {
    @extend %regular;
    color: $light-gray;
    font-size: 0.8rem;

    span {
      position: relative;
      top: 0;
    }
  }
}

.fs-1 {
  font-size: 1rem;
}

.flex {
  display: flex;
  &.spaced {
    justify-content: space-between;
  }
  &.center {
    justify-content: center;
  }
}

.m-center {
  text-align: center;
  @include min-screen($min-medium) {
    text-align: inherit;
  }
}

.align-left-for-medium {
  @include min-screen($min-medium) {
    text-align: left;
  }
}

.align-center-for-medium {
  @include min-screen($min-medium) {
    text-align: center;
  }
}

.align-right-for-medium {
  @include min-screen($min-medium) {
    text-align: right;
  }
}

.block-for-small {
  @include max-screen($max-small) {
    display: block;
  }
}

.hide-for-xsmall {
  @include max-screen($max-xsmall) {
    height: 0;
    margin: 0 !important;
    overflow: hidden;
    padding: 0 !important;
    position: absolute;
    transition: none;
    visibility: hidden;
    width: 0;
    z-index: -2;
  }
}

.hide-for-small {
  @include max-screen($max-small) {
    height: 0;
    margin: 0 !important;
    overflow: hidden;
    padding: 0 !important;
    position: absolute;
    transition: none;
    visibility: hidden;
    width: 0;
    z-index: -2;
  }
}

.hide-for-rt {
  @include max-screen($max-rt) {
    height: 0;
    margin: 0 !important;
    overflow: hidden;
    padding: 0 !important;
    position: absolute;
    transition: none;
    visibility: hidden;
    width: 0;
    z-index: -2;
  }
}

.show-for-small-only {
  @include min-screen($min-medium) {
    height: 0;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    transition: none;
    visibility: hidden;
    width: 0;
    z-index: -2;
  }
}

.show-for-xsmall-only {
  @include min-screen($min-small) {
    height: 0;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    transition: none;
    visibility: hidden;
    width: 0;
    z-index: -2;
  }
}

.show-for-small {
  position: absolute;
  transition: none;
  visibility: hidden;
  z-index: -2;

  @include min-screen($min-small) {
    position: relative;
    visibility: visible;
    z-index: 2;
  }
}

.show-for-medium {
  position: absolute;
  transition: none;
  visibility: hidden;
  z-index: -2;

  @include min-screen($min-medium) {
    position: relative;
    visibility: visible;
    z-index: 2;
  }
}

.show-for-large {
  position: absolute;
  transition: none;
  visibility: hidden;
  z-index: -2;

  @include min-screen($min-large) {
    position: relative;
    visibility: visible;
    z-index: 2;
  }
}

.show-for-xlarge {
  position: absolute;
  transition: none;
  visibility: hidden;
  z-index: -2;

  @include min-screen($min-xlarge) {
    position: relative;
    visibility: visible;
    z-index: 2;
  }
}

.hide-for-medium {
  @include min-screen($min-medium) {
    height: 0;
    margin: 0 !important;
    overflow: hidden;
    padding: 0 !important;
    position: absolute;
    transition: none;
    visibility: hidden;
    width: 0;
    z-index: -2;
  }
}

.hide-for-large {
  @include min-screen($min-large) {
    height: 0;
    margin: 0 !important;
    overflow: hidden;
    padding: 0 !important;
    position: absolute;
    transition: none;
    visibility: hidden;
    width: 0;
    z-index: -2;
  }
}

.hide-for-xlarge {
  @include min-screen($min-xlarge) {
    height: 0;
    margin: 0 !important;
    overflow: hidden;
    padding: 0 !important;
    position: absolute;
    transition: none;
    visibility: hidden;
    width: 0;
    z-index: -2;
  }
}

.hide-for-rt {
  @include max-screen($max-rt) {
    height: 0;
    margin: 0 !important;
    overflow: hidden;
    padding: 0 !important;
    position: absolute;
    transition: none;
    visibility: hidden;
    width: 0;
    z-index: -2;
  }
}

// 25%
.wx25 {
  width: 25% !important;
}
.wx25-for-small {
  @include min-screen($min-small) {
    width: 25% !important;
  }
}
.wx25-for-medium {
  @include min-screen($min-medium) {
    width: 25% !important;
  }
}
.wx25-for-large {
  @include min-screen($min-large) {
    width: 25% !important;
  }
}
.wx25-for-xlarge {
  @include min-screen($min-xlarge) {
    width: 25% !important;
  }
}

// 33.333
.wx33 {
  width: 33.333% !important;
}
.wx33-for-small {
  @include min-screen($min-small) {
    width: 33.333% !important;
  }
}
.wx33-for-medium {
  @include min-screen($min-medium) {
    width: 33.333% !important;
  }
}
.wx33-for-large {
  @include min-screen($min-large) {
    width: 33.333% !important;
  }
}
.wx33-for-xlarge {
  @include min-screen($min-xlarge) {
    width: 33.333% !important;
  }
}

// 50
.wx50 {
  width: 50% !important;
}
.wx50-for-small {
  @include min-screen($min-small) {
    width: 50% !important;
  }
}
.wx50-for-medium {
  @include min-screen($min-medium) {
    width: 50% !important;
  }
}
.wx50-for-large {
  @include min-screen($min-large) {
    width: 50% !important;
  }
}
.wx50-for-xlarge {
  @include min-screen($min-xlarge) {
    width: 50% !important;
  }
}

// 66.666
.wx66 {
  width: 66.666% !important;
}
.wx66-for-small {
  @include min-screen($min-small) {
    width: 66.666% !important;
  }
}
.wx66-for-medium {
  @include min-screen($min-medium) {
    width: 66.666% !important;
  }
}
.wx66-for-large {
  @include min-screen($min-large) {
    width: 66.666% !important;
  }
}
.wx66-for-xlarge {
  @include min-screen($min-xlarge) {
    width: 66.666% !important;
  }
}

// 75
.wx75 {
  width: 75% !important;
}
.wx75-for-small {
  @include min-screen($min-small) {
    width: 75% !important;
  }
}
.wx75-for-medium {
  @include min-screen($min-medium) {
    width: 75% !important;
  }
}
.wx75-for-large {
  @include min-screen($min-large) {
    width: 75% !important;
  }
}
.wx75-for-xlarge {
  @include min-screen($min-xlarge) {
    width: 75% !important;
  }
}

// 100
.wx100 {
  width: 100% !important;
}
.wx100-for-small {
  @include min-screen($min-small) {
    width: 100% !important;
  }
}
.wx100-for-medium {
  @include min-screen($min-medium) {
    width: 100% !important;
  }
}
.wx100-for-large {
  @include min-screen($min-large) {
    width: 100% !important;
  }
}
.wx100-for-xlarge {
  @include min-screen($min-xlarge) {
    width: 100% !important;
  }
}

.screen-reader-text {
  height: 0;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  visibility: hidden;
  width: 0;
  z-index: -2;
}

.column-count-2 {
  column-count: 2;
  -ms-column-count: 2;
  -moz-column-count: 2;
  -o-column-count: 2;
  -webkit-column-count: 2;
}

.column-count-3 {
  column-count: 3;
  -ms-column-count: 3;
  -moz-column-count: 3;
  -o-column-count: 3;
  -webkit-column-count: 3;
}

img {
  max-width: 100%;
}

img.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

#root iframe {
  border: 0;
}

a,
.ant-breadcrumb a {
  color: var(--link);
  cursor: pointer;
  text-decoration: underline;
  transition: all 0.2s;

  &:active,
  &:focus,
  &:hover {
    color: var(--link-hover);
    text-decoration: none;
  }
}

.ant-breadcrumb {
  display: none;
  opacity: 0;

  @include min-screen($min-medium) {
    display: inline-block;
    opacity: 1;
    padding: 15px 0;
  }
}

%dd {
  text-decoration: underline;
}

%nd {
  text-decoration: none;
}

ul:empty,
ol:empty {
  height: 0;
  margin: 0;
  overflow: hidden;
  padding: 0;
}

nav ul,
nav ol {
  list-style: none;
  margin: 0;
  padding: 0;

  li a {
    @extend %nd;
  }
}

.inline-block {
  display: inline-block;
  vertical-align: middle;
}

a.disabled {
  color: $all-black;
  cursor: default;
  opacity: 0.75;
  pointer-events: none;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  filter: grayscale(100%);
}

// borders
.border-left {
  border-left: 1px solid $hr;
  padding-left: 20px;
}

.border-right {
  border-right: 1px solid $hr;
  padding-right: 20px;
}

span.icon-block {
  color: $error;
  white-space: nowrap;

  &:before {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
  }
}
span.icon-ok {
  color: $ok;
  white-space: nowrap;
}

.icon-file-excel {
  &:before {
    @extend %nd;
    color: #1f7f4f;
    margin-left: 0;
  }
}

.icon-file-pdf {
  &:before {
    @extend %nd;
    color: #e74915;
    margin-left: 0;
  }
}
