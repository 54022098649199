// header
.header {
  background: $white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  height: 76px;
  left: 0;
  min-width: 320px;
  padding: 10px 20px 0;
  position: fixed;
  text-align: left;
  top: 0;
  transition: none;
  width: 100%;
  z-index: 11;

  @include min-screen($min-medium) {
    min-height: 75px;
    padding: 10px 40px 0;
    position: relative;
  }

  // if we add timezone for all solutions this will work, otherwise we will need a if statement/class name i.e. 'has-timezone'
  @include max-screen($max-medium) {
    padding-bottom: 100px;
  }

  .account-details {
    display: inline-block;
    vertical-align: middle;
  }

  .timezone-wrapper {
    border-right: 1px solid $lightest-gray;
    display: inline-block;
    font-size: 0.75rem;
    line-height: 1rem;
    margin-right: 10px;
    padding-right: 10px;
    position: relative;
    top: 1px;
    transition: none;
    vertical-align: middle;
    white-space: nowrap;

    @include max-screen($max-medium) {
      border-right: 0;
      border-top: 1px solid $lightest-gray;
      display: block;
      margin: 0;
      padding: 0;
      position: absolute;
      right: -60px;
      text-align: center;
      top: 60px;
      width: calc(100vw - 100px);
    }
    @include max-screen($max-small) {
      width: calc(100vw - 60px);
    }

    .timezone,
    .local-timezone {
      position: relative;
      span > strong {
        display: inline-block;
        width: 70px;

        @include max-screen($max-medium) {
          padding: 0 10px;
          width: auto;
        }
      }
    }
    .local-timezone {
      top: 1px;
      @include max-screen($max-medium) {
        display: inline-block;
        padding-top: 5px;
        top: auto;
        vertical-align: middle;
        width: 150px;
      }
    }
    .timezone {
      @include max-screen($max-medium) {
        display: inline-block;
        padding-top: 5px;
        vertical-align: middle;
      }
      .icon-attention {
        color: $warning;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }

  .logo-home {
    margin-right: 10px;
    max-height: 55px;
    max-width: 160px;

    @include min-screen($min-medium) {
      max-width: 220px;
    }
    @include min-screen($min-large) {
      max-width: 380px;
    }
  }

  .logobar {
    > a {
      height: 55px;
      line-height: 55px;

      img {
        display: inline-block;
      }
    }
  }
}

.stick {
  header.header {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    @include min-screen($min-medium) {
      box-shadow: none;
    }
  }
}

.local-time {
  display: none;
  font-size: 0.7rem;
  vertical-align: middle;

  @include min-screen($min-medium) {
    display: inline-block;
    margin: 0 10px;
  }
  strong {
    color: $all-black;
  }
  a {
    display: block;
    line-height: 1;
  }
}

.mnav-open {
  @include max-screen($max-small) {
    .local-time {
      display: block;
      position: fixed;
      z-index: 3;
    }
  }
}

.icon-menu,
.icon-menu:active,
.icon-menu:focus,
.icon-menu:hover {
  background: none;
  border: 0;
  color: $lightest-gray;
  display: none; // for now, until we have a mobile navigation panel
  font-size: 0;
  left: 5px;
  max-width: 100%;
  outline: none;
  padding: 0;
  position: fixed;
  top: 27px;
  white-space: nowrap;
  z-index: 7;

  &:before {
    font-size: 1.5rem;
  }
}
.icon-menu:hover {
  color: $mid-gray;
}

.account-area {
  position: absolute;
  right: 20px;
  top: 10px;
  z-index: 6;

  @include min-screen($min-medium) {
    right: 40px;
  }
  .user {
    border-right: 1px solid $lightest-gray;
    display: inline-block;
    height: 32px;
    padding-right: 50px;
    position: relative;
    text-align: right;
    top: 5px;
    transition: none;
    vertical-align: middle;
    white-space: nowrap;

    @include min-screen($min-medium) {
      max-width: none;
    }
    img {
      border-radius: 50%;
      height: 32px;
      position: absolute;
      right: 0;
      top: 0;
      width: 32px;
      z-index: 2;
    }
    .icon-user {
      display: inline-block;
      font-size: 0;
      position: absolute;
      right: 5px;
      top: 0;
      z-index: 1;

      &:before {
        background: $lightest-gray;
        border-radius: 50%;
        color: $white;
        display: inline-block;
        font-size: 1.35rem;
        height: 32px;
        line-height: 32px;
        text-align: center;
        width: 32px;
      }
    }
    a.name,
    span.name {
      @extend %bold;
      color: var(--link);
      cursor: pointer;
      display: block;
      font-size: 0.8rem;
      line-height: 1.1;
      max-width: 150px;
      overflow: hidden;
      position: relative;
      text-align: right;
      text-overflow: ellipsis;
      top: 2px;
      white-space: nowrap;

      &:hover {
        @extend %nd;
        color: $all-black;
      }
      @include max-screen($max-small) {
        display: none;
      }
      @include min-screen($min-large) {
        max-width: none;
      }
    }
    span.job-title,
    span.solution-name,
    a.solution-name {
      color: $all-black;
      cursor: pointer;
      display: block;
      font-size: 0.7rem;
      line-height: 0.9rem;
      max-width: 150px;
      overflow: hidden;
      position: relative;
      text-align: right;
      text-overflow: ellipsis;
      top: 5px;
      transition: none;

      @include max-screen($max-small) {
        display: none;
      }
      @include min-screen($min-large) {
        max-width: none;
      }
    }
  }

  .toggle-account-details {
    border-left: 1px solid $lightest-gray;
    cursor: pointer;
    display: inline-block;
    font-size: 0;
    height: 32px;
    margin: 0 5px 0 15px;
    position: relative;
    vertical-align: middle;

    &:before {
      border-right: 7px solid $light-gray;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      content: ' ';
      display: block;
      height: 0;
      left: -12px;
      margin-top: -3px;
      position: absolute;
      top: 50%;
      width: 0;
    }
  }

  a.icon-logout {
    @extend %nd;
    display: inline-block;
    font-size: 0.7rem;
    line-height: 1;
    max-width: 100%;
    position: relative;
    text-align: center;
    top: 9px;
    vertical-align: sub;
    white-space: nowrap;
    width: 60px;

    span {
      @extend %dd;
    }
    &:hover {
      span {
        @extend %nd;
      }
    }
    &:before {
      color: $light-gray;
      display: block;
      font-size: 1.2rem;
      margin: 2px auto 0;
    }
  }

  a.name {
    @extend %nd;
  }
  @include max-screen($max-small) {
    .user {
      span.icon-user,
      img {
        left: 10px;
        top: -15px;
      }
      a.name {
        display: block;
        height: 0;
        left: -1000px;
        margin: 0;
        opacity: 0;
        overflow: hidden;
        padding: 0;
        position: absolute;
        top: -1000px;
        transition: none;
        width: 0;
        z-index: -2;
      }
    }
  }
}

.account-area {
  .user {
    span.job-title,
    span.solution-name,
    a.solution-name {
      @include max-screen($max-small) {
        display: none;
      }
      @include max-screen($max-xsmall) {
        font-size: 0;
      }
    }
  }
}

html.no-touch body {
  .account-area {
    .user {
      span.job-title,
      span.solution-name,
      a.solution-name {
        @include max-screen($max-small) {
          font-family: 'open_sanssemibold', Arial, Helvetica, sans-serif;
          font-weight: normal;
        }
      }
    }
  }
}

// account panel
html .account-panel {
  .wrapper {
    overflow-x: hidden;
    overflow-y: auto;

    .signout {
      display: inline-block;
      position: absolute;
      right: 70px;
      top: 20px;

      @include min-screen($min-small) {
        right: 110px;
      }
      &:before {
        @extend %nd;
        color: $light-gray;
        display: inline-block;
        font-size: 1.2rem;
        margin-right: 10px;
        vertical-align: middle;
      }
      @include max-screen($max-xsmall) {
        font-size: 0;
        &:before {
          font-size: 1.2rem;
          margin-right: 0;
        }
      }
    }
    .form {
      padding: 20px 0;
      @include min-screen($min-small) {
        width: calc(85% - 5px);
      }
    }
  }
}
