// q and a
.q-and-a {
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid $lightest-gray;
  border-bottom: 0;
  border-top: 0;
  font-size: 0.8rem;
  margin-top: -15px;
  padding: 0 10px 10px 10px;
  position: relative;

  .author {
    @extend %regular;
    border-bottom: 1px dashed $lightest-gray;
    color: $mid-gray;
    font-size: 0.7rem;
    margin-bottom: 5px;
    margin-top: 2px;
    padding-bottom: 5px;
  }

  .question {
    @extend %bold;
    border-top: 1px dashed $lightest-gray;
    color: $all-black;
    padding-top: 10px;
  }

  .question,
  .answer {
    padding-left: 20px;
    position: relative;

    &:after {
      @extend %clearfix;
    }
    span.type {
      color: var(--highlight);
      left: 0;
      position: absolute;
      top: 10px;
    }
    p {
      margin: 0;
      padding: 0;
    }
  }
  .answer {
    span.type {
      color: $light-gray;
      top: 0;
    }
    .author {
      border-bottom: 0;
      margin-bottom: -5px;
      padding-bottom: 0;
    }
  }
  &:first-of-type {
    border-radius: 3px 3px 0 0;
    border-top: 1px solid $lightest-gray;
    margin-top: 0;

    .question {
      border-top: 0;
    }
  }
  &:last-of-type {
    border-radius: 0 0 3px 3px;
    border-bottom: 1px solid $lightest-gray;
  }
}

// ask a question
.qa-wrapper {
  textarea,
  form textarea.ant-input {
    margin-bottom: 0;
  }
}

.q-and-a + .nui-alert {
  p {
    font-size: 0.8rem;
    line-height: 1.2rem;
  }
}
