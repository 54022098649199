﻿// nui detailpage
.order-actions {
  margin: 0 0 -5px;
  position: relative;

  @include min-screen($min-medium) {
    border-bottom: 1px solid $hr;
    padding: 0 0 10px;
  }
  @include min-screen($min-large) {
    padding: 0 0 10px;
  }
  &:after {
    @extend %clearfix;
  }
  .actions {
    float: right;

    .button-set {
      .nui-button {
        width: auto;
      }
    }
  }
  .status {
    padding-top: 5px;

    @include max-screen($max-small) {
      strong {
        display: none;
      }
    }
  }
}

// details in modal
.nui-modal {
  .full-page-header {
    background: none;
    border: 0;
    margin: 0;
    padding: 10px 0 20px;

    h2 {
      font-size: 1.3rem;
      span.icon-file-pdf:before {
        font-size: 1rem;
        position: relative;
        top: -2px;
      }
    }
  }
  .full-page-content {
    .nui-table.generic-table table tr th:first-child,
    .nui-table.generic-table table tr td:first-child {
      border-left: 1px solid $hr;
    }
  }
}

// edit customer
html .nui-form.order-form {
  .form-item-type-Boolean {
    &.form-item-name-docsapproved,
    &.form-item-name-nocreditlimit {
      padding: 10px 0;

      .ant-form-item-label {
        width: 250px;
        white-space: nowrap;
        label:not(.ant-form-item-required):after {
          right: 20px;
          top: 2px;
        }
      }
    }
    &.form-item-name-nocreditlimit {
      border-top: 1px solid $hr;
      margin-top: 20px;
      padding-top: 20px;
    }
  }
  .form-item-name-creditperiod {
    .ant-form-item-control-wrapper {
      width: 200px;
    }
  }
  .form-item-name-creditamount {
    .ant-input-number {
      width: 200px;
    }
  }
}

// freight rates
.freight-rates {
  padding: 20px 0 30px;

  .freight-rates {
    padding: 0;
  }
  ul {
    list-style: none;
    margin: 20px 0 0;
    padding: 0;

    li {
      border-top: 1px solid $hr;
      padding: 10px 0 0;
      position: relative;

      @include max-screen($max-small) {
        display: flex;
      }
      &:first-child {
        border: 0;
        padding-top: 0;
      }
      p.smaller {
        white-space: normal;
      }
      h3 {
        display: none;
        font-size: 1rem;
        height: 30px;
        line-height: 1;
        margin: 0;

        span {
          @extend %regular;
          color: $mid-gray;
          font-size: 0.8rem;
          white-space: nowrap;
        }
      }
      &:first-child {
        h3 {
          display: block;
        }
      }
      h4 {
        font-size: 0.9rem;
        margin: 0;
        padding: 0;
      }
      .warehouse-section {
        display: inline-block;
        padding: 0 20px 10px 0;
        vertical-align: top;
        p {
          padding-top: 8px !important;
        }
        @include max-screen($max-small) {
          width: 100%;
        }
        @include min-screen($min-medium) {
          width: 360px;
        }
      }
      .incoterms-section {
        display: inline-block;
        vertical-align: top;

        @include max-screen($max-small) {
          width: 100%;
        }
        @include min-screen($min-medium) {
          width: calc(100% - 360px);
        }
        .nui-fieldset {
          display: inline-block;
          padding: 0;
          vertical-align: top;

          .input-holder {
            input {
              min-height: 35px;
              width: 100%;
              &[disabled] {
                background: none;
              }
            }
          }
        }
        button.nui-button {
          font-size: 0.9rem;
        }
      }
    }
  }
}

// freight rates sticky
.freight-rates {
  left: 20px;
  max-width: calc(100vw - 60px);
  position: sticky;
  position: -webkit-sticky;

  @include min-screen($min-medium) {
    max-width: calc(100vw - 100px);
  }
  @include min-screen($min-large) {
    max-width: none;
    white-space: nowrap;
  }
}

// Service fees
.service-fee-alert {
  @include min-screen($min-medium) {
    margin-left: 20px;
    margin-right: 20px;
  }
  @include min-screen($min-large) {
    margin-left: 40px;
    margin-right: 40px;
  }
}

.nui-tabs-tabpane .generic-table {
  .service-fee-alert {
    margin: 0 20px;

    @include min-screen($min-medium) {
      margin-left: 40px;
      margin-right: 40px;
    }
  }
}

.service-fees {
  margin-bottom: -20px;
  margin-top: -25px;

  .inset-form {
    border-bottom: 1px solid $hr;
    padding: 20px 0;
  }
  .form-item-name-name,
  .form-item-name-premiumtype {
    max-width: 360px;

    @include min-screen($min-medium) {
      display: inline-block;
      margin-right: 15px;
      max-width: 360px;
      vertical-align: top;
      width: 25%;
    }
  }

  .form-item-name-rate {
    width: 130px;

    @include min-screen($min-medium) {
      display: inline-block;
      margin-right: 15px;
      vertical-align: top;
    }
  }
}

.service-fees button[type='submit'] {
  @include min-screen($min-medium) {
    display: inline-block;
    height: 40px;
    line-height: 40px;
    padding: 0 25px;
    position: relative;
    vertical-align: top;
    top: calc(1.4rem + 7px);
  }
}

// product (brand) logo (link)
span.icon-file-image.image-preview {
  @extend %dd;
  color: var(--link);
  cursor: zoom-in;
  display: inline-block;
  left: -22px;
  max-width: calc(100% + 22px);
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% + 22px);

  &:before {
    color: $light-gray;
    display: inline-block;
    margin: 0;
    vertical-align: middle;
    width: 22px;
  }
  &:hover {
    @extend %nd;
    color: $all-black;
  }
}

// customer prices on product detail page
html .full-page-content {
  .generic-table.products-table {
    .nui-table-content {
      .nui-table-body {
        table tbody tr td:first-child {
          @include min-screen($min-medium) {
            padding-left: 40px !important;
          }
        }
      }
    }
  }
  .customer-prices-form {
    .ant-form {
      padding: 0 20px 20px;
      @include min-screen($min-medium) {
        padding: 0 40px 20px;
      }
    }
  }
}

// New customer page
.customers2-table {
  background: #f9f9f9;
}

html
  .full-page-content
  .generic-table.customers2-table
  .nui-table-content
  .nui-table-body
  table {
  thead,
  tbody {
    tr:nth-child(odd),
    tr:nth-child(even),
    tr.nui-table-expanded-row,
    tr:hover,
    tr.nui-table-expanded-row:hover {
      > td {
        background: #fafbfe;
      }
      th.col-details-small,
      td.col-details-small {
        left: 0;
        max-width: 90px;
        min-width: 90px;
        position: sticky !important;
        position: -webkit-sticky !important;
        width: 90px;
        z-index: 4;

        @include min-screen($min-medium) {
          max-width: 110px;
          min-width: 110px;
          width: 110px;
        }
      }

      th.col-buyer-name,
      td.col-buyer-name,
      th.col-seller-name,
      td.col-seller-name {
        box-shadow: 2px 0 2px rgba(0, 0, 0, 0.05);
        left: 90px;
        max-width: 150px;
        min-width: 150px;
        position: sticky;
        position: -webkit-sticky;
        width: 150px;
        z-index: 6;

        .td-content,
        .th-content {
          max-width: 100%;
          min-width: 100%;
          width: 100%;
        }
        @include min-screen($min-medium) {
          left: 110px;
          max-width: 240px;
          min-width: 240px;
          width: 240px;
        }
      }

      th.col-buyer-shortcode,
      td.col-buyer-shortcode {
        max-width: 150px;
        min-width: 150px;
        width: 150px;
      }

      td.col-limit-period {
        .select-holder {
          width: 130px;
        }
      }
    }

    // expanded section
    tr.nui-table-expanded-row > td,
    tr.nui-table-expanded-row:hover > td {
      padding: 0 0 10px !important;

      table.edit-buyers-table {
        width: 100%;
        thead {
          tr,
          tr:hover {
            th {
              @extend %bold;
              background: #eef1f8;
              border-color: #eef1f8;
              border-bottom: 1px solid #d9d9d9;
              box-shadow: none;
              color: $all-black;
              padding: 0;

              .th-content {
                padding: 10px;
              }
            }
            th.col-details-small {
              background: #f3f5fa;
              border-right-color: #f3f5fa;
            }
            th.col-seller-name {
              background: #f3f5fa;
              border-right: 1px solid #d6d6d6;
              box-shadow: 2px 0 2px rgba(0, 0, 0, 0.05) !important;
            }
          }
        }
        tbody {
          tr,
          tr:hover {
            td {
              background: #eef1f8;
              border-color: #eef1f8;
              border-bottom: 1px solid #d9d9d9;
              box-shadow: none;
              padding: 0;

              .td-content {
                padding: 10px;
              }
            }

            td.col-details-small {
              background: #f3f5fa;
              border-right-color: #f3f5fa;
            }
            td.col-seller-name {
              background: #f3f5fa;
              border-right: 1px solid #d6d6d6;
              box-shadow: 2px 0 2px rgba(0, 0, 0, 0.05) !important;
            }
          }
        }

        thead,
        tbody {
          th,
          td {
            vertical-align: middle;

            &.col-docs,
            &.col-credit {
              width: 100px;

              .nui-check-fieldset {
                margin: 0;
                padding: 0;
              }
            }
            &.col-credit-limit {
              text-align: right;
              width: 100px;

              .th-content,
              .td-content {
                text-align: right;
              }
            }
            &.col-credit-remaining {
              text-align: right;
              width: 100px;

              .th-content,
              .td-content {
                text-align: right;
              }
            }
            &.col-limit-period {
              width: 100px;
            }
            &.col-reference {
              min-width: 150px;
            }
          }
        }
      }

      // bloody hacks
      table.edit-buyers-table > tbody > div[style='display: block;'] {
        display: table-row !important;

        > td {
          padding: 10px 0 !important;

          form {
            display: inline-block;
            left: 0;
            padding: 0 0 0 90px;
            position: sticky;
            vertical-align: top;
            width: 100%;

            @include min-screen($min-medium) {
              padding: 0 0 0 110px;
            }
          }
          .ant-form-item {
            display: block;
            margin: 0;
            max-width: 480px;
            padding: 10px;

            .ant-input-number {
              width: 200px;
            }
          }

          .form-item-name-creditperiod,
          .form-item-name-paymentterm {
            width: 220px;
          }

          .form-item-name-docsapproved,
          .form-item-name-nocreditlimit {
            padding: 0 10px;

            .ant-form-item-label {
              display: inline-block;
              vertical-align: top;
              width: 150px;

              label {
                margin: 8px 0;
              }
            }
            .ant-form-item-control-wrapper {
              display: inline-block;
              vertical-align: top;
              width: calc(100% - 150px);
            }
          }
        }
      }
    }
  }
}

// Reports page
.report-lists {
  ul {
    list-style: none;
    margin: 0;
    padding: 0 0 20px;

    @include min-screen($min-medium) {
      display: inline-block;
      padding: 0 60px 20px 0;
      vertical-align: top;
    }
  }
}

.report-filter-select-div {
  width: 100%;

  @include min-screen($min-medium) {
    margin-right: 20px;
    width: calc(33% - 20px);
  }
}

.report-filter {
  @include min-screen($min-medium) {
    width: calc(100% + 40px);
  }
  .ant-form-item {
    @include min-screen($min-medium) {
      display: inline-block;
      margin-right: 20px;
      vertical-align: top;
      width: calc(33% - 20px);
    }
  }
}

html .generic-table.light.reports-table {
  .nui-table-content .nui-table-body table {
    thead,
    tbody {
      tr {
        th {
          white-space: normal !important;
        }
        th,
        td {
          .td-content,
          .th-content {
            text-align: right;
          }

          &:last-child {
            .td-content,
            .th-content {
              text-align: right;
            }
          }

          &:first-child {
            box-shadow: 2px 0 2px rgba(0, 0, 0, 0.05);
            left: 0;
            min-width: 240px;
            max-width: 240px;
            position: sticky;
            position: -webkit-sticky;
            width: 240px;
            z-index: 2;

            @include min-screen($min-medium) {
              min-width: 300px;
              max-width: 300px;
              width: 300px;
            }
            @include min-screen($min-xlarge) {
              min-width: 400px;
              max-width: 400px;
              width: 400px;
            }
            .td-content,
            .th-content {
              padding-left: 0;
              text-align: left;
            }
          }
          &:nth-child(2) {
            .td-content,
            .th-content {
              text-align: left;
            }
          }
        }
        th,
        th .th-content {
          font-size: 0.8rem;
          line-height: 1.1rem;
          min-width: 70px;
          text-align: left;
          vertical-align: top !important;
        }
        th:first-child {
          opacity: 0.9;
        }
        &:hover {
          td {
            box-shadow: 2px 0 2px rgba(0, 0, 0, 0.05),
              0 -5px 0 rgba(0, 0, 0, 0.035) inset;
          }
        }
      }
      .tr-level.tr-level-0 {
        td:first-child,
        th:first-child {
          @extend %bold;
          background: $white;
          color: $all-black;
        }
      }

      .tr-level {
        td:first-child,
        th:first-child {
          .td-content,
          .th-content {
            left: 15px;
            max-width: calc(100vw - 60px);
            padding-left: 0;
            position: sticky;
            position: -webkit-sticky;
            z-index: 2;
          }
        }
      }

      tr:not(:last-child) {
        &.tr-level.tr-level-0 {
          td:first-child {
            box-shadow: none;
          }
        }
      }
      tr.tr-level.tr-level-0:last-child {
        td {
          background: $white;
          box-shadow: 0 1px 0 $light-gray inset;

          &:first-child {
            box-shadow: 0 1px 0 $light-gray inset, 2px 0 2px rgba(0, 0, 0, 0.05);

            .td-content {
              padding-left: 10px;
            }
          }
        }
      }
      tr.tr-level.tr-level-0:last-child:hover {
        td {
          box-shadow: 0 1px 0 $light-gray inset;

          &:first-child {
            box-shadow: 0 1px 0 $light-gray inset, 2px 0 2px rgba(0, 0, 0, 0.05);
          }
        }
      }
      // levels
      .tr-level.tr-level-1 {
        th:first-child .th-content,
        td:first-child .td-content {
          padding-left: 10px;
        }
      }
      .tr-level.tr-level-2 {
        th:first-child .th-content,
        td:first-child .td-content {
          padding-left: 20px;
        }
      }
      tr.tr-level.tr-level-0:last-child {
        th:first-child .th-content,
        td:first-child .td-content {
          padding-left: 0;
        }
      }
    }
  }
}

// Trade information, PO number, comments etc.
.full-page-content.has-trade-info {
  background: #f9f9f9;
  margin-top: -20px;

  hr.show-for-xlarge,
  .detail-lists .list-box-container {
    margin-left: 20px !important;
    margin-right: 20px !important;
    @include min-screen($min-medium) {
      margin-left: 40px !important;
      margin-right: 40px !important;
    }
  }
}

.trade-info {
  th,
  td {
    &.col-isprivate {
      max-width: 110px;
      width: 110px;
    }
  }
  .nui-form {
    background: #efefef;
    border-bottom: 1px solid $hr;
    padding: 10px 20px 20px;

    textarea {
      height: 40px !important;
      margin: 0 !important;
      max-height: 40px !important;
      min-height: 40px !important;
      padding: 8px 10px;
    }

    @include min-screen($min-medium) {
      padding: 10px 40px 20px;

      .ant-form-item,
      button.nui-primary {
        display: inline-block;
        margin: 0 15px 0 0;
        vertical-align: top;
      }
      button.nui-primary {
        height: 40px;
        margin-right: 0;
        margin-top: 32px;
      }

      .form-item-name-type_id {
        width: 150px;
      }

      .form-item-name-value {
        max-width: 480px;
        min-width: 230px;
        width: calc(100vw - 540px);
      }

      .form-item-name-isprivate {
        width: 120px;
      }
    }
  }
  .nui-tabs-nav,
  .nui-alert {
    margin-left: 20px;
    margin-right: 20px;

    @include min-screen($min-medium) {
      margin-left: 40px;
      margin-right: 40px;
    }
  }
}

// users
.full-page-content {
  .users-table {
    .nui-table-expanded-row {
      .user-details {
        left: 40px;
        position: sticky;
        position: -webkit-sticky;
        width: calc(100vw - 80px);

        @include min-screen($min-medium) {
          width: calc(100vw - 100px);
        }
        .nui-form {
          border-top: 1px solid $hr;
          padding-top: 15px;
          position: relative;

          span.user-solution {
            white-space: nowrap;

            &:before {
              @extend %bold;
              color: $all-black;
              content: 'Solution';
              display: inline-block;
              padding-right: 10px;
              width: 100px;
            }
            &:empty:before {
              display: none !important;
            }
          }
          @include max-screen($max-small) {
            span.user-solution {
              display: block;
              padding: 0 0 10px;
            }
          }
          @include min-screen($min-medium) {
            padding-left: 200px;

            span.user-solution {
              left: 0;
              position: absolute;
              top: 23px;
              &:before {
                display: none;
              }
            }
          }
          .ant-form-item {
            .ant-form-item-control-wrapper {
              max-width: 320px;
              padding-right: 10px;
            }

            @include min-screen($min-medium) {
              display: inline-block;
              vertical-align: top;
              width: 200px;

              .ant-form-item-label {
                display: none;
              }
              .ant-form-item-control-wrapper {
                padding-right: 10px;
              }
            }
          }
        }

        @include min-screen($min-xlarge) {
          width: calc(100vw - 100px);
        }
        .users {
          display: none;

          @include min-screen($min-small) {
            display: block;
            white-space: nowrap;
          }
          padding: 10px 0;
          span.user-solution,
          span.user-division,
          span.user-role {
            @extend %bold;
            color: $all-black;
            display: inline-block;
            vertical-align: top;
            width: calc(30vw - 35px);

            @include min-screen($min-medium) {
              width: 200px;
            }
          }
        }
        .user {
          border-top: 1px solid $hr;
          padding: 5px 0;
          position: relative;
          .user-solution,
          .user-division,
          .user-role {
            display: block;
            padding: 0;

            &:before {
              @extend %bold;
            }
            &:empty:before {
              display: none !important;
            }
            @include max-screen($max-small) {
              &:before {
                color: $all-black;
                content: 'Solution';
                display: inline-block;
                padding-right: 10px;
                width: 100px;
              }
            }

            @include min-screen($min-small) {
              display: inline-block;
              padding: 5px 0;
              vertical-align: top;
              width: calc(30vw - 35px);

              &:before {
                display: none;
              }
            }
            @include min-screen($min-medium) {
              width: 200px;
            }
          }

          .user-division:before {
            content: 'Division';
          }
          .user-role:before {
            content: 'Role';
          }

          button.add-role {
            @include min-screen($min-small) {
              float: left;
            }
          }
          button.editme,
          button.deleteme {
            position: absolute;
            right: 50px;
            top: 5px;
          }
          button.deleteme {
            right: 2px;
          }
        }
      }
    }
  }
}

// add user
.add-user-form {
  max-width: 600px;
  .nui-fieldset,
  .nui-fieldset select,
  .nui-fieldset input {
    min-height: 40px;
  }
  .form-item-name-firstname {
    @include min-screen($min-small) {
      display: inline-block;
      margin-right: 10px;
      vertical-align: top;
      width: calc(40% - 10px);
    }
  }
  .form-item-name-lastname {
    @include min-screen($min-small) {
      display: inline-block;
      vertical-align: top;
      width: 60%;
    }
  }

  .input-holder.icon-globe {
    &:before {
      color: $lightest-gray;
      font-size: 1.2rem;
      left: 13px;
      pointer-events: none;
      position: absolute;
      top: 11px;
      z-index: 2;
    }
  }

  .PhoneInputCountryIconImg {
    border: 0;
    height: 20px;
    left: 7px;
    pointer-events: none;
    position: absolute;
    top: 10px;
    width: 30px;
    z-index: 3;
  }
  .PhoneInputCountry {
    background: $white;
    border-radius: 3px;
    border: 1px solid $lightest-gray;
    color: $all-black;
    display: inline-block;
    margin-right: 10px;
    min-height: 40px;
    padding: 5px 10px;
    position: relative;
    vertical-align: top;
    width: 68px;

    &:after {
      border-top: 6px solid rgba(0, 0, 0, 0.25);
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      content: ' ';
      display: block;
      margin-top: -2px;
      pointer-events: none;
      position: absolute;
      right: 12px;
      top: 50%;
      transition: all 0.2s linear;
      z-index: 2;
    }
    &:active {
      &:after {
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
      }
    }

    .PhoneInputCountryIcon {
      background: none !important;
      box-shadow: none !important;
      margin: 0;

      svg {
        display: none;
      }
    }
    .PhoneInputCountrySelectArrow {
      display: none;
    }
  }
}
