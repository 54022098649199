// pagination styles
ul.ant-pagination,
ul.ant-table-pagination.ant-pagination,
ul.ant-pagination.mini {
  float: none;
  margin: 0;
  padding: 10px 0;
  position: sticky;
  position: -webkit-sticky;
  z-index: 5;

  * {
    vertical-align: top;
  }

  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    background-color: $white;
    border: 1px solid $lightest-gray;
    border-radius: 3px;
    font-family: inherit;
    font-size: 0.7rem;
    height: 24px;
    line-height: 24px;
    margin: 0 3px 3px 3px;
    min-width: 24px;
    position: relative;

    .ant-pagination-item-link,
    .ant-pagination-item-link {
      border: 0;
      left: 2px;
      position: absolute;
      top: 5px;

      svg {
        display: none;
      }
    }
    &:before {
      font-size: 1rem;
      font-weight: 400;
    }
  }

  .ant-pagination-prev {
    &:before {
      @extend %fontello;
      content: '\f104';
    }
  }

  .ant-pagination-next {
    &:before {
      @extend %fontello;
      content: '\f105';
    }
  }

  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    font-size: 0;
    line-height: 0.75rem;

    &:before {
      content: '...';
      display: inline-block;
      font-size: 0.8rem;
    }
  }

  .ant-pagination-total-text {
    color: $light-gray;
    display: block;
    font-family: inherit;
    font-size: 0.8rem;
    font-weight: 400;
    height: 34px;
    line-height: 25px;

    @include min-screen($min-medium) {
      display: inline-block;
    }
  }

  li.ant-pagination-item:not(.ant-pagination-item-active),
  li.ant-pagination-item {
    background-color: $white;
    border: 1px solid $lightest-gray;
    border-radius: 3px;
    font-family: inherit;
    font-size: 0.7rem;
    height: 24px;
    line-height: 21px;
    margin: 0 3px 3px 3px;
    min-width: 22px;

    a {
      @extend %nd;
      color: var(--link);
      margin: 0 3px;
    }
    &:active,
    &:focus,
    &:hover {
      border-color: $light-gray;
    }
    &.ant-pagination-item-active {
      background: var(--link);
      border-color: var(--link);

      a {
        @extend %nd;
        color: $white;
        cursor: default;
      }
      &:active,
      &:focus,
      &:hover {
        background: var(--link);
        border-color: var(--link);

        a {
          color: $white;
        }
      }
    }
  }
}

// show per page
.ant-pagination-options-size-changer {
  position: relative;

  .ant-pagination-options-size-changer,
  .ant-select-selection--single {
    background-color: $white;
    border: 1px solid $lightest-gray;
    border-radius: 3px;
    font-family: inherit;
    font-size: 0.8rem;
    height: 24px;
    line-height: 21px;

    .ant-select-selection__rendered {
      line-height: 21px;
      margin-left: 6px;
      margin-right: 6px;
      padding-right: 15px;

      .ant-select-selection-selected-value {
        box-shadow: none;
        outline: none;

        &:active,
        &:focus {
          border-color: var(--link);
          box-shadow: none;
          outline: none;
        }
      }
    }
    .ant-select-arrow {
      &:after {
        @include arrow(3, -3);
        transform: scale(0.8, 0.8);
        -webkit-transform: scale(0.8, 0.8);
      }
      i,
      em {
        display: none;
      }
    }
  }

  .ant-select-focused .ant-select-selection,
  .ant-select-selection:focus,
  .ant-select-selection:active {
    border-color: $mid-gray;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}
