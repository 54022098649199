// nui goup
.goup {
  background: none;
  border: 0;
  bottom: -30px;
  box-shadow: none;
  left: 50vw;
  margin-left: -25px;
  position: fixed;
  transition: 0.2s all ease-out;
  z-index: 20;

  &.visible {
    bottom: 0;
  }
  @include max-screen($max-small) {
    display: none;
  }
}

.goup span:before {
  border-bottom: 26px solid rgba(0, 0, 0, 0.2);
  border-left: 28px solid transparent;
  border-right: 28px solid transparent;
  content: ' ';
  display: block;
  height: 0;
  position: relative;
  transition: 0.2s all ease-out;
  width: 0;
}
.goup span {
  &:active,
  &:focus,
  &:hover {
    &:before {
      border-bottom-color: rgba(0, 0, 0, 0.4);
      transform: scale(0.5, 0.5);
      -webkit-transform: scale(0.5, 0.5);
      top: -3px;
    }
  }
  &:active,
  &:focus {
    &:before {
      border-bottom-color: var(--link);
    }
  }
}
