﻿// consolidation and delivery specific styles
.start-delivery {
  .nui-fieldset {
    .select-holder {
      select.select-delivery {
        min-height: 35px;
        padding: 4px 30px 4px 10px;
      }
    }
  }
}

.delivery-attachments {
  margin-bottom: 20px;
  .file-list {
    li {
      padding-left: 25px;
      padding-right: 45px;

      span.icon-file-pdf {
        top: 4px;
      }
    }
  }

  .file-owner {
    display: block;
    @include min-screen($min-medium) {
      float: right;
    }
  }
}

.delivery-info {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      border-bottom: 1px solid $hr;
      display: block;
      padding: 5px 0;
      position: relative;
    }
  }

  h4.info-item-title {
    font-size: 0.9rem;
    margin: 0;
  }

  .info-item-content {
    .delivery-attrib {
      .nui-button {
        position: absolute;
        right: 0;
        top: 10px;

        &.saveme {
          right: 40px;
        }
      }
    }
    .edit-attrib {
      .nui-fieldset {
        input {
          max-width: calc(100% - 100px);
          width: 300px;
        }
      }
    }
    .view-attrib {
      max-width: calc(100% - 50px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.delivery-info-content {
  margin-bottom: 20px;
}

// layout
.delivery-info {
  left: 20px;
  max-width: calc(100vw - 40px);
  position: sticky;
  position: -webkit-sticky;

  ul {
    border-top: 1px solid $lightest-gray;
  }
  @include min-screen($min-medium) {
    left: 40px;
    max-width: calc(100vw - 80px);
  }
}

.delivery-trades {
  padding-bottom: 40px;

  .delivery-trades-table {
    td,
    th {
      border-left: 0 !important;
      white-space: normal !important;
    }
    td {
      vertical-align: top !important;
    }
    .col-product {
      max-width: none;
      min-width: 1px;
      width: auto;
    }
    .col-delete {
      .td-content {
        padding-right: 0 !important;
        text-align: right;
      }
    }
  }

  @include min-screen($min-large) {
    display: inline-block;
    padding-bottom: 20px;
    padding-right: 40px;
    vertical-align: top;
    width: 50%;
  }
}

.delivery-details {
  @include min-screen($min-large) {
    display: inline-block;
    padding-bottom: 20px;
    vertical-align: top;
    width: 50%;
  }
}

// consolidation page
h2.division-trades {
  margin: -5px 20px 15px 0;
}

.select-division-fieldset {
  margin: -5px 10px 15px 0;
  min-width: 200px;
}
