// switch css
.nui-switch {
  background: $cancel;
  border-radius: 100px 100px;
  border: 0;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  height: 22px;
  line-height: 22px;
  margin: 0 5px 0 0;
  min-width: 44px;
  padding: 0;
  position: relative;
  transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  vertical-align: middle;
  width: 44px;

  span.animate-spin {
    background: inherit;
    border-radius: 22px;
    color: $white;
    left: 23px;
    position: relative;
    transition: none;
  }
  &:focus {
    box-shadow: none;
    outline: none;
  }
  &:before {
    @extend %fontello;
    color: $white;
    content: '\e814';
    font-size: 0.8rem;
    left: 25px;
    margin: 2px 0 0;
    position: absolute;
    top: 3px;
  }

  .nui-switch-inner {
    color: $white;
    font-size: 12px;
    position: absolute;
    left: 1px;
    top: 0;

    &:after {
      background-color: $white;
      border-radius: 50%;
      box-shadow: none;
      content: ' ';
      cursor: pointer;
      opacity: 0.5;
      position: absolute;
      width: 18px;
      height: 18px;
      left: 1px;
      top: 2px;
      transition: left 0.3s cubic-bezier(0.35, 0, 0.25, 1);
    }
  }

  // disabled
  &.nui-switch-disabled,
  &.nui-switch.nui-switch-checked.nui-switch-disabled {
    background-color: $light-gray;
    cursor: not-allowed;
  }
  // checked
  &.nui-switch-checked {
    background: $ok;
    span.animate-spin {
      left: 1px;
    }
    &:before {
      content: '\e802';
      left: 6px;
    }
    .nui-switch-inner {
      left: 23px;
    }
  }
  // undetermined
  &.nui-switch-undetermined {
    background: $dark-gray;
    &:before {
      display: none;
    }
    .nui-switch-inner {
      left: 23px;
    }
  }
}

// view only
.view-only {
  background: $white;
  border-radius: 100px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: none;
  color: $mid-gray;
  cursor: default;
  display: inline-block;
  font-size: 0.5rem;
  height: 22px;
  line-height: 22px;
  margin: 0;
  min-height: 22px;
  min-width: 44px;
  padding: 0;
  text-align: center;
  text-transform: uppercase;
  user-select: none;
  vertical-align: middle;
}
