.order-pdv-summary {
  .data-list .data-item {
    & > strong {
      display: inline-block;
      vertical-align: top;
      width: 120px;
    }

    & > span {
      display: inline-block;
    }

    .index-name-summary {
      > span {
        display: block;
      }
    }
  }
}

.index-name-summary {
  &.font-inherit {
    span,
    strong {
      font-size: inherit !important;
      line-height: inherit !important;
    }
  }

  span,
  strong {
    display: inline-block;
    font-size: 0.75rem !important;
    line-height: 1rem !important;
    width: auto !important;
  }

  span {
    display: block;
  }
}
