// legend
.legend {
  @include legend($legend);
  @extend %nd;
  background: $white;
  border-radius: 3px;
  cursor: default;
  display: inline-block;
  font-size: 0.7rem;
  font-style: normal;
  @extend %bold;
  height: 18px;
  line-height: 18px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  transition: none;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  width: 18px;

  &.gfsi,
  &.specs {
    @include legend($specs);
    &:hover {
      background: $specs;
      border-color: $specs;
      color: $white;
    }
  }
  &.export {
    @include legend($export);
    &:hover {
      background: $export;
      border-color: $export;
      color: $white;
    }
  }
  &.halal {
    @include legend($halal);
    &:hover {
      background: $halal;
      border-color: $halal;
      color: $white;
    }
  }
  &.kosher {
    @include legend($kosher);
    &:hover {
      background: $kosher;
      border-color: $kosher;
      color: $white;
    }
  }
  &.organic {
    @include legend($organic);
    background: none;
    border: 1px solid transparent;
    font-size: 0;

    &:before {
      @extend %fontello;
      content: '\e817';
      font-size: 1rem;
      left: -2px;
      position: absolute;
      top: 0;
    }
    &:hover {
      &:before {
        color: darken($organic, 15%);
      }
    }
  }

  &.other {
    @include legend($other);
    &:hover {
      background: $other;
      border-color: $other;
      color: $white;
    }
  }
}

ul.legend-list {
  display: inline-block;
  list-style: none;
  margin: 0 10px 0 0;
  padding: 0;

  li {
    border: 0 !important;
    display: inline-block;
    margin: 0 5px 0 0;
  }
}

// specs in modal
h2.icon-file-pdf {
  padding-left: 40px;
  position: relative;

  &:before {
    color: $warning;
    display: inline-block;
    font-size: 1.25rem;
    left: 6px;
    position: absolute;
    top: 7px;
  }
}

.types {
  @include min-screen($min-small) {
    width: calc(100% + 10px);
  }
}

.specification-type {
  border: 1px solid transparent;
  border-radius: 3px;
  margin: 0;
  padding: 10px;
  width: 100%;

  @include min-screen($min-small) {
    display: inline-block;
    margin: 0 10px 0 0;
    vertical-align: top;
    width: calc(50% - 10px);
  }
  h3 {
    font-size: 0.9rem;
    margin: 0;
    padding: 0 0 5px 30px;
    position: relative;

    span {
      left: 0;
      position: absolute;
      top: 3px;
    }
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0 0 0 30px;

    li {
      line-height: 1.1rem;
      margin: 0;
      padding: 0 0 0.5rem;
    }
  }
  &.highlighted {
    background: $focus;
    border-color: lighten($highlight, 40%);
  }
}

// product specifications, editable in modal
.edit-product {
  .types {
    @include min-screen($min-small) {
      width: 100%;
    }
    @include min-screen($min-large) {
      width: calc(100% + 10px);
    }
  }

  .specification-type {
    display: block;
    margin: 0 0 10px 0;
    padding: 0;
    width: 100%;

    @include min-screen($min-large) {
      display: inline-block;
      margin: 0 10px 10px 0;
      width: calc(50% - 10px);
    }
    ul {
      li {
        padding: 5px 0;
        position: relative;

        a.delete-me {
          @extend %nd;
          color: $light-gray;
          display: block;
          font-size: 0;
          left: -30px;
          margin: 0;
          position: absolute;
          text-align: center;
          top: 10px;

          &:before {
            font-size: 1rem;
            margin: 0;
          }
          &:active,
          &:focus,
          &:hover {
            box-shadow: none;
            color: $all-black;
          }
        }
      }
    }
  }
}

// bid legend (tenders)
.bid-legend {
  margin: 0;
  position: absolute;
  right: 0;
  top: 0;

  h3,
  span {
    color: $mid-gray;
    display: inline-block;
    font-size: 0.7rem;
    padding: 0 5px 2px 12px;
    position: relative;
    vertical-align: middle;

    &:before {
      background: $white;
      border: 1px solid var(--link);
      border-radius: 50%;
      content: ' ';
      display: block;
      height: 9px;
      left: 0;
      position: absolute;
      top: 8px;
      width: 9px;
    }
    &.leading {
      &:before {
        background: $ok;
        border: 1px solid transparent;
      }
    }
    &.outbid {
      &:before {
        background: $cancel;
        border: 1px solid transparent;
      }
    }
    &.newbid {
      &:before {
        background: var(--link);
        border: 1px solid transparent;
      }
    }
  }
  h3 {
    color: $all-black;
    font-size: 0.7rem;
    margin: 0;
    padding-left: 0;

    &:before {
      display: none;
    }
  }
}
