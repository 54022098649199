﻿// nui form elements

// need to split into separate partials!
select::-ms-expand {
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  box-shadow: 0 0 0 1000px $white inset !important;
  -webkit-box-shadow: 0 0 0 1000px $white inset !important;
}

label {
  cursor: pointer;
}

.nui-fieldset,
.nui-check-fieldset,
.nui-radio-fieldset {
  border: 0;
  margin: 0 0 10px;
  padding: 0;
  position: relative;

  // label, legend
  label.nui-label,
  legend.nui-legend {
    color: $all-black;
    cursor: pointer;
    display: block;
    margin: 0 0 8px;
  }
}

// text input
.nui-fieldset {
  .input-holder {
    zoom: 1; // styles to come
  }
  &.small {
    input:not([type='radio']),
    input:not([type='checkbox']),
    input:not([type='submit']),
    input:not([type='reset']),
    input:not([type='button']),
    input.inputfile + .dummy,
    select,
    textarea {
      font-size: 0.8rem;
      min-height: 1px;
    }
  }
}

// select dropdown
select {
  -webkit-appearance: none;
  -moz-appearance: none;
}

.nui-fieldset {
  .select-holder {
    max-width: 100%;
    position: relative;
    width: 100%;

    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
    &:after {
      border-top: 6px solid rgba(0, 0, 0, 0.25);
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      content: ' ';
      display: block;
      margin-top: -2px;
      pointer-events: none;
      position: absolute;
      right: 12px;
      top: 50%;
      transition: all 0.2s linear;
      z-index: 2;
    }
    &:active {
      &:after {
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
      }
    }
    select {
      cursor: pointer;
      padding: 8px 30px 8px 10px;
      width: 100%;
      max-width: 100%;

      optgroup {
        @extend %regular;
        background: #eee;
        display: block;
        font-size: 0.9rem;
        font-style: normal;
        font-weight: normal;
        margin: 0 0 20px;
        padding: 5px;

        option {
          background: $white;
          box-shadow: 0 0 100px $white inset;
        }
      }
    }
  }
}

// textarea
.nui-fieldset {
  .input-holder {
    textarea {
      font-size: 100%;
      min-height: 100px;
      resize: vertical;
    }
  }
}

// radio select
.nui-radio-fieldset {
  zoom: 1; // styles to come

  label.nui-label {
    user-select: none;
    -webkit-user-select: none;
  }
}

// checkbox
.nui-check-fieldset {
  label.nui-label {
    @extend %regular;
    background: none;
    color: $mid-gray;
    display: inline-block;
    font-size: 0.9rem;
    margin: 0;
    padding: 0 0 0 30px;
    position: relative;
    line-height: 1.35rem;
    text-align: left;
    user-select: none;
    -webkit-user-select: none;
    white-space: normal;

    &:before {
      background: $white;
      border: 2px solid $lightest-gray;
      border-radius: 3px;
      color: transparent;
      content: ' ';
      display: block;
      font-size: 0.9rem;
      min-height: 20px;
      height: 20px;
      left: 0;
      line-height: 20px;
      margin: 0;
      padding: 0;
      position: absolute;
      top: 0;
      transition: all 0.2s linear;
      width: 20px;
      z-index: 2;
    }
  }
  input[type='checkbox'] {
    vertical-align: middle;
    min-height: 18px;
    height: 18px;
    left: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    width: 20px;
    z-index: 1;

    &:disabled {
      + .nui-label {
        color: rgba(0, 0, 0, 0.45);
        cursor: not-allowed;
        &:before {
          background: #f1f1f1;
          border-color: $lightest-gray;
        }
      }
    }
    &:checked {
      + .nui-label {
        &:before {
          @extend %fontello;
          background: var(--checkbox);
          border-color: var(--checkbox);
          color: $white;
          content: '\e802';
          line-height: 1.2;
          margin: 0;
          width: 20px;
        }
      }
      &:disabled {
        + .nui-label {
          color: rgba(0, 0, 0, 0.45);
          cursor: not-allowed;

          &:before {
            background: $lightest-gray;
            border-color: $lightest-gray;
          }
        }
      }
    }

    &:indeterminate {
      + .nui-label {
        &:before {
          @extend %fontello;
          background: $white;
          border-color: var(--checkbox);
          color: var(--checkbox);
          content: '\e843';
          line-height: 1.2;
          margin: 0;
          width: 20px;
        }
      }
      &:disabled {
        cursor: not-allowed;
        + .nui-label {
          &:before {
            border-color: $light-gray;
            color: $light-gray;
          }
        }
      }
    }
  }
}

// hover state
.nui-check-fieldset {
  &:hover {
    input[type='checkbox'] {
      &:not(:disabled) {
        + .nui-label {
          &:before {
            border-color: var(--checkbox);
          }
        }
      }
    }
  }
}

// prefix
.nui-fieldset {
  .input-holder.prefixed {
    position: relative;

    span.prefix {
      border-right: 1px solid $lightest-gray;
      color: $light-gray;
      display: block;
      font-size: 0.9rem;
      height: 32px;
      left: 1px;
      line-height: 32px;
      position: absolute;
      text-align: center;
      top: 5px;
      width: 50px;
      z-index: 2;

      // updated, needs checking
      @extend %bold;
      background: inherit;
      color: $all-black;
      font-size: 12px;
      line-height: 34px;
      margin: 0 0 0 5px;
      padding: 0 10px 0 5px;
      width: auto;
      text-transform: uppercase;
      text-align: center;

      [class^='icon-']:before {
        color: $light-gray;
        font-size: 1.2rem;
        margin: 10px 0 0;
      }
    }
    input {
      padding-left: 70px;
    }
  }
}

// error styling

.nui-fieldset {
  &.has-error {
    input:not([type='radio']),
    input:not([type='checkbox']),
    input:not([type='submit']),
    input:not([type='reset']),
    input:not([type='button']),
    input.inputfile + .dummy,
    select,
    textarea {
      border-color: $error;
    }
  }

  .error-list {
    ul {
      border: 0;
      display: block;
      margin: 5px 0;
      position: relative;
      white-space: normal;
      width: auto;

      &:before {
        border-bottom: 5px solid $error;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        content: ' ';
        display: inline-block;
        height: 0;
        left: 0;
        margin: 0 5px 0 0;
        position: absolute;
        top: 10px;
        vertical-align: top;
        width: 0;
      }
      li {
        border: 0;
        color: $error;
        line-height: 1.2rem;
        padding: 2px 0 2px 15px;
        position: relative;
      }
    }
  }
}

// search
.search-fieldset {
  .nui-fieldset {
    .input-holder {
      position: relative;

      &:before {
        @extend %fontello;
        color: $lightest-gray;
        content: '\e815';
        font-size: 1.2rem;
        height: 40px;
        line-height: 40px;
        margin: 0;
        position: absolute;
        right: 5px;
        text-align: center;
        top: 2px;
        width: 30px;
      }

      input:not([type='radio']),
      input:not([type='checkbox']),
      input:not([type='submit']),
      input:not([type='reset']),
      input:not([type='button']) {
        padding-right: 40px;
      }
    }
  }
}

.nui-row.search-customer {
  margin: 10px 0 0;

  .search-fieldset {
    margin-left: 14px;
  }
  @include min-screen($min-medium) {
    left: 200px;
    position: absolute;
    top: 9px;

    .nui-fieldset {
      width: 320px;
    }
  }
}
