// post trade
.post-trade {
  a,
  button {
    span.icon-link-ext-alt {
      @extend %nd;
      color: darken($lightest-gray, 5%);
      display: inline-block;
      font-size: 0.8rem;
      margin: 0 4px;
      vertical-align: middle;

      &:before {
        margin: 0;
      }
    }
    &:hover {
      span.icon-link-ext-alt {
        color: var(--link);
      }
    }
  }

  .order-actions {
    &.trade-actions {
      margin: 0;
      padding-top: 10px;

      h2 {
        @extend %light;
        display: inline-block;
        margin: 0;
        padding: 5px 0 0;
      }
      .status {
        display: inline-block;
        margin: 0 0 0 20px;
        top: -55px;
        vertical-align: top;

        @include min-screen($min-medium) {
          float: right;
          margin: 0 20px 0 0;
          top: auto;

          span {
            position: relative;
            top: -2px;
          }
        }
      }
      .button-set {
        float: right;
        margin-top: -30px;
        text-align: right;
        width: 100%;

        @include min-screen($min-medium) {
          margin: 0;
          width: auto;
        }
      }

      // columns
      .ptcol1,
      .ptcol2 {
        @include min-screen($min-medium) {
          display: block;
          float: left;
          vertical-align: top;
          width: 50%;
        }
      }

      .ptcol1 {
        @include min-screen($min-large) {
          width: 41.66666667%;
        }
      }

      .ptcol2 {
        @include min-screen($min-large) {
          width: 58.33333333%;
        }
      }
    }
  }

  .trade-type {
    display: inline-block;
    vertical-align: middle;
  }
  .post-trade-details {
    min-height: 200px;
    position: relative;

    @include min-screen($min-medium) {
      background: linear-gradient(
        to right,
        #f2f2f2 0%,
        #f2f2f2 50%,
        transparent 50%,
        transparent 100%
      );
      margin: 0;

      &:before {
        box-shadow: -1px 0 0 $lightest-gray;
        content: ' ';
        display: block;
        height: 100%;
        left: 50%;
        position: absolute;
        width: 1px;
        z-index: 4;
      }
    }

    @include min-screen($min-large) {
      background: linear-gradient(
        to right,
        #f2f2f2 0%,
        #f2f2f2 41.66666667%,
        transparent 41.66666667%,
        transparent 100%
      );
      &:before {
        left: 41.66666667%;
      }
      &:after {
        box-shadow: -1px 0 0 $lightest-gray;
        content: ' ';
        display: block;
        height: calc(100% - 30px);
        position: absolute;
        right: calc(29.166666665% + 19px);
        top: 15px;
        width: 1px;
        z-index: 4;
      }
    }

    // left column {
    .trade-details-section {
      @include min-screen($min-medium) {
        display: inline-block;
        vertical-align: top;
        width: 50%;
      }
      @include min-screen($min-large) {
        width: calc(41.66666667% - 7px);
      }
      .content {
        padding: 10px 0;
        @include min-screen($min-medium) {
          padding: 10px 20px 10px 0;
        }
      }

      ul.trade-details-list {
        border-bottom: 1px solid $hr;
        list-style: none;
        margin: 0 0 10px;
        padding: 0 0 10px;

        &:last-of-type {
          border-bottom: 0;
        }
        &:last-of-type + div {
          border-top: 1px solid $hr;
          padding-top: 10px;
        }
        &.type-list {
          border-bottom: 0;
          margin: 0;
          padding: 0;
        }
        li {
          padding: 0 0 2px;

          strong {
            color: $all-black;
            display: inline-block;
            line-height: 1.3rem;
            vertical-align: top;
            width: 200px;
          }
          span {
            display: inline-block;
            line-height: 1.3rem;
            vertical-align: top;

            // should not be displayed in here at all but it does, so... :/
            p {
              padding: 0;
            }
          }
        }
      }
    }

    ul.trade-details-list {
      &.partners-list {
        border-bottom: 1px solid $hr;

        &:last-of-type {
          border-bottom: 1px solid $hr;
        }
      }
    }

    .post-trade-lists {
      div:last-of-type {
        ul.trade-details-list {
          border-bottom: 0;
        }
      }
    }

    // right column
    .post-trade-section {
      @include min-screen($min-medium) {
        position: sticky;
        position: -webkit-sticky;
        top: 80px;

        // update
        display: inline-block;
        vertical-align: top;
        width: 50%;
      }
      @include min-screen($min-large) {
        width: calc(58.33333333% + 7px);
      }
      .post-trade-actions,
      .post-trade-history {
        max-width: 100%;

        @include min-screen($min-medium) {
          display: inline-block;
          margin: 20px 0;
          vertical-align: top;

          .content {
            padding: 0 20px;
          }
        }
        @include min-screen($min-large) {
          width: 50%;
        }
      }

      .post-trade-actions,
      .post-trade-history {
        @include min-screen($min-large) {
          position: sticky;
          position: -webkit-sticky;
          top: 100px;
        }
      }

      .file-link {
        &:before {
          display: inline-block;
          margin: 6px 0 0;
          text-align: left;
          vertical-align: top;
          width: 20px;
        }
        button {
          display: inline-block;
          text-align: left;
          vertical-align: top;
          width: calc(100% - 20px);
        }
      }

      // action history column
      .post-trade-history {
        margin-top: 20px;
        padding-bottom: 20px;

        @include min-screen($min-medium) {
          margin-top: 0;
        }
        @include min-screen($min-large) {
          margin-left: -1px;
          margin-top: 20px;
        }
      }

      // accordion styles
      .accordion {
        list-style: none;
        margin: 0;
        padding: 0;

        .post-trade-form:has(.pallets) {
          .ant-row {
            align-items: normal;
            display: flex !important;
            flex-direction: row-reverse;
            justify-content: flex-end;

            & > * {
              flex-basis: auto;
            }
            .ant-row.ant-form-item.form-item-type-Checkbox {
              flex-shrink: 0;
            }
            .ant-form-item-control-wrapper {
              width: auto;
            }
            .ant-form-item-label {
              margin-left: 10px;
            }
            .ant-checkbox-wrapper .ant-checkbox {
              vertical-align: baseline;
            }
          }
        }

        .accordion-item {
          margin: -1px 0 0;

          .info-form-area {
            hr {
              display: none;
            }

            .nui-fieldset,
            .nui-fieldset:hover {
              label {
                line-height: 1.4rem;
              }
              label:after {
                display: none;
              }
              &.has-errors {
                .input-holder input[type='file'].inputfile + .dummy,
                .document-list-box .file-list {
                  border-color: $error;
                }
                ul.error-list {
                  color: $error;
                  display: block;
                  list-style: none;
                  margin: 0;
                  padding: 5px 0 0 15px;
                  position: relative;

                  li {
                    line-height: 1.4rem;
                  }
                  &:before {
                    border-bottom: 5px solid $error;
                    border-left: 4px solid transparent;
                    border-right: 4px solid transparent;
                    content: ' ';
                    display: inline-block;
                    height: 0;
                    left: 0;
                    margin: 0 5px 0 0;
                    position: absolute;
                    top: 13px;
                    vertical-align: middle;
                    width: 0;
                  }
                }
              }
            }
          }
          textarea {
            min-height: 80px !important;
          }

          .form-item-name-comment {
            margin-top: 15px;
          }

          .upload-attachments .nui-fieldset {
            padding-bottom: 0;

            label {
              padding-right: 0;
            }

            ul.file-list li {
              padding-bottom: 0;
              padding-top: 0;

              .nui-button {
                margin: 0;
              }
            }
          }

          .nui-button {
            margin-bottom: 5px;
            top: 4px;
          }

          // change this again when we remove Ant!
          .ant-form-item,
          .ant-form-item-control-wrapper {
            display: block !important;
            width: 100%;
          }

          .form-item-name-accept {
            padding: 0;

            span.ant-radio + span {
              margin-right: 10px;
            }
          }

          .ant-radio-group {
            label {
              margin-right: 0;
              padding: 0 0 8px;
            }
            span.ant-radio {
              left: 0;
              position: absolute;
              top: 3px;
            }

            span.ant-radio + span {
              display: inline-block;
              padding-left: 30px;
              white-space: normal;
            }
          }

          h4 {
            border-bottom: 1px solid $hr;
            border-top: 1px solid $hr;
            cursor: pointer;
            font-size: 0.9rem;
            margin: -1px 0 0;
            padding: 8px 15px 8px 40px;
            position: relative;
            transition: 0.2s all ease;
            z-index: 2;

            @include max-screen($max-small) {
              padding: 8px 0 8px 25px;
            }
            &:hover {
              background: rgba(255, 255, 255, 0.5);
              border-color: darken($hr, 5%);
              z-index: 3;
            }
            &:before {
              @extend %fontello;
              color: $light-gray;
              content: '\f105';
              font-size: 1.1rem;
              left: 16px;
              position: absolute;
              top: 12px;
              transition: 0.2s all ease;

              @include max-screen($max-small) {
                left: 0;
              }
            }
          }

          h4 > span.item {
            @extend %regular;
            color: $light-gray;

            a span.icon-link-ext-alt:before {
              margin-right: 0;
            }
          }

          h4 > span.item-status {
            span {
              font-size: 0.7rem;
              text-transform: uppercase;

              &.pending,
              &.received,
              &.provided,
              &.requested {
                color: $warning;
              }
              &.accepted {
                color: $ok;
              }
              &.declined {
                color: $error;
              }
            }
          }

          .view-files {
            @extend %regular;

            span.icon-link-ext-alt {
              color: #bfbfbf;
              display: inline-block;
              font-size: 0.8rem;
              margin: 0 4px;
              vertical-align: middle;
            }
            &:hover {
              span.icon-link-ext-alt {
                color: var(--link);
              }
            }
          }
          .details {
            background: #eef1f8;
            border-left: 1px solid $hr;
            border-right: 1px solid $hr;
            height: 0;
            overflow: hidden;
            padding: 0 20px;

            div.icon-info-circled {
              padding-left: 25px;
              position: relative;

              &:before {
                color: var(--link);
                display: inline-block;
                font-size: 1rem;
                left: 0;
                margin: 0;
                position: absolute;
                top: 3px;
              }
            }

            @include min-screen($min-medium) {
              border-left: 0;
            }
            @include min-screen($min-large) {
              border-right: 0;
            }
            h5 {
              font-size: 1rem;
              margin-bottom: 10px;
            }
          }
          &.open {
            h4 {
              &:before {
                color: $dark-gray;
                left: 14px;
                transform: rotate(90deg);

                @include max-screen($max-small) {
                  left: 0;
                }
              }
            }
            .details {
              border-bottom: 1px solid $hr;
              height: auto;
              overflow: unset;
              padding: 15px 20px;
            }
          }
        }
      }

      // action history
      ul.action-history {
        list-style: none;
        margin: 0;
        padding: 0;

        > li {
          border-bottom: 1px solid $hr;
          margin-bottom: 10px;
          padding-bottom: 10px;

          h4 {
            font-size: 0.9rem;
            line-height: 1.25rem;
            margin: 10px 0;
            padding-right: 120px;
            position: relative;

            .date {
              @extend %regular;
              color: $light-gray;
              display: block;
              font-size: 0.8rem;
              position: absolute;
              right: 0;
              text-align: right;
              top: 0;
            }
          }
          ul {
            list-style: none;
            margin: 0;
            padding: 0;
          }
          &:last-child {
            border-bottom: 0;
            margin-bottom: 0;
          }

          // comment box
          .comment-box {
            line-height: 1.25rem;
            padding-left: 30px;
            position: relative;

            p {
              padding-bottom: 0;
            }
            strong {
              color: $all-black;
            }
            &:before {
              @extend %fontello;
              color: $lightest-gray;
              content: '\f2bd';
              font-size: 1.35rem;
              left: 0;
              margin: 0;
              position: absolute;
              top: 0;
            }
            blockquote {
              background: #ecf8e4;
              border: 1px solid #a0ca7a;
              border-radius: 3px;
              font-size: 0.9rem;
              margin: 5px 0;
              padding: 5px 5px 5px 32px;
              position: relative;

              &:before {
                @extend %fontello;
                color: rgba(0, 0, 0, 0.2);
                content: '\e84d';
                font-size: 1rem;
                left: 5px;
                margin: 0;
                position: absolute;
                top: 5px;
              }
            }
          }

          .comment-box {
            &.theirs {
              padding-left: 0;
              padding-right: 30px;

              &:before {
                left: auto;
                right: 0;
              }
              blockquote {
                background: #ebf7ff;
                border: 1px solid #b9cfdd;
              }
            }
            &.submission {
              padding-left: 0;
              padding-right: 0;

              &:before {
                display: none;
              }
              blockquote {
                background: $alert-warning-bg;
                border-color: $alert-warning-color;

                &:before {
                  color: $light-gray;
                  content: '\e804';
                  top: 6px;
                }
              }
            }
          }
        }
      }
    }
  }
}

// dev mode
.dev-mode {
  .post-trade {
    .post-trade-details {
      .post-trade-section {
        @include min-screen($min-medium) {
          top: 100px;
        }
        @include min-screen($min-large) {
          .post-trade-actions,
          .post-trade-history {
            top: 120px;
          }
        }
      }
    }
  }
}

// overrides
.post-trade-lists {
  h2 {
    color: $all-black;
    font-size: 1.15rem;
    margin: 0 0 10px;
  }
}

.post-trade > .nui-row {
  hr {
    margin: 10px 0 0;

    @include min-screen($min-medium) {
      margin: 0;
    }
  }
}

// trade values
.last-trade,
.post-trade {
  .trade-values {
    border-bottom: 1px solid $hr;
    margin-bottom: 10px;
    padding: 0 0 10px;

    .unit-detail {
      @extend %bold;
      color: $all-black;
      display: inline-block;
      font-size: 1.2rem;
      margin: 0;
      min-width: 25%;
      padding: 5px 0;
      vertical-align: top;

      .tbd {
        @extend %bold;
        color: $all-black;
        display: inline-block;
        font-size: 1.2rem;
        padding: 3px 0;
        vertical-align: top;
      }
      .tbd + .show-help {
        position: relative;
        top: 2px;
      }
      h4 {
        @extend %regular;
        color: $warning;
        font-size: 0.7rem;
        margin: 0;
        padding: 0;
      }
      span.unit,
      span.cur-unit,
      span.index-unit,
      span.index-name {
        @extend %regular;
        color: $light-gray;
        display: inline-block;
        font-size: 0.7rem;
        position: relative;
        top: 0;
      }
      span.index-name {
        display: block;
        line-height: 1rem;
      }
      .qrts {
        @extend %regular;
        border-top: 1px solid $hr;
        color: $light-gray;
        display: block;
        font-size: 0.7rem;
        margin: 5px 10px 0 0;
        padding: 2px 0;
        text-align: left;
      }
    }

    .unit-detail:nth-last-child(3):first-child,
    .unit-detail:nth-last-child(3):first-child ~ .unit-detail,
    .unit-detail:nth-last-child(5):first-child,
    .unit-detail:nth-last-child(5):first-child ~ .unit-detail,
    .unit-detail:nth-last-child(6):first-child,
    .unit-detail:nth-last-child(6):first-child ~ .unit-detail {
      width: 33%;
    }

    .unit-detail:nth-last-child(4):first-child,
    .unit-detail:nth-last-child(4):first-child ~ .unit-detail {
      width: 25%;
    }

    // value
    .unit-detail.value {
      font-size: 0.9rem;
      h4 {
        font-size: 0.7rem;
      }
      span.unit {
        font-size: 0.7rem;
      }

      span {
        strong.tbd {
          font-size: 0.9rem;
        }
      }
    }
  }
}

// files in modal
.files-ours,
.files-theirs {
  .post-trade-files {
    li {
      padding-right: 180px;
      position: relative;

      > a {
        display: inline-block;
        padding-left: 20px;
        position: relative;
        word-break: break-all;

        &:before {
          left: 0;
          top: 4px;
          position: absolute;
        }
      }
    }
    span.date {
      color: $light-gray;
      display: block;
      font-size: 0.8rem;
      position: absolute;
      right: 0;
      top: 2px;
    }
  }
}
