html {
  button,
  .button,
  a.button,
  span.button,
  button.button,
  button.expand,
  button.mr-10,
  input.button,
  a.new-tender,
  a.new-request,
  a.place-bid,
  a.place-offer,
  button.nui-button.nui-offer,
  .marketplace .last a,
  .sort ul li a,
  .totals a.show-totals,
  .current a.bid,
  .current a.open-bid,
  .current a.own-bid,
  .display-popout .icon-ellipsis,
  .tender2-action-box a.nui-button,
  div.action-list.tender-actions.button.nui-button.nui-secondary.has-dd,
  .search-fieldset .nui-fieldset .input-holder.search-holder input,
  .ant-table-row-expand-icon,
  .col-details-small span.nui-table-row-expand-icon,
  a.show-dd-items,
  .dds a.show-dd-items,
  .order-actions .actions .button-set a.nui-button.icon-pencil,
  .edit-counter {
    @extend %bold;
    border-radius: 100px !important;
  }

  .status {
    &-viewonly,
    &-tradeable,
    &-nottradeable,
    &-sfctradeable,
    &-traded {
      border-radius: 100px !important;
    }
  }

  .generic-table
    .ant-table-content
    .ant-table-body
    table
    tr
    td
    .ant-table-row-expand-icon,
  .col-details small .span.nui-table-row-expand-icon,
  button.nui-row-toggle {
    padding: 0 10px 0 20px !important;
  }

  .col-details-small {
    .nui-table-row-expand-icon.nui-table-row {
      &-expanded,
      &-collapsed {
        padding: 0 10px 0 20px !important;
        right: 4px !important;
      }
    }
  }

  // Reset font weight
  button.nui-row-toggle,
  button.nui-button.showoptions,
  button.signout,
  button.nui-button.expand,
  .solution-picker span.solution-name,
  .search-fieldset .nui-fieldset .input-holder.search-holder input,
  .ant-table-row-expand-icon,
  .col-details-small span.nui-table-row-expand-icon {
    @extend %regular;
  }

  button.presets-dd,
  button.nui-button.nui-button-icon,
  span.icon-floppy.button.tiny.secondary {
    border-radius: 4px !important;
  }

  .presets .presets-dd::after {
    top: 17.5px;
  }

  button.nui-button-link {
    border-radius: 0 !important;
  }

  // More padding on rounded buttons
  .nui-row .nui-alert.nui-alert-info.has-icon button.nui-button,
  .tender2-details .tender2-action-box a.nui-button {
    padding: 8px 24px !important;
  }

  .navbar .navigation button.show-more {
    padding: 0 5px;
  }

  .nui-table-content .nui-table-body {
    .col-details {
      padding: 0 !important;

      .nui-table {
        &-row-expand-icon,
        &-row-collapsed {
          border-radius: 100px;
        }
      }
    }
  }

  span.ant-form-item-children {
    button.nui-button:not(.nui-small) {
      min-width: 100px;
      width: auto;
    }
  }

  .view-options,
  .table-display-options {
    button.nui-button {
      margin-right: 2px;
    }
  }
}

.col-product-price-alt {
  span.openordertype {
    margin: 2px 0;
    min-width: 90px;
    padding: 0 10px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content !important;
  }
}

.col-product-type-alt {
  .td-content {
    .col-product-type {
      span:not(.broker, .private, .private-group, .private-icon) {
        margin: 2px 0;
        min-width: 90px;
        padding: 0 10px;
        position: relative;
        text-align: center;
        white-space: nowrap;
        width: fit-content !important;
      }
      span {
        &.private,
        &.private-group {
          min-width: 90px !important;
          width: fit-content !important;
        }
      }
      span.icon-help-circled {
        position: relative;

        &::before {
          margin: 0 0 12px -7px !important;
        }
      }
    }
  }
}

.col-status {
  .td-content {
    span {
      min-width: 90px;
      padding: 0 10px;
    }
  }
}

.nui-tabs-nav {
  .nui-tabs-nav-list {
    span.notification {
      line-height: normal !important;
      min-width: 22px;
      text-align: center;
    }
  }
}

span.openordertype {
  min-width: 90px !important;
  text-align: center;
}

.col-trade-status {
  .td-content {
    span {
      display: inline-block;
      font-size: 9.6px;
      line-height: 1.1;
      min-width: 90px !important;
      max-width: max-content;
      position: relative;
      text-align: center;
      text-transform: uppercase;
      top: 1.2px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

span.formstatus {
  min-width: 95px !important;
}

.tender2-status {
  span.status {
    height: 22px !important;
    line-height: 21px !important;
    min-width: 90px;
    padding: 0 10px;
  }
}

.market {
  .nui-table .nui-table-content .nui-table-body .col-details {
    button,
    a,
    span:not(.nui-table-row-indent.indent-level-0) {
      padding: 0 10px 0 20px;
      top: 10px;
    }
  }
}

.ant-table-content .ant-table-body table .ant-table-tbody {
  .ant-table-row.ant-table-row-level-0 {
    td.expand-text {
      .td-content {
        div {
          padding: 0 10px 0 20px;
        }
      }
    }
  }
}

.marketplace.timeline.show {
  &-in-weeks,
  &-in-months,
  &-in-days {
    a.offer.ghost,
    a.type.bid,
    a.type.ghost {
      border-radius: 100px;
    }
    .time-content,
    .view-options {
      .toggle-volume {
        border-radius: 100px;
        padding: 3px;
      }
    }
  }
  a.type.bid,
  a.type.open-bid,
  a.type.own-offer,
  span.monthly-bids {
    border-radius: 100px;
  }
}

.marketplace.timeline {
  .time-content {
    .tbody-container {
      table {
        .table-data {
          td:not(.no-trades) {
            .current {
              .offers > a,
              .bids > a {
                min-width: 100px;
              }
            }
            .last > a {
              min-width: 100px;
            }
          }
        }
      }
    }
  }
}

.rolodex {
  .rolodex-column.rolodex-wheel {
    .display-popout.rolodex-popout.boxed {
      border-radius: 100px;
    }
    button.nui-button.nui-button.expand {
      padding: 0 10px 0 20px;
      text-overflow: clip;

      #text {
        @extend %regular;
      }
      @include max-screen($max-medium) {
        padding: 0 10px;

        .arrow:before {
          left: 8px;
        }
      }
    }
  }
  a.nui-button,
  .add-counter-bid.nui-button.nui-reverse.nui-beveled {
    border-radius: 100px;
  }
}

.filter-export + .has-dd-holder-reverse.cancel-all {
  border-radius: 100px;
  margin-left: 8px;

  .has-dd + ul {
    background-color: $white;
    border-radius: 4px;
    border: 1px solid $light-gray;
    box-shadow: 2px 2px 4px rgb(0 0 0 / 10%);
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: 45px;
    width: auto !important;

    :hover,
    li:hover {
      background-color: $light-panel;
      transition: none;
    }
    li + li {
      border-top: 1px solid $lightest-gray;
    }
  }
}

.table-data
  th
  .product-col-width.no-ws.product
  .product-info.pt-5
  .actions
  .legend-list {
  li + a {
    border-radius: 100px;
  }
}

.ant-spin-nested-loading .ant-spin-container {
  .ant-pagination.ant-table-pagination.mini {
    li:not(.ant-pagination-total-text),
    a {
      border-radius: 100px;
      padding-top: 1px;
    }
    li.ant-pagination-options {
      .ant-select-dropdown.ant-select-dropdown {
        div ul {
          li.ant-select-dropdown-menu-item {
            border-radius: 0;
          }
        }
        .ant-select-selection {
          border-radius: 100px;
          padding: 2px 0 0 2px;
        }
      }
      .ant-select-sm.ant-pagination-options-size-changer.ant-select.ant-select-enabled
        .ant-select-selection.ant-select-selection--single {
        border-radius: 100px;
        padding: 2px 0 0 0;
      }
    }
  }
  .ant-table-row.ant-table-row-level-0 {
    .col-country-code .td-content .country-code span {
      position: relative;
      top: 2px;
    }
  }
}

.tender-history-footer {
  li.ant-pagination-options {
    .ant-select-dropdown.ant-select-dropdown {
      div ul {
        li.ant-select-dropdown-menu-item {
          border-radius: 0;
        }
      }
    }
  }
}

.sticky-nav .navigation .main {
  ul {
    li.navsmall {
      a.admin {
        border-radius: 100px;
      }
    }
  }
}

.users-table {
  .generic-table.light.mt-0
    .nui-table.table-wrapper.th-sticky
    div
    .nui-table.generic-table.nui-table.nui-table2.table.light.expand-table
    .nui-table.nui-table-scroll-position-left
    .nui-table-content
    .nui-table-body
    table
    tr.nui-table-row.nui-table-row-level-0
    td.column-details.col-details
    .td-content {
    button.nui-row {
      &-toggle,
      &-toggle.open {
        padding: 0 10px 0 20px;
      }
    }
  }
}

.tender-history-footer {
  .inline-block .ant-pagination {
    li {
      border-radius: 100px;
    }
    li.ant-pagination {
      &-item {
        padding-top: 2px;
      }
      &-prev,
      &-next {
        padding-top: 1px;
      }
      &-options {
        .ant-select-selection {
          border-radius: 100px;
          padding: 2px 0 0 2px;
        }
      }
    }
  }
}

// Deliveries page
ul.ant-pagination {
  li.ant-pagination {
    &-disabled,
    &-item,
    &-next {
      border-radius: 100px !important;
    }
  }
  li.ant-pagination {
    &-item {
      padding-top: 2px;
    }
    &-next {
      padding-top: 1px;
    }
    &-options {
      .ant-select-selection {
        border-radius: 100px;
        padding: 2px 0 0 2px;
      }
    }
  }
}

.ant-table-content .ant-table-body {
  table .ant-table-tbody {
    .ant-table-row.ant-table-row-level-0 {
      .col-type {
        .td-content {
          span {
            margin: 2px 0;
            min-width: 90px;
            padding: 0 10px;
            width: auto !important;
          }
        }
      }
    }
  }
}

.bid-message a.place-bid,
a.place-offer {
  font-size: 0.65rem !important;
  padding: 0 12px !important;
}

.bid-message {
  .nowrap {
    a {
      line-height: 26px !important;
    }
  }
}

a.show-dd-items,
.dds a.show-dd-items {
  display: inline-block;
  font-size: 0.8rem;
  height: 2.2rem;
  margin: 0 0 5px;
  padding: auto !important;
  padding-left: 20px !important;
  position: relative;
  text-align: center;
  top: -2px !important;
  width: auto !important;
}

.show-filter-section {
  padding-right: 30px !important;
}

.market {
  .inline-block {
    width: auto !important;

    button {
      line-height: 19.2px !important;
      height: 35.2px !important;
    }
  }
}

.ant-dropdown.ant-dropdown-placement-bottomLeft {
  box-shadow: rgba(0, 0, 0, 0.1) 2px 2px 4px 0 !important;
  border-radius: 4px !important;

  ul.ant-dropdown-menu {
    background-color: $white;
    border-radius: 4px;
    box-sizing: border-box;
    border: 1px solid #727e82;
    list-style: none;
    margin: 0;
    overflow: hidden;
    padding: 0;
    text-align: left;
    white-space: nowrap;

    li {
      border-bottom: 1px solid $lightest-gray;

      &:hover {
        color: $all-black;
      }
      &:only-child,
      &:last-child {
        border-bottom: none !important;
      }
    }
  }
}

.tender-item {
  .tender-item-content {
    .action-list.tender-actions.button.nui-button.nui-secondary.has-dd {
      width: 120px;
    }
    .action-list {
      &.visible {
        &.tender-actions {
          &:hover {
            ul.actions-list-items {
              background-color: $white !important;
              box-shadow: none !important;
            }
          }
          ul.actions-list-items {
            background-color: $white !important;
            border-radius: 4px !important;
            box-sizing: border-box;
            border: 1px solid #727e82 !important;
            color: $white !important;
            display: block !important;
            left: -70px !important;
            list-style: none !important;
            margin: 0 !important;
            min-width: 100% !important;
            overflow: hidden;
            position: absolute !important;
            text-align: left !important;
            top: 45px !important;
            width: 190px !important;
            white-space: nowrap !important;
            z-index: 10 !important;

            &:hover {
              box-shadow: none !important;
            }

            > li {
              a,
              button {
                @extend %regular;
                border-radius: 0 !important;
                border-bottom: 1px solid $lightest-gray !important;
                color: var(--link) !important;
                display: block !important;
                padding: 5px 10px;

                &:hover {
                  background: #f6f6f6 !important;
                  box-shadow: none !important;
                  color: $all-black !important;
                }
              }
              &:only-child,
              &:last-child {
                a,
                button {
                  border-bottom: none !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

.nui-modal {
  .nui-modal-content {
    .button-set {
      .nui {
        &-primary,
        &-secondary {
          @extend %bold;
          border-radius: 100px;
        }
      }
    }
  }
}
