﻿// nui accordion
.nui-accordion {
  &.shipment-accordion {
    .accordion {
      margin-bottom: 8px;
      .accordion-header {
        position: relative;
        .company-header {
          background: $white;
          border-bottom: 1px solid $hr;
          border-top: 1px solid $hr;
          padding: 10px 20px 10px 65px;

          @include min-screen($min-medium) {
            padding: 10px 40px 10px 85px;
          }
        }
      }
      .accordion-content {
        &.open {
          background: #eef1f8;
          border-bottom: 1px solid $hr;

          .nui-table-body {
            max-width: 100vw;
            overflow-x: auto;
          }
        }
      }
    }
  }
}
