$nui-quill-dark: $light-gray;
$nui-quill-light: $lightest-gray;

@mixin nui-input-border() {
  border: solid 1px $lightest-gray;
  border-radius: 3px;
}

@mixin nui-quill-overrides($light: $lightest-gray, $dark: $dark-gray) {
  $gap: 4px;
  $border-radius: 4px;

  .ql-picker-label {
    padding: 0 3px;
    margin: 0;
  }
  .ql-picker {
    .ql-picker-label {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .ql-picker-label::before {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .ql-toolbar.ql-snow {
    display: flex;
    gap: $gap;
    flex-wrap: wrap;

    & > .ql-formats {
      display: flex;
      padding: 0;
      margin: 0;
    }
  }
  .nui-richtext {
    background: $white;
    padding-bottom: 16px;
    @include nui-input-border();
    outline: none;

    &.has-focus {
      border-color: $dark;
    }

    .quill {
      .ql-toolbar,
      .ql-container {
        border: none;
      }
      .ql-header {
        .ql-picker-options {
          max-height: 50vh;
          overflow-y: auto;
        }
      }
      .ql-editor {
        padding-top: 0;
        font-size: 16px;
      }
    }
    .ql-tooltip.ql-editing {
      position: fixed;
      padding: 0 8px;
      margin: 0;

      @include nui-input-border();
      input[type='text'] {
        @include nui-input-border();
      }
    }
    &:not(.has-selection) {
      .ql-link,
      .ql-clean {
        pointer-events: none;
        opacity: 0.5;
      }
    }
    .nui-quill-clear-format-text {
      display: none;
    }
  }
}
