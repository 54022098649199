// nui modal
.nui-modal-overlay {
  background: rgba(0, 0, 0, 0.65);
  height: 100%;
  left: 0;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1002;

  @include min-screen($min-medium) {
    -webkit-align-items: center;
    align-items: center;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
  }

  div.tc {
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
  }
}

.nui-modal {
  background: $modal-bg;
  transition: all 0.2s linear;
  pointer-events: auto;

  button.modal-close {
    background: none;
    border: 0;
    box-shadow: none;
    color: $light-gray;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 2rem;
    font-weight: 300;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 20px;
    text-align: center;
    text-decoration: none;
    top: 20px;
    transition: 0.2s all linear;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    z-index: 1001;

    &:active,
    &:focus,
    &:hover {
      background: none;
      box-shadow: none;
      color: $all-black;
      transform: rotate(135deg) scale(1.2, 1.2);
      -webkit-transform: rotate(135deg) scale(1.2, 1.2);
      transition: transform 0.6s ease;
      -webkit-transition: -webkit-transform 0.6s ease;
    }
  }

  @include max-screen($max-xsmall) {
    height: 100vh;
    left: 0;
    overflow: hidden;
    overflow-y: auto;
    position: fixed;
    top: 0;
    width: 100vw;
  }

  @include min-screen($min-small) {
    border-radius: 3px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
    margin: 20px;
  }
  &.small {
    @include min-screen($min-medium) {
      width: 480px;
    }
  }
  &.medium {
    max-height: calc(100% - 40px);
    overflow-y: auto;

    @include min-screen($min-medium) {
      width: 680px;
    }
  }
  &.large {
    max-height: calc(100% - 40px);
    overflow-y: auto;

    @include min-screen($min-medium) {
      width: 768px;
    }
    @include min-screen($min-large) {
      width: 1024px;
    }
  }

  &.xlarge,
  &.full {
    border-radius: 3px;
    height: auto;
    left: 0;
    margin: 10px;
    position: absolute;
    top: 0;
    transition: none;
    width: calc(100% - 20px);

    table {
      .button-set {
        border-top: 0;
      }
    }
    &.full-height {
      height: calc(100% - 20px);
      position: fixed;
    }
  }

  &.xlarge {
    @include min-screen($min-medium) {
      left: 5%;
      margin: 20px 0;
      width: 90%;
    }
  }
}

.nui-modal-content {
  font-size: 0.9rem;
  padding: 20px;
  position: relative;
  transition: all 0.2s linear;

  .button-set {
    white-space: normal;

    .nui-button {
      display: inline-block;
      margin: 0 10px 0 0;
      width: auto;
    }
  }
  h2 {
    @extend %light;
    font-size: 1.5rem;
    line-height: 1.75rem;
    margin: 0 0 10px;

    @include min-screen($min-medium) {
      font-size: 1.75rem;
      line-height: 2rem;
      &.smaller {
        color: $dark-gray;
        font-size: 1.5rem;
      }
    }
  }

  p:last-of-type {
    padding-bottom: 0;
  }
}

footer.modal-footer {
  background: none;
  border: 0;
}

.nui-modal {
  .nui-form {
    button.nui-button {
      margin: 0 10px 0 0;
    }
  }
}

// slick slideshow in modal
#slick-root {
  position: relative;
  z-index: 999999;
}

#slick-root .nui-modal-overlay {
  background: none;

  .close-gallery {
    background: none;
    box-shadow: none !important;
    padding: 0;
    position: fixed;
    right: 40px;
    top: 20px;
    z-index: 10;

    span {
      color: $white;
      display: block;
      font-size: 1.35rem;
      margin: 0;
    }
  }

  .gallery-wrapper {
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
    width: 100vw;

    .slick-prev,
    .slick-next {
      background: none;
      border: 0;
      display: block !important;
      font-size: 0;
      left: 10px;
      line-height: 100px;
      margin-top: -55px;
      position: fixed;
      top: 50%;
      z-index: 10;

      &:before {
        @extend %fontello;
        color: $white;
        content: '\f104';
        font-size: 2.5rem;
        margin: 0;
      }
      &.slick-disabled {
        cursor: default;
        opacity: 0.25;
      }
    }
    .slick-next {
      left: auto;
      right: 10px;

      &:before {
        content: '\f105';
      }
    }
    .slick-slider {
      background: rgba(0, 0, 0, 0.65);
      height: 100vh;
      max-height: 100vh;

      .slick-slide {
        align-items: center;
        display: inline-flex;
        height: 100vh;
        justify-content: center;
      }
      .slick-slide img {
        display: block;
        margin: auto;
        max-width: 500px !important;
        transition: 0.3s all ease;
      }
    }
  }
}
